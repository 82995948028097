import React from "react";
import { Link } from "react-router-dom";

// CLASSES
import Settings from "classes/Settings";

// COMPONENTS
import Button from "components/Button/Button";
import ContentBox from "components/ContentBox/ContentBox";
import Form from "components/Form/Form";
import TextField from "components/TextField/TextField";
import StackLayout from "components/layouts/StackLayout/StackLayout";

// HELPERS
import { connectRedux } from "helpers/utils";
import i18n from "helpers/i18n";
import api from "helpers/api";

// REDUCERS
import { actions as appActions } from "reducers/app";


export default connectRedux(state => ({
    app: state.app,
}), {
    setUser: appActions.setUser,
}, class Login extends React.Component {
    state = {
        loggingIn: false,
        wrongCredentials: false,
        credentials: {
            email: "",
            password: "",
        },
        loginmessage: ''
    }

    render() {
        const { state } = this;
        const { loggingIn, credentials } = state;

        return (
            <div className="Login">
                <ContentBox className="Login-box" title={ i18n("login", "sign_in") }>
                    <Form onSubmit={ this._onLogin } autoComplete={ false } submitOnEnter>
                        <TextField
                            name="email"
                            label={ i18n("login", "email") }
                            value={ credentials.email }
                            onChange={ this._onEmailChange }
                        />
                        <TextField
                            type="password"
                            name="password"
                            label={ i18n("login", "password") }
                            value={ credentials.password }
                            onChange={ this._onPasswordChange }
                        />

                        {/* <div style={{ flexDirection: "row" }}>
                            <input type="checkbox" id="rememberMyAccount" ref="rememberMyAccountInput" />
                            <label for="rememberMyAccount">{ i18n("login", "remember_my_account") }</label>
                        </div> */}
                        <div> { this.state.loginmessage } </div>
                        <StackLayout orientation="vertical">
                            <Button type="submit" label={ i18n("login", "sign_in") } appearance="primary" filled={ true } busy={ loggingIn } />
                            <Link className="forgot-password" to="/password-reset"
                                  style={{color: Settings.getGlobalColor('label') }}>
                                    { i18n("login", "forgot_password") }
                            </Link>
                        </StackLayout>
                    </Form>
                </ContentBox>
            </div>
        );
    }

    // Internal methods
    // _checkAuth = async function() {
    //     await api();
    // }

    _onEmailChange = (value) => this.setState({ credentials: { ...this.state.credentials, email: value } });
    _onPasswordChange = (value) => this.setState({ credentials: { ...this.state.credentials, password: value } });

    _onLogin = ({ email, password }) => {
        const { actions, navigate } = this.props;

        this.setState({ loggingIn: true }, () => {
            // const rememberMyAccount = this.refs.rememberMyAccountInput.checked;

            setTimeout(async () => { // Fake delay (temporary)
                const { success, message, jwt: token } = await api("/auth/token", { email, password });

                const newStateProps = { loggingIn: false };
                let willNavigate = false;
                let isManager = false;
                if (success) {
                    Settings.set("AUTH_TOKEN", token);
                    let message = '';
                    try {
                        const user = await api("/login");
                        message = user.message;

                        actions.setUser(user);
                        willNavigate = true;
                        isManager = user.roleType === 'manager'
                    } catch(error) {
                        newStateProps.wrongCredentials = true;
                        newStateProps.loginmessage = message;
                    }
                } else {
                    newStateProps.wrongCredentials = true;
                    newStateProps.loginmessage = message;
                }

                this.setState(newStateProps, () => {
                    if (willNavigate) navigate("/timereport/overview?view=" + (isManager ? 'resources' : 'projects'));
                });
            }, 0);
        });
    }
});


// PRIVATE FUNCTIONS
