import React from "react"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import Button from "components/Button/Button"

import MessageBox from "components/MessageBox/MessageBox"
import EditableField from "components/EditableField/EditableField"

import i18n from "helpers/i18n"
import api from "helpers/api"

export default class FortnoxContent extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            ID: props.data.ID || "",
            connectionID: "",
            clientID: props.data.clientID || "",
			MessageBox: null
        };
    }

    connectToFortnox = async () => {
        const result = await api('integrations/fortnox/connect', {connectionID: this.state.connectionID})
        if (result.success) {
            if (this.props.onSaved) this.props.onSaved(this.state)
        } else {
            MessageBox(this, {
                message: result.message,
                buttons: [
                    {
                        label: "OK",
                        appearance: "primary",
                    },
                ],
            })
        }
    }

    disconnectFromFortnox = async () => {
        // const result = await api('integrations/fortnox/disconnect', {connectionID: this.state.connectionID})
        // if (result.success) {
        //     if (this.props.onSaved) this.props.onSaved(this.state)
        // } else {
        //     MessageBox(this, {
        //         message: result.message,
        //         buttons: [
        //             {
        //                 label: "OK",
        //                 appearance: "primary",
        //             },
        //         ],
        //     })
        // }
    }

    render() {
        const { ID, connectionID, clientID } = this.state

        return (
            <div style={{marginTop: "20px"}}>
                {this.state.MessageBox}
                <h2>Fortnox</h2>

				{!ID && <EditableField type="monitor" label={ i18n("fortnox", "apiCode") } value={connectionID} onChange={ (value) => this.setState({connectionID: value}) } />}
				<EditableField readOnly type="monitor" label={ i18n("fortnox", "integrationID") } value={ clientID } />

               {!!ID ? <StackLayout orientation="horizontal">
                    <Button
                        type="submit"
                        label={i18n("fortnox", "disconnect")}
                        appearance="danger"
                        filled
                        onClick={ this.disconnectFromFortnox }
                    />
                </StackLayout>

               : <StackLayout orientation="horizontal">
					<Button
                        type="submit"
                        label={i18n("fortnox", "connect")}
                        appearance="primary"
                        filled
                        onClick={ this.connectToFortnox }
                    />
                </StackLayout>}
            </div>
        )
    }
}
