import React from "react"
import styled, {css} from 'styled-components'

// HELPERS
import * as utils from "helpers/utils"
import i18n from "helpers/i18n"


export default class Select extends React.PureComponent {
    state = {
        focused: false,
        dropdownRect: null,
        options: null,
        inputValue: "",
        highlightedIndex: -1,
        selectedIndex: -1,
        selectedOption: null,
    }

    get value() { return this.state.selectedOption }

    componentDidMount() {
        const { props } = this
        const { options, selectedIndex } = props
        let newState = { options }

        if(options && selectedIndex >= 0) {
            newState = { ...newState, selectedIndex, selectedOption: options[selectedIndex] || null }
        }

        var currSubTitle = -1
        options.forEach((option, index) => {
            if (!option.value) {
                currSubTitle = index
                newState.options[index].closed = true
            }
            if (currSubTitle !== -1 && index === selectedIndex) {
                newState.options[currSubTitle].closed = false
            }
        })

        this.setState(newState)
    }

    componentDidUpdate(prevProps) {
        const newState = {}

        if(prevProps.options !== this.props.options) newState.options = _getOptions.call(this)
        if(prevProps.selectedIndex !== this.props.selectedIndex) {
            const options = newState.options || this.props.options

            if(options) {
                newState.selectedIndex = this.props.selectedIndex
                newState.selectedOption = options[this.props.selectedIndex] || null
            }
        }

        this.setState(newState)
    }

    render() {
        const { props, state } = this
        const { style, label, placeholder, name, required, type } = props
        const { focused, inputValue, highlightedIndex, selectedIndex, selectedOption } = state
        const options = state.options || props.options
        const disabled = props.disabled || !options || props.options.length === 0
        const classes = utils.createClassName(props.className, {
            "Select": true,
            "focus": focused,
            "placeholder": !!placeholder || focused,
            "highlighting": highlightedIndex >= 0,
            "selected": selectedOption !== null,
            "required": required === true,
            "disabled": disabled,
            "hasIcon": selectedOption && selectedOption.imgSrc
        })
        let renderOption = true
        return (
            <StyledSelect ref={ ref => this.containerElement = ref }
                          $classes={classes.split(' ')}
                          className={ classes }
                          style={ style }>
                <SelectInfo ref={ ref => this.infoElement = ref }
                    $classes={focused ? ['focus'] : []}
                    type={ type }
                    className="Select-info" onClick={ !disabled ? this._onInfoClick : undefined }>
                    { !!label && <SelectLabel className="Select-label">{ label }</SelectLabel> }
                    { !!selectedOption && !inputValue && <Div className="Select-selected">{ selectedOption.renderItem || selectedOption.label || selectedOption.value }{ selectedOption && selectedOption.imgSrc && <img style={{position:"relative", left:"10px", bottom:"5px", width:"25px", height:"25px"}} src={selectedOption.imgSrc} alt=""/> }</Div> }
                    { !!placeholder && !selectedOption && <SelectPlaceholder className="Select-placeholder">{ placeholder }</SelectPlaceholder> }
                </SelectInfo>
                <SelectInput
                    readOnly
                    ref={ ref => this.input = ref }
                    type="text"
                    className="Select-input"
                    placeholder={ !selectedOption ? placeholder || i18n("components", "select") + "..." : undefined }
                    value={ inputValue }
                    required={ required }
                    disabled={ disabled }
                    onFocus={ !disabled  ? this._onInputFocus : undefined }
                    onInput={ undefined }
                    onChange={() => {}}
                />
                <input
                    type="hidden"
                    name={ name }
                    value={ !!selectedOption ? selectedOption.value : "" }
                    required={ required }
                    disabled={ disabled }
                    onSubmit={(e)=>{e.target.keyCode === 13 && e.preventDefault()}}
                />
                <div ref={ ref => this.dropdownContainerElement = ref } className="Select-dropdown-container">
                    <SelectDropdown
                        ref={ ref => this.dropdownElement = ref }
                        $classes={classes.split(' ')}
                        className="Select-dropdown"
                        type= {type }
                        style={ state.dropdownRect && {
                            minWidth: state.dropdownRect.width-2,
                            maxWidth: 2 * (state.dropdownRect.width-2),
                            top: state.dropdownRect.top - (type === 'reminder' || 'monitor' ? 47 : 0),
                            left: state.dropdownRect.left,
                        }}
                        onClick={ !disabled ? this._onDropdownClick : undefined }
                    >
                        { !!options && options.length > 0 && options.map((option, index) => {
                            if (!option.value) {
                                renderOption = !option.closed
                            }
                            return (renderOption || !option.value) ? <SelectDropdownOption
                                key={ `Select-dropdown-option-${ index }` }
                                className={ utils.createClassName("Select-dropdown-option", {
                                    "highlight": highlightedIndex === index,
                                    "disabled": option.disabled === true,
                                    "selected": selectedIndex === index,
                                    "custom": !option.label && !!option.renderItem,
                                    "subTitle": !option.value && !option.disabled && option.label,
                                    "hide": option.hide,
                                    "hasIcon": option.imgSrc
                                })}
                                $classes={
                                    utils.createClassName("Select-dropdown-option", {
                                        "highlight": highlightedIndex === index,
                                        "disabled": option.disabled === true,
                                        "selected": selectedIndex === index,
                                        "custom": !option.label && !!option.renderItem,
                                        "subTitle": !option.value && !option.disabled,
                                        "hide": option.hide,
                                        "hasIcon": option.imgSrc
                                    }).split(' ')
                                }
                                title={ option.hoverInfo || option.label }
                                style={{justifyContent:"space-between"}}
                            >
                                {!option.value && !option.disabled && <i style={{position: "relative", top: "5px", transform: option.closed ? "rotate(180deg)" : "rotate(0deg)"}} className="material-icons">keyboard_arrow_down</i>}
                                { option.renderItem || (typeof option.label !== "undefined" ? option.label : option.value) }
                                { option.imgSrc && <img style={{width:"19px", height:"19px"}} src={option.imgSrc} alt=""/> }
                            </SelectDropdownOption> : <div key={ `Select-dropdown-option-${ index }` }/>
                        })}

                        { (!options || options.length === 0) && (
                            <SelectDropdownOption
                                $classes={[]}
                                className="Select-dropdown-option not-found disabled" title={ i18n("components", "no_results_found") }>
                                { i18n("components", "no_results_found") }
                            </SelectDropdownOption>
                        )}
                    </SelectDropdown>
                </div>
                
                <div className="Select-arrow material-icons">keyboard_arrow_down</div>
                {/* { selectedOption !== null ? (
                    <div className="Select-status material-icons">check</div>
                ) : (
                    <div className="Select-arrow material-icons">keyboard_arrow_down</div>
                )} */}
            </StyledSelect>
        )
    }

    // Internal methods
    _onInfoClick = () => this.input.focus()

    _onInputFocus = () => {

        const { containerElement, dropdownContainerElement } = this
        const dropdownRect = dropdownContainerElement.getBoundingClientRect()

        this.setState({ focused: true, dropdownRect })

        // Body click event
        if(!this._bodyClickEvent) {
            document.body.addEventListener("click", this._bodyClickEvent = e => {
                const closestElement = utils.getClosestElementByQuery(e.target, ".Select")
                const isAncestor = !!closestElement && (closestElement === containerElement || containerElement.contains(closestElement))

                if(!isAncestor) this._onBlur.call(this)
            }, false)
        }

        // Body key up event
        if(!this._bodyKeyUpEvent) {
            //document.body.addEventListener("keyup", this._bodyKeyUpEvent = e => this._onKeyPress.call(this, e), false)
        }

        // Document scroll event
        if(!this._documentScrollEvent) {
            document.addEventListener("scroll", this._documentScrollEvent = e => {
                this.setState({ dropdownRect: dropdownContainerElement.getBoundingClientRect() })
            }, false)
        }

        // Window resize event
        if(!this._windowResizeEvent) {
            window.addEventListener("resize", this._windowResizeEvent = e => {
                this.setState({ dropdownRect: dropdownContainerElement.getBoundingClientRect() })
            }, false)
        }
    }

    _onBlur = () => {
        const { props } = this
        const newState = {
            options: props.options,
            focused: false,
            inputValue: "",
            highlightedIndex: -1
        }

        if(this._bodyClickEvent) {
            document.body.removeEventListener("click", this._bodyClickEvent)
            delete this._bodyClickEvent
        }

        if(this._bodyKeyUpEvent) {
            document.body.removeEventListener("keyup", this._bodyKeyUpEvent)
            delete this._bodyKeyUpEvent
        }

        if(this._documentScrollEvent) {
            document.removeEventListener("scroll", this._documentScrollEvent)
            delete this._documentScrollEvent
        }

        if(this._windowResizeEvent) {
            window.removeEventListener("resize", this._windowResizeEvent)
            delete this._windowResizeEvent
        }

        this.setState(newState)
    }

    _onInput = (e) => {
        const { props, state } = this
        const options = _getOptions.call(this)
        const inputValue = this.input.value
        const newState = {
            options,
            inputValue,
            highlightedIndex: options.length > 0 ? 0 : -1,
        }

        // Temporary solution for a weird reference issue when using indexOf method
        if(state.selectedOption) {
            options.forEach((o, i) => {
                if(
                    state.selectedOption.value === o.value &&
                    state.selectedOption.label === o.label &&
                    state.selectedOption.disabled === o.disabled
                ) newState.selectedIndex = i
            })
        } else {
            newState.selectedIndex = -1
        }
        // newState.selectedIndex = options.indexOf(state.selectedOption)

        this.setState(newState, () => {
            const { onInput } = props
            if(onInput) onInput(inputValue)
        })
    }

    _onDropdownClick = (e) => {
        const { props, state } = this
        const prevSelectedIndex = state.selectedIndex
        const closestOptionElement = utils.getClosestElementByQuery(e.target, ".Select-dropdown-option")
        if (closestOptionElement && closestOptionElement.classList.contains("subTitle")) {
            
            let options = this.state.options || this.props.options
  
            const selectedIndex = Array.from(closestOptionElement.parentNode.children).indexOf(closestOptionElement)
            options[selectedIndex].closed = !options[selectedIndex].closed


        } else {
            const selectedIndex = (closestOptionElement && !(closestOptionElement.classList.contains("disabled")) ?
            Array.from(closestOptionElement.parentNode.children).indexOf(closestOptionElement) :
            -1
            )

            if(selectedIndex >= 0) {
                this.setState({ focused: false, inputValue: "", highlightedIndex: -1, selectedIndex, selectedOption: state.options[selectedIndex] }, () => {
                    if(selectedIndex !== prevSelectedIndex && props.onChange) {
                        switch(props.type){
                            case("monitor"):
                                props.onChange(state.options[selectedIndex].value, props.name)
                                break
                            default:
                                props.onChange(state.options[selectedIndex], selectedIndex)
                                break
                        } 
                    }
                })
            }
        }
    }
    _onKeyPress = (e) => {
        const { key, target } = e
        const { props, state } = this
        const { options } = state
        const newState = {}
        let newStateCallback

        if(key === "Tab" && target !== this.input) {
            this.setState({ focused: false, inputValue: "", highlightedIndex: -1 })
        } else if(key === "ArrowUp") {
            const { selectedIndex, highlightedIndex: prevHighlightedIndex } = state
            const highlightedIndex = newState.highlightedIndex = Math.max((prevHighlightedIndex >= 0 ? prevHighlightedIndex : selectedIndex) - 1, 0)

            newStateCallback = () => {
                const selectedOptionElement = this.dropdownElement.children[highlightedIndex]
                _ensureOptionInView.call(this, selectedOptionElement)
            }
        } else if(key === "ArrowDown") {
            const { selectedIndex, highlightedIndex: prevHighlightedIndex } = state
            const highlightedIndex = newState.highlightedIndex = Math.min((prevHighlightedIndex >= 0 ? prevHighlightedIndex : selectedIndex) + 1, options.length-1)

            newStateCallback = () => {
                const selectedOptionElement = this.dropdownElement.children[highlightedIndex]
                _ensureOptionInView.call(this, selectedOptionElement)
            }
        } else if(key === "Enter") {
            const { highlightedIndex: prevHighlightedIndex } = state

            newState.focused = false
            newState.inputValue = ""
            newState.highlightedIndex = -1
            newState.selectedIndex = prevHighlightedIndex
            newState.selectedOption = options[prevHighlightedIndex] || null

            newStateCallback = () => {
                const { onChange } = props
                const { selectedIndex } = this.state
                if(onChange) onChange(options[selectedIndex], selectedIndex)
            }
        }

        this.setState(newState, newStateCallback)

        // Remove event listener from body
        if(target !== this.input) {
            document.body.removeEventListener("keyup", this._bodyKeyUpEvent)
            delete this._bodyKeyUpEvent
        }
    }
}


// PRIVATE FUNCTIONS
function _getOptions() {
    const { props, state, input } = this

    state.options && props.options.forEach((option, index) => {
        if (option.value === state.options[index].value) {
            option.closed = state.options[index].closed
        }
    })
    return input.value ? props.options.filter(option => {
        return option.label.toLowerCase().indexOf(input.value.toLowerCase()) === 0
    }) : props.options
}

function _ensureOptionInView(optionElement) {

    const { dropdownElement } = this

    // Determine dropdownElement top and bottom
    const cTop = dropdownElement.scrollTop
    const cBottom = cTop + dropdownElement.clientHeight

    // Determine optionElement top and bottom
    const eTop = optionElement.offsetTop
    const eBottom = eTop + optionElement.clientHeight

    // Check if out of view
    if(eTop < cTop) {
        dropdownElement.scrollTop -= (cTop - eTop)
    } else if (eBottom > cBottom) {
        dropdownElement.scrollTop += (eBottom - cBottom)
    }
}

const StyledSelect = styled.div`
    border-bottom: 1px solid ${props => props.theme.background};
    ${props => props.$classes.includes('focus') && css`
        border-color: 1px solid ${props.theme.secondary};
        ::before{
          background: ${props.theme.background};
        }
    `}
`

const SelectInfo = styled.div`
    ${props => props.$classes.includes('focus') && !["monitor", "reminder"].includes(props.type) && css`
        border: 1px solid ${props.theme.secondary};
    `}
`

const SelectLabel = styled.div`
    color: ${props => props.theme.label};
`

const SelectPlaceholder = styled.div`
    color: ${props => props.theme.subtitle};
`

const SelectDropdown = styled.div`
    background: ${props => props.theme.overlay};
    ${props => ["monitor", "reminder"].includes(props.type) ? 
        `border: 1px solid ${props.theme.secondary};
        border-radius: 5px;`
    : 
        `border-bottom: 1px solid ${props.theme.secondary};
        border-left: 1px solid ${props.theme.secondary};
        border-right: 1px solid ${props.theme.secondary};
        
        ${props => props.$classes.includes('focus') && css`
            border-color ${props.theme.secondary};
        `}
    `}
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.secondary};
    }
    &::-webkit-scrollbar-track {
        background: ${props => props.theme.secondary}60; 
    }
`

const SelectDropdownOption = styled.div`
    ${props => props.$classes.includes('focus') && css`
      border-color: ${props.theme.secondary};
    `}
    ${props => props.$classes.includes('subTitle') && css`
      color: ${props.theme.subtitle};
    `}
    &:hover{
      ${props => !(props.$classes.includes('disabled') || props.$classes.includes('subTitle')) && css`
        background: ${props.theme.background};
      `}
    }
    ${props => props.$classes.includes('highlight') && css`
      color: ${props.theme.secondary};
    `}
    ${props => props.$classes.includes('selected') && css`
      color: ${props.theme.secondary};
    `}
`

const SelectInput = styled.input`
    color: ${props => props.theme.subtitle};
`

const Div = styled.div`
    background: ${props => props.theme.overlay};
`
