import React, { Component } from 'react'

import { Grid } from "components"
import Settings from "classes/Settings"

import Button from "components/Button/Button"
import ContentBox from "components/ContentBox/ContentBox"
import Form from "components/Form/Form"
import TextField from "components/TextField/TextField"
import StackLayout from "components/layouts/StackLayout/StackLayout"

import * as utils from "helpers/utils"
import api, { getAssetsURIForPath } from "helpers/api"
import i18n from "helpers/i18n"


export default class PublicLink extends Component {
    constructor(props) {
        super(props)
        this.state = {
            width: 0,
            password: "",
            loading: false,
            errorMessage: ""
        }
    }

    async componentDidMount() {
        const { success, dashboard, message, errorType } = await this._getPublicDashboard()

        if (success) {
            this.setState({ dashboard })
            document.getElementById("nav-bar").style.display = "none"
        } else {
            this.setState({ errorType, message })
        }
        this.resize()
        window.addEventListener('resize', this.eventResize)
    }

    _getPublicDashboard(password) {
        const link = window.location.search ? utils.parseQueryString(window.location.search).link : null
        return api('/monitor/dashboards/getpublic', {code: link, password: password})
    }

    async _onLogin({password}) {
        this.setState({loading: true})
        const {dashboard, success, message} = await this._getPublicDashboard(password)
        if (success) {
            this.setState({ dashboard, loading: false })
            document.getElementById("nav-bar").style.display = "none"
        } else {
            this.setState({errorMessage: message, loading: false})
        }
    }

    componentDidUpdate() {
        this.resize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.eventResize)
    }

    eventResize = () => this.resize()

    onChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    resize() { // updating width on window resize to properly display layout
        const container = document.getElementById('monitor_view-container')

        // set current width of container to properly scale grid
        if (container !== null && container.offsetWidth > 0
            && container.offsetWidth !== this.state.width)
            this.setState({ width: container.offsetWidth })
    }

    async _updateCard(dashboardID, Cards, cardID, Chart, Options, Setup, backgroundImageUploadData = null, forceUpdate = false) {
        if (cardID[0] === 'c') return

        const newCard = {...Cards[cardID], Chart, Options, Setup }

        if (backgroundImageUploadData) {
            const { backgroundImage } = await api('/monitor/cards/update/backgroundImage', { card: newCard, image: backgroundImageUploadData })

            newCard.BackgroundImage = backgroundImage
        }

        if (forceUpdate && Setup) {    
            const newData = await api('/monitor/updateCardData', { ID: cardID, Chart, Options, Setup })
            newCard.Chart.data = newData
        }

        this.setState(prevState => ({
            dashboard: {
                ...prevState.dashboard,
                Cards: {
                    ...prevState.dashboard.Cards,
                    [cardID]: newCard
                }
            }
        }))
    }

    render() {
        const { width, dashboard, errorType } = this.state
        
        if (dashboard) var { BackgroundImage, BackgroundColor, ID } = dashboard

        return (
            <React.Fragment>
                {dashboard ? 
                    <div id='monitor_view-container' style={{top: 0}}>
                        <div id='monitor-container'> 
                            <div id='content-container' style={{backgroundColor: BackgroundColor ? BackgroundColor : (Settings.getGlobalColor('background')), height: "100vh", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "50% 50%", backgroundImage: BackgroundImage ? `url(${ getAssetsURIForPath("/dashboards/"+BackgroundImage) })` : "none"}}>
                                <div id={`tab-${ID}`} className='tab' key={ID}>
                                    <Grid 
                                        className='content-layout' 
                                        dashboard={dashboard} 
                                        width={width} 
                                        updateCard={this._updateCard.bind(this, dashboard.ID, dashboard.Cards)} 
                                        shared />
                                </div>
                            </div>
                        </div>
                    </div>
                : errorType === "password" ?
                    <div className="Login">
                        <ContentBox className="Login-box" title={ i18n("login", "dashboard_password") }>
                            <Form onSubmit={ this._onLogin.bind(this) } autoComplete={ false } submitOnEnter>
                                <TextField
                                    type="password"
                                    name="password"
                                    label={ i18n("login", "password") }
                                    value={ this.state.password }
                                    onChange={ (password) => this.setState({password}) }
                                />
                                <div> { this.state.errorMessage } </div>
                                <StackLayout orientation="vertical">
                                    <Button type="submit" label={ i18n("login", "sign_in") } appearance="primary" filled={ true } />
                                </StackLayout>
                            </Form>
                        </ContentBox>
                    </div>
                : errorType === "link" || !utils.parseQueryString(window.location.search).link ?
                    <div className="Login">
                        <ContentBox className="Login-box" title={ i18n("errors", "invalid_link") } />
                    </div>
                : null}
            </React.Fragment>
        )
    }
}