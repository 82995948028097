import {
    START_FETCH,
    SET_PREVIEW_DATA,
    RESET_DATA
} from '../redux/actions/cardSettings/action-types'

const initialState = {
    newData: {},
    fetching: false
}

const cardSettingsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case START_FETCH:
            return {
                ...state,
                fetching: true
            }
        case SET_PREVIEW_DATA:
            return {
                ...state,
                newData: payload,
                fetching: false
            }
        case RESET_DATA:
            return {
                ...state,
                newData: {}
            }
        default:
            return state
    }
}

export default cardSettingsReducer