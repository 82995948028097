import React from "react"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import Button from "components/Button/Button"
import EditableTable from "../EditableTable/EditableTable"
import TextField from "components/TextField/TextField"

import * as utils from "helpers/utils"
import i18n from "helpers/i18n"
import api from "helpers/api"

export default class PunchClockContent extends React.PureComponent {
    constructor(props) {
        super(props)

        const dataFixed = !props.new ? props.data.resources.map((e, i) => ({
            selected: false,
            ID: e.ID,
            name: e.name
        })) : []
        this.state = {
            ID: props.ID,
            editedTitle: props.name || "",
            editedCode: props.code || "",
            data: dataFixed,
            editingCode: !props.code,
            editingTitle: !!props.new
        }
    }

    _onRowChecked = (type, colKey, rowIndex, checked) => {
        const updatedRows = {...this.state.data}
        updatedRows[rowIndex]['selected'] = checked

        this.setState(prevState => ({
            data: [...prevState.data]
        }))

    }

    _onAddRow = () => {
        let rows = [...this.state.data]
        rows.unshift({
            isNew: true,
            selected: false,
            user:"",
            name:"",
            type: '',
            ID: -1,
            shadow: 0
        })

        this.setState({ data: rows })
    }
    //need change, backend support
    _onSubmitHandler = async () => {
        const url = "globals/punchclock/save"
        console.log('data', JSON.stringify(this.state))
        await api(url, this.state)

        if(this.props.onSaved) this.props.onSaved(this.state)
    }

    _onDeleteHandler = async () => {
        const url = "globals/punchclock/delete"
        console.log('data', JSON.stringify(this.state))
        await api(url, this.state)

        if(this.props.onSaved) this.props.onSaved(this.state)
    }

    _onRemoveSelected = ()=> {
        const rows = [...this.state.data].map(row => {
            if (row.selected) return {...row, delete: true }
            else return {...row}
        })
        this.setState({ data: rows })
    }

    _onTitleChangedHandler = (e) => {
        this.setState({editedTitle: e})
    }
    _onCodeChangedHandler = (e) => {
        this.setState({editedCode: e})
    }

    _onAssignAll = async (e) => {
        e.preventDefault()
        const { data } = this.state
        let rows = data.assignings
        const response = await api('/globals/getall', { type: 'user', withStartDate: data.shadow }) // auto-set earliest possible start date if it's a shadow project
        const resources = Object.values(response.data)

        if (resources.length > 0) {
            resources.map(res => {
                let taken = false
                rows.forEach(row => {
                    if (row.UserName === res.name) {
                        taken = true
                    }
                })

                if (!taken) {
                    rows.push({
                        isNew: true,
                        selected: false,
                        ID: utils.createUUID(),
                        UserID_FK: res.ID,
                        UserName: res.name,
                        Fee: "0",
                        SM: 100,
                        StartDate: res.startDate,
                        EndDate: "",
                    })
                }
                return taken
            })}
    }

    _onAddAllHandler = async () => {
        const url = "globals/getall"
        const result = await api(url, { type: 'user' })
        const resources = Object.values(result.data)

        //Add selected to beginning of each row and filter out duplicates.
        const newRows = resources.map((e, i) => ({
            isNew: true,
            selected: false,
            ID: e.ID,
            name: e.name
        })).filter((item,i) => !this.state.data.find(e => e.name === item.name && !e.delete))

        this.setState({
          data: [...this.state.data, ...newRows]
        })
    }
    _onTitleClickedHandler = () => {
        this.setState({ editingTitle: true }, () => {
            let textfield = document.getElementById("title")
            if (textfield) textfield.focus()
        })
    }
    _onCodeClickedHandler = () => {
        this.setState({ editingCode: true }, () => {
            let textfield = document.getElementById("code")
            if (textfield) textfield.focus()
        })
    }
    
    _closeTextHandler = (e) => {
        this._onCloseTitleTextHandler(e)
        this._onCloseCodeTextHandler(e)
    }

    _onCloseTitleTextHandler = (e) => {

        let editedTitle = this.state.editedTitle
        const onlyWhiteSpaces = (str) => {
          return !str || !str.replace(/\s/g, '').length
        }

        if(onlyWhiteSpaces(this.state.editedTitle) && this.state.title !== undefined){
            this.setState({
                editedTitle: this.state.title
            })
        } else if((!this.state.editedTitle || onlyWhiteSpaces(this.state.editedTitle) ) && (!this.state.title || onlyWhiteSpaces(this.state.title) ) ){
            return
        } else if(this.state.editedTitle === ''){
            editedTitle = this.state.title
            this.setState({
                editedTitle: editedTitle
            })
        } else {
          if(e.target.id !== 'title'){
            this.setState({
                editingTitle: false
            })
          }
        }
    }

    _onCloseCodeTextHandler = (e) => {
        let editedCode = this.state.editedCode
        const onlyWhiteSpaces = (str) => {
          return !str || !str.replace(/\s/g, '').length
        }

        if(onlyWhiteSpaces(this.state.editedCode) && this.state.code !== undefined){
            this.setState({
                editedCode: this.state.code
            })
        } else if((!this.state.editedCode || onlyWhiteSpaces(this.state.editedCode) ) && (!this.state.code || onlyWhiteSpaces(this.state.code) ) ){
            return
        } else if(this.state.editedCode === ''){
            editedCode = this.state.code
            this.setState({
                editedCode: editedCode
            })
        } else {
          if(e.target.id !== 'code'){
            this.setState({
                editingCode: false
            })
          }
        }
    }

    _onAutoCompleteSelected = (e) => {
        this.forceUpdate()
    }

    render() {
        const { state } = this
        const { data, editingTitle, editingCode } = state

        const footerTemplate = (
            <div style={{ width: "100%" }}>
                <StackLayout justifyContent="space-between" rowWrap fullWidth>
                <Button size="small" style = {{margin: '5px 0'}}
                            label={ i18n("general", "remove_selected") }
                            appearance="danger"
                            onClick={ this._onRemoveSelected } />
                    <Button size="small"
                            label={ i18n("globals", "add_all_resources") }
                            appearance="secondary"
                            filled
                            style={{margin: '5px 0'}}
                            onClick={ this._onAddAllHandler.bind(this,'resources') } />
                    <Button size="small"
                            label={ i18n("globals", "add") }
                            appearance="secondary"
                            filled
                            style={{margin: '5px 0'}}
                            onClick={ this._onAddRow } />
                </StackLayout>
            </div>
        )

        const titleText = state.editedTitle !== undefined ? state.editedTitle : state.title
        const codeText = state.editedCode !== undefined ? state.editedCode : state.code
        const title = (
            <center>
                {!editingTitle ? (<h2 style={{marginTop: '-6px', cursor: "pointer"}}>
                    <div id={'title'} onClick = {this._onTitleClickedHandler}>
                        { titleText }
                    </div>
                </h2>) : (
                    <TextField  id={'title'}  className={ 'split' } style={{ width: '30%', height:'inherit'}}
                        value={ titleText }
                        placeholder="Title"
                        onChange={ this._onTitleChangedHandler }
                        />
                )}
           </center>
        )

        //need change, fix scalings/margins
        const code = (
            <center>
                {!editingCode ? (<div style={{marginTop: '26px', cursor: "pointer"}}>
                    <div id={'code'} onClick = {this._onCodeClickedHandler}>
                        { codeText }
                    </div>
                </div>) : (
                    <TextField  id={'code'}  className={ 'split' } style={{ marginTop:"5px", width: '30%', height:'inherit'}}
                        value={ codeText }
                        placeholder="Code"
                        onChange={ this._onCodeChangedHandler }
                        />               
                )}
           </center>
        )

        return (
            <div onClick = { this._closeTextHandler }>
                <form autoComplete="off">
                    {title}
                    {code}
                </form>

                <EditableTable
                    context={ this }
                    type={ 'punchclock' }
                    rows={ data.filter(e => !e.delete) }
                    columns={[
                        { key: "selected",  label: "", width: "10%" },
                        { key:"users", name: "users",   label: ``, width: "90%", }
                    ]}
                    onRowChecked={ this._onRowChecked }
                    footerTemplate = { footerTemplate }
                    onAutoCompleteSelected = { this._onAutoCompleteSelected }
                    />

                <StackLayout orientation="horizontal">
                {!this.props.new && data.filter(e => !e.delete).length === 0 &&
                    <Button type="submit"
                        label={ i18n("general", "delete") }
                        appearance="danger"
                        filled
                        onClick = {this._onDeleteHandler}
                        />}

                    <Button type="submit"
                        label={ i18n("general", "save") }
                        appearance="primary"
                        filled
                        onClick = {this._onSubmitHandler}
                        />
                </StackLayout>
            </div>
        )
    }
}
