import React from "react";
import styled, {css} from 'styled-components';

// HELPERS
import { createClassName } from "helpers/utils";

export default class TextField extends React.Component {
    state = {
        filled: false,
        focused: false,
        // success: false,
        // invalid: false,
    }

    get value() { return this.input.value }

    componentDidMount() {
        // _validationAsync.call(this);
        document.addEventListener('load', this.setState({pageLoaded: true}))
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if(prevProps !== this.props) _validationAsync.call(this);
    // }

    render() {
        const { props, state } = this;
        const { style, id, name, label, type, placeholder, required, readOnly, hideRequiredSymbol, value, valid, tooltip, table, autoComplete } = props;
        const disabled = props.disabled === true;
        const classes = createClassName(props.className, {
            "TextField": true,
            [type || "text"]: true,
            "focus": state.focused,
            "disabled": disabled,
            "placeholder": !!placeholder,
            "filled": state.filled || String(value),
            "required": required === true,
            "tooltip": !!tooltip,
            "valid": valid === true,
            "invalid": valid === false,
            "readOnly": readOnly === true,
        });

        return (
            <Div className={ classes } $classes={classes.split(' ')} style={ style }>
                {!table && <TextFieldLabel className="TextField-label">{ (state.invalid && props.invalidMessage) || label }</TextFieldLabel>}
                <TextFieldInput
                    ref={ ref => this.input = ref }
                    className="TextField-input"
                    id={ id }
                    name={ name }
                    type={ type || "text" }
                    placeholder={ placeholder }
                    defaultValue={ props.defaultValue }
                    value={ value }
                    required={ required === true }
                    disabled={ disabled }
                    readOnly={ readOnly }
                    onFocus={ !disabled ? this._onFocus : undefined }
                    onBlur={ !disabled ? this._onBlur : undefined }
                    autoComplete={ autoComplete }
                    // onInput={ !disabled ? this._onInput : undefined }
                    onChange={ !disabled ?  this._onChange : undefined }
                    style={ !label ? { paddingTop:'0px' } : null }
                />
                { !!tooltip ?
                    <TextFieldTooltip text={ tooltip } />
                : valid === true ?
                    <TextFieldStatus icon="check" />
                : required === true && !hideRequiredSymbol ?
                    <TextFieldStatus requiredSymbol />
                : valid === false ?
                    <TextFieldStatus icon="error" />
                : null }
            </Div>
        );
    }

    // Internal methods
    _onFocus = ({ target }) => {
        const { onFocus } = this.props;
        this.setState({ focused: true }, onFocus ? () => onFocus(target.value) : undefined);
    }

    _onBlur = ({ target }) => {
        const { onBlur } = this.props;
        this.setState({ focused: false }, onBlur ? () => onBlur(target.value) : undefined);
    }

    _onChange = (e) => {
        const { onChange, table, name } = this.props;
        if (name === 'ssn') e.target.value = e.target.value.match(/[a-zA-Z0-9-]+/)
        if(onChange) onChange(table ? e : e.target.value);
    }
}


// PRIVATE COMPONENTS
function TextFieldTooltip({ text, onClick }) {
    return (
        <StyledTextFieldTooltip className="TextField-tooltip">
            <TextFieldTooltipIcon className="TextField-tooltip-icon">?</TextFieldTooltipIcon>
            <div className="TextField-tooltip-text">{ text }</div>
        </StyledTextFieldTooltip>
    );
}

const StyledTextFieldTooltip = styled.div`
    :hover {
      background: ${props => props.theme.secondary};
    }
`;

const TextFieldTooltipIcon = styled.div`
    background: ${props => props.theme.background};
`;


function TextFieldStatus({ icon, requiredSymbol }) {
    return (
        <div className="TextField-status">
            <div className={ createClassName("", {
                "icon": !!icon,
                "material-icons": !!icon,
                "required-symbol": requiredSymbol === true,
            })}>{ requiredSymbol === true ? "*" : icon }</div>
        </div>
    );
}


// PRIVATE FUNCTIONS
const Div = styled.div`
    input {
        border-bottom: 1px solid ${props => props.theme.background};
    }
    
    ${props => props.$classes.includes('focus') && props.$classes.includes('readOnly') && css`
        border-color: ${props.theme.secondary};
    `}
    ${props => props.$classes.includes('readOnly') && css`
        cursor: default;
    `}
    ${props => props.$classes.includes('invalid') && css`
        border-color: ${props.theme.danger};
    `}
    ${props => props.$classes.includes('success') && css`
        border-color: ${props.theme.success};
    `}


`;

const TextFieldLabel = styled.label`
    color: ${props => props.theme.label};
`;

const TextFieldInput = styled.input`
    ::placeholder {
        color: ${props => props.theme.label};
    }
`;
