import { useEffect, useRef, useState } from "react"
import SketchPicker from "@uiw/react-color-sketch"
import styled, {css} from "styled-components"

const ColorPicker = ({ color, onChange, disableAlpha, style = {} }) => {
    const [showSketchPicker, setShowSketchPicker] = useState(false)
    const colorPickerRef = useRef(null)
    const pos = colorPickerRef.current?.getBoundingClientRect()

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setShowSketchPicker(false)
            }
        }

        document.addEventListener("mousedown", handleOutsideClick)

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const toggleSketchPicker = () => {
        setShowSketchPicker(prevState => !prevState)
    }

    return (
        <div ref={colorPickerRef} style={{ display: "inline-block", position: "relative", ...style }}>
            <I
                className="fas fa-palette"
                onClick={toggleSketchPicker}
                $color={color}
            />

            {(showSketchPicker) && pos && (
                <div style={{ zIndex: 2, position: 'fixed', top: pos.top - 140, left: pos.left + 25 }}>
                    <SketchPicker
                        color={color}
                        onChange={onChange}
                        disableAlpha={disableAlpha || false}
                    />
                </div>
            )}
        </div>
    )
}

const I = styled.i`
    ${props => css`
        color: ${props.$color};
        text-shadow: -1px -1px 0 ${props.theme.default}, 1px -1px 0 ${props.theme.default}, -1px 1px 0 ${props.theme.default}, 1px 1px 0 ${props.theme.default};
    `}
`

export default ColorPicker