import React from "react";
import PropTypes from "prop-types";
import Settings from "classes/Settings";
import styled, {css} from 'styled-components';

// COMPONENTS
import Button from "components/Button/Button";
import Form from "components/Form/Form";
import StackLayout from "components/layouts/StackLayout/StackLayout";
import Select from "components/Select/Select";
import Switch from "components/Switch/Switch";
import TagField from "components/TagField/TagField";
import EditableField from "components/EditableField/EditableField";
import EditableTable from "../EditableTable/EditableTable";
import Autocomplete from "components/Autocomplete/Autocomplete";
import SketchPicker from '@uiw/react-color-sketch';

import UploaderForm from "../UploaderForm/UploaderForm";
import Modal from "components/Modal/Modal";
import ShowMessageBox from "components/MessageBox/MessageBox";


// HELPERS
import * as utils from "helpers/utils"
import { createClassName } from "helpers/utils"
import api, { getAssetsURIForPath } from "helpers/api"
import i18n from "helpers/i18n"

export default utils.connectRedux(
    state => ({
        app: state.app
    }), {}, class ContextReviewContent extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number,
        context: PropTypes.object,
        type: PropTypes.string.isRequired,
        isNew: PropTypes.bool,
        onSave: PropTypes.func
    }

    static defaultProps = {
        id: null,
        context: null,
        type: null,
        isNew: false
    }

    constructor(props) {
        super(props)

        this.state = {
            data: {
                ...(this.props.context ? JSON.parse(JSON.stringify(this.props.context)) : _createDataTemplate.call(this))
            },
            mode: this.props.isNew ? 'edit' : 'overview',
            subMode: "general",
            tags: [],
            id: null,
            wages: [],
            assignings: [],
            activities: [],
            message: '',
            backgroundColorPaletteVisible: false,
            textColorPaletteVisible: false,
            busy: false,
            markedPeriods: [],
            newActivityDate: null,
            tagRows: [[]],
            filterModeVisible: false
        }

        this.handleOutsideClick = this.handleOutsideClick.bind(this)
    }

    componentDidMount() {
        const { type, id, isNew, isShadow } = this.props
        const { data } = this.state, { tags = [] } = data, newStateProps = { tags }
        document.addEventListener('click', this.handleOutsideClick, false)
        if (!isNew) {
            newStateProps.data = data
            newStateProps.data['ID'] = id

            if (type === "resource") {
                newStateProps.data.wages = data.wages || [];
                newStateProps.data.wages.sort((a, b) => this._sortDates(a, b, 'wages'))
                newStateProps.data.activities = data.activities || [];
            } else if (type === "project") {
                newStateProps.data.assignings = data.assignings || [];
                newStateProps.data.assignings.sort((a, b) => this._sortDates(a, b, 'assignings'))
            }
        } else {
            newStateProps.data = data

            if (isShadow) {
                newStateProps.data.shadow = 1
            }
        }

        this.setState(newStateProps)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false)
    }

    handleOutsideClick(e) {
        if (!this.bgColorRef) return
        if (this.bgColorRef.contains(e.target)) return

        if (!this.bgColorRefPreview) return
        if (this.bgColorRefPreview.contains(e.target)) return

        if (!this.textColorRef) return
        if (this.textColorRef.contains(e.target)) return

        if (!this.textColorRefPreview) return
        if (this.textColorRefPreview.contains(e.target)) return

        if(this.state.mode ===  "edit") {
            if (utils.isMobileDevice()) document.getElementsByClassName("scrollable-y")[0].style.overflowY = "auto"
            this.setState({
                backgroundColorPaletteVisible: false,
                textColorPaletteVisible: false
            })
        }
    }

    render() {
        const { writeable, type } = this.props
        const { mode, subMode, data, avatarUploadData, uploaderForm, colorpickerForm, MessageBox } = this.state
        const { name, Name, firstName, lastName, avatarPath } = data

        const classes = createClassName('ContextReviewContent', {
            'read': writeable !== true,
            'write': writeable === true
        })

        return <div className={classes} >
            <Div className='top'>
                <Div className='avatar' onClick={this._onAvatarPreviewClick} style={avatarUploadData ? {backgroundImage: `url(${ avatarUploadData.content })` } : avatarPath ? { backgroundImage: `url(${ getAssetsURIForPath(avatarPath) })` } : { background: Settings.getGlobalColor('label') + '50% 50% no-repeat' }} />
                <h2>{ type === 'project' ? name : type === 'company' ? Name : firstName + ' ' + lastName}</h2>
                {writeable && (<P>ID {data.ID}</P>)}
            </Div>

            {writeable === true && <EditToggler type={type} active={mode === 'edit'} subMode={subMode} onChange={this._changeModes} /> }

            {mode === "overview"
                ? _getOverviewContent.call(this, type, data, writeable)
                : (mode === "edit"
                    ? _getEditContent.call(this, type, data, subMode)
                    : ''
                )
            }

            <Modal visible={!!uploaderForm && mode === "edit"} onClosed={this._onUploaderFormClose} >
                {uploaderForm}
            </Modal>

            <Modal visible={!!colorpickerForm && mode === "edit"} onClosed={this._onColorpickerFormClose} >
                {colorpickerForm}
            </Modal>

            {MessageBox}
        </div>
    }

    // Internal methods
    _changeModes = (editing, subMode) => this.setState({ mode: editing ? "edit" : "overview", subMode })
    
    _sortDates = (a, b, type) => {
        // sorting after endDate, where the latest date is placed earlier in array
        // if endDate is not present then that element is placed earlier in array instead
        // if both endDates are not present then elements are sorted after startDate...
        // ...where the latest date is placed earlier in array
        const aStartDate = utils.moment(a.startDate),
        aEndDate = a.endDate !== '' ? utils.moment(a.endDate) : Infinity,
        bStartDate = utils.moment(b.startDate),
        bEndDate = b.endDate !== '' ? utils.moment(b.endDate) : Infinity

        if (type === 'wages') {
            return bStartDate.diff(aStartDate) // place the element with latest startDate earlier in array
        } else if (type === 'assignings') {
            if (aEndDate === Infinity && bEndDate === Infinity) return bStartDate.diff(aStartDate) // sort after startDate if both dates are not present
            else if (aEndDate === Infinity) return -1 // place the element with no endDate earlier in array
            else if (bEndDate === Infinity) return 1 // place the element with no endDate earlier in array
            else return bEndDate.diff(aEndDate) // place the element with latest endDate earlier in array
        }
    }

    _onTagsTagAdd = (newTag) => {
        let tags = [...this.state.data.tags]

        if (!tags.find(tag => tag["Name"] === newTag["Name"])) tags.unshift({ ...newTag, isNew: true })

        this.setState(prevState => ({ data: {...prevState.data, tags }}))
    }

    _onTagsTagRemove = (tagIndex) => {
        const tags = [...this.state.data.tags].map((tag, i) =>
            i === tagIndex ? {...tag, delete: true } : {...tag }
        )

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                tags: [...tags]
            }
        }))
    }

    _onUploaderFormClose = () => {
        this.setState({ uploaderForm: null })
    }

    _onAvatarPreviewClick = () => {
        const { mode, avatarUploadData, data } = this.state, { ID, avatarPath } = data

        if (mode ===  "edit") {
            this.setState({
                uploaderForm: <UploaderForm type="avatar" id={ID} uploadedImage={avatarUploadData ? avatarUploadData : avatarPath} onUpload={this._onUpload} />
            })
        }
    }

    _onCellPreviewClick = () => {
        const { mode, cellUploadData, data } = this.state, { ID, cellImagePath } = data

        if (mode ===  "edit") {
            this.setState({
                uploaderForm: <UploaderForm type="cell" id={ID} uploadedImage={cellUploadData ? cellUploadData : cellImagePath} onUpload={this._onUpload} />
            })
        }
    }

    _onTextColorChange = (_color) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                textColor: _color.hex
            }
        }))
    }
    _onColorChange = (_color) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                color: _color.hex
            }
        }))
    }

    _onColorRemove = (_color) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                color: ''
            }
        }))
    }

    _onTextColorRemove = (_color) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                textColor: ''
            }
        }))
    }

    _onColorpickerClick = (type) => {
        const { mode, backgroundColorPaletteVisible, textColorPaletteVisible } = this.state

        if(mode ===  "edit") {
            this.setState({
                backgroundColorPaletteVisible: type === 'backgroundcolor' ? !backgroundColorPaletteVisible : false,
                textColorPaletteVisible: type === 'backgroundcolor' ? false : !textColorPaletteVisible
            }, () => {
                if (utils.isMobileDevice()) {
                    if (this.state.backgroundColorPaletteVisible || this.state.textColorPaletteVisible) {
                        document.getElementsByClassName("scrollable-y")[0].style.overflowY = "hidden"
                    } else {
                        document.getElementsByClassName("scrollable-y")[0].style.overflowY = "auto"
                    }
                }
            })
        }
    }

    _onUpload = (value) => {
        if (value.type === 'avatar') {
            this.setState({
                avatarUploadData: value,
                uploaderForm: null
            })
        } else {
            this.setState({
                cellUploadData: value,
                uploaderForm: null
            })
        }
    }

    _onSwitchChange = (onoff) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                status: onoff ? 'active' : 'inactive'
            }
        }))
    }

    _onUserStatusChange = () => {
        let activities = [...this.state.data.activities].filter(e => !e.isNew)
        let newStatus = activities[0] ? !activities[0].Active : true

        ShowMessageBox(this, {
            prevUserStatus: newStatus,
            message : i18n('warnings', 'date_of_change'),
            datepicker: true,
            buttons: [{
                label: i18n('warnings', 'confirm'),
                role: 'confirm',
                appearance: 'primary',
                onClick: (date) => {
                    if (activities.length > 0) // automaticly set EndDate to the last activity
                        activities[0].EndDate = utils.moment(date).subtract(1, 'days').format('YYYY-MM-DD')

                    activities.unshift({
                        isNew: true,
                        selected: false,
                        ID: utils.createUUID(),
                        UserID_FK: this.state.data.ID,
                        Active: newStatus,
                        StartDate: date,
                        EndDate: null
                    })

                    if (activities.length === 1 && Date.parse(utils.moment(date).format('YYYY-MM-DD')) > Date.parse(utils.moment().format('YYYY-MM-DD')))
                        activities.push({
                            isNew: true,
                            selected: false,
                            ID: utils.createUUID(),
                            UserID_FK: this.state.data.ID,
                            Active: !newStatus,
                            StartDate: utils.moment().format('YYYY-MM-DD'),
                            EndDate: utils.moment(date).subtract(1, 'days').format('YYYY-MM-DD')
                        })

                    if (utils.moment(date).format('YYYY-MM-DD') !== utils.moment().format('YYYY-MM-DD'))
                        newStatus = !newStatus
                    
                    this.setState(prevState => ({
                        data: {
                            ...prevState.data,
                            status: newStatus ? 'active' : 'inactive',
                            activities
                        }
                    }))
                }
            }, {
                label: i18n('warnings', 'cancel'),
                role: 'cancel',
                appearance: 'danger'
            }],
            links: [{
                text: i18n('warnings', 'changes_advanced'),
                onClick: () => this._changeModes(true, 'activities')
            }]
        })
    }

    _onShadowSwitchChange = (onoff) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                shadow: onoff ? 1 : 0
            }
        }))
    }
    _onSelectChange = (item) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                roleType: item.value
            }
        }))
    }

    _onSuggestionSelected = (item) => {
        let companyInfo = this.state.data.companyInfo

        companyInfo.id = item.id ? item.id : companyInfo.id
        companyInfo.companyname = item.label ? item.label : companyInfo.companyname
        companyInfo.street = item.data ? item.data.street : companyInfo.street
        companyInfo.city  = item.data ? item.data.city : companyInfo.city
        companyInfo.zipCode = item.data ? item.data.zipCode : companyInfo.zipCode
        companyInfo.organizationNumber = item.data ? item.data.organizationNumber : companyInfo.organizationNumber
        companyInfo.country = item.data ? item.data.country : companyInfo.country

        this.setState({
            data: {
                ...this.state.data,
                companyInfo: companyInfo
            }
        })
    }


    _onEditableFieldChange = (name, value) => {
        switch (name) {
            case 'street':
            case 'zipCode':
            case 'city':
            case 'country':
            case 'companyname':
            case 'organizationNumber':
                if (this.state.data.postalInfo !== undefined) {
                    this.setState(prevState => ({
                        data: {
                            ...prevState.data,
                            postalInfo: {
                                ...prevState.data.postalInfo,
                                [name]: value
                            }
                        }
                    }))
                }

                if (this.state.data.companyInfo !== undefined) {
                    this.setState(prevState => ({
                        data: {
                            ...prevState.data,
                            companyInfo: {
                                ...prevState.data.companyInfo,
                                [name]: value
                            }
                        }
                    }))
                }

                break
            case 'fixedfee':
            case 'fixedhours':
                if (!isNaN(value)) {
                    if (value[0] === "-") value = value.substring(1)

                    value = parseInt(value, 10) === 0 ? value : value.replace(/^0+/, '') //unless value is zero, remove all leading zeros
                } else {
                    value = this.state.data[name]
                }
                break
            default: break
        }

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            }
        }))
    }

    _onRowChecked = (type, colKey, rowIndex, checked) => {
        const rows = [...this.state.data[type]]
        rows[rowIndex][colKey] = checked
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [type]: rows
            }
        }))
    }

    _onPayrollSwitchChanged = (checked, index) => {
        const data = {...this.state.data }
        data.wages[index]['Payroll'] = checked
        delete data.wages.stopAnimation
        this.setState({ data })
    }

    _onStatusSwitchChanged = (checked, index) => {
        const data = {...this.state.data }
        data.activities[index]['Active'] = checked
        delete data.activities.stopAnimation
        this.setState({ data })
    }

    _onRemoveSelected = async (type) => {
        const warningMsg = type === 'assignings' ? 'confirmRemoveAssigning' : false
        const rowsToDelete = [...this.state.data[type]].filter(row => row.selected)

        if (!warningMsg) this._removeSelected(type)
        else if (rowsToDelete.length > 0) {
            const result = await api('/project/general/willAffectTimereports', {
                ID: this.state.data.ID,
                assigningsToDelete : rowsToDelete
            })

            if (result.affectedResources.length > 0) {
                ShowMessageBox(this, {
                    title: i18n('warnings', 'warning'),
                    message : utils.format(i18n("warnings", warningMsg), [result.affectedResources.join()]),
                    buttons : [
                        {
                            label: i18n('warnings', 'confirm'),
                            role: 'confirm',
                            appearance: 'primary',
                            onClick: () => {
                                this._removeSelected(type);
                            }
                        }, {
                            label: i18n('warnings', 'cancel'),
                            role: 'cancel',
                            appearance: 'danger',
                        }
                    ]
                });
            } else {
                this._removeSelected(type)
            }
        }
    }

    _removeSelected = (type) => {
        // filter out newRows that are marked to be deleted, add a 'delete' flag to other marked rows
        const rows = [...this.state.data[type]].filter(row =>!row.selected || !row.isNew).map(row =>
            row.selected ? {...row, delete: true } : {...row })

        rows.stopAnimation = true

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [type]: rows
            }
        }))
    }

    _onAddRow = (type, firstElement = null) => {
        let rows = [...this.state.data[type]]

        if (type === 'wages') {
            rows.stopAnimation = true

            rows.unshift({
                isNew: true,
                selected: false,
                ID: utils.createUUID(),
                StartDate: '',
                HourlySalary: '',
                MonthlySalary: '',
                WorkPercent: '100',
                Payroll: true,
                Pension: 0
            })
        } else if (type === 'assignings') {
            rows.unshift({
                isNew: true,
                selected: false,
                ID: utils.createUUID(),
                UserID_FK: '',
                Fee: '',
                SM: 100,
                StartDate: '',
                EndDate: ''
            })
        } else if (type === 'activities') {
            rows.stopAnimation = true
            rows.unshift({
                isNew: true,
                selected: false,
                ID: utils.createUUID(),
                UserID_FK: this.state.data.ID,
                StartDate: firstElement && firstElement.EndDate ? utils.moment(firstElement.EndDate).add(1, 'days').format('YYYY-MM-DD') : '',
                EndDate: '',
                Active: firstElement ? !firstElement.Active : true
            })
        } else if (type === 'extras') {
            rows.unshift({
                isNew: true,
                selected: false,
                ID: utils.createUUID(),
                Label: '',
                Cost: '',
                Fee: '',
                StartDate: '',
                EndDate: '',
            })
        }

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [type]: rows
            }
        }))
    }

    _onShowTagWindow = () => {
        document.getElementsByClassName("scrollable-y")[0].style.overflowY = "hidden"
        this.setState({filterModeVisible: true})
    }

    _onDismiss = () => {
        document.getElementsByClassName("scrollable-y")[0].style.overflowY = "auto"
        this.setState({
            filterModeVisible: false, 
            tagRows: [[]]
        })
    }

    _onSubmitResourcesByTag = async () => {
        let tagRows = this.state.tagRows
        
        if (tagRows.length > 0 ) {
            const { data } = this.state
            const fetchData = {
                settings: {
                    tags: tagRows.filter(tagRow => tagRow.length > 0)
                }
            }
            const response = await api('/users/tag/get', fetchData)
            const resources = Object.values(response.data)

            if (resources.length > 0) {
                let rows = data.assignings

                resources.map(res => {
                    rows.unshift({
                        isNew: true,
                        selected: false,
                        ID: utils.createUUID(),
                        UserID_FK: res.ID,
                        UserName: res.FirstName + ' ' + res.LastName,
                        Fee: "0",
                        SM: 100,
                        StartDate: res.startDate,
                        EndDate: ''
                    })

                    return true
                })

                this.setState(prevState => ({ 
                    data: {
                        ...prevState.data,
                        assignings: rows
                    }
                }))
            }
        }

        this._onDismiss()
    }

    _onTagAdd = (tag, tagRowIndex) => {
        if (tag) {
            this.setState(prevState => ({
                tagRows: [...prevState.tagRows.map((tagRow, i) =>
                    tagRowIndex === i
                        ? [...tagRow, tag]
                        : [...tagRow]
                ).filter((tagRow, i) => i === 0 || tagRow.length > 0)]
            }))
        }
    }

    _onTagRemove = (tagIndex, tagRowIndex) => {
        if (this.state.tagRows[tagRowIndex].length === 0) return

        this.setState(prevState => ({
            hasChanged: true,
            tagRows: [...prevState.tagRows.map((tagRow, i) =>
                tagRowIndex === i
                    ? [...tagRow.filter((tag, i) => tagIndex !== i)]
                    : [...tagRow]
            ).filter((tagRow, i) => i === 0 || tagRow.length > 0)]
        }))
    }

    _onTagRowAdd = () => {
        this.setState(prevState => ({ tagRows: [...prevState.tagRows, []] }))
    }

    _onAssignAll = async (e) => {
        e.preventDefault()
        const { data } = this.state
        const response = await api('/globals/getall', { type: 'user', withStartDate: data.shadow }) // auto-set earliest possible start date if it's a shadow project
        const resources = Object.values(response.data)

        if (resources.length > 0) {
            let rows = data.assignings

            resources.map(res => {
                let taken = rows.some(row => row.UserName === res.name)

                if (!taken) {
                    rows.push({
                        isNew: true,
                        selected: false,
                        ID: utils.createUUID(),
                        UserID_FK: res.ID,
                        UserName: res.name,
                        Fee: "0",
                        SM: 100,
                        StartDate: res.startDate,
                        EndDate: "",
                    })
                }

                return true
            })

            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    assignings: rows
                }
            }))
        }
    }
})


// PRIVATE COMPONENTS
class EditToggler extends React.PureComponent {

    render() {
        const { props } = this;
        const { active } = props;
        const classes = createClassName("ContextReviewContent-EditToggler", {
            "active": active
        });

        return (
            <div className={ classes }>
                <Div className={"toggler"} onClick={ this._onToggle } $active={active} >
                    <Div className="icon material-icons">build</Div>
                    <Div className="text">{ i18n("general", "edit") }</Div>
                </Div>
                <EditSubModeNav { ...props } />
            </div>
        );
    }

    // Internal methods
    _onToggle = () => {
        const { active, subMode, onChange } = this.props;
        if(onChange) onChange(!active, subMode);
    }
}

function EditSubModeNav({ type, active, subMode: activeSubMode, onChange }) {
    const Item = ({ label, subMode }) => (
        <Div className={ createClassName("item", { "active": activeSubMode === subMode }) } onClick={ onChange && (() => onChange(active, subMode)) }>{ label }</Div>
    );

    if(type === "resource") {
        return (
            <Nav>
                <Item label={ i18n("general", "general") }     subMode="general" />
                <Item label={ i18n("general", "settings") }    subMode="settings" />
                <Item label={ i18n("general", "wage_growth") } subMode="wages" />
                {activeSubMode === 'activities' && <Item label={ i18n("general", "activity") }    subMode="activities" />}
            </Nav>
        );
    } else if(type === "project") {
        return (
            <Nav>
                <Item label={ i18n("general", "general") }      subMode="general" />
                <Item label={ i18n("general", "settings") }     subMode="settings" />
                <Item label={ i18n("general", "extras") }       subMode="extras" />
                <Item label={ i18n("timereport", "resources") } subMode="resources" />
            </Nav>
        );
    } else if(type === "company") {
        return (
            <Nav>
                <Item label={ i18n("general", "general") }      subMode="general" />
            </Nav>
        );
    }
}

// PRIVATE FUNCTIONS
function _createDataTemplate() {
    const { type } = this.props;
    if (type === "resource") {
        return {
            roleType: "employee",
            name: "",
            firstName: "",
            lastName: "",
            status: "inactive",
            ssn: "",
            birthDate: "",
            tags: [],
            salary: { salaryType: "", monthly: "", hourly: "" },
            wages: [],
            activities: [],
            postalInfo: { street: "", zipCode: "", city: "", country: "" },
            email: "",
            phoneNumber: "",
            color: Settings.getGlobalColor('primary'),
            textColor: Settings.getGlobalColor('overlay'),
            avatarPath: "",
            cellImagePath: "",
            password: '',
            password2: ''
        };
    } else if (type === 'project') {
        return {
            name: "",
            fixedfee: "",
            fixedhours: "",
            avatarPath: "",
            color: Settings.getGlobalColor('primary'),
            textColor: Settings.getGlobalColor('overlay'),
            contactPerson: "",
            phoneNumber: "",
            tags: [],
            extras: [],
            email: "",
            companyInfo: {
              companyname: "",
              street: "",
              organizationNumber: "",
              zipCode: "",
              city: "",
              country: ""
            },
            assignings: [],
            status: "active",
            shadow: 0,
            cellImagePath: ""
        };
    } else if (type === 'company') {
        return {
            Name: '',
            OrgNr: '',
            Address: '',
            PostNr: '',
            City: '',
            Country: ''
        }
    }
}

function _getOverviewContent(type, data, isManager) {
    const { state } = this, { postalInfo, companyInfo } = state.data
    const Item = ({ className, label, value, address }) => {
        let url = false
        if (address && (postalInfo || companyInfo)) {
            let info = postalInfo || companyInfo
            url = info.street && 'https://www.google.com/maps/search/' + `${info.country} ${info.city} ${info.street}`.trim()
        } else if (type === 'company' && address) {
            url = state.data.Address && 'https://www.google.com/maps/search/' + `${state.data.Country} ${state.data.City} ${state.data.Address}`.trim()
        }
        return <Li className={ "item " + className }>
            <Strong className="label">{ label } </Strong>
            <span className="value">
                { value }
                {url && <Marker id='location_link' className='material-icons' target='_blank' href={url}>location_on</Marker>}
            </span>
        </Li>
    };

    if (type === "resource") {
        const { ssn, birthDate, email, phoneNumber, postalInfo, tags, salary } = data;

        let dailyHours = 8 * salary?.workPercent / 100
        let workdays = utils.getWorkdaysBetween(utils.moment().startOf('month'), utils.moment().endOf('month'))
        let currentMonth = new Date().toLocaleString('default', { month: 'long' })

        return (
            <div className="overview-content">
                <H3>{ i18n("general", "general") }</H3>
                <Ul className="table general">
                    <Item className="email"          label={ i18n("general", "email") }          value={ email } />
                    <Item className="ssn even"       label={ i18n("general", "ssn") }            value={ ssn } />
                    <Item className="bithDate"       label={ i18n("general", "birth_date") }     value={ birthDate } />
                    <Item className="phone even"     label={ i18n("general", "phone_number") }   value={ phoneNumber } />
                    <Item className="street" address label={ i18n("general", "street_address") } value={ postalInfo.street } />
                    <Item className="zipCode even"   label={ i18n("general", "zip_code") }       value={ postalInfo.zipCode } />
                    <Item className="city "          label={ i18n("general", "city") }           value={ postalInfo.city } />
                    <Item className="country even"   label={ i18n("general", "country") }        value={ postalInfo.country } />
                </Ul>

                {isManager && salary && (salary.monthly !== null || salary.hourly !== null) && (<React.Fragment>
                    <H3>{ i18n("general", "salary") }</H3>
                    <Ul className="table">
                        <Item
                            className="salary"
                            label={ i18n("general", "full_time") + ' ' + i18n("general", "salary") + ' ' + i18n("general", "in") + ' ' + currentMonth }
                            value={ salary.monthly ? salary.monthly + ` ${Settings.get("CURRENCY")}` : salary.hourly * dailyHours * workdays + ` ${Settings.get("CURRENCY")} (${salary.hourly} ${Settings.get("CURRENCY")}/${i18n("general", "hour")})` } />
                    </Ul>
                </React.Fragment>)}

                {isManager && (<React.Fragment>
                    <H3>{ i18n("general", "other") }</H3>
                    <Ul className="table other">
                        <Item className="tags" label={ i18n("general", "tags") } value={ tags.map(tag => tag.Name).join(", ") || "n/a" } />
                    </Ul>
                </React.Fragment>)}
            </div>
        );
    } else if(type === "project") {
        const { email, phoneNumber, contactPerson, companyInfo, tags = {} } = data;

        return (
            <div className="overview-content">
                <H3>{ i18n("general", "general") }</H3>
                <Ul className="table general">
                    <Item className="email"         label={ i18n("general", "email") }          value={ email } />
                    <Item className="phone even"    label={ i18n("general", "phone_number") }   value={ phoneNumber } />
                    <Item className="contactPerson" label={ i18n("general", "contact_person") } value={ contactPerson } />
                </Ul>

                <H3>
                    { i18n("general", "company") }
                    {companyInfo.avatarPath && <img className="companyLogo" src={getAssetsURIForPath(companyInfo.avatarPath)} alt="" />}
                </H3>
                <Ul className="table company">
                    <Item className="name"                    label={ i18n("general", "company") }             value={ companyInfo.companyname } />
                    <Item className="organizationNumber even" label={ i18n("general", "organization_number") } value={ companyInfo.organizationNumber } />
                    <Item className="street"      address     label={ i18n("general", "street_address") }      value={ companyInfo.street } />
                    <Item className="zipCode even"            label={ i18n("general", "zip_code") }            value={ companyInfo.zipCode } />
                    <Item className="city"                    label={ i18n("general", "city") }                value={ companyInfo.city } />
                    <Item className="country even"            label={ i18n("general", "country") }             value={ companyInfo.country } />
                </Ul>

                {isManager && (<React.Fragment>
                    <H3>{ i18n("general", "other") }</H3>
                    <Ul className="table other">
                        <Item className="tags" label={ i18n("general", "tags") } value={ tags.map(tag => tag.Name).join(", ") || "n/a" } />
                    </Ul>
                </React.Fragment>)}
            </div>
        );
    } else if (type === "company") {
        const { Name, OrgNr, Address, PostNr, City, Country } = data;

        return (
            <div className="overview-content">
                <H3>{ i18n("general", "company") }</H3>
                <Ul className="table company">
                    <Item className="name"                    label={ i18n("general", "company") }             value={ Name } />
                    <Item className="organizationNumber even" label={ i18n("general", "organization_number") } value={ OrgNr } />
                    <Item className="street"      address     label={ i18n("general", "street_address") }      value={ Address } />
                    <Item className="zipCode even"            label={ i18n("general", "zip_code") }            value={ PostNr } />
                    <Item className="city"                    label={ i18n("general", "city") }                value={ City } />
                    <Item className="country even"            label={ i18n("general", "country") }             value={ Country } />
                </Ul>
            </div>
        );
    }
}

async function checkAffectedAssignings(projectId,data,context, callback) {
    const checkUrl = "/project/general/willAffectTimereports";
    const result = await api(checkUrl, {ID: data.ID, assigningsToCheckIfEdited : data.assignings});

    if(result.affectedResources.length > 0){
        ShowMessageBox(context,
        {
            title : i18n("warnings", "warning"),
            message : utils.format(i18n("warnings", "confirmEditAssigning"),[result.affectedResources.join()]),
            buttons : [
                {
                    label: i18n('warnings', 'confirm'),
                    role: 'confirm',
                    appearance: 'primary',
                    onClick: () => {
                        callback();
                    }
                },
                {
                    label: i18n('warnings', 'cancel'),
                    role: 'cancel',
                    appearance: 'danger',
                }
            ]
        });
    } else {
      callback();
    }
}

function showDeleteWarning(context,type, callback){
    ShowMessageBox(context,
    {
        title : i18n("warnings", "warning"),
        message : utils.format(i18n("warnings", "confirmRemove"),[type]),
        buttons : [
            {
                label: i18n('warnings', 'confirm'),
                role: 'confirm',
                appearance: 'primary',
                onClick: () => {
                    callback();
                }
            },
            {
                label: i18n('warnings', 'cancel'),
                role: 'cancel',
                appearance: 'danger',
            }
        ]
    });
}

async function submitData(context, type, subMode) {
    const { data, cellUploadData, avatarUploadData } = context.state

    const action = data.ID ? "save" : "create"
    const url = (type === "resource" ? "/users" : type === 'project' ? "/project" : 'company')  + "/" + subMode + "/" + action

    console.log('url', url);
    console.log('data', JSON.stringify(data))
    const result = await api(url, data)

    if (!result.success) {
        if (result.affectedAssignings) {
            ShowMessageBox(context, {
                message: result.message,
                affectedAssignings: result.affectedAssignings,
                buttons: [
                    {
                        label: i18n('general', 'save'),
                        appearance: 'primary',
                        onClick: async (newAssignings) => {
                            const url = "/project/assignings/save"
                            const result = await api(url, {assignings: newAssignings})
                            return result
                        }
                    },
                    {
                        label: i18n('warnings', 'cancel'),
                        appearance: 'danger',
                        onClick: async () => {
                            return {success: true}
                        }
                    }
                ]
            })
        } else {
            ShowMessageBox(context, {
                message: result.message
            })
        }
        
        context.setState({ markedPeriods: result.ids })

        return
    } else {
        console.log(result)

        if (action === 'save' && type === 'resource' && context.props.reducers.app.user.id === data.ID && context.props.context.roleType !== data.roleType) {
            localStorage.clear()
            window.location.href = "/"
        }
    }

    if (cellUploadData) {
        let data = cellUploadData

        if (data.content) {
            const url = (type === "resource" ? "/users" : "/project") + "/avatar"

            if (!cellUploadData.ID) {
                if (type === "resource") {
                  data.ID = result.user.ID
                } else {
                  data.ID = result.project.ID
                }
            }

            await api(url, data)
        } else {
            const url = (type === "resource" ? "/users" : "/project") + "/avatar/delete"

            if (!cellUploadData.ID) {
                if (type === "resource") {
                  data.ID = result.user.ID
                } else {
                  data.ID = result.project.ID
                }
            }

            await api(url, data)
        }
    }

    if (avatarUploadData) {
        let data = avatarUploadData

        if (data.content) {
            const url = (type === "resource" ? "/users" : type === 'project' ? "/project" : 'company') + "/avatar"

            if (!avatarUploadData.ID) {
                if (type === "resource") {
                  data.ID = result.user.ID
                } else if (type === 'company') {
                  data.ID = result.company.ID
                } else {
                  data.ID = result.project.ID
                }
            }

            console.log('url', url)
            console.log('', JSON.stringify(data))
            await api(url, data)
        } else {
            const url = (type === "resource" ? "/users" : type === 'project' ? "/project" : 'company') + "/avatar/delete"

            if (!avatarUploadData.ID) {
                if (type === "resource") {
                  data.ID = result.user.ID
                } else if (type === 'company') {
                  data.ID = result.company.ID
                } else {
                  data.ID = result.project.ID
                }
            }

            await api(url, data)
        }
    }

    const { onSave } = context.props
    if (onSave) onSave()
}

function _getEditContent(type, data, subMode) {
    const { state } = this;

    const onFormSubmit = async () => {
        const action = data.ID !== undefined ? "save" : "create";

        if (type === "project" && action === "save") {
            checkAffectedAssignings(data.ID, data, this, () => submitData(this, type, subMode))
        } else if (type === "resource" && data.activities.filter(e => e.Active && !e.delete).length === 0) {
            ShowMessageBox(this, {
                prevUserStatus: 1,
                message : i18n('warnings', 'date_of_activation'),
                datepicker: true,
                buttons: [{
                    label: i18n('warnings', 'confirm'),
                    role: 'confirm',
                    appearance: 'primary',
                    onClick: (date) => {
                        let activities = [{
                            isNew: true,
                            selected: false,
                            ID: utils.createUUID(),
                            UserID_FK: data.ID,
                            Active: 1,
                            StartDate: date,
                            EndDate: null
                        }]

                        if (Date.parse(utils.moment(date).format('YYYY-MM-DD')) > Date.parse(utils.moment().format('YYYY-MM-DD'))) {
                            activities.push({
                                isNew: true,
                                selected: false,
                                ID: utils.createUUID(),
                                UserID_FK: data.ID,
                                Active: 0,
                                StartDate: utils.moment().format('YYYY-MM-DD'),
                                EndDate: utils.moment(date).subtract(1, 'days').format('YYYY-MM-DD')
                            })
                        }
                        
                        this.setState({
                            data: {
                                ...this.state.data,
                                status: utils.moment(date).format('YYYY-MM-DD') === utils.moment().format('YYYY-MM-DD') ? 'active' : 'inactive',
                                activities
                            }
                        })
                    }
                }, {
                    label: i18n('warnings', 'cancel'),
                    role: 'cancel',
                    appearance: 'danger'
                }],
                links: [{
                    text: i18n('warnings', 'changes_advanced'),
                    onClick: () => this._changeModes(true, 'activities')
                }]
            })
        } else {
            submitData(this, type, subMode)
        }
    }

    const onDelete = async(formData) => {
        showDeleteWarning(this, type, () => {
            this.setState({ busy: true }, async () => {
                const url = (type === "resource" ? "/users" : (type === 'project' ? "/project" : 'company')) + "/delete"
                const data = { ID: this.state.data.ID }
                const user = this.props.reducers.app.user
                console.log(this.state.data)

                let deletable = true

                if (type === 'resource' && user.id === data.ID) {
                    const response = await api('users/general/isLastManager', data)
                    if (response.isLastManager) deletable = false
                }

                if (deletable) {
                    await api(url, data)

                    if (type === 'resource' && user.id === data.ID) {
                        localStorage.clear()
                        window.location.href = "/"
                    } else {
                        const { onSave } = this.props
                        if (onSave) onSave()
                    }
                } else {
                    this.setState({ busy: false })

                    ShowMessageBox(this, {
                        message : i18n('warnings', 'cannot_delete_last_manager'),
                        buttons: [{
                            label: i18n('warnings', 'cancel'),
                            role: 'cancel',
                            appearance: 'primary'
                        }]
                    })
                }
            })
        })
    }

    if(type === "resource") {
        const { ID, firstName, lastName, email, phoneNumber, postalInfo, color, textColor, roleType, ssn, birthDate, activities } = data;
        const { password, password2 } = data;
        const { tags, wages, cellImagePath } = data;
        const { cellUploadData, backgroundColorPaletteVisible, textColorPaletteVisible, markedPeriods } = state;



        const roleTypeOptions = [
            { label: i18n("general", "employee"), value: "employee" },
            { label: i18n("general", "manager"), value: "manager" }
        ];
        const selectedRoleTypeIndex = roleTypeOptions.indexOf(roleTypeOptions.filter(rt => rt.value === roleType)[0]);



        const filteredActivities = activities.filter(e => Date.parse(utils.moment().format('YYYY-MM-DD')) >= Date.parse(e.StartDate)
            && (Date.parse(utils.moment().format('YYYY-MM-DD')) <= Date.parse(e.EndDate) || !e.EndDate)
            && !e.delete)
        const status = filteredActivities[0] ? (filteredActivities[0].Active ? 'active' : 'inactive') : 'inactive'

        return (
            <div className="edit-content">
                <Form onSubmit={ onFormSubmit } autoComplete={ false }>
                    { subMode === "general" ? (
                        <React.Fragment>
                            <EditableField key="firstName"   type="text"                       name="firstName"   label={ i18n("general", "first_name") }     value={ firstName }          onChange={ this._onEditableFieldChange } split required/>
                            <EditableField key="lastName"    type="text"                       name="lastName"    label={ i18n("general", "last_name") }      value={ lastName }           onChange={ this._onEditableFieldChange } split />
                            <EditableField key="email"       type="text"     className="split" name="email"       label={ i18n("general", "email") }          value={ email }              onChange={ this._onEditableFieldChange } split required/>
                            <EditableField key="ssn"         type="text"     className="split" name="ssn"         label={ i18n("general", "ssn") }            value={ ssn }                onChange={ this._onEditableFieldChange } split required/>
                            <EditableField key="phoneNumber" type="text"     className="split" name="phoneNumber" label={ i18n("general", "phone_number") }   value={ phoneNumber }        onChange={ this._onEditableFieldChange } split />
                            <EditableField key="street"      type="text"     className="split" name="street"      label={ i18n("general", "street_address") } value={ postalInfo.street }  onChange={ this._onEditableFieldChange }  />
                            <EditableField key="zipCode"     type="text"     className="split" name="zipCode"     label={ i18n("general", "zip_code") }       value={ postalInfo.zipCode } onChange={ this._onEditableFieldChange } split />
                            <EditableField key="city"        type="text"     className="split" name="city"        label={ i18n("general", "city") }           value={ postalInfo.city }    onChange={ this._onEditableFieldChange } split />
                            <EditableField key="country"     type="text"                       name="country"     label={ i18n("general", "country") }        value={ postalInfo.country } onChange={ this._onEditableFieldChange } split />
                            <EditableField key="birthDate"   type="text"                       name="birthDate"   label={ i18n("general", "birth_date_ph") }  value={ birthDate }          onChange={ this._onEditableFieldChange } split required />
                            <EditableField key="password"    type="password" className="split" name="password"    label={ i18n("login", "password") }         value={ password }           onChange={ this._onEditableFieldChange } placeholder={ ID ? i18n("general", "leave_blank_to_keep_current") : "" } split required={data.ID ? !!password2 : true} autoComplete="new-password"/>
                            <EditableField key="password2"   type="password" className="split" name="password2"   label={ i18n("login", "repeat_password") }  value={ password2 }          onChange={ this._onEditableFieldChange } placeholder={ ID ? i18n("general", "leave_blank_to_keep_current") : "" } split required={data.ID ? !!password : true}  autoComplete="new-password"/>
                        </React.Fragment>
                    ) : subMode === "settings" ? (
                        <React.Fragment>
                            <H3>{ i18n("editable_table", "status") }</H3>
                            <StackLayout justifyContent="space-between">
                                <div className='userStatus-container'>
                                    <div>
                                    <Status className='userStatus' $active={status === 'active'}>{status}</Status>
                                    {filteredActivities[0] && filteredActivities[0].EndDate && <span className='userStatusEndDate'>
                                        {` ${i18n('general', 'until')} ${filteredActivities[0].EndDate}`}
                                    </span>}
                                    </div>
                                    
                                    <Button label={ i18n("general", "change_status") } appearance="secondary" onClick={this._onUserStatusChange} size='small' filled />
                                </div> 
                        
                                <Select style={{ maxWidth: "130px" }} type="reminder" selectedIndex={selectedRoleTypeIndex} onChange={this._onSelectChange} options={roleTypeOptions} />
                            </StackLayout>
                            <H3>{ i18n("general", "tags") }</H3>
                            {console.log(tags)}
                            <TagField key="tags" name="tags"   label={ i18n("general", "tags") }   placeholder={ i18n("components", "separate_with_enter") }
                                tags={ tags }
                                onTagAdd={ this._onTagsTagAdd }
                                onTagRemove={ this._onTagsTagRemove }
                                canCreateTag
                            />
                            <H3>{ i18n("general", "overview_cell_design") }</H3>
                            <div className="overview-cell-design-container">
                                <StyledCellPreview className="cellpreview" 
                                    style={ cellUploadData ?
                                        { backgroundImage: `url(${ cellUploadData.content })`, backgroundColor: color } :
                                            cellImagePath ?
                                                {backgroundImage: `url(${ getAssetsURIForPath(cellImagePath) })`, backgroundColor: color} :
                                                {backgroundColor: color}
                                    }
                                >
                                    <h1 style={{color:textColor, marginTop:"0", textAlign:"center"}}>8</h1>
                                </StyledCellPreview>

                                <div className="button-container">
                                    <Button label={ `${i18n('general', 'edit_image')}` } appearance="secondary" onClick = { this._onCellPreviewClick } size='small' filled />
                                    <div className="split">
                                        <div ref={bgColorRef => this.bgColorRef = bgColorRef} >
                                            <Button label={ `${i18n('general', 'background')}` } appearance="secondary" onClick = { () => this._onColorpickerClick('backgroundcolor') } size='small' filled />
                                        </div>
                                        <div ref={bgColorRefPreview => this.bgColorRefPreview = bgColorRefPreview} className='color_picker' style={{display: backgroundColorPaletteVisible ? 'block' : 'none', position: 'absolute'}}>
                                            <SketchPicker
                                                color={color}
                                                disableAlpha={ false }
                                                onChange={this._onColorChange}
                                            />
                                            <div className ={ "icon material-icons deleteColorPreview" } onClick = { this._onColorRemove } >delete</div>
                                        </div>
                                    </div>
                                    <div className="split">
                                        <div ref={textColorRef => this.textColorRef = textColorRef} >
                                            <Button label={ `${i18n('general', 'text_color')}` } appearance="secondary" onClick = { () => this._onColorpickerClick('textcolor') } size='small' filled />
                                        </div>
                                        <div ref={textColorRefPreview => this.textColorRefPreview = textColorRefPreview} className='color_picker' style={{display: textColorPaletteVisible ? 'block' : 'none', position: 'absolute'}}>
                                            <SketchPicker
                                                color={textColor}
                                                disableAlpha={ false }
                                                onChange={this._onTextColorChange}/>
                                                <div className ={ "icon material-icons deleteColorPreview" } onClick = { this._onTextColorRemove } >delete</div>
                                        </div>
                                    </div>
                                </div>
                           </div>
                        </React.Fragment>
                    ) : subMode === "wages" ? (
                        <EditableTable
                            context={ this }
                            type={ "wages" }
                            rows={ wages }
                            columns={[
                                { key: "selected",       label: "",                                            width: "5%"  },
                                { name: "StartDate",     label: `${i18n('editable_table', 'start_date')}`,     width: "15%" },
                                { name: "HourlySalary",  label: `${i18n('editable_table', 'hourly_salary')}`,  width: "15%" },
                                { name: "MonthlySalary", label: `${i18n('editable_table', 'monthly_salary')}`, width: "20%" },
                                { name: "WorkPercent",   label: `${i18n('editable_table', 'work_percent')}`,   width: "10%" },
                                { name: "Payroll",       label: `${i18n('editable_table', 'payroll')}`,        width: "10%" },
                                { name: "Pension",       label: `${i18n('editable_table', 'pension')}`,        width: "15%" }
                            ]}
                            onRowChecked={ this._onRowChecked }
                            onRemoveSelected={ this._onRemoveSelected }
                            onAddRow={ this._onAddRow }
                            onPayRollChanged ={ this._onPayrollSwitchChanged }
                        />
                    ) : subMode === "activities" ? (
                        <EditableTable
                            context={ this }
                            type={ "activities" }
                            rows={ activities }
                            columns={[
                                { key: "selected",   label: "",                                        width: "5%" },
                                { name: "Active",    label: `${i18n('editable_table', 'status')}`,     width: "10%" },
                                { name: "StartDate", label: `${i18n('editable_table', 'start_date')}`, width: "15%" },
                                { name: "EndDate",   label: `${i18n('editable_table', 'end_date')}`,   width: "15%" }
                            ]}
                            onRowChecked={ this._onRowChecked }
                            onRemoveSelected={ this._onRemoveSelected }
                            onAddRow={ this._onAddRow }
                            onStatusChanged ={ this._onStatusSwitchChanged }
                            markedPeriods={ markedPeriods }
                        />
                    ) : null }

                    <StackLayout orientation="horizontal">
                        {subMode === "settings" && (status ? status !== "active" : false) && ID &&
                            <Button label={ i18n("general", "delete") } appearance="danger" filled onClick={onDelete} busy={state.busy}/>}
                        <Button type="submit" label={ i18n("general", ID ? 'save' : 'create') } appearance="primary" filled />
                    </StackLayout>
                </Form>
            </div>
        );
    } else if(type === "project") {
        const { ID, name, email, phoneNumber, contactPerson, companyInfo = {
            companyname: '', organizationNumber: '', street: '', zipCode: '', city: '', country: ''
        }, color, textColor, status, fixedfee, fixedhours, shadow } = data;

        const { tags, assignings, extras } = data;
        const { cellImagePath } = data;
        const { cellUploadData, backgroundColorPaletteVisible, textColorPaletteVisible, markedPeriods, tagRows } = state;
        return (
            <div className="edit-content">
                <Form onSubmit={ onFormSubmit } autoComplete={ false }>
                    { subMode === "general" ? (
                        <React.Fragment>
                            <EditableField key="name"          type="text" name="name"          label={ i18n("general", "name") }           value={ name }          onChange={ this._onEditableFieldChange } split required/>
                            <EditableField key="email"         type="text" name="email"         label={ i18n("general", "email") }          value={ email }         onChange={ this._onEditableFieldChange } split />
                            <EditableField key="phoneNumber"   type="text" name="phoneNumber"   label={ i18n("general", "phone_number") }   value={ phoneNumber }   onChange={ this._onEditableFieldChange } split />
                            <EditableField key="contactPerson" type="text" name="contactPerson" label={ i18n("general", "contact_person") } value={ contactPerson } onChange={ this._onEditableFieldChange } split />
                            
                            <div style={{width: '50%'}}>
                                <InnerTitle>Company</InnerTitle>
                                <Autocomplete
                                    value={ companyInfo.companyname }
                                    item = { companyInfo.id }
                                    onSelect = { this._onSuggestionSelected }
                                    onChange={this._onEditableFieldChange }
                                    searchUrl = "/project/general/search/"
                                    key="companyname" name="companyname"
                                    required={!!companyInfo.companyname || !!companyInfo.organizationNumber}
                                />
                                <EditableField key="organizationNumber" type="text" name="organizationNumber" label={ i18n("general", "organization_number") } value={ companyInfo.organizationNumber } onChange={ this._onEditableFieldChange } required={!!companyInfo.companyname || !!companyInfo.organizationNumber} />
                            </div>
                        </React.Fragment>
                    ) : subMode === "settings" ? (
                        <React.Fragment>
                            <Switch key="active" name="active" label={ i18n("general", "active") } checked={ status === "active" } onChange={ this._onSwitchChange } />
                            <Switch key="shadow" name="shadow" label={ i18n("general", "shadow") } checked={ !!shadow }            onChange={ this._onShadowSwitchChange } />
                            <TagField key="tags" name="tags"   label={ i18n("general", "tags") }   placeholder={ i18n("components", "separate_with_enter") }
                                tags={ tags }
                                onTagAdd={ this._onTagsTagAdd }
                                onTagRemove={ this._onTagsTagRemove }
                                canCreateTag
                            />

                            <div className="overview-cell-design-container">
                                <StyledCellPreview className="cellpreview" 
                                    style={ cellUploadData ?
                                        { backgroundImage: `url(${ cellUploadData.content })`, backgroundColor: color } :
                                            cellImagePath ?
                                                {backgroundImage: `url(${ getAssetsURIForPath(cellImagePath) })`, backgroundColor: color} :
                                                {backgroundColor: color}
                                    }
                                >
                                    <h1 style={{color:textColor, marginTop:"0", textAlign:"center"}}>8</h1>
                                </StyledCellPreview>

                                <div className="button-container">
                                    <Button label={ `${i18n('general', 'edit_image')}` } appearance="primary" onClick = { this._onCellPreviewClick } size='small' filled />
                                    <div className="split">
                                        <div ref={bgColorRef => this.bgColorRef = bgColorRef} >
                                            <Button label={ `${i18n('general', 'background')}` } appearance="primary" onClick = { () => this._onColorpickerClick('backgroundcolor') } size='small' filled />
                                        </div>
                                        <div ref={bgColorRefPreview => this.bgColorRefPreview = bgColorRefPreview} className='color_picker' style={{display: backgroundColorPaletteVisible ? 'block' : 'none', position: 'absolute'}}>
                                            <SketchPicker
                                                color={color}
                                                disableAlpha={ false }
                                                onChange={this._onColorChange}/>
                                                <div className ={ "icon material-icons deleteColorPreview" } onClick = { this._onColorRemove } >delete</div>
                                        </div>
                                    </div>
                                    <div className="split">
                                        <div ref={textColorRef => this.textColorRef = textColorRef} >
                                            <Button label={ `${i18n('general', 'text_color')}` } appearance="primary" onClick = { () => this._onColorpickerClick('textcolor') } size='small' filled />
                                        </div>
                                        <div ref={textColorRefPreview => this.textColorRefPreview = textColorRefPreview} className='color_picker' style={{display: textColorPaletteVisible ? 'block' : 'none', position: 'absolute'}}>
                                            <SketchPicker
                                                color={textColor}
                                                disableAlpha={ false }
                                                onChange={this._onTextColorChange}/>
                                                <div className ={ "icon material-icons deleteColorPreview" } onClick = { this._onTextColorRemove } >delete</div>
                                        </div>
                                    </div>
                                </div>
                           </div>

                        </React.Fragment>
                    ) : subMode === "extras" ? (
                        <React.Fragment>
                                <EditableTable
                                context={ this }
                                type="extras"
                                shadow={!!shadow}
                                rows={ extras }
                                columns={[
                                    { key:  "selected",  label: "",                                        width: "5%" },
                                    { name: "StartDate", label: `${i18n('editable_table', 'start_date')}`, width: "14%" },
                                    { name: "EndDate",   label: `${i18n('editable_table', 'end_date')}`,   width: "14%" },
                                    { name: "Label",     label: `${i18n('editable_table', 'label')}`,      width: "20%" },
                                    { name: "Fee",       label: `${i18n('editable_table', 'fee')}`,        width: "13%" },
                                    { name: "Cost",      label: `${i18n('editable_table', 'cost')}`,       width: "13%" },
                                    { name: "Margin",    label: `${i18n('editable_table', 'margin')}`,     width: "12%" },
                                ]}
                                onAddRow={ this._onAddRow }
                                onRowChecked={ this._onRowChecked }
                                
                                onRemoveSelected={ this._onRemoveSelected }
                                onChange= { this._onEditableFieldChange }
                            />
                        </React.Fragment>
                    ) : subMode === "resources" ? (
                        <React.Fragment>
                            <EditableTable
                                context={ this }
                                type="assignings"
                                shadow={!!shadow}
                                rows={ assignings }
                                columns={[
                                    { key: "selected",   label: "",                                        width: "5%" },
                                    { name: "UserName",  label: `${i18n('editable_table', 'resource')}`,   width: "19%" },
                                    { name: "Fee",       label: `${i18n('editable_table', 'fee')}`,        width: "9%" },
                                    { name: "SM",        label: `${i18n('editable_table', 'sm')}`,         width: "8%" },
                                    { name: "StartDate", label: `${i18n('editable_table', 'start_date')}`, width: "14%" },
                                    { name: "EndDate",   label: `${i18n('editable_table', 'end_date')}`,   width: "14%" }
                                ]}
                                onRowChecked={ this._onRowChecked }
                                onRemoveSelected={ this._onRemoveSelected }
                                onAddRow={ this._onAddRow }
                                onAssignAll={ this._onAssignAll }
                                onChange= { this._onEditableFieldChange }
                                _onShowTagWindow = { this._onShowTagWindow }
                                onSuggestionSelected={ this._onSuggestionSelected }
                                disableHourlyFee={ fixedfee !== '' }
                                markedPeriods={ markedPeriods }
                            />
                            <Modal visible={ this.state.filterModeVisible && tagRows.length > 0 } onClosed={ this._onDismiss }>
                                {tagRows.length > 0 && tagRows.map((tagRow, index) => {
                                    return (
                                        <div key={index} style={{width:'100%'}}>
                                            <TagField removable
                                                showAmp
                                                name="tags"
                                                label={i18n("general", "tags")}
                                                tags={tagRow}
                                                onTagAdd={(tag) => this._onTagAdd(tag, index)}
                                                onTagRemove={(tagIndex) => this._onTagRemove(tagIndex, index)}
                                            />
                                            <div style={{ paddingLeft:'10px', fontSize: '14px' }}>
                                                {tagRows.length > 1 && index !== tagRows.length-1 && <div>OR</div>}
                                            </div>
                                            {index === tagRows.length-1 && <StackLayout orientation="horizontal" style={{ margin:"auto 10px auto 0" }}>
                                                <Button size="small" label={ i18n("general", "submit_tags") } appearance="secondary" filled onClick={this._onSubmitResourcesByTag}/>
                                                <div  style={{ float:'right', cursor: 'pointer', paddingRight:'10px', fontSize: '20px', height:"23.69px", margin:"auto 0 auto 0" }}
                                                    onClick={ this._onTagRowAdd.bind(this) }
                                                >
                                                    +
                                                </div>
                                            </StackLayout>}
                                        </div>
                                    );
                                })}     
                            </Modal>
                            
                            <EditableField key="fixedfee"   type="text" name="fixedfee"   label={ i18n("assignings", "fixedfee") }   value={ fixedfee }   onChange={ this._onEditableFieldChange } split />
                            <EditableField key="fixedhours" type="text" name="fixedhours" label={ i18n("assignings", "fixedhours") } value={ fixedhours } onChange={ this._onEditableFieldChange } split2 />
                        </React.Fragment>
                    ) : null }

                    <div style={{width: '100%'}}></div>

                    <StackLayout orientation="horizontal">
                        { subMode === "settings" && (status ? status !== "active" : false) && ID &&
                            <Button label={ i18n("general", "delete") } appearance="danger" filled onClick={onDelete} busy={state.busy} />}
                        <Button type="submit" label={ i18n("general", ID ? 'save' : 'create') } appearance="primary" filled />
                    </StackLayout>
                </Form>
            </div>
        );
    } else if (type === 'company') {
        const { ID, Name, OrgNr, Address, PostNr, City, Country } = data;

        return (
            <div className="edit-content">
                <Form onSubmit={ onFormSubmit } autoComplete={ false }>
                    {subMode === 'general' && <React.Fragment>
                        <EditableField key="Name"    type="text" name="Name"    label={ i18n("general", "company_name") }        value={ Name }    onChange={ this._onEditableFieldChange } split2 required />
                        <EditableField key="OrgNr"   type="text" name="OrgNr"   label={ i18n("general", "organization_number") } value={ OrgNr }   onChange={ this._onEditableFieldChange } split2 required />
                        <EditableField key="Street"  type="text" name="Address" label={ i18n("general", "street_address") }      value={ Address } onChange={ this._onEditableFieldChange } split2 />
                        <EditableField key="ZipCode" type="text" name="PostNr"  label={ i18n("general", "zip_code") }            value={ PostNr }  onChange={ this._onEditableFieldChange } split2 />
                        <EditableField key="City"    type="text" name="City"    label={ i18n("general", "city") }                value={ City }    onChange={ this._onEditableFieldChange } split2 />
                        <EditableField key="Country" type="text" name="Country" label={ i18n("general", "country") }             value={ Country } onChange={ this._onEditableFieldChange } split2 />

                        <div style={{width: '100%'}}></div>

                        <StackLayout orientation="horizontal">
                            {ID && <Button label={ i18n("general", "delete") } appearance="danger" filled onClick={onDelete} busy={this.state.busy} />}
                            <Button type="submit" label={ i18n("general", ID ? 'save' : 'create') } appearance="primary" filled />
                        </StackLayout>
                    </React.Fragment>}
                </Form>
            </div>
        );
    }
}

const StyledCellPreview = styled.div`
    background: ${props => props.theme.background} 50% 50% no-repeat;
`

const P = styled.p`
    color: ${props => props.theme.label}
`

const Div = styled.div`
    ${props => props.className === 'top' && css`
        border-bottom: 1px solid ${props.theme.background};
    `}

    ${props => props.className.split(' ').includes('item') && css`
        color: ${props.theme.label};
    `}

    ${props =>  props.className.split(' ').includes('item') &&
                props.className.split(' ').includes('active')
                && css`
        color: ${props.theme.primary};
    `}

    ${props => props.className === 'toggler' && css`
        background: ${props.theme.background};
        &::after {
            background: ${props.theme.primary};
        }

        ${props => props.className.split(' ').includes('icon') && css`
            color: ${props.theme.label};
        `}
        ${props => props.className.split(' ').includes('text') && css`
            color: ${props.theme.overlay};
        `}
    `}

    ${props => props.$active && css`
        background: ${props.theme.primary};
        color: ${props.theme.overlay};
        &:hover {
            background: ${props.theme.primary};
        }
    `}
`

const Li = styled.li`
    ${props => props.className === 'item' && css`
        background: ${props.theme.background};
    `}
    ${props =>  props.className.split(' ').includes('even') &&
                props.className.split(' ').includes('item') && css`
        background: ${props.theme.background};
    `}
`

const Strong = styled.p`
    ${props => props.className === 'label' && css`
        color: ${props.theme.label};
    `}
`

const Ul = styled.ul`
    ${props => props.className === 'label' && css`
        color: ${props.theme.label};
    `}
`

const H3 = styled.h3`
    color: ${props => props.theme.subtitle};
    width: 100%;
`

const InnerTitle = styled.h4`
    color: ${props => props.theme.subtitle};
    margin: 30px 0 0 15px;
`

const Marker = styled.a`
    color: ${props => props.theme.subtitle}
`

const Nav = styled.nav`
    background: ${props => props.theme.background};
`

const Status = styled.span`
    ${props => props.$active ? css`
        color: ${props.theme.success}`
    : css`
        color: ${props.theme.label}`
    }
`