import React, { Component } from 'react'
import { Responsive as GridLayout } from 'react-grid-layout'
import { Card } from '../'

import * as utils from "helpers/utils"
import i18n from "helpers/i18n"

export default class Grid extends Component {
    render() {
        const { dashboard, width, shared, isMoveable = false } = this.props, { ID, Layouts, CompactType, Cards } = dashboard
        let movementLocked = shared || (utils.isMobileDevice() ? !isMoveable : false)
        let layoutSize = width > 1500 ? "lg" : width > 1135 ? "md" : width > 830 ? "sm" : width > 576 ? "xs" : "xxs"
        return (
            <div className='grid_layout-container'>
                {Layouts.lg.length > 0 && Layouts.lg.length === Object.keys(Cards).length // if there is at least 1 card
                    ? <GridLayout
                        width={width} // changing on resize in Monitor component
                        compactType={CompactType} // 'vertical' / null - pins card to top
                        isDraggable={!movementLocked} // true / false
                        isResizable={!movementLocked} // true / false
                        className='layout'
                        rowHeight={5} // px
                        layouts={Layouts} // load in all layouts
                        breakpoints={{ lg: 1500, md: 1135, sm: 830, xs: 576, xxs: 0 }} // responsive breakpoints
                        cols={{ lg: 150, md: 114, sm: 83, xs: 58, xxs: 30 }} // amount of cols for each breakpoint
                        onLayoutChange={!shared ? (l, allLayouts) => this.props.updateDashboardLayouts(ID, allLayouts, CompactType) : () => void 0} // mandatory to update layouts on every drag/resize
                    >
                        {Layouts[layoutSize].map((card)=> ( // rendering each card
                            Cards[card.i] && <div className='card' key={card.i} data-grid={{ w: card.w, h: card.h, x: card.x, y: card.y }}>
                                <Card
                                    shared={shared}
                                    dashboardID={ID}
                                    cardID={card.i}
                                    card={Cards[card.i]}
                                    updateCard={this.props.updateCard}
                                    openNotificationSettings={this.props.openNotificationSettings}
                                    duplicateCard={this.props.duplicateCard}
                                    openBlur={this.props.openBlur}
                                />
                            </div>
                        ))}
                    </GridLayout>

                    // a hint in case of 0 cards and locked layout
                    : <p id='empty-message'>
                        {i18n("monitor", 'click')}
                        <i className='material-icons'>add</i> 
                        {i18n("monitor", 'createCards')}
                    </p>
                }
            </div>
        )
    }
}