import React from "react";
import Hammer from "react-hammerjs-18";
import _Hammer from "hammerjs";

// HELPERS
import * as utils from "helpers/utils";


export default class SwipeCards extends React.Component {
    render() {
        const { props } = this;
        const child = React.Children.only(props.children);
        const childWithProps = React.cloneElement(child, {
            className: utils.createClassName(child.props.className, {
                "SwipeCards": true,
            })
        });

        return childWithProps;
    }
}

export const SwipeCard = class SwipeCard extends React.Component {
    state = {
        // angle: 0,
        offsetX: 0,
        offsetY: 0
    }

    _outOfBoundsCalled = false
    _isMounted = false;

    componentDidMount() { 
        this._isMounted = true;
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { props, state } = this;
        const { offset, scale } = props;
        const hammerOptions = {
            touchAction: "none",
            recognizers: {
                pan: { direction: _Hammer.DIRECTION_ALL }
            }
        };
        const child = React.Children.only(props.children);
        const childWithProps = React.cloneElement(child, {
            className: utils.createClassName(child.props.className, {
                "SwipeCard": true,
                "swiping": !!offset || state.swiping
            }),
            style: {
                ...child.props.style,
                transform: `${scale || ''} translate3d(${offset ? offset.x : state.offsetX}px, ${offset ? offset.y : state.offsetY}px, 0)`
            },
            "data-interactive": ""
        });

        return (
            <Hammer
                onPanStart={ this._handleCardPanStart }
                onPan={ this._handleCardPan }
                onPanEnd={ this._handleCardPanEnd }
                options={ hammerOptions }
            >
                { childWithProps }
            </Hammer>
        );
    }

    // Internal methods
    _handleCardPanStart = (e) => {
        const { props } = this;
        const { additionalEvent } = e;
        const element = utils.getClosestElementByQuery(e.target, ".SwipeCard");
        if (additionalEvent === "panright" || additionalEvent === "panleft") {
            this.setState({panDirection: "horizontal"})
            this.setState({hours: this.props.hours});
        } else {
            this.setState({panDirection: "vertical"})
        }

        this._panTargetHasHammerInstance = _panTargetHasHammerInstance(e.target, element);
        if (this._panTargetHasHammerInstance) return;

        if (!this._elementOriginalRect) this._elementOriginalRect = element.getBoundingClientRect();
        this._outOfBoundsCalled = false;
        if(props.onSwipeStart) props.onSwipeStart();
    }

    _handleCardPan = (e) => {
        if (this._panTargetHasHammerInstance) return;
        const { props } = this;
        const { deltaX } = e;

        if (this.state.panDirection !== "vertical") {
            // if (!utils.isMobileDevice() || deltaX > 25 || deltaX < -25) {
            if (!utils.isMobileDevice() || deltaX !== 0) {
                this.setState({ offsetX: deltaX, swiping: true });
                if (props.onSwipe) props.onSwipe({ offsetX: deltaX }); 
            }    
        } 
    }

    _handleCardPanEnd = (e) => {
        if (this._panTargetHasHammerInstance) return;
        delete this._panTargetHasHammerInstance;
        const { props } = this;
        if (props.onSwipeEnd) props.onSwipeEnd();
        
        const { deltaX, deltaY, velocity } = e;
        const deltaMs = Math.max(deltaX - deltaY, 1);
        const element = utils.getClosestElementByQuery(e.target, ".SwipeCard");
        const containerElement = utils.getClosestElementByQuery(element, ".SwipeCards") || document.body;
        // const containerElementRect = containerElement.getBoundingClientRect();
        
        // const minDistance = props.minDistance || containerElementRect.width * 0.25;
        const friction = (props.friction || 1) / 10;
        const threshold = this.state.panDirection === "vertical" ? 99999 : props.threshold || 0;
        // Speeds
        let speedX = Math.max(Math.min(deltaX / deltaMs, 1), -1);
        let speedY = Math.max(Math.min(deltaY / deltaMs, 1), -1);
    
        // if(Math.abs(velocity) > 0 && distance > minDistance) {
        if (Math.abs(velocity) > threshold && Math.abs(deltaX) > Math.abs(deltaY)) {
            
            if (props.onMomentum) props.onMomentum(this.state.hours);
            
            // Momentum
            let lastStepTime = new Date();
            // let lastOffsetX = 0;
            // let lastOffsetY = 0;
    
            utils.animate({
                duration: Math.max(Math.abs(speedX), Math.abs(speedY)) * 1000,
                run: rate => {
                    if (this._isMounted) {
                        const rateInv = 1 - rate;
                        speedX *= rateInv;
                        speedY *= rateInv;
            
                        const now = new Date();
                        const stepDuration = now.getTime() - lastStepTime.getTime();
                        lastStepTime = now;
                        
                        const elementRect = element.getBoundingClientRect();
                        let elementOriginalRectLeft = this._elementOriginalRect ? this._elementOriginalRect.left : (window.innerWidth-576)/2

                        const position = {
                             left: elementRect.left - elementOriginalRectLeft,
                            // top: elementRect.top - this._elementOriginalRect.top
                        };
                        
                        const offsetX = Math.round((position.left + (speedX * stepDuration / friction)) * 1000) / 1000;
                        const isOutOfBounds = (utils.boundHorizontally(element, containerElement) || utils.boundVertically(element, containerElement));

                        if (rate === 1 && !isOutOfBounds) {
                            this.setState({ offsetX: 0, offsetY: 0, swiping: false });
                        } else {
                            this.setState({ offsetX, swiping: false });
                        }
    
                        // Events
                        if (isOutOfBounds && !this._outOfBoundsCalled && props.onOutOfBounds) {
                            this._outOfBoundsCalled = true;
                            props.onOutOfBounds();
                        }
                    }
                }
            });
        } else {
            this.setState({ offsetX: 0, offsetY: 0, swiping: false });
        }
    }
}


// PRIVATE FUNCTIONS
function _panTargetHasHammerInstance(target, swipeCardElement) {
    const element = utils.getClosestElementByQuery(target, "[data-interactive]");
    return element && element !== swipeCardElement;
}