import React from "react";


// HELPERS
import * as utils from "helpers/utils";


export default function Vector({ className, children, width, height, viewBox }) {
    const classes = utils.createClassName(className, {
        "Vector": true
    });

    return (
        <svg className={ classes } xmlns="http://www.w3.org/2000/svg" width={ width } height={ height } viewBox={ viewBox || `0 0 ${width} ${height}` }>{ children }</svg>
    );
}