import {
    CREATE_CARD,
    OPEN_CARD_SETTINGS,
    CLOSE_CARD_SETTINGS
} from './action-types'

export const createCard = () => dispatch => {
    return dispatch({ type: CREATE_CARD })
}

export const openCardSettings = (card, layout) => dispatch => {
    return dispatch({ type: OPEN_CARD_SETTINGS, payload: { card, layout }})
}

export const closeCardSettings = () => dispatch => {
    return dispatch({ type: CLOSE_CARD_SETTINGS })
}