// HELPERS
import api from "helpers/api";
import { moment } from "helpers/utils";

export default class TimeReporter {
    _data = null
    _listeners = {}

    // Methods
    setUnreportedDates(unreportedDates) {
        const data = {
            ...this._data,
            unreportedDates
        }

        this._data = data
        
        return data
    }

    getData() {
        return this._data
    }

    getListeners() {
        return this._listeners
    }

    removeUnreportedData(date, resourceId, projectId) {
        let unreportedDatesResource = this.getUnreportedDates('resources')[resourceId]
        let unreportedDatesProject = this.getUnreportedDates('projects')[projectId]

        if (!unreportedDatesResource || !unreportedDatesProject) return

        const updatedUnreportedDatesResource = unreportedDatesResource.map(unreportedDate => {
            if (unreportedDate.date === date) {
                const filteredDataset = unreportedDate.dataset.filter(dataset => !(dataset.resourceId === resourceId && dataset.projectId === projectId));
                return filteredDataset.length > 0
                    ? { ...unreportedDate, dataset: filteredDataset }
                    : null;
            }
            return unreportedDate;
        }).filter(unreportedDate => unreportedDate !== null);

        const updatedUnreportedDatesProject = unreportedDatesProject.map(unreportedDate => {
            if (unreportedDate.date === date) {
                const filteredDataset = unreportedDate.dataset.filter(dataset => !(dataset.projectId === projectId && dataset.resourceId === resourceId));
                return filteredDataset.length > 0 ?
                    { ...unreportedDate, dataset: filteredDataset }
                    : null;
            }
            return unreportedDate;
        }).filter(unreportedDate => unreportedDate !== null);

        const newUnreportedDates = {
            resources: {
                ...this.getUnreportedDates('resources'),
                [resourceId]: updatedUnreportedDatesResource,
            },
            projects: {
                ...this.getUnreportedDates('projects'),
                [projectId]: updatedUnreportedDatesProject
            }
        }

        this.setUnreportedDates(newUnreportedDates)
    }

    getOverheads() {
        return this.getData().overheads.sort((a, b) => {
            const A = moment(a.StartDate),
            B = moment(b.StartDate)
            return A.diff(B)
        }); // sort them by startDate
    }

    getTimelines(type) {
        return this.getData().timelines[type];
    }

    clearTimelines(type) {
        const newData = {
            ...this.getData(),
            timelines: {
                ...this.getData().timelines
            }
        }

        delete newData.timelines[type];
        this._data = newData
    }

    getUnreportedDates(type) {
        return this.getData().unreportedDates[type];
    }

    getUnreportedDatesForProject(projectId, shadowData) {
        if (!this.getUnreportedDates("projects")[projectId] && shadowData) {
            return [{
                date: shadowData.date,
                dataset: shadowData.dataset
            }]
        }

        var unreportedDates = [];

        for (var i = 0; i < this.getUnreportedDates("projects")[projectId].length; i++){
            var dataset = this.getUnreportedDates("projects")[projectId][i].dataset;
            var newdataset = [];
            for(var j = 0; j < dataset.length; j++){
                var data = dataset[j];

                if (data.projectId === projectId ){
                    newdataset.push(data);
                }
            }
            if (newdataset.length > 0) {
                var newDataset = {
                    date: this.getUnreportedDates("projects")[projectId][i].date, dataset: newdataset
                };
                unreportedDates.push(newDataset);
            }
        }
        return unreportedDates;
    }

    getCurrentPeriod() {
        return {
            startDate: this.getData().startDate,
            endDate: this.getData().endDate,
            holidays: this.getData().holidays,
        };
    }

    hasData() {
        return !!this.getData();
    }

    async fetchData(options) {
        const prevData = this.getData();
        
        try {
            const data = await api("timereports/getoverview", options);
            delete data.type;
            const newData = {
                ...data,
                timelines: {
                    ...(prevData ? prevData.timelines : undefined), // Old timelines
                    ...data.timelines, // New timelines
                },
                unreportedDates: prevData ? ( prevData.unreportedDates ? prevData.unreportedDates : [] ) : []
            };

            this._data = newData
            _notify.call(this, "change", { type: "FETCH_DATA", data: newData });
        } catch(e) {
            console.error(e);
        }
    }

    async fetchUnreportedDates(options) {
        try {
            const data = await api("timereports/getUnreportedDates", options);
            const newData = this.setUnreportedDates(data.unreportedDates)
            _notify.call(this, "change", { type: "FETCH_DATA", data: newData });
        } catch(e) {
            if (e && e.message) console.error(e.message);
            else console.error(e);
        }
    }

    addListener(eventName, callback) {
        const listeners = {...this.getListeners()};

        if (!listeners[eventName]) listeners[eventName] = [];
        listeners[eventName].push(callback);

        this._listeners = listeners
    }

    removeListener(eventName, callback) {
        const listeners = this.getListeners();

        if (listeners[eventName]) {
            const index = listeners[eventName].indexOf(callback);
            if (index !== -1) listeners[eventName].splice(index, 1);
            if (listeners[eventName].length === 0) delete listeners[eventName];
        }

        this._listeners = listeners
    }
}


// PRIVATE FUNCTIONS
function _notify(eventName, args) {
    const listeners = this.getListeners();

    if (listeners[eventName]) {
        for(let i = 0; i < listeners[eventName].length; i++) {
            const callback = listeners[eventName][i];
            callback(args);
        }
    }
}
