import React, {Component} from 'react'
import { connect } from 'react-redux'

import styled, {css} from 'styled-components';

// import i18n from "helpers/i18n";
// import { getAssetsURIForPath } from "helpers/api";

class PunchClockCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalHours: 0,
            totalCost: 0,
            totalFee: 0,
            totalSalary: 0,
        }
    }

    _onContextReviewClick = () => {
        const { data, onContextReviewClick } = this.props;
        if(onContextReviewClick) onContextReviewClick(data.id, data.context);
    }

    render() {
        const {data} = this.props;
        const name = data.name;
        const code = data.code;
        const ID = data.ID
        const members = data.resources.map((a,i) => {
            if(i < 5){
              return (
                <p key={i}>{a.name}</p>
              );
            } else return ''
        });

        return (
          <Div className="tagContainer">
              <Div className="Timeline-toolbar punchClock-card" onClick={() => this.props.onClick(data, name, code, ID)}>
                  <div className="context-info">
                    <Div className="name">{name}</Div>
                    
                    <div className='assigned'>
                      <ul>
                        {members}
                        {members.length >= 5 && (<i className="fas fa-ellipsis-v"></i>)}
                      </ul>
                    </div>

                  </div>
              </Div>
          </Div>
        )

    }
}

export default connect()(PunchClockCard)

const Div = styled.div`
    ${props => props.className.split(' ').includes('Timeline-toolbar') && css`
        border-bottom: 1px solid ${props.theme.background};
        background: ${props.theme.overlay} !important;
    `}
    ${props => props.className === 'tagContainer' && css`

    `}
    ${props => props.className === 'name' && css`
        color: ${props.theme.primary};
    `}
    ${props => props.className === 'circle' && css`
        background: ${props.theme.background};
    `}
    ${props => props.className === 'trapezoid' && css`
        border-bottom: 70px solid ${props.theme.overlay};
    `}
`;
