import React from "react"

// CLASSES
import Settings from "classes/Settings"

import TimeReporter from "classes/TimeReporter"

// COMPONENTS
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"
import ReportTimeWindow from "../../components/ReportTimeWindow/ReportTimeWindow"
import Tooltip from "components/Tooltip/Tooltip"

// HELPERS
import * as utils from "helpers/utils"

// VIEWS
import ResourcesView from "./views/Resources"
import ProjectsView from "./views/Projects"
import Monitor from "./views/Monitor"
import Globals from "./views/Globals"
import Fortnox from "./views/Fortnox"

export default utils.connectRedux(
    state => ({
        app: state.app,
    }), {},
    class TimeReportOverview extends React.PureComponent {
        constructor(props) {
            super(props)

            this.state = {
                busy: true,
                reportDates: null,
                ...(utils.getWindowDimensions().width > 640
                    ? {
                        startDate: utils.moment().startOf("month"),
                        endDate: utils.moment().endOf("month")
                    } : {
                        startDate: utils.moment().startOf("week"),
                        endDate: utils.moment().endOf("week")
                    }),
                tooltipdata: null,
                currentReportData: null
            }

            this.timeReporter = new TimeReporter()
        }

        async componentDidMount() {
            if (!Settings.get("AUTH_TOKEN")) return

            await this._fetchDataAsync()
            this.setState({ busy: false })
            this._fetchUnreportedDatesAsync()
        }
    
        componentDidUpdate(prevProps, prevState) {
            const { reportedResourceId, reportedProjectId, automaticReportTimeWindow, currentReportData } = this.state
            const { reducers, location } = this.props, { app } = reducers

            const newStateProps = {...this.state.newStateProps} || {}
            const usertype = !!app.user ? app.user.roleType : null

            const viewName = location.search ? utils.parseQueryString(location.search).view 
                : usertype && usertype === "manager" ? "resources" : "projects"

            if (location.search !== prevProps.location.search) this.closeReportTimeWindow()

            if (prevState.currentReportData !== currentReportData) {
                if (!currentReportData) {
                    newStateProps.reportDates = null
                    return this.setState(newStateProps)
                }

                let resourceId = currentReportData.resourceid || reportedResourceId
                let projectId = currentReportData.projectid || reportedProjectId
                let spread = currentReportData.spread || this.state.spread

                newStateProps.reportedResourceId = resourceId
                newStateProps.reportedProjectId = projectId

                if (viewName === "resources") newStateProps.spread = spread
                else newStateProps.spread = undefined

                if (currentReportData) {
                    let reportDates = []

                    if (usertype === "manager" && (viewName === "resources" || automaticReportTimeWindow)) {
                        reportDates = [
                            currentReportData,
                            ...this.timeReporter.getUnreportedDates(automaticReportTimeWindow ? "resources" : viewName)[currentReportData.dataset[0].resourceId]
                                .filter(e => e.date !== currentReportData.date)
                        ].reverse()
                        .sort((a, b) => this._sortDates(a, b, currentReportData))

                        // sort by now to last unreported day of the period, then first unreported day of to now (excluding now)
                        if ((currentReportData.isSingle && projectId)) {
                            // filter out other projects via click on single timeline
                            reportDates = reportDates.reduce((result, rd, i) => {
                                let dataset = rd.dataset.filter((d, j) => d.projectId === projectId)

                                if (dataset.length > 0) result.push({...rd, dataset })

                                return result
                            }, [])
                        }
                    } else if (viewName === "projects" && !automaticReportTimeWindow) {
                        reportDates = [
                            currentReportData,
                            ...this.timeReporter.getUnreportedDatesForProject(projectId, currentReportData)
                                .filter(e => e.date !== currentReportData.date)
                        ].reverse()
                        .sort((a, b) => this._sortDates(a, b, currentReportData))
                    } else if (usertype !== "manager" && (viewName === "resources" || automaticReportTimeWindow)) {
                        reportDates = [
                            currentReportData,
                            ...this.timeReporter.getUnreportedDates(automaticReportTimeWindow ? "resources" : viewName)[currentReportData.dataset[0].resourceId]
                                .filter(e => e.date !== currentReportData.date)
                        ].reverse()
                        .sort((a, b) => this._sortDates(a, b, currentReportData))
                        // sort by now to last unreported day of the period, then first unreported day of to now (excluding now)
                        if (currentReportData.isSingle && projectId) {
                        // filter out other projects via click on single timeline
                            reportDates = reportDates.reduce((result, rd, i) => {
                                let dataset = rd.dataset.filter((d, j) => d.projectId === projectId)

                                if (dataset.length > 0) result.push({...rd, dataset })

                                return result
                            }, [])
                        }
                    }

                    newStateProps.reportDates = reportDates
                } else {
                    newStateProps.reportDates = null
                }
            }

            this.setState(newStateProps)
        }

        closeReportTimeWindow() {
            return new Promise(resolve => {
                this.setState({ visible: false }, () => {
                    this._setTimeReportData(null)
                    resolve()
                })
            })
        }

        render() {
            const { busy, reportDates, tooltipdata, currentReportData } = this.state
            const { location, reducers } = this.props
            const { user } = reducers.app

            const queryString = utils.parseQueryString(location.search)
            const viewName = queryString ? queryString.view 
                : user?.roleType === "manager" ? "resources" : "projects"

            const viewProps = {
                busy: busy,
                location: location,
                user: user,
                timeReporter: this.timeReporter,
                currentReportData: currentReportData,
                setTimeReportData: this._setTimeReportData,
                fetchDataAsync: this._fetchDataAsync,
                onStartDateChange: this._onStartDateChange,
                onEndDateChange: this._onEndDateChange,
                onFilterChange: this._onFilterChange,
                onHover: this._onHover,
                onJump: this._onJump
            }

            return (
                <div className="TimeReportOverview">
                    {!!user && (!!this.timeReporter?.hasData() || viewName === 'monitor') ? (<React.Fragment>
                        {viewName === "resources" ? (<ResourcesView key="TimeReport-Overview-ResourcesView" {...viewProps} />)
                        : viewName === "projects" ? (<ProjectsView key="TimeReport-Overview-ProjectsView" {...viewProps} />)
                        : viewName === "monitor" && user.roleType === "manager" ? (<Monitor key="TimeReport-Overview-MonitorView" {...viewProps} />)
                        : viewName === "globals" && user.roleType === "manager" ? (<Globals key="TimeReport-Overview-Globals" {...viewProps} />)
                        : viewName === "fortnox" && user.roleType === "manager" ? (<Fortnox key="TimeReport-Overview-Fortnox" {...viewProps} />)
                        : null}

                        {/* dont load if user has punchclock && user is not manager, or if a view doesn't need it */}
                        {(user.roleType === "manager" || !user.hasPunchClock) && viewName !== 'monitor' && viewName !== 'globals' && (
                            <ReportTimeWindow
                                onClose={() => this.state.automaticReportTimeWindow && this.setState({automaticReportTimeWindow: false})}
                                type={viewName}
                                user={user}
                                reportDates={reportDates}
                                fetchDataAsync={this._fetchDataAsync}
                                location={this.props.location}
                                timeReporter={this.timeReporter}
                                setTimeReportData={this._setTimeReportData}
                            />
                        )}

                        <Tooltip data={tooltipdata} />
                    </React.Fragment>) : (<ActivityIndicator busy />)}
                </div>
            )
        }

        // Internal methods
        _fetchDataAsync = async () => {
            const user = this.props.reducers.app.user

            if (!this.props.location.search) return

            const queryString = utils.parseQueryString(this.props.location.search)
            const viewName = queryString ? queryString.view 
                : user?.roleType === "manager" ? "resources" : "projects"
            const overviewType = viewName === 'globals' ? 'shadows' : viewName

            if (viewName === 'monitor') { // temp fix to not fetch unnecessary data, but to make sure further navigation works
                await this.timeReporter.fetchData({
                    type: overviewType
                })
                return
            }

            await this.timeReporter.fetchData({
                type: overviewType,
                fromDate: queryString.startDate ? queryString.startDate : this.state.startDate.format("YYYY-MM-DD"),
                toDate: queryString.endDate ? queryString.endDate : this.state.endDate.format("YYYY-MM-DD"),
                settings: {
                    includeAssigned: Settings.get("OVERVIEW_INCLUDE_ASSIGNED_" + overviewType),
                    includeUnassigned: Settings.get("OVERVIEW_INCLUDE_UNASSIGNED_" + overviewType),
                    includeShadows: overviewType !== 'shadows' && Settings.get("OVERVIEW_INCLUDE_SHADOWS_" + overviewType),
                    includeActive: true,
                    mode: Settings.get("OVERVIEW_MODE_" + overviewType + "_mode"),
                    tags: Settings.get("OVERVIEW_INCLUDE_TAGS_" + overviewType)
                }
            })
        }
        /*
        {
            "fromDate" : "2017-11-01",
            "toDate"   : "2017-11-05",
            "type" : "resources"
        }
        */
        _fetchUnreportedDatesAsync = async () => {
            const user = this.props.reducers.app.user
            const queryString = utils.parseQueryString(this.props.location.search)
            
            const viewName = queryString ? queryString.view 
                : user?.roleType === "manager" ? "resources" : "projects"

            if (viewName === 'monitor' || viewName === 'globals') return

            await this.timeReporter.fetchUnreportedDates({
                type: ["resources", "projects"],
                fromDate: queryString.startDate
                    ? queryString.startDate
                    : this.state.startDate.format("YYYY-MM-DD"),
                toDate: queryString.endDate
                    ? queryString.endDate
                    : this.state.endDate.format("YYYY-MM-DD")
            })

            // pop-up if there is at least 1 unreported day
            if (this.timeReporter.hasData() && user && ["projects", "resources"].includes(viewName) &&
            this.timeReporter.getUnreportedDates("resources")[user.id].length > 0
            ) {
                let unreportedCards = this.timeReporter.getUnreportedDates("resources")[user.id]
                unreportedCards
                .sort((a, b) => this._sortDates(a, b))
                .reverse()
                this.setState({
                    reportedResourceId: user.id,
                    reportedProjectId: unreportedCards[0].dataset[0].projectId,
                    automaticReportTimeWindow: true,
                    spread: undefined
                })

                this._setTimeReportData(unreportedCards[0])
            }
        }

        _onHover = e => {
            this.setState({ tooltipdata: e })
        }

        _onFilterChange = e => {
            this.setState({ busy: true }, async () => {
                // Fetch and load time report data
                await this._fetchDataAsync()
                this.setState({ busy: false })
            })
        }

        _onJump = (startDate, endDate) => {
            this.setState({
                busy: true,
                startDate: startDate,
                endDate: endDate
                }, async () => {
                    await this._fetchDataAsync()
                    this.setState({ busy: false })
                }
            )
        }

        _onStartDateChange = startDate => {
            this.setState({ busy: true, startDate }, async () => {
                // Fetch and load time report data
                await this._fetchDataAsync()
                this.setState({ busy: false })
            })
        }

        _onEndDateChange = endDate => {
            this.setState({ busy: true, endDate }, async () => {
                // Fetch and load time report data
                await this._fetchDataAsync()
                this.setState({ busy: false })
            })
        }

        _sortDates = (a, b, current = { date: "1970-01-01" }) => {
            // when clicked on e.g. 2019-01-02 expecting to sort as ...
            // [2019-01-01, 2018-12-31, 2018-12-10, 2018-11-20, 2019-01-03, 2019-01-02]
            // ... where last in array is the one to be shown as swipe card
            const isInitialA = a.isCellClick,
                isInitialB = b.isCellClick,
                A = utils.moment(a.date),
                B = utils.moment(b.date),
                Current = utils.moment(current.date)

            if (isInitialA || a.isSingle) return 1 // the initial click
            if (isInitialB || b.isSingle) return -1 // the initial click
            else if (Current.diff(A) < 0 && Current.diff(B) > 0) return 1 // if A is after Current and B is before Current send B to the end
            else if (Current.diff(A) > 0 && Current.diff(B) < 0) return -1 // if A is before Current and B is after Current send A to the end
            else if (Current.diff(A) === 0 || Current.diff(B) === 0) return 0 // if A or B is current card, leave it untouched
            else return B.diff(A) // sort them relatively
        }

        _setTimeReportData = (currentReportData) => {
            this.setState({currentReportData})
        }
    }
)

// PRIVATE FUNCTIONS
