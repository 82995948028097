import React from "react";

// HELPERS
import Settings from "classes/Settings";
// import * as utils from "helpers/utils";
// import i18n from "helpers/i18n";


export default class Timejumper extends React.PureComponent {

    render() {
        const { props } = this;
        const { Previous, Next} = props;

        return (
          <div className = "timejumper" onClick = {(e)=>this.onClick(Next ? 'next' : 'previous')}>
            <i className={'material-icons'} style={{color: Settings.getGlobalColor("subtitle")}}>
            { Next && ('skip_next') }
            { Previous && ('skip_previous') }
            </i>
          </div>
        );
    }

    onClick = (e) => {
        const { onClick } = this.props;
        if(onClick) onClick(e);

    }
}
