import React, { Component } from 'react'
import Button from "components/Button/Button"
import EditableField from "components/EditableField/EditableField"
import Select from "components/Select/Select"
import Switch from "components/Switch/Switch"
import Range from "components/Range/Range"
import Modal from "components/Modal/Modal"
import UploaderForm from "routes/TimeReport/components/UploaderForm/UploaderForm"
import styled, {css} from 'styled-components'
import * as utils from "helpers/utils"
import i18n from "helpers/i18n"
import ColorPicker from 'components/ColorPicker/ColorPicker'

class ChartStyle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sketchKey: undefined,
            generalSubMode: "general",
            colorsSubMode: "general"
        }
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    render() { 
        const { generalSubMode, colorsSubMode } = this.state
        const { cardData, calcType } = this.props
        const { options, type, data, handleStyleChange, handleAssetColorChange, resetAssetColor, handleGradientOptionChange, handleSortTypeChange, handleUpdateRateChange, handleBulletValues, removeArrayColor, handleCardOptionsChange, handleCardNameChange, handleGradientChange, addArrayColor, handleBackgroundChange, handleArrayColorChange, handleChartColorChange } = this.props
        const { cardOptions, setup, subMode: mode, backgroundImageUploadData, uploaderForm } = cardData
        const { useGradient, gradientType, gradientDirection, firstStop, secondStop, radialShape, gradientColor } = cardOptions.gradientOptions
        const { colors, minColors, maxColors, splitNegatives, textFontSize, legendFontSize, axisFontSize } = options
        const { titleFontSize, displayUnit, fontType, background, name, sameColor, updateRate, displayPlusSign } = cardOptions
        const keys = ['pie', "bullet"].includes(type) ? data.dataset : data.keys

        return (
            <React.Fragment>       
                {mode === "general" ? 
                    <div id='chart_style-container'>
                        <EditToggler mode={mode} chartType={type} active={ true } subMode={ generalSubMode } onChange={ this._changeGeneralMode } />
                        {generalSubMode === "general" && 
                            <React.Fragment>
                                <div className='setup_row'>
                                    <div className="row_element">
                                        <Textbox label={ i18n("monitor", 'cardTitle') } name="name" value={ name } onChange={ handleCardNameChange } />
                                    </div>
                                    <div className="row_element">
                                        <Dropdown label={ i18n("monitor", 'chartType') } name="chartType" onChange={this._onChartTypeChange} value={type}
                                            options={[
                                                { value: "textdata", label: i18n("monitor", 'textData'), hoverInfo: "Transforms to list if the result has multiple values"  },
                                                { value: "bar",      label: i18n("monitor", 'barChart'), imgSrc: utils.getNivoIcon("bar-dark-colored"), hoverInfo: " " },
                                                { value: "line",     label: i18n("monitor", 'lineChart'), imgSrc: utils.getNivoIcon("line-dark-colored"), hoverInfo: " " },
                                                { value: "pie",      label: i18n("monitor", 'pieChart'), imgSrc: utils.getNivoIcon("pie-dark-colored"), hoverInfo: " " },
                                                { value: "calendar",      label: i18n("monitor", 'calendar'), imgSrc: utils.getNivoIcon("calendar-dark-colored"), hoverInfo: " " },
                                                { value: "heatmap",      label: i18n("monitor", 'heatmap'), imgSrc: utils.getNivoIcon("heatmap-dark-colored"), hoverInfo: " " },
                                                { value: "bullet",   label: i18n("monitor", 'bullet'), imgSrc: utils.getNivoIcon("bullet-dark-colored"), hoverInfo: " " },
                                                { value: "radar",     label: i18n("monitor", 'radar'), imgSrc: utils.getNivoIcon("radar-dark-colored"), hoverInfo: " ", 
                                                    disabled: ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'overhead net', 'projected margin', 'overhead net per employee', 'employee alteration'].includes(setup.kpi) },
                                                { value: "sunburst",      label: i18n("monitor", "sunburst"), imgSrc: utils.getNivoIcon("sunburst-dark-colored"), hoverInfo: " " },
                                                { value: "bubble",      label: i18n("monitor", "bubble"), imgSrc: utils.getNivoIcon("circle-packing-dark-colored"), hoverInfo: " ",
                                                    disabled: ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'overhead net', 'projected margin', 'overhead net per employee', 'employee alteration'].includes(setup.kpi) },
                                                { value: "scatterPlot",     label: i18n("monitor", 'scatterPlot'), imgSrc: utils.getNivoIcon("scatterplot-light-colored"), hoverInfo: " " },
                                                //Marimekko, requires react 16.8+
                                            ]} />
                                    </div>
                                </div>
                                <div className='setup_row'>
                                    <div className="row_element">
                                        <Range label={ i18n("monitor", 'titleFontSize') } onChange={ handleCardOptionsChange }
                                            name='titleFontSize' value={ titleFontSize }
                                            min='0' max='200' step='1' />
                                    </div>

                                    {![null, undefined].includes(textFontSize) ? 
                                        <div className="row_element">
                                            <Range label={ i18n("monitor", 'textFontSize') } onChange={ handleStyleChange }
                                                name='textFontSize' value={ textFontSize }
                                                min='0' max='200' step='1' />
                                        </div>
                                    : ![null, undefined].includes(axisFontSize) ?
                                        <div className="row_element">
                                            <Range label={ i18n("monitor", 'axisFontSize') } onChange={ handleStyleChange }
                                                name='axisFontSize' value={ axisFontSize }
                                                min='0' max='50' step='1' />
                                        </div>
                                    : ![null, undefined].includes(legendFontSize) ?
                                        <div className={"row_element"}>
                                            <Range label={ i18n("monitor", 'legendFontSize') } onChange={ handleStyleChange }
                                                name='legendFontSize' value={ legendFontSize }
                                                min='0' max='50' step='1' />
                                        </div>
                                    : null
                                }
                                </div>

                                <div className='setup_row'>
                                    {(![null, undefined].includes(axisFontSize) && ![null, undefined].includes(textFontSize)) && <div className={"row_element"} style={{paddingLeft: !legendFontSize && "0px"}}>
                                        <Range label={ i18n("monitor", 'axisFontSize') } onChange={ handleStyleChange }
                                            name='axisFontSize' value={ axisFontSize }
                                            min='0' max='50' step='1' />
                                    </div>}
                                    {(![null, undefined].includes(legendFontSize) && !([null, undefined].includes(textFontSize) && [null, undefined].includes(axisFontSize))) 
                                    && <div className={"row_element"} style={{paddingLeft: (!axisFontSize || !textFontSize) && "0px"}}>
                                        <Range label={ i18n("monitor", 'legendFontSize') } onChange={ handleStyleChange }
                                            name='legendFontSize' value={ legendFontSize }
                                            min='0' max='50' step='1' />
                                    </div>}
                                </div>

                                <div className='setup_row'>
                                    <div className="row_element">
                                        <Dropdown label={ i18n("monitor", 'fontType') } name="fontType" onChange={ handleCardOptionsChange } value={fontType}
                                            options={[
                                                { value: "Arial",           label: "Arial"           },
                                                { value: "Verdana",         label: "Verdana"         },
                                                { value: "Helvetica",       label: "Helvetica"       },
                                                { value: "Times New Roman", label: "Times New Roman" },
                                                { value: "Georgia",         label: "Georgia"         },
                                                { value: "Courier New",     label: "Courier New"     },
                                                { value: "Lucida Console",  label: "Lucida Console"  },
                                                { value: "Impact",          label: "Impact"          },
                                                { value: "Comic Sans MS",   label: "Comic Sans MS"   },
                                            ]} />
                                    </div>
                                    <div className="row_element">
                                        <Checkbox label={ i18n("monitor", 'displayUnit') } onChange={handleCardOptionsChange}
                                            name='displayUnit' checked={displayUnit} />
                     
                                    </div>
                                    <div className="row_element">
                                        <Checkbox label={ i18n("monitor", 'displayPlusSign') } onChange={handleCardOptionsChange}
                                            name='displayPlusSign' checked={displayPlusSign} disabled={calcType !== "%" || !["textdata", "bar"].includes(type)} />
                     
                                    </div>
                                </div>
                                <div className='setup_row' id='update_rate'>
                                    <div style={{width:"100%"}}>
                                        <Label>{ i18n("monitor", 'updateFrequency') }</Label>
                                        <div className='inner_wrapper'>
                                            <div className="row_element"> 
                                                <Dropdown label={ i18n("monitor", 'interval') } name="type" value={updateRate.type} onChange={handleUpdateRateChange} 
                                                    options={[
                                                        { value: "hourly", label: i18n("monitor", 'hourly') },
                                                        { value: "daily",  label: i18n("monitor", 'daily') },
                                                    ]}
                                                />
                                            </div>
                                            <div className="row_element"> 
                                                <Textbox disabled={updateRate.type === 'hourly'} label={ i18n("monitor", 'time') } name="time" value={updateRate.time} onChange={handleUpdateRateChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        <PropertyList
                            type={type}
                            options={options}
                            generalSubMode={generalSubMode}
                            handleBulletValues={handleBulletValues}
                            addArrayColor={addArrayColor}
                            removeArrayColor={removeArrayColor}
                            onChange={handleStyleChange}
                            handleSortTypeChange={handleSortTypeChange}
                        />
                    </div>
                : mode === "colors" ? 
                    <div id='chart_style-container'>
                        <EditToggler mode={mode} chartType={type} active={ true } subMode={ colorsSubMode } onChange={ this._changeColorsMode } />
                        {colorsSubMode === "general" &&
                            <React.Fragment>
                                
                                <Button label={ i18n('monitor', 'editBackgroundImage') } appearance='secondary' size='small' filled onClick={()=>this.setState({uploaderContent: <UploaderForm type = "monitor" uploadedImage = { uploaderForm || "/cards/"+backgroundImageUploadData } onUpload = {this._onUpload} />})} />
                                
                                <Modal visible={ this.state.uploaderContent !== null } onClosed={ () => this.setState({uploaderContent: null}) } >
                                    {this.state.uploaderContent}
                                </Modal>
                                <div className='setup_row'>
                                    <div className="row_element"> 
                                        <Label>{ i18n("monitor", 'cardColor') }</Label>
                                        <div className='icons-wrapper'>
                                            <ColorPicker color={background} onChange={handleBackgroundChange}/>
                                        </div>
                                    </div> 
                                    <div className="row_element"> 
                                        <Label>{ i18n("monitor", 'textColor') }</Label>
                                        <div className='icons-wrapper'>
                                            <ColorPicker color={this.props.options.textColor} onChange={this.props.handleTextColorChange} />
                                        </div>
                                    </div>

                                    {type === "heatmap" && 
                                        <div className='row_element'>
                                            <Label>{ i18n("monitor", 'cellBorderColor') }</Label>
                                            <div className='icons-wrapper'>
                                                <ColorPicker color={options["cellBorderColor"]} onChange={(color) => handleChartColorChange("cellBorderColor", color)} />
                                            </div>
                                        </div>
                                    }
                                    {type === "sunburst" && 
                                        <div className='row_element'>
                                            <Label>{ i18n("monitor", 'cellBorderColor') }</Label>
                                            <div className='icons-wrapper'>
                                                <ColorPicker color={options["cellBorderColor"]} onChange={(color) => handleChartColorChange("cellBorderColor", color)} />
                                            </div>
                                        </div>
                                    }
                                    {type === "bubble" && 
                                        <div className='row_element'>
                                            <Label>{ i18n("monitor", 'cellBorderColor') }</Label>
                                            <div className='icons-wrapper'>
                                                <ColorPicker color={options["borderColor"]} onChange={(color) => handleChartColorChange("borderColor", color)} />
                                            </div>
                                        </div>
                                    }
                                    {type === "radar" && 
                                        <div className='row_element'>
                                            <Label>{ i18n("monitor", 'gridColor') }</Label>
                                            <div className='icons-wrapper'>
                                                <ColorPicker color={options["gridColor"]} onChange={(color) => handleChartColorChange("gridColor", color)} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                {type === "calendar" && 
                                    <div className="setup_row">
                                        <div className='row_element'>
                                            <Label>{ i18n("monitor", 'cellBorderColor') }</Label>
                                            <div className='icons-wrapper'>
                                                <ColorPicker color={options["dayBorderColor"]} onChange={(color) => handleChartColorChange("dayBorderColor", color)} />
                                            </div>
                                        </div>
                                        <div className='row_element'>
                                            <Label>{ i18n("monitor", 'monthBorderColor') }</Label>
                                            <div className='icons-wrapper'>
                                                <ColorPicker color={options["monthBorderColor"]} onChange={(color) => handleChartColorChange("monthBorderColor", color)} />
                                            </div>
                                        </div>
                                        <div className='row_element'>
                                            <Label>{ i18n("monitor", 'empty') }</Label>
                                            <div className='icons-wrapper'>
                                                <ColorPicker color={options["emptyColor"]} onChange={(color) => handleChartColorChange("emptyColor", color)} />
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="setup_row">
                                    <div className="row_element" style={{padding: 0, width: "100%"}}>
                                        <Checkbox label={ i18n("monitor", 'useGradient') } onChange={handleGradientOptionChange}
                                            name='useGradient' checked={useGradient} />
                                    </div>
                                </div>
                                <div style={{display: useGradient ? "block" : "none"}}>
                                    <div className='setup_row' id='text_color'>
                                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                                            <Label>{ i18n("monitor", 'gradientColor') }</Label>
                                            <div className='icons-wrapper'>
                                                <ColorPicker color={gradientColor} onChange={handleGradientChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="setup_row">
                                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                                            <Dropdown label={ i18n("monitor", 'gradientType') } name="gradientType" value={gradientType} onChange={handleGradientOptionChange} 
                                                options={[
                                                    { value: "linear-gradient",  label: i18n("monitor", 'linearGradient')  },
                                                    { value: "radial-gradient",  label: i18n("monitor", 'radialGradient')  }
                                                ]} />
                                        </div>
                                    </div>
                                    {gradientType.includes("radial") && 
                                        <div className="setup_row">
                                            <div className="row_element" style={{padding: 0, width: "100%"}}>
                                                <Dropdown label={ i18n("monitor", 'radialShape') } name="radialShape" value={radialShape} onChange={handleGradientOptionChange} 
                                                    options={[
                                                        { value: "circle",  label: i18n("monitor", 'circle') },
                                                        { value: "ellipse",  label: i18n("monitor", 'ellipse') }
                                                    ]} />
                                            </div>
                                        </div>
                                    }
                                    <div className="setup_row">
                                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                                            <Dropdown label={ i18n("monitor", 'gradientDirection') } name="gradientDirection" value={gradientDirection} onChange={handleGradientOptionChange} 
                                                options={[
                                                    { hide: gradientType.includes("linear"), value: "middle",  label: i18n("monitor", 'middle')  },
                                                    { value: "bottom right",  label: i18n("monitor", 'bottomRight')  },
                                                    { value: "bottom",  label: i18n("monitor", 'bottom')  },
                                                    { value: "bottom left",  label: i18n("monitor", 'bottomLeft')  },
                                                    { value: "left",  label: i18n("monitor", 'left')  },
                                                    { value: "top left",  label: i18n("monitor", 'topLeft')  },
                                                    { value: "top",  label: i18n("monitor", 'top') },
                                                    { value: "top right",  label: i18n("monitor", 'topRight')  },
                                                    { value: "right",  label: i18n("monitor", 'right')  }
                                                ]} />
                                        </div>
                                    </div>
                                    <div className='setup_row'>
                                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                                            <Range label={ i18n("monitor", 'gradientRange') } onChange={handleGradientOptionChange}
                                                value={ firstStop } name='firstStop' secondName="secondStop" secondValue={secondStop} 
                                                min='-100' max='200' step='5' colorOne={background} colorTwo={gradientColor} />
                                        </div>
                                    </div>
                                </div>
                         
                            </React.Fragment>
                        }

                            {colorsSubMode === "assetColors" ?
                                type === "textdata" ?
                                    <React.Fragment>
                                        <div className="setup_row">
                                            <Checkbox label={ i18n("monitor", 'splitNegatives') } onChange={handleStyleChange}
                                                name='splitNegatives' checked={splitNegatives} />
                                        </div>
                                        {splitNegatives && 
                                        <div className="setup_row">
                                            <div className='row_element'>
                                                <Label>{ i18n("monitor", 'positiveColor') }</Label>
                                                <div className='icons-wrapper'>
                                                    <ColorPicker color={options["positiveColor"]} onChange={(color) => handleChartColorChange("positiveColor", color)} />
                                                </div>
                                            </div>
                                            <div className='row_element'>
                                                <Label>{ i18n("monitor", 'negativeColor') }</Label>
                                                <div className='icons-wrapper'>
                                                    <ColorPicker color={options["negativeColor"]} onChange={(color) => handleChartColorChange("negativeColor", color)} />
                                                </div>
                                            </div>
                                        </div>}
                                    </React.Fragment>
                                : ["sunburst"].includes(type) ? 
                                    <div id='asset-container'>
                                        <div className='setup_row' style={{position: "relative"}}>
                                            <Button faIcon={"fas fa-dice"} label={ i18n("monitor", 'randomizeColors') } appearance="secondary" size='small' filled onClick={() => handleAssetColorChange({ hex: null }, null, data.keys, data.dataset, true, true)} />
                                        </div>
                                        {keys.length > 0 && keys.map((key, i) => {
                                                return <div className='asset-wrapper' key={`asset-wrapper-${i}`}>
                                                    <span className='asset' key={`asset-${i}`}>{key}</span>
                                                    <div className='icons-wrapper'>
                                                        <i className='fa fa-times' onClick={() => resetAssetColor(key)} />
                                                        <ColorPicker color={colors[key]} onChange={(color) => this.props.handleAssetColorChange(color, key)} />
                                                    </div>
                                                </div>
                                        })}
                                    </div>
                                : type === "heatmap" ?
                                    <React.Fragment>
                                        <div className='setup_row' style={{position: "relative"}}>
                                            <div className="row_element" style={{padding: 0, width: "100%"}}>
                                                <Checkbox label={ i18n("monitor", 'splitNegatives') } onChange={handleStyleChange}
                                                    name='splitNegatives' checked={splitNegatives} />
                                            </div>
                                            {!splitNegatives && <Button label={ i18n("monitor", 'addColor') } style={{marginTop: "auto"}} appearance="secondary" size='small' filled onClick={() => addArrayColor("maxColors")} />}                                            
                                        </div>
                                        
                                        <div className="setup_row" style={{display: "flex", flexDirection: splitNegatives ? "row" : "column-reverse"}}>
                                            <div style={{marginRight: splitNegatives ? "10px" : "0px"}}>
                                                {splitNegatives && <Button label={ i18n("monitor", 'addNegativeColor') } appearance="secondary" size='small' filled onClick={() => addArrayColor("minColors")} />}
                                                {minColors.map((color, index) => {
                                                    return (
                                                        <div key={"minColors" + index} style={{marginBottom:"20px"}}>
                                                            <div className='icons-wrapper' style={{display: "flex", justifyContent:"space-between"}}>
                                                                <ColorPicker style={{margin: "auto 10px 5px 0px"}} color={color} onChange={(newColor) => handleArrayColorChange("minColors", index, newColor)} />
                                                                <i style={{margin:"auto 0px 5px 10px"}} name="minColors" className={`fas fa-trash-alt`} onClick={() => removeArrayColor("minColors", index)} />                                                      
                                                            </div>
                                                            <div style={{background: color, width: "100%", height: "5px"}}/>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div style={{marginLeft: splitNegatives ? "10px" : "0px"}}>
                                                {splitNegatives && <Button label={ i18n("monitor", 'addPositiveColor') } appearance="secondary" size='small' filled onClick={() => addArrayColor("maxColors")} />}
                                                {maxColors.map((color, index) => {
                                                    return (
                                                        <div key={"maxColors" + index} style={{marginBottom:"20px"}}>
                                                            <div className='icons-wrapper' style={{display: "flex", justifyContent:"space-between"}}>
                                                                <ColorPicker style={{margin: "auto 10px 5px 0px"}} color={color} onChange={(newColor) => handleArrayColorChange("maxColors", index, newColor)} />
                                                                <i style={{margin:"auto 0px 5px 10px"}} index={index} name="maxColors" className={`fas fa-trash-alt`} onClick={() => removeArrayColor("maxColors", index)}/>   
                                                            </div>
                                                            <div style={{background: color, width: "100%", height: "5px"}}/>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                : <div id='asset-container'>
                                    <div className='setup_row' style={{padding:"0px", position: "relative"}}>
                                        <Checkbox label={ i18n("monitor", 'sameColor') } onChange={handleCardOptionsChange}
                                            name='sameColor' checked={sameColor} />
                                        <Button style={{marginTop: "5%"}} faIcon={"fas fa-dice"} label={ i18n("monitor", 'randomizeColors') } appearance="secondary" size='small' filled onClick={() => handleAssetColorChange({ hex: null }, null, data.keys, data.dataset, true, true)} />
                                    </div>
                                    {sameColor ? (
                                        <div className='asset-wrapper'>
                                            <span className='asset'>{i18n("monitor", 'all')}</span>
                                            <div className='icons-wrapper'>
                                                <ColorPicker color={colors[keys[0]] || '#E8C19F'} onChange={(color) => this.props.handleAssetColorChange(color, keys[0])} />
                                           </div>
                                        </div>
                                    ) : (
                                        keys && keys.length > 0 && keys.map((k, i) => {
                                            const key = ['pie', "bullet"].includes(type) ? k.label : k
                                            return (
                                                <div className='asset-wrapper' key={`asset-wrapper-${i}`}>
                                                    <span className='asset' key={`asset-${i}`}>{key}</span>
                                                    <div className='icons-wrapper'>
                                                        <i className='fa fa-times'
                                                            onClick={() => resetAssetColor(key)}
                                                        />
                                                        <ColorPicker color={colors[key] || '#E8C19F'} onChange={(color) => this.props.handleAssetColorChange(color, key)} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )}
                                </div>
                            : null}
                    </div>
                : null }
            </React.Fragment>
        )
    }
    _onUpload = (value) => {
        this.setState({uploaderContent: null})
        if (this.props.updateBackgroundImage) this.props.updateBackgroundImage(value)
    }
    _changeGeneralMode = (subMode) => this.setState({ generalSubMode: subMode })

    _changeColorsMode = (subMode) => this.setState({ colorsSubMode: subMode })

    _onChartTypeChange = (value, name) => {
        if (this.props.handleChartTypeChange) {
            this.setState({
                showLayoutOptions: false,
                showAdjustmentOptions: false,
                showStyleOptions: false,
                chartTypeOptions: false,
                generalSubMode: "general",
                colorsSubMode: "general"
            }, this.props.handleChartTypeChange(value, name))
        }
    }
}

export default ChartStyle

class EditToggler extends React.PureComponent {

    render() {
        const { props } = this;
        const { active } = props;
        const classes = utils.createClassName("ContextReviewContent-EditToggler", {
            "active": active,
        });

        return (
            <Div className={ classes } >
                <EditSubModeNav { ...props } />
            </Div>
        );
    }
}

function EditSubModeNav({ mode, subMode: activeSubMode, onChange, chartType }) {
    const Item = ({ label, subMode}) => {
        return (
            <Div className={ utils.createClassName("item", { "active": activeSubMode === subMode }) } onClick={ onChange && (() => onChange(subMode)) } >{ label }</Div>
        )
    }
    switch(mode) {
        case("general"):
            return (
                <Div className="togglerShadow">
                    <nav>
                        <Item label={ i18n("monitor", "general") } subMode="general" />
                        {!["textdata", "pie", "sunburst", "bubble"].includes(chartType) && <Item label={ i18n("monitor", "layout") } subMode="layout" /> }
                        {!["textdata", "calendar", "bullet", "heatmap"].includes(chartType) && <Item label={ i18n("monitor", "style") }  subMode="style" /> }
                        <Item label={ i18n("monitor", chartType) } subMode="specific" />
                        {<Item label={ i18n("monitor", "adjustment") }  subMode="adjustment" /> }
                    </nav>
                </Div>
            )
        case("colors"):
            return (
                <Div className="togglerShadow">
                    <nav>
                        <Item label={ i18n("monitor", "general") } subMode="general" />
                        <Item label={ i18n("monitor", "assetColors") }  subMode="assetColors" />
                    </nav>
                </Div>
            )
        default: return false
    }
}

function PropertyList(props) {
    switch(props.type) {
        case 'textdata': return <TextDataList {...props} />
        case 'bar': return <BarList {...props} />
        case 'pie': return <PieList {...props} />
        case 'line': return <LineList {...props} />
        case 'calendar': return <CalendarList {...props} />
        case 'heatmap': return <HeatmapList {...props} />
        case 'radar': return <RadarList {...props} />
        case 'scatterPlot': return <ScatterPlotList {...props} />
        case 'sunburst': return <Sunburst {...props} />
        case 'bubble': return <Bubble {...props} />
        case 'bullet': return <Bullet {...props} />
        default: return false
    }
}

function TextDataList(props) {
    const { options, handleSortTypeChange, generalSubMode, onChange } = props
    const { sortType, margin } = options
    //list / table
    //font
    return (
        <React.Fragment>
            {generalSubMode === "specific" && 
                <React.Fragment>
                    <div className='setup_row' id='select-chart'>
                        <Dropdown label={ i18n("monitor", 'sortingOption') } name="sortType" onChange={handleSortTypeChange} value={sortType}
                            options={[
                                { value: "label_ascending",  label: i18n("monitor", 'labelAscending')   },
                                { value: "label_descending", label: i18n("monitor", 'labelDescending')  },
                                { value: "value_ascending",  label: i18n("monitor", 'valueAscending')  },
                                { value: "value_descending", label: i18n("monitor", 'valueDescending') },
                            ]} />
                    </div>
                </React.Fragment>
            }
            {generalSubMode === "adjustment" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                               name='top' value={ margin.top }
                               min='0' max='500' step='5' />
                            </div>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                                name='bottom' value={ margin.bottom }
                                min='0' max='500' step='5' />
                        </div>
                    </div>
                    
                    <div className='setup_row'>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                                name='left' value={ margin.left }
                                min='0' max='500' step='5' />
                        </div>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                                name='right' value={ margin.right }
                                min='0' max='500' step='5' />
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}
function BarList(props) {
    const { options, onChange, generalSubMode } = props
    const { axisLeft, axisBottom, axisLeftEnabled, axisBottomEnabled, axisBottomAngle, margin, layout, groupMode, gridX, gridY, legend, label,
        padding, innerPadding, outerRadius, minValue, minValueAuto, maxValue, maxValueAuto } = options
    return (
        <React.Fragment>
            {generalSubMode === "layout" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Checkbox label={ i18n("monitor", 'showLegend') } onChange={onChange}
                                name='legend' checked={legend} />
                        </div>
                    </div>
                    <div className='setup_row' id='select-chart'>

                            <InputWithCheckbox title={'Left Axis'} onChange={onChange}
                                name='axisLeft' value={axisLeft} type='text' textLabel="Custom Text" disabled={!axisLeftEnabled}
                                checkboxLabel={i18n("monitor", 'showLeftAxis')} checkboxName={'axisLeftEnabled'} checked={axisLeftEnabled} />
                     
                    </div>
                    <div className='setup_row' id='select-chart'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <InputWithCheckbox title={'Bottom Axis'} onChange={onChange}
                                name='axisBottom' value={axisBottom} type='text' textLabel="Custom Text" disabled={!axisBottomEnabled}
                                checkboxLabel={i18n("monitor", 'showBottomAxis')} checkboxName={'axisBottomEnabled'} checked={axisBottomEnabled} />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'axisBottomAngle') } onChange={ onChange }
                                name='axisBottomAngle' value={ axisBottomAngle }
                                min='-90' max='90' step='10' />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "style" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="switch-wrapper">
                            <Checkbox label={ i18n("monitor", 'verticalGrid') } onChange={onChange}
                                name='gridX' checked={gridX} />
                            <Checkbox label={ i18n("monitor", 'horizontalGrid') } onChange={onChange}
                                name='gridY' checked={gridY} />
                            <Checkbox label={ i18n("monitor", 'showLabels') } onChange={onChange}
                                name='label' checked={label} />
                        </div>
                    </div>
   
                    <div className='setup_row'>
                        <div>
                        <InputWithCheckbox title='Max value' onChange={onChange} disabled={maxValueAuto}
                            textLabel={"Value"} name='maxValue' value={maxValue} type='number'
                            checkboxLabel={"Auto value"} checkboxName='maxValueAuto' checked={maxValueAuto} />
                        </div>
                        <div>
                            <InputWithCheckbox title='Min value' onChange={onChange} disabled={minValueAuto}
                                textLabel={"Value"} name='minValue' value={minValue} type='number'
                                checkboxLabel={"Auto value"} checkboxName='minValueAuto' checked={minValueAuto} />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "specific" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="switch-wrapper">
                            <Checkbox label={ i18n("monitor", 'horizontalLayout') } onChange={onChange}
                                name='layout' checked={layout} />
                            <Checkbox label={ i18n("monitor", 'grouped') } onChange={onChange}
                                name='groupMode' checked={groupMode} />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'outerPadding') } onChange={onChange}
                                    name='padding' value={padding}
                                    min='0' max='0.9' step='0.03' />
                        </div>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'innerPadding') } onChange={onChange}
                                name='innerPadding' value={innerPadding}
                                min='0' max='10' step='0.5' />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'cornerRadius') } onChange={onChange}
                                name='outerRadius' value={outerRadius}
                                min='0' max='45' step='0.5' />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "adjustment" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                                name='top' value={ margin.top }
                                min='0' max='200' step='1' />
                        </div>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                                name='bottom' value={ margin.bottom }
                                min='0' max='200' step='1' />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                                name='left' value={ margin.left }
                                min='0' max='200' step='1' />
                        </div>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                                name='right' value={ margin.right }
                                min='0' max='200' step='1' />
                        </div>
                    </div>
                </React.Fragment>
            }
    </React.Fragment>)
}
function PieList(props) { 
    const { options, onChange, generalSubMode } = props
    const { legend, label, margin, outerRadius, innerRadius, angle } = options
    return (
        <React.Fragment>
            {generalSubMode === "style" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                        <Checkbox label={ i18n("monitor", 'showLegend') } onChange={onChange}
                            name='legend' checked={legend} />
                    </div>
                    </div>
                    <div className='setup_row'>
                    <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Checkbox label={ i18n("monitor", 'showLabels') } onChange={onChange}
                                name='label' checked={label} />
                    </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "specific" &&
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'angle') } onChange={onChange}
                                name='angle' value={angle}
                                min='0' max='45' step='0.5' />
                                </div>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'innerRadius') } onChange={onChange}
                                name='innerRadius' value={innerRadius}
                                min='0' max='0.95' step='0.03' />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'cornerRadius') } onChange={onChange}
                                name='outerRadius' value={outerRadius}
                                min='0' max='45' step='0.5' />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "adjustment" && 
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                            name='top' value={ margin.top }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                            name='bottom' value={ margin.bottom }
                            min='0' max='200' step='1' />
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                            name='left' value={ margin.left }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                            name='right' value={ margin.right }
                            min='0' max='200' step='1' />
                    </div>
                </div>
            </React.Fragment>
            }
    </React.Fragment>)
}

function LineList(props) {
    const { options, onChange, generalSubMode } = props
    const { axisLeft, axisBottom, axisLeftEnabled, axisBottomEnabled, axisBottomAngle, margin, layout, gridX, gridY, legend, label,
        dotSize, lineWidth, enableArea, areaOpacity, minValue, minValueAuto, maxValue, maxValueAuto } = options
    return (
        <React.Fragment>
            {generalSubMode === "layout" && 
                <React.Fragment>
                    <div className='setup_row' id='select-chart'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Checkbox label={ i18n("monitor", 'showLegend') } onChange={onChange}
                                name='legend' checked={legend} />
                        </div>
                    </div>
                    <div className='setup_row' id='select-chart'>
                        <InputWithCheckbox title={`${layout ? 'Bottom' : 'Left'} Axis`} onChange={onChange}
                            name='axisLeft' value={axisLeft} type='text' textLabel="Custom Text" disabled={!axisLeftEnabled}
                            checkboxLabel={`Show ${layout ? 'Bottom' : 'Left'} Axis`} checkboxName={'axisLeftEnabled'} checked={axisLeftEnabled} />
                    </div>
                    <div className='setup_row' id='select-chart'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                        <InputWithCheckbox title={`${layout ? 'Left' : 'Bottom'} Axis`} onChange={onChange}
                            name='axisBottom' value={axisBottom} type='text' textLabel="Custom Text" disabled={!axisBottomEnabled}
                            checkboxLabel={`Show ${layout ? 'Left' : 'Bottom'} Axis`} checkboxName={'axisBottomEnabled'} checked={axisBottomEnabled} />
                        </div>
                    </div>
                    <div className='setup_row'> 
                        <Range label={ i18n("monitor", 'axisBottomAngle') } onChange={onChange}
                            name='axisBottomAngle' value={axisBottomAngle}
                            min='-90' max='90' step='10' />
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "style" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="switch-wrapper">
                            <Checkbox label={ i18n("monitor", 'verticalGrid') } onChange={onChange}
                                name='gridX' checked={gridX} />
                            <Checkbox label={ i18n("monitor", 'horizontalGrid') } onChange={onChange}
                                name='gridY' checked={gridY} />
                            <Checkbox label={ i18n("monitor", 'showLabels') } onChange={onChange}
                                name='label' checked={label} />
                        </div>
                    </div>
                    <div className='setup_row' id='select-chart'>
                        <div>
                        <InputWithCheckbox title='Max value' onChange={onChange} disabled={maxValueAuto}
                            textLabel={"Value"} name='maxValue' value={maxValue} type='number'
                            checkboxLabel={"Auto value"} checkboxName='maxValueAuto' checked={maxValueAuto} />
                        </div>
                        <div>
                            <InputWithCheckbox title='Min value' onChange={onChange} disabled={minValueAuto}
                                textLabel={"Value"} name='minValue' value={minValue} type='number'
                                checkboxLabel={"Auto value"} checkboxName='minValueAuto' checked={minValueAuto} />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "specific" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element">
                        <Checkbox label={ i18n("monitor", 'enableArea') } onChange={onChange}
                            name='enableArea' checked={enableArea} />
                        </div>
                        <div className="row_element" style={{width:"100%"}}>
                        <Range label={ i18n("monitor", 'areaOpacity') } onChange={onChange}
                            name='areaOpacity' value={areaOpacity}
                            min='0.1' max='1' step='0.05' />
                            </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'dotSize') } onChange={onChange}
                                name='dotSize' value={dotSize}
                                min='0' max='20' step='1' />
                        </div>
                        <div className="row_element">
                            <Range label={ i18n("monitor", 'lineWidth') } onChange={onChange}
                                name='lineWidth' value={lineWidth}
                                min='0' max='20' step='1' />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "adjustment" && 
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                            name='top' value={ margin.top }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                            name='bottom' value={ margin.bottom }
                            min='0' max='200' step='1' />
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                            name='left' value={ margin.left }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                            name='right' value={ margin.right }
                            min='0' max='200' step='1' />
                    </div>
                </div>
            </React.Fragment>
            }
        </React.Fragment>
    )
}
function ScatterPlotList(props) {
    const { options, onChange, generalSubMode } = props
    const { axisLeft, axisBottom, axisLeftEnabled, axisBottomEnabled, axisBottomAngle, margin, layout, gridX, gridY, legend,
        dotSize, minValue, minValueAuto, maxValue, maxValueAuto } = options
    return (
        <React.Fragment>
            {generalSubMode === "layout" && 
                <React.Fragment>
                    <div className='setup_row' id='select-chart'>
                        <Checkbox label={ i18n("monitor", 'showLegend') } onChange={onChange}
                            name='legend' checked={legend} />
                    </div>
                    <div className='setup_row' id='select-chart'>
                        <InputWithCheckbox title={`${layout ? 'Bottom' : 'Left'} Axis`} onChange={onChange}
                            name='axisLeft' value={axisLeft} type='text' textLabel="Custom Text" disabled={!axisLeftEnabled}
                            checkboxLabel={`Show ${layout ? 'Bottom' : 'Left'} Axis`} checkboxName={'axisLeftEnabled'} checked={axisLeftEnabled} />
                    </div>
                    <div className='setup_row' id='select-chart'>
                        <InputWithCheckbox title={`${layout ? 'Left' : 'Bottom'} Axis`} onChange={onChange}
                            name='axisBottom' value={axisBottom} type='text' textLabel="Custom Text" disabled={!axisBottomEnabled}
                            checkboxLabel={`Show ${layout ? 'Left' : 'Bottom'} Axis`} checkboxName={'axisBottomEnabled'} checked={axisBottomEnabled} />
                    </div>
                    <div className='setup_row'> 
                        <Range label={ i18n("monitor", 'axisBottomAngle') } onChange={onChange}
                            name='axisBottomAngle' value={axisBottomAngle}
                            min='-90' max='90' step='10' />
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "style" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="switch-wrapper">
                            <Checkbox label={ i18n("monitor", 'verticalGrid') } onChange={onChange}
                                name='gridX' checked={gridX} />
                            <Checkbox label={ i18n("monitor", 'horizontalGrid') } onChange={onChange}
                                name='gridY' checked={gridY} />
                        </div>
                    </div>
                    <div className='setup_row' id='select-chart'>
                        <div>
                        <InputWithCheckbox title='Max value' onChange={onChange} disabled={maxValueAuto}
                            textLabel={"Value"} name='maxValue' value={maxValue} type='number'
                            checkboxLabel={"Auto value"} checkboxName='maxValueAuto' checked={maxValueAuto} />
                        </div>
                        <div>
                            <InputWithCheckbox title='Min value' onChange={onChange} disabled={minValueAuto}
                                textLabel={"Value"} name='minValue' value={minValue} type='number'
                                checkboxLabel={"Auto value"} checkboxName='minValueAuto' checked={minValueAuto} />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "specific" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <Range label={ i18n("monitor", 'dotSize') } onChange={onChange}
                            name='dotSize' value={dotSize}
                            min='0' max='20' step='1' />
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "adjustment" && 
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                            name='top' value={ margin.top }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                            name='bottom' value={ margin.bottom }
                            min='0' max='200' step='1' />
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                            name='left' value={ margin.left }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                            name='right' value={ margin.right }
                            min='0' max='200' step='1' />
                    </div>
                </div>
            </React.Fragment>
            }
        </React.Fragment>
    )
}

function Bullet(props) {
    const { options, onChange, generalSubMode, addArrayColor, removeArrayColor, handleBulletValues, handleArrayColorChange } = props
    const { reverse, measureSize, markerSize, titleRotation, titleOffsetX, margin, verticalLayout, rangesInPercent, bulletRangeColors, bulletMarkColors, bulletRanges, bulletMarkers, markersInPercent } = options
    return (
        <React.Fragment>
            {generalSubMode === "layout" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="switch-wrapper">
                        <Checkbox label={ i18n("monitor", 'verticalLayout') } onChange={onChange}
                            name='verticalLayout' checked={verticalLayout} />
                        <Checkbox label={ i18n("monitor", 'reverse') } onChange={onChange}
                            name='reverse' checked={reverse} />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'bulletTitleRotation') } onChange={ onChange }
                                name='titleRotation' value={ titleRotation }
                                min='0' max='360' step='10' />
                                </div>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'bulletTitleOffset') } onChange={ onChange }
                                name='titleOffsetX' value={ titleOffsetX }
                                min='-50' max='50' step='1' />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "specific" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'measureSize') } onChange={ onChange }
                                name='measureSize' value={ measureSize }
                                min='0' max='1' step='0.05' />
                        </div>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'markerSize') } onChange={ onChange }
                                name='markerSize' value={ markerSize }
                                min='0' max='2' step='0.05' />
                        </div>
                    </div>
                    
                    <div className="setup_row" style={{display: "flex", flexDirection: "row"}}>
                        <div style={{width: "100%", paddingRight: "10px"}}>
                            <div>
                                <Button label={ i18n("monitor", 'addRange') } appearance="secondary" size='small' filled onClick={() => addArrayColor("bulletRangeColors", false)} />
                                <Checkbox label={ i18n("monitor", 'asPercent') } onChange={onChange}
                                    name='rangesInPercent' checked={rangesInPercent} />
                            </div>
                            {bulletRangeColors.map((element, index) => {
                                return (
                                    <div key={"bulletRangeColors" + element + index} style={{marginBottom:"20px"}}>
                                        <div className='icons-wrapper' style={{display: "flex", justifyContent:"space-between"}}>
                                            <ColorPicker style={{margin: "auto 10px 5px 0px"}} color={options["bulletRangeColors"][index]} onChange={(color) => handleArrayColorChange("bulletRangeColors", index, color)} />
                                            <Textbox index={index} label={ 'value' } name="bulletRanges" value={ bulletRanges[index] } onChange={handleBulletValues}/>                            
                                            <i style={{margin:"auto 0px 5px 10px"}} name="bulletRangeColors" className={`fas fa-trash-alt`} onClick={() => removeArrayColor("bulletRangeColors", index)} />                                                      
                                        </div>
                                        <div style={{background: element, width: "100%", height: "5px"}}/>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{width: "100%", paddingLeft: "10px"}}>
                            <div>
                                <Button label={ i18n("monitor", 'addMarker') } appearance="secondary" size='small' filled onClick={() => addArrayColor("bulletMarkColors", false)} />
                                <Checkbox label={ i18n("monitor", 'asPercent') } onChange={onChange}
                                        name='markersInPercent' checked={markersInPercent} />
                            </div>
                        
                            {bulletMarkColors.map((element, index) => {
                                return (
                                    <div key={"bulletMarkColors" + element + index} style={{marginBottom:"20px"}}>
                                        <div className='icons-wrapper' style={{display: "flex", justifyContent:"space-between"}}>
                                            <ColorPicker style={{margin: "auto 10px 5px 0px"}} color={options["bulletMarkColors"][index]} onChange={(color) => handleArrayColorChange("bulletMarkColors", index, color)} />
                                            <Textbox index={index} label={ 'value' } name="bulletMarkers" value={ bulletMarkers[index] } onChange={handleBulletValues}/>                            
                                            <i style={{margin:"auto 0px 5px 10px"}} name="bulletMarkColors" className={`fas fa-trash-alt`} onClick={() => removeArrayColor("bulletMarkColors", index)} />                                                      
                                        </div>
                                        <div style={{background: element, width: "100%", height: "5px"}}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "adjustment" && 
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                            name='top' value={ margin.top }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                            name='bottom' value={ margin.bottom }
                            min='0' max='200' step='1' />
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                            name='left' value={ margin.left }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                            name='right' value={ margin.right }
                            min='0' max='200' step='1' />
                    </div>
                </div>
            </React.Fragment>
            }
        </React.Fragment>
    )
}

function Sunburst(props) {
    const { options, onChange, generalSubMode } = props
    const { margin, cornerRadius, borderWidth, enableArcLabels, showAsPercent } = options

    return (
        <React.Fragment>
            {generalSubMode === "style" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="switch-wrapper">
                            <Checkbox label={ i18n("monitor", 'showLabels') } onChange={onChange}
                                name='enableArcLabels' checked={enableArcLabels} />
                            <Checkbox label={ i18n("monitor", 'show_as_percent') } onChange={onChange}
                                name='showAsPercent' checked={showAsPercent} />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "specific" && 
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element" style={{padding: 0, width: "100%"}}>
                        <Range label={ i18n("monitor", 'cornerRadius') } onChange={ onChange }
                            name='cornerRadius' value={ cornerRadius }
                            min='0' max='45' step='1' />
                    </div>
                    <div className="row_element" style={{padding: 0, width: "100%"}}>
                        <Range label={ i18n("monitor", 'cellBorderWidth') } onChange={ onChange }
                            name='borderWidth' value={ borderWidth }
                            min='0' max='20' step='1' />
                    </div>
                </div>
                </React.Fragment>
            }

            {generalSubMode === "adjustment" && 
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                            name='top' value={ margin.top }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                            name='bottom' value={ margin.bottom }
                            min='0' max='200' step='1' />
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                            name='left' value={ margin.left }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                            name='right' value={ margin.right }
                            min='0' max='200' step='1' />
                    </div>
                </div>
            </React.Fragment>
            }
        </React.Fragment>
    )
}

function Bubble(props) {
    const { options, onChange, generalSubMode } = props
    const { margin, enableLabel, borderWidth, cellSpacing, hideZeroSumCells, labelSkipRadius, showAsPercent, showLabelDepth0, showLabelDepth1, showLabelDepth2, showLabelDepth3 } = options

    return (
        <React.Fragment>
            {generalSubMode === "style" &&
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <div className="switch-wrapper">
                                <Checkbox label={ i18n("monitor", 'showLabels') } onChange={onChange}
                                    name='enableLabel' checked={enableLabel} />
                                <Checkbox label={ i18n("monitor", 'show_as_percent') } onChange={onChange}
                                    name='showAsPercent' checked={showAsPercent} />
                            </div>
                        </div>
                    </div>

                    <div className='setup_row'>
                        <div style={{width:"100%"}}>
                            <Label>{ i18n("monitor", 'label_depth_filter') }</Label>
                            <div className="setup_row">
                                <Checkbox label={ `${i18n("monitor", 'show_label_depth')} 1` } onChange={onChange}
                                    name='showLabelDepth0' checked={showLabelDepth0} />
                            </div>
                            <div className="setup_row">
                                <Checkbox label={ `${i18n("monitor", 'show_label_depth')} 2` } onChange={onChange}
                                    name='showLabelDepth1' checked={showLabelDepth1} />
                            </div>
                            <div className="setup_row">
                                <Checkbox label={ `${i18n("monitor", 'show_label_depth')} 3` } onChange={onChange}
                                    name='showLabelDepth2' checked={showLabelDepth2} />
                            </div>
                            <div className="setup_row">
                                <Checkbox label={ `${i18n("monitor", 'show_label_depth')} 4` } onChange={onChange}
                                    name='showLabelDepth3' checked={showLabelDepth3} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "specific" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'cellSpacing') } onChange={ onChange }
                                name='cellSpacing' value={ cellSpacing }
                                min='0' max='45' step='1' />
                        </div>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'cellBorderWidth') } onChange={ onChange }
                                name='borderWidth' value={ borderWidth }
                                min='0' max='20' step='1' />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element">
                            <Checkbox label={ i18n("monitor", 'hideZeroSumCells') } onChange={onChange}
                                    name='hideZeroSumCells' checked={hideZeroSumCells} /> 
                        </div>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'labelSkipRadius') } onChange={ onChange }
                                name='labelSkipRadius' value={ labelSkipRadius }
                                min='0' max='100' step='5' />
                        </div>
                    </div>
     
                </React.Fragment>
            }

            {generalSubMode === "adjustment" && 
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                            name='top' value={ margin.top }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                            name='bottom' value={ margin.bottom }
                            min='0' max='200' step='1' />
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                            name='left' value={ margin.left }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                            name='right' value={ margin.right }
                            min='0' max='200' step='1' />
                    </div>
                </div>
            </React.Fragment>
            }
        </React.Fragment>
    )
}

function CalendarList(props) {
    const { options, onChange, generalSubMode } = props
    const { itemCount, horizontalLayout, margin, legend, horizontalLegend,
        monthBorderWidth, dayBorderWidth, daySpacing } = options
    return (
        <React.Fragment>
            {generalSubMode === "layout" &&
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Checkbox label={ i18n("monitor", 'horizontalLayout') } onChange={onChange}
                                name='horizontalLayout' checked={horizontalLayout} />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="switch-wrapper">
                            <Checkbox label={ i18n("monitor", 'showLegend') } onChange={onChange}
                                name='legend' checked={legend} />
                            <Checkbox label={ i18n("monitor", 'horizontalLegend') } disabled={!legend} onChange={onChange}
                                name='horizontalLegend' checked={horizontalLegend} />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "specific" &&
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'legendItems') } onChange={ onChange }
                                name='itemCount' value={ itemCount }
                                min='0' max='25' step='1' />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'monthBorderWidth') } onChange={ onChange }
                                name='monthBorderWidth' value={ monthBorderWidth }
                                min='0' max='20' step='1' />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'cellBorderWidth') } onChange={ onChange }
                                name='dayBorderWidth' value={ dayBorderWidth }
                                min='0' max='20' step='1' />
                        </div>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'cellSpacing') } onChange={ onChange }
                                name='daySpacing' value={ daySpacing }
                                min='-1' max='20' step='1' />
                        </div>
                    </div>
                </React.Fragment>
            }

            {generalSubMode === "adjustment" &&
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                            name='top' value={ margin.top }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                            name='bottom' value={ margin.bottom }
                            min='0' max='200' step='1' />
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                            name='left' value={ margin.left }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                            name='right' value={ margin.right }
                            min='0' max='200' step='1' />
                    </div>
                </div>
            </React.Fragment>
            }
            
        </React.Fragment>
    )
}

function HeatmapList(props) {
    const { options, onChange, generalSubMode } = props
    const { margin, cellSpacing, cellBorderWidth, showLeftAxis, leftLegendRotation, forceSquare, showTopAxis, circularCells, topLegendRotation } = options

    return (
        <React.Fragment>
            {generalSubMode === "layout" &&
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element">
                        <Checkbox label={ i18n("monitor", 'showTopAxis') } onChange={onChange}
                            name='showTopAxis' checked={showTopAxis} />
                        </div>
                        <div className="row_element" style={{width:"100%"}}>
                        <Range label={ i18n("monitor", 'topLegendRotation') } onChange={onChange}
                            name='topLegendRotation' disabled={!showTopAxis} value={topLegendRotation}
                            min='-90' max='90' step='10' />
                            </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element">
                        <Checkbox label={ i18n("monitor", 'showLeftAxis') } onChange={onChange}
                            name='showLeftAxis' checked={showLeftAxis} />
                        </div>
                        <div className="row_element" style={{width:"100%"}}>
                        <Range label={ i18n("monitor", 'leftLegendRotation') } onChange={onChange}
                            name='leftLegendRotation' disabled={!showLeftAxis} value={leftLegendRotation}
                            min='-90' max='90' step='10' />
                            </div>
                    </div>
                </React.Fragment>
            }
            {generalSubMode === "specific" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'cellSpacing') } onChange={ onChange }
                                name='cellSpacing' value={ cellSpacing }
                                min='0' max='35' step='1' />
                        </div>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'cellBorderWidth') } onChange={ onChange }
                                name='cellBorderWidth' value={ cellBorderWidth }
                                min='0' max='20' step='1' />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="switch-wrapper">
                            <Checkbox label={ i18n("monitor", 'forceSquare') } onChange={onChange}
                                name='forceSquare' checked={forceSquare} />
                
                            <Checkbox label={ i18n("monitor", 'circularCells') } onChange={onChange}
                                name='circularCells' checked={circularCells} />
                        </div>
                    </div>
                </React.Fragment>
            }
            {generalSubMode === "adjustment" &&
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                            name='top' value={ margin.top }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                            name='bottom' value={ margin.bottom }
                            min='0' max='200' step='1' />
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                            name='left' value={ margin.left }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                            name='right' value={ margin.right }
                            min='0' max='200' step='1' />
                    </div>
                </div>
            </React.Fragment>
            }
        </React.Fragment>
    )
}
function RadarList(props) {
    const { options, onChange, generalSubMode } = props,
    { margin, linearLines, linearGridShape, showDots, dotSize, showDotLabel, dotLabelYOffset,
        fillOpacity, lineWidth, gridLevels, maxValue, autoMaxValue, showLegend } = options
 
    return (
        <React.Fragment>
            {generalSubMode === "layout" && 
                <React.Fragment>
                    <div className="setup_row">
                        <Checkbox label={ i18n("monitor", 'showLegend') } onChange={onChange}
                            name='showLegend' checked={showLegend} />
                    </div>
                </React.Fragment>
            }
            {generalSubMode === "style" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'gridLevels') } onChange={ onChange }
                                name='gridLevels' value={ gridLevels }
                                min='0' max='20' step='1' />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element">
                        <Checkbox label={ i18n("monitor", 'showDotLabel') } onChange={onChange}
                            name='showDotLabel' checked={showDotLabel} />
                        </div>
                        <div className="row_element" style={{width:"100%"}}>
                        <Range label={ i18n("monitor", 'dotLabelOffset') } onChange={onChange}
                            name='dotLabelYOffset' disabled={!showDotLabel} value={dotLabelYOffset}
                            min='-25' max='25' step='1' />
                            </div>
                    </div>
                    <div className='setup_row'>
                        <InputWithCheckbox title='Max value' onChange={onChange} disabled={autoMaxValue} 
                            textLabel={"Value"} name='maxValue' value={maxValue} type='number'
                            checkboxLabel={"Auto value"} checkboxName='autoMaxValue' checked={autoMaxValue} />
                    </div>
                </React.Fragment>
            }
            {generalSubMode === "specific" && 
                <React.Fragment>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'lineWidth') } onChange={ onChange }
                                name='lineWidth' value={ lineWidth }
                                min='0' max='20' step='1' />
                        </div>
                    </div>
                   <div className='setup_row'>
                        <div className="switch-wrapper">
                            <Checkbox label={ i18n("monitor", 'linearLines') } onChange={onChange}
                                name='linearLines' checked={linearLines} />
                            <Checkbox label={ i18n("monitor", 'linearGridShape') } onChange={onChange}
                                name='linearGridShape' checked={linearGridShape} />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element" style={{padding: 0, width: "100%"}}>
                            <Range label={ i18n("monitor", 'areaOpacity') } onChange={ onChange }
                                name='fillOpacity' value={ fillOpacity }
                                min='0' max='1' step='0.05' />
                        </div>
                    </div>
                    <div className='setup_row'>
                        <div className="row_element">
                        <Checkbox label={ i18n("monitor", 'showDots') } onChange={onChange}
                            name='showDots' checked={showDots} />
                        </div>
                        <div className="row_element" style={{width:"100%"}}>
                        <Range label={ i18n("monitor", 'dotSize') } onChange={onChange}
                            name='dotSize' disabled={!showDots} value={dotSize}
                            min='0' max='60' step='1' />
                        </div>
                    </div>
                </React.Fragment>
            }
            {generalSubMode === "adjustment" &&
                <React.Fragment>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'topMargin') } onChange={ onChange }
                            name='top' value={ margin.top }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'bottomMargin') } onChange={ onChange }
                            name='bottom' value={ margin.bottom }
                            min='0' max='200' step='1' />
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'leftMargin') } onChange={ onChange }
                            name='left' value={ margin.left }
                            min='0' max='200' step='1' />
                    </div>
                    <div className="row_element">
                        <Range label={ i18n("monitor", 'rightMargin') } onChange={ onChange }
                            name='right' value={ margin.right }
                            min='0' max='200' step='1' />
                    </div>
                </div>
            </React.Fragment>
            }
        </React.Fragment>
    )
}

function Dropdown(props) {
    const { name, onChange, value, label, options, imgSrc, disabled } = props
    let selectedIndex = options.findIndex(item => item.value === value)
    if (options[selectedIndex].hide || options[selectedIndex].disabled) selectedIndex = options.findIndex(item => (!item.hide && !item.disabled))
    return (
        <Select disabled={ selectedIndex === -1 ? true : disabled } name={ name } label={ label } imgSrc={ imgSrc } type='monitor' selectedIndex={ selectedIndex } onChange={ onChange } options={ options } />
    )
}
function Textbox(props) {
    const { name, label, value, onChange, disabled, index } = props
    return (
        <EditableField disabled={disabled} type="monitor" name={name} label={ label } value={ value } onChange={ onChange } index={index} />
    )
}

function Checkbox(props) {
    const { label, name, checked, onChange, style, disabled } = props
    return (
        <Switch disabled={disabled} style={style} name={ name } label={ label } checked={ !disabled && checked } onChange={ onChange } animate={ true }/>
    )
}

function InputWithCheckbox(props) {
    const { title, checkboxLabel, textLabel, onChange, name, value, checkboxName, checked, disabled, disableCheckbox } = props
    return (
        <div style={{width:"100%"}}>
            <Label>{title}</Label>
            <div style={{display:"flex", paddingRight: "10px", paddingLeft: "10px"}}>
                <div style={{width:"50%", paddingRight:"10px"}}>
                    <Checkbox disabled={disableCheckbox} label={checkboxLabel} onChange={onChange} name={checkboxName} checked={checked} /> 
                    </div>
                    <div style={{marginTop: "auto"}}>
                    <Textbox disabled={disabled} label={textLabel} name={name} value={ value } onChange={ onChange } />
                    </div>
            </div>
        </div>
    )
}

const Label = styled.label`
    min.width: 35px
    font-weight: 400
    font-size: 12px
    margin: 0px
    color: ${props => props.theme.label}
`

const Div = styled.div`
    padding-left: 5px;
    ${props => props.className.split(' ').includes('globalcolors') && css`
        background: ${props.theme.overlay};
    `}
    ${props => props.className.split(' ').includes('TimeReportOverview-top-bar') && css`
        background: ${props.theme.overlay};
    `}
    ${props => props.className.split(' ').includes('top') && css`
        border-bottom: 1px solid ${props.theme.background};
    `}
    ${props => props.className.split(' ').includes('avatar') && css`
        background: ${props.theme.label} 50% 50% no-repeat;
    `}

    ${props => props.className.split(' ').includes('item') && css`
        color: ${props.theme.label};
    `}

    ${props =>  props.className.split(' ').includes('item') &&
                props.className.split(' ').includes('active')
                && css`
        color: ${props.theme.primary};
    `}

    ${props => props.className.split(' ').includes('togglerShadow') && css`
        background: ${props.theme.background};
        &:hover {
            background: ${props.theme.background};
        }
        &::-webkit-scrollbar-thumb {
            background: ${props.theme.background};
            border-radius: 20px;
        }
        &::-webkit-scrollbar-track {
            background: ${props.theme.label}80;
            border-radius: 20px;
        }
        &::-webkit-scrollbar {
            height: 4px;
        }
    `}

    ${props => props.active && css`
        background: ${props.theme.primary};
    `}

    ${props => props.className.split(' ').includes('label') && css`
        color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('icon') && css`
        color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('text') && css`
        color: ${props.theme.subtitle};
    `}
    ${props => props.className.split(' ').includes('color_picker') && css`
        @media (max-width: 640px) {
            transform: translate(0,8%) !important;
        }
    `}

`;