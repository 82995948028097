import React from "react"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import Button from "components/Button/Button"
import EditableTable from "../EditableTable/EditableTable"
import MessageBox from "components/MessageBox/MessageBox"
import TextField from "components/TextField/TextField"

import i18n from "helpers/i18n"
import api from "helpers/api"

export default class TagsReviewContent extends React.PureComponent {
    constructor(props) {
        super(props)

        const dataFixed = !props.isNew  ? props.data.map((e, i) => ({
            selected:false,
            ...e
        })) : []

        this.state = {
            isNew: props.isNew,
            ID: props.ID,
            title: props.title,
            data: dataFixed,
            editingTitle: !props.title,
            editedTitle: ''
        }
    }

    _onRowChecked = (type, colKey, rowIndex, checked) => {
        const updatedRows = {...this.state.data}
        updatedRows[rowIndex]['selected'] = checked

        this.setState(prevState => ({
            data: [...prevState.data]
        }))

    }

    _onAddRow = () => {
        let rows = [...this.state.data]
        rows.unshift({
            isNew: true,
            selected: false,
            name: '',
            type: '',
            ID: null
        })

        this.setState({ data: rows })
    }

    _onSubmitHandler = async () => {
        console.log('data', JSON.stringify(this.state))
        const result = await api('tags/save', this.state)

        if (result[0] === "Saved") {
            if(this.props.onSaved) this.props.onSaved(this.state)
        } else {
            console.log("show message box")
            MessageBox(this, {
                message: result.message,
                buttons: [{
                    label: "OK",
                    appearance: "primary"
                }],
            })
        }
    }

    _onRemoveSelected = ()=> {
        const rows = [...this.state.data].map(row => {
            if (row.selected) return {...row, delete: true }
            else return {...row}
        })

        this.setState({ data: rows })
    }

    _onTitleChangedHandler = (e) => {
        this.setState({editedTitle: e})
    }

    _onAddAllHandler = async (type) => {
        const result = await api('globals/getall', { type })
        const data = Object.values(result.data)

        //Add selected to beginning of each row and filter out duplicates.
        const newRows = data.map((e, i) => ({
            isNew: true,
            selected: false,
            ...e
        })).filter((item) => {
            const exists = this.state.data.find((e) => {
                if (!e.delete) return e.name === item.name && e.type === item.type && +e.ID === +item.ID
                else return false
            })

            return !exists
        })

        this.setState(prevState => ({ data: [...prevState.data, ...newRows] }))
    }

    _onTitleClickedHandler = () => {
        this.setState({ editingTitle: true }, () => {
            let textfield = document.getElementById("title")
            if (textfield) textfield.focus()
        })
    }

    _onCloseTitleTextHandler = (e) => {
        const onlyWhiteSpaces = (str) => !str || !str.replace(/\s/g, '').length

        if (onlyWhiteSpaces(this.state.editedTitle) && this.state.title !== undefined) {
            this.setState(prevState => ({
                editedTitle: prevState.title
            }))
        } else if (onlyWhiteSpaces(this.state.editedTitle) && onlyWhiteSpaces(this.state.title)) {
            return
        } else if (this.state.editedTitle === '') {
            this.setState(prevState => ({
                editedTitle: prevState.title
            }))
        } else {
            if (e.target.id !== 'title') this.setState({ editingTitle: false })
        }
    }

    _onAutoCompleteSelected = (e) => {
        this.forceUpdate()
    }

    render() {
        const { data, editingTitle, editedTitle, title} = this.state
        const footerTemplate = (
            <div style={{ width: "100%" }}>
                <StackLayout justifyContent="space-between" rowWrap fullWidth>
                    <Button size="small"
                            label={i18n("globals", "add_all_projects")}
                            appearance="secondary"
                            filled
                            style={{ margin: '5px 0' }}
                            onClick={this._onAddAllHandler.bind(this, 'project')}
                    />
                    <Button size="small"
                            label={i18n("globals", "add_all_resources")}
                            appearance="secondary"
                            filled
                            style={{ margin: '5px 0' }}
                            onClick={this._onAddAllHandler.bind(this, 'user')}
                    />
                    <Button size="small"
                            label={i18n("globals", "add")}
                            appearance="secondary"
                            filled
                            style={{ margin: '5px 0' }}
                            onClick={this._onAddRow}
                    />
                    <Button size="small"
                            style={{ margin: '5px 0' }}
                            label={i18n("general", "remove_selected")}
                            appearance="danger"
                            onClick={this._onRemoveSelected}
                    />
                </StackLayout>
            </div>
        )

        const tagtitletext = editedTitle || title || ''
        const tagtitle = <center>
            {!editingTitle
            ? <h2 id="title"style={{ marginTop: '-1px', cursor: "pointer" }} onClick={this._onTitleClickedHandler}>
                {tagtitletext}
            </h2>
            : <TextField
                id='title'
                className='split'
                style={{ width: '30%', height:'inherit'}}
                value={tagtitletext}
                onChange={this._onTitleChangedHandler}
            />}
        </center>

        return <div onClick={this._onCloseTitleTextHandler}>
            {this.state.MessageBox}
            {tagtitle}
            <p/>
            <EditableTable
                context={this}
                type='tags'
                rows={data.filter(e => !e.delete)}
                columns={[
                    { key: "selected",  label: "", width: "10%" },
                    { name: "tagged",   label: `${i18n('editable_table', 'tagged')}`, width: "55%" },
                    { name: "shadow",   label: '', width: "10%" }
                ]}
                onRowChecked={this._onRowChecked}
                footerTemplate={footerTemplate}
                onAutoCompleteSelected={this._onAutoCompleteSelected}
            />

            <StackLayout orientation="horizontal">
                <Button type="submit"
                        label={i18n("general", !this.props.isNew ? 'save' : 'create')}
                        appearance="primary"
                        filled
                        onClick={this._onSubmitHandler}
                />
            </StackLayout>
        </div>
    }
}
