import React, { Component } from 'react'

import DatePicker from "components/DatePicker/DatePicker";
import Autocomplete from "components/Autocomplete/Autocomplete";
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"
import Select from "components/Select/Select"
import Switch from "components/Switch/Switch"
import Button from "components/Button/Button"

import Settings from "classes/Settings"
import styled from 'styled-components'

import * as utils from "helpers/utils"
import i18n from "helpers/i18n"

class DefaultSetup extends Component {
    constructor(props) {
        super(props)
        this.state = {showLayerOptions: false}
        this.mainAutocomplete = React.createRef()
        this.secondaryAutocomplete = React.createRef()
        this.layoutOptionsRef = React.createRef()
        this.kpi_shouldOrNotIncludeShadows = [
            'wage cost', 'salary', 'payroll', 'pension', 'extras cost',
            'resources without projects', 'resources in external projects',
            'resources in internal projects', 'number of new projects', 'number of ongoing projects', 'number of finished projects',
            'number of billable projects', 'number of non-billable projects', 'new resources in project',
            'available work hours', 'finished project calendar duration', 'chargeability resource', 'chargeability project',
            'projected chargeability resource', 'projected chargeability project', 'projected total revenue', 'projected total expenses',
            'projected net margin', 'projected net margin hit rate'
        ]
    }

    componentDidMount() {
        if (this.props.getDataMode === 'auto' && this.props.getData) this.props.getData()
    }

    render() {
        const { chartType, setup, addMainAsset, deleteMainAsset, addSecondaryAsset, deleteSecondaryAsset, handleSetupChange, getData, fetching, getDataMode, handleGetDataModeChange, handleChartTypeChange, changeLayerOrder } = this.props
        const { kpi, mainAssets, mainAssetType, includeMainAsset, secondaryAssets, secondaryAssetType, includeSecondaryAsset, calcType, unitType, startDate, endDate, startDateType, endDateType, timeScale, includeShadows, layerOrder, componentBreakdown } = setup

        const autoIncludeShadows = !this.kpi_shouldOrNotIncludeShadows.includes(kpi)
        return (
            <div id='data_style-container'>
                <div className='setup_row'>
                    <div className="row_element">
                        <Dropdown label={i18n("monitor", 'kpi')} name="kpi" onChange={handleSetupChange} value={kpi}
                            options={[
                                { label: i18n("monitor", 'financial') },
                                { value: "gross margin", label: i18n("monitor", 'grossMargin'), disabled: ["radar", "bubble"].includes(chartType)  },
                                { value: "net margin", label: i18n("monitor", "netMargin"), disabled: ["radar", "bubble"].includes(chartType)  },
                                { value: "projected net margin", label: i18n("monitor", "projectedNetMargin"), disabled: ["radar", "bubble"].includes(chartType)  },
                                { value: "projected net margin hit rate", label: i18n("monitor", "projectedNetMarginHitRate"), disabled: ["radar", "bubble"].includes(chartType)  },
                                { value: "extras net", label: i18n("monitor", "extrasNet"), disabled: ["radar", "bubble"].includes(chartType) },
                                { value: "overhead net", label: i18n("monitor", "overheadNet"), disabled: ["radar", "bubble"].includes(chartType) },
                                { value: "overhead net per employee", label: i18n("monitor", "overheadNetPerEmployee"),disabled: ["radar", "bubble"].includes(chartType) },
                                { label: i18n("monitor", 'financialIncome') },
                                { value: "total revenue", label: i18n("monitor", "totalRevenue") },
                                { value: "projected total revenue", label: i18n("monitor", "projectedTotalRevenue") },
                                { value: "hourly fee", label: i18n("monitor", 'hourlyFee') },
                                { value: "extras fee", label: i18n("monitor", "extrasFee") },
                                { value: "overhead fee", label: i18n("monitor", "overheadFee") },

                                { label: i18n("monitor", 'financialCost') },
                                { value: "total expenses", label: i18n("monitor", "totalExpenses") },
                                { value: "projected total expenses", label: i18n("monitor", "projectedTotalExpenses") },
                                { value: "wage cost", label: i18n("monitor", 'wageCost') },
                                { value: "salary", label: i18n("monitor", "salary") },
                                { value: "payroll", label: i18n("monitor", "payroll") },
                                { value: "pension", label: i18n("monitor", "pension") },
                                { value: "extras cost", label: i18n("monitor", "extrasCost") },
                                { value: "overhead cost", label: i18n("monitor", "overheadCost") },
                                { value: "cost of non-billable hours", label: i18n("monitor", "costOfNonBillableHours") },
                                
                                { label: i18n("monitor", 'resources') },
                                { value: "resources in billable projects", label: i18n("monitor", "resourcesInExternalProjects") },
                                { value: "resources in non-billable projects", label: i18n("monitor", "resourcesInInternalProjects") },
                                { value: "resources without projects", label: i18n("monitor", "resourcesWithoutProjects") },
                                { value: "resources with projects", label: i18n("monitor", "resourcesWithProjects") },
                                { value: "number of employees", label: i18n("monitor", "numberOfEmployees") },
                                { value: "new hires", label: i18n("monitor", "newHires") },
                                { value: "leavers", label: i18n("monitor", "leavers") },
                                { value: "resources in non-billable/without projects", label: i18n("monitor", "resourcesInNonBillableOrWithoutProjects") },
                                { value: "employment period", label: i18n("monitor", "employmentPeriod") },
                                //{ value: "employee turnover", label: i18n("monitor", "employeeTurnover") },
                                { value: "employee alteration", label: i18n("monitor", "employeeAlteration"), disabled: ["radar", "bubble"].includes(chartType)},
                                { value: "age", label: i18n("monitor", 'age') },
                                { value: "gender", label: i18n("monitor", "gender") },

                                { label: i18n("monitor", "projects") },
                                { value: "number of new projects", label: i18n("monitor", "numberOfNewProjects") },
                                { value: "number of ongoing projects", label: i18n("monitor", "numberOfOngoingProjects") },
                                { value: "number of finished projects", label: i18n("monitor", "numberOfFinishedProjects") },
                                { value: "number of billable projects", label: i18n("monitor", "numberOfBillableProjects") },
                                { value: "number of non-billable projects", label: i18n("monitor", "numberOfNonBillableProjects") },
                                { value: "finished project calendar duration", label: i18n("monitor", "finishedProjectCalendarDuration") },
                                { value: "new resources in project", label: i18n("monitor", "newResourcesInProject") },
                            
                                { label: i18n("monitor", 'hours') },
                                { value: "reported hours", label: i18n("monitor", 'reportedHours') },
                                { value: "billed hours", label: i18n("monitor", 'billedHours') },
                                { value: "available work hours", label: i18n("monitor", 'workHours') },
                                { value: "chargeability resource", label: i18n("monitor", "chargeabilityResource") },
                                { value: "chargeability project", label: i18n("monitor", "chargeabilityProject") },
                                { value: "projected chargeability resource", label: i18n("monitor", "projectedChargeabilityResource") },
                                { value: "projected chargeability project", label: i18n("monitor", "projectedChargeabilityProject") },

                                { label: i18n("monitor", 'companies') },
                                { value: "new clients", label: i18n("monitor", "newClients") },
                                { value: "recurring clients", label: i18n("monitor", "recurringClients") },

                            ]} 
                        />
                    </div>
                    <div className="row_element">
                        <Dropdown label={ i18n("monitor", 'chartType') } name="chartType" onChange={handleChartTypeChange} value={chartType}
                            options={[
                                { value: "textdata", label: i18n("monitor", 'textData'), hoverInfo: "Transforms to list if the result has multiple values"  },
                                { value: "bar",      label: i18n("monitor", 'barChart'), imgSrc: utils.getNivoIcon("bar-dark-colored"), hoverInfo: " " },
                                { value: "line",     label: i18n("monitor", 'lineChart'), imgSrc: utils.getNivoIcon("line-dark-colored"), hoverInfo: " " },
                                { value: "pie",      label: i18n("monitor", 'pieChart'), imgSrc: utils.getNivoIcon("pie-dark-colored"), hoverInfo: " " },
                                { value: "calendar", label: i18n("monitor", 'calendar'), imgSrc: utils.getNivoIcon("calendar-dark-colored"), hoverInfo: " " },
                                { value: "heatmap",      label: i18n("monitor", 'heatmap'), imgSrc: utils.getNivoIcon("heatmap-dark-colored"), hoverInfo: " " },
                                { value: "bullet",   label:  i18n("monitor", 'bullet'), imgSrc: utils.getNivoIcon("bullet-dark-colored"), hoverInfo: " " },
                                { value: "radar",     label: i18n("monitor", 'radar'), imgSrc: utils.getNivoIcon("radar-dark-colored"), hoverInfo: " ", 
                                    disabled: ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'overhead net', 'projected margin', 'overhead net per employee', 'employee alteration'].includes(kpi) },
                                { value: "sunburst",      label: i18n("monitor", "sunburst"), imgSrc: utils.getNivoIcon("sunburst-dark-colored"), hoverInfo: " " },
                                { value: "bubble",      label: i18n("monitor", "bubble"), imgSrc: utils.getNivoIcon("circle-packing-dark-colored"), hoverInfo: " ", 
                                    disabled: ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'overhead net', 'projected margin', 'overhead net per employee', 'employee alteration'].includes(kpi) },
                                { value: "scatterPlot",     label: i18n("monitor", 'scatterPlot'), imgSrc: utils.getNivoIcon("scatterplot-light-colored"), hoverInfo: " " },

                            ]} />
                    </div>
                </div>

                {["sunburst", "bubble"].includes(chartType) &&
                    <div className="setup_row">
                        <div className="row_element">
                            <Checkbox label={i18n("monitor", 'componentBreakdown')} onChange={handleSetupChange} name={"componentBreakdown"} checked={componentBreakdown} disabled={!['total revenue', 'projected total revenue', 'total expenses', 'projected total expenses', 'wage cost', 'cost of non-billable hours'].includes(kpi)} /> 
                        </div>
                        <div ref={this.layoutOptionsRef} className="row_element" style={{position: "relative"}} >
                            <Button label={ i18n("monitor", "layerOrder") } appearance="secondary" size='small' filled onClick={() => this.toggleLayerOptions()} />

                                {this.state.showLayerOptions && <LayerOrderContainer>
                                    {layerOrder.map((name, index) => {
                                        return (
                                            <div key={index} style={{display: "flex"}}>
                                                <LayerOrderNavIcons>
                                                    <i style={{color: Settings.getGlobalColor(index === 0 ? "label" : "subtitle")}} className="material-icons" onClick={() => changeLayerOrder(index, "up")}>expand_less</i>
                                                    <i style={{color: Settings.getGlobalColor(index === 2 ? "label" : "subtitle")}} className="material-icons" onClick={() => changeLayerOrder(index, "down")}>expand_more</i>
                                                </LayerOrderNavIcons>
                                                <div style={{color: Settings.getGlobalColor(this.isLayerActive(name) ? "subtitle" : "label"), margin: "auto 0"}}>{i18n("monitor", name)}</div>
                                                {name === "assets" && <div style={{color: Settings.getGlobalColor(this.isLayerActive("&") ? "subtitle" : "label"), margin: "auto 0", whiteSpace: "pre"}}>{" & "}</div>}
                                                {name === "assets" && <div style={{color: Settings.getGlobalColor(this.isLayerActive("group") ? "subtitle" : "label"), margin: "auto 0"}}>{i18n("monitor", "group")}</div>}
                                            </div>
                                        )
                                    })
                                    }
                                </LayerOrderContainer>}
                        </div>
                    </div>
                }
                
                <div className='setup_row'>
                    <div className="row_element">
                        <Dropdown label={i18n("monitor", 'startDate')} name="startDateType" onChange={handleSetupChange} value={startDateType}
                            options={[
                                { value: "all", label: i18n("monitor", 'noLimitStart') , hoverInfo: " "  },
                                { value: "year", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'year')}`, hoverInfo: " "  },
                                { value: "quarter", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'quarter')}`, hoverInfo: " "  },
                                { value: "month", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'month')}`, hoverInfo: " "  },
                                { value: "week", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'week')}`, hoverInfo: " "  },
                                { value: "day", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'day')}`, hoverInfo: " "  },
                                { value: "custom", label: i18n("monitor", 'customDate'), hoverInfo: " "  },
                            ]} />
                   </div>
                   <div className="row_element">
                        <Dropdown label={ i18n("monitor", 'endDate') } name="endDateType" onChange={handleSetupChange} value={endDateType}
                            options={[
                                { value: "all", label: i18n("monitor", 'noLimitEnd') , hoverInfo: " "  },
                                { value: "year", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'year')}`, hoverInfo: " "  },
                                { value: "quarter", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'quarter')}`, hoverInfo: " "  },
                                { value: "month", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'month')}`, hoverInfo: " "  },
                                { value: "week", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'week')}`, hoverInfo: " "  },
                                { value: "workweek", label: `${i18n("monitor", 'current')} ${i18n("monitor", 'workWeek')}`, hoverInfo: " "  },
                                { value: "day", label: i18n("monitor", 'current day'), hoverInfo: " "  },
                                { value: "custom", label: i18n("monitor", 'customDate'), hoverInfo: " "  },
                            ]} />
                    </div>
                </div>
                
                {(startDateType === 'custom' || endDateType === 'custom') && 
                    <div className='setup_row' style={{paddingTop:"0px"}}>
                        <div className="row_element" style={{width:"calc(50% - 7px)", marginLeft:"7px"}}>
                            {startDateType === 'custom' && <DatePicker
                                date={ startDate ? utils.moment(startDate) : null }
                                onDateChange={ ({target}) => handleSetupChange(
                                    utils.moment(target.value).format('YYYY-MM-DD'), 'startDate')}
                            />}
                        </div>
                        <div className="row_element" style={{width:"calc(50% - 7px)"}}>
                            {endDateType === 'custom' && <DatePicker
                                date={ endDate ? utils.moment(endDate) : null  }
                                onDateChange={ ({target}) => handleSetupChange(
                                    utils.moment(target.value).format('YYYY-MM-DD'), 'endDate')}
                            />}
                        </div>
                    </div>
                }
                <div className='setup_row'>
                    <div className="row_element">
                        <Dropdown disabled={chartType === "calendar" || kpi === 'age'} label={ i18n("monitor", 'timeScale') } name="timeScale" onChange={handleSetupChange} value={timeScale}
                            options={[
                                { value: "none", label: i18n("monitor", 'none'), hoverInfo: " "  },
                                { value: "year", label: i18n("monitor", 'year'), hoverInfo: " " , hide: this.disableTimeScaleOption("year") },
                                { value: "quarter", label: i18n("monitor", 'quarter'), hoverInfo: " ", hide: this.disableTimeScaleOption("quarter")  },
                                { value: "month", label: i18n("monitor", 'month'), hoverInfo: " ", hide: this.disableTimeScaleOption("month")  },
                                { value: "week", label: i18n("monitor", 'week'), hoverInfo: " ", hide: this.disableTimeScaleOption("week")  },
                                { value: "day", label: i18n("monitor", 'day'), hoverInfo: " "  },
                            ]} />
                    </div>
                    <div className="row_element">
                        <Checkbox label={i18n("monitor", 'includeShadows')} onChange={handleSetupChange} name={"includeShadows"} disabled={autoIncludeShadows} checked={includeShadows || autoIncludeShadows} /> 
                    </div>
                </div>
                <div className='setup_row'>
                    <div className="row_element">
                        <Dropdown label={ i18n("monitor", 'assetType') } name="mainAssetType" onChange={handleSetupChange} value={mainAssetType}
                            options={[
                                { value: "total", label: i18n("monitor", 'total'), hoverInfo: " "},
                                { value: "users", label: i18n("monitor", 'resources'), hoverInfo: " ",
                                    hide: ['extras net', 'overhead net', 'extras fee', 'overhead fee', 'extras cost', 'overhead cost', 'overhead net per employee', 'employee turnover'].includes(kpi)},
                                { value: "projects", label: i18n("monitor", 'projects'), hoverInfo: " ", 
                                    hide: ['number of projects', 'overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee', 'resources without projects', 'employment period'].includes(kpi)},
                                { value: "userTags", label: i18n("monitor", 'userTags'), hoverInfo: " ",
                                    hide: ['extras net', 'overhead net', 'extras fee', 'overhead fee', 'extras cost', 'overhead cost', 'overhead net per employee'].includes(kpi)},
                                { value: "projectTags", label: i18n("monitor", 'projectTags'), hoverInfo: " ",
                                    hide: ['overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee', 'resources without projects', 'employment period'].includes(kpi)},
                                { value: "companies", label: i18n("monitor", 'companies'), hoverInfo: " ",
                                    hide: ['overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee', 'resources without projects', 'employment period'].includes(kpi)},
                            ]} />
                    </div>
                    <div className="row_element">
                        <Dropdown label={ i18n("monitor", 'includeMainAsset') } name="includeMainAsset" onChange={handleSetupChange} value={includeMainAsset} disabled={mainAssetType === 'total'}
                            options={[
                                { value: "every", label: i18n("monitor", 'every'), hoverInfo: " "},
                                { value: "include", label: i18n("monitor", 'include'), hoverInfo: " "},
                                { value: "exclude", label: i18n("monitor", 'exclude'), hoverInfo: " "},
                            ]} />
                    </div>
                </div>

                {mainAssetType !== 'total' && includeMainAsset !== "every" &&
                    <div className='setup_row' style={{display: "flex", flexDirection: "column", marginTop:"-20px", paddingLeft: "20px"}}>
                        <div style={{width:"100%", borderBottom:`1px solid ${Settings.getGlobalColor('background')}`}}>
                            <Autocomplete ref={this.mainAutocomplete}
                                value={''}
                                assetType={mainAssetType}
                                chosenAssets={mainAssets}
                                onSelect={addMainAsset}
                                placeholder={`${i18n("general", 'search')} ${mainAssetType === "users" ? "resources" : mainAssetType}`}
                                searchUrl={`/${mainAssetType}/monitor/search/`}
                                small isMonitor />
                        </div>
                        {mainAssets.length > 0 && includeMainAsset !== "every" &&
                            <div className='asset-container'>
                                {(mainAssets.map((asset, i) => {
                                    return (
                                        <li className="TagField-tag" key={"mainAsset-"+i}>
                                            <TagFieldTagWrapper className="TagField-tag-wrapper">
                                                <div className="TagField-tag-value">{ asset.label }</div>
                                                <TagFieldTagRemove className="TagField-tag-remove material-icons" onClick={ () => deleteMainAsset(i) }>close</TagFieldTagRemove>
                                            </TagFieldTagWrapper>
                                        </li>
                                )}))}
                            </div>
                        }       
                    </div>
                }

                <div className='setup_row'>
                    <div className="row_element">
                        <Dropdown label={ i18n("monitor", 'groupBy') } name="secondaryAssetType" onChange={handleSetupChange} value={secondaryAssetType} disabled={['textdata', 'pie', 'calendar', 'bullet'].includes(chartType)}
                            options={[ 
                                { value: "none", label: i18n("monitor", 'none') , hoverInfo: " "  },
                                { hide: 
                                    ['extras net', 'overhead net', 'extras fee', 'overhead fee', 'extras cost', 'overhead cost', 'overhead net per employee', 
                                        'employee turnover', 'chargeability project', 'projected chargeability project'].includes(kpi)
                                    || (["users"].includes(mainAssetType) && timeScale === "none"),
                                    value: "users", label: i18n("monitor", 'resources') , hoverInfo: " "  },
                                { hide: 
                                    ['overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee', 'resources without projects', 
                                        'employment period', 'chargeability resource', 'projected chargeability resource'].includes(kpi)
                                    || (["projects"].includes(mainAssetType) && timeScale === "none"),
                                    value: "projects", label: i18n("monitor", 'projects'), hoverInfo: " "  },
                                { hide: 
                                    ['extras net', 'overhead net', 'extras fee', 'overhead fee', 'extras cost', 'overhead cost', 
                                        'overhead net per employee', 'employment period', 'chargeability project', 'projected chargeability project'].includes(kpi)
                                    || (["userTags"].includes(mainAssetType) && timeScale === "none"),
                                    value: "userTags", label: i18n("monitor", 'userTags'), hoverInfo: " "  },
                                { hide: 
                                    ['overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee', 'resources without projects', 
                                        'employment period', 'chargeability resource', 'projected chargeability resource'].includes(kpi)
                                    || (["projectTags"].includes(mainAssetType) && timeScale === "none"),
                                    value: "projectTags", label: i18n("monitor", 'projectTags'), hoverInfo: " "  },
                                { hide: 
                                    ['overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee', 'resources without projects', 
                                        'employment period', 'chargeability resource', 'projected chargeability resource'].includes(kpi)
                                    || (["projects", "companies"].includes(mainAssetType) && timeScale === "none"),
                                    value: "companies", label: i18n("monitor", 'companies'), hoverInfo: " "  },
                                { hide: 
                                    ['overhead net', 'hourly fee', 'overhead fee', 'wage cost', 'hourly wage cost', 'salary', 'pension', 'payroll', 'resources in non-billable/without projects',
                                        'overhead cost', 'overhead net per employee', 'cost of non-billable hours', 'resources in external projects', 'resources in internal projects', 
                                        'resources without projects', 'resources with projects', 'number of employees', 'new hires', 'leavers', 'employment period', 'employee turnover', 'employee alteration', 'age', 'gender', 
                                        'number of new projects', 'number of ongoing projects', 'number of finished projects', 'number of external projects', 'project duration', 'new resources in project', 
                                        'reported hours', 'billed hours', 'available work hours', 'chargeability resource', 'projected chargeability resource', 'chargeability project', 'projected chargeability project', 'new clients', 'recurring clients'].includes(kpi)
                                    || ["users", 'userTags'].includes(mainAssetType)
                                    || (calcType === '%' && ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'overhead net', 'projected margin', 'overhead per employee'].includes(kpi)),
                                    value: "extras", label: i18n("monitor", 'extras'), hoverInfo: " "  },
                                { hide: 
                                    ['gross margin', 'extras net', 'hourly fee', 'extras fee', 'wage cost', 'hourly wage cost', 'salary', 'pension', 'payroll', 'resources with projects',
                                        'extras cost', 'cost of non-billable hours', 'resources in external projects', 'resources in internal projects', 'resources without projects', 
                                        'number of employees', 'new hires', 'leavers', 'employment period', 'employee turnover', 'employee alteration', 'age', 'gender', 'number of new projects', 
                                        'number of ongoing projects', 'number of finished projects', 'number of external projects', 'project duration', 'new resources in project', 'reported hours', 
                                        'billed hours', 'available work hours', 'chargeability resource', 'projected chargeability resource', 'chargeability project', 'projected chargeability project', 'new clients', 'recurring clients', 'resources in non-billable/without projects'].includes(kpi)
                                    || mainAssetType !== "total"
                                    || (calcType === '%' && ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'projected margin', 'overhead per employee'].includes(kpi)),
                                    value: "overheads", label: i18n("monitor", 'overheads'), hoverInfo: " "  },
                                { hide: 
                                    ['hourly fee', 'extras fee', 'overhead fee', 'salary', 'pension', 'payroll', 'extras cost', 'resources with projects',
                                        'overhead cost', 'resources in external projects', 'resources in internal projects', 'resources without projects', 'number of employees', 'new hires', 
                                        'leavers', 'employment period', 'employee turnover', 'employee alteration', 'age', 'number of new projects', 'number of ongoing projects', 
                                        'number of finished projects', 'number of external projects', 'project duration', 'new resources in project', 'reported hours', 'billed hours', 'available work hours', 
                                        'chargeability resource', 'projected chargeability resource', 'chargeability project', 'projected chargeability project', 'new clients', 'recurring clients', 'resources in non-billable/without projects'].includes(kpi)
                                    || (calcType === '%' && ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'overhead net', 'projected margin', 'overhead net per employee'].includes(kpi)) || (['sunburst', 'bubble'].includes(chartType)), 
                                    value: "components", label: i18n("monitor", 'components'), hoverInfo: " "  },
                            ]} />
                    </div>
                    <div className="row_element">
                        <Dropdown label={ i18n("monitor", 'includeSecondaryAsset') } name="includeSecondaryAsset" onChange={handleSetupChange} value={includeSecondaryAsset} disabled={["none"].includes(secondaryAssetType) || ['textdata', 'pie', 'calendar', 'bullet'].includes(chartType)}
                            options={[
                                { value: "every", label: i18n("monitor", 'every'), hoverInfo: " "},
                                { value: "include", label: i18n("monitor", 'include'), hoverInfo: " "},
                                { value: "exclude", label: i18n("monitor", 'exclude'), hoverInfo: " "},
                            ]} />
                    </div>
                </div>

                {secondaryAssetType !== 'none' && includeSecondaryAsset !== "every" &&
                    <div className='setup_row' style={{display: "flex", flexDirection: "column", marginTop:"-20px", paddingLeft: "20px"}}>
                        <div style={{width:"100%", borderBottom:`1px solid ${Settings.getGlobalColor('background')}`}}>
                            <Autocomplete ref={this.secondaryAutocomplete}
                                value={''}
                                assetType={secondaryAssetType}
                                chosenAssets={secondaryAssets}
                                onSelect={addSecondaryAsset}
                                placeholder={`${i18n("general", 'search')} ${secondaryAssetType === "users" ? "resources" : secondaryAssetType}`}
                                searchUrl={`/${secondaryAssetType}/monitor/search/`}
                                small isMonitor />
                        </div>
                        {secondaryAssets.length > 0 && includeSecondaryAsset !== "every" && <div className='asset-container'>
                            {(secondaryAssets.map((asset, i) => <li className="TagField-tag" key={"secondaryAsset-"+i}>
                                    <TagFieldTagWrapper className="TagField-tag-wrapper">
                                        <div className="TagField-tag-value">{ asset.label }</div>
                                        <TagFieldTagRemove className="TagField-tag-remove material-icons" onClick={ () => deleteSecondaryAsset(i) }>close</TagFieldTagRemove>
                                    </TagFieldTagWrapper>
                            </li>))}
                        </div>}
                    </div>
                }

                <div className='setup_row'>
                    <div className="row_element">
                        <Dropdown label={ i18n("monitor", 'calculationType') } name="calcType" onChange={handleSetupChange} value={calcType}
                            options={[
                                { value: "amount", label: i18n("monitor", 'default formula'), hoverInfo: " ", hide: ['projected net margin hit rate', 'employee turnover', 'chargeability resource', 'projected chargeability resource', 'chargeability project', 'projected chargeability project'].includes(kpi)},
                                { value: "%", label: i18n("monitor", 'percentage formula'), hoverInfo: " ", // % calculation formula cannot be used with components or other secondaryAssetTypes that are a component of a formula (t.ex we hide it when extras are selected for gross margin KPI)
                                    hide: !['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'overhead net', 'projected margin hit rate', 'projected margin', 'employee alteration', 'employee turnover', 'gender', 'chargeability resource', 'projected chargeability resource', 'chargeability project', 'projected chargeability project', 'overhead net per employee'].includes(kpi) // list of KPI that have % formula
                                        || (secondaryAssetType === "components")
                                        || (secondaryAssetType === 'extras' && kpi !== "extras net")
                                        || (secondaryAssetType === 'overheads' && !["overhead net", 'overhead net per employee'].includes(kpi)) },
                                // { value: "avg time", label: i18n("monitor", 'avarage for time period'), hoverInfo: " ", hide: false },
                                // { value: "avg asset", label: i18n("monitor", 'avarage for asset'), hoverInfo: " ", hide: false }
                            ]}
                        />
                    </div>
                    <div className="row_element">
                        <Dropdown label={ i18n("monitor", 'unitType') } name="unitType" onChange={handleSetupChange} value={unitType}
                            options={[
                                { value: "amount", label: i18n("monitor", 'default display'), hoverInfo: " " },
                                { value: "% main", label: i18n("monitor", 'proportion to main axis'), hoverInfo: " ",
                                    hide: ['employment period', 'age', 'project duration', 'available work hours', 'gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate',
                                        'extras net', 'overhead net', 'projected margin', 'overhead net per employee'].includes(kpi) },
                                { value: "% total", label: i18n("monitor", 'proportion to everything'), hoverInfo: " ",
                                    hide: ['employment period', 'age', 'project duration', 'available work hours', 'gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate',
                                        'extras net', 'overhead net', 'projected margin', 'overhead net per employee'].includes(kpi) }
                            ]}
                        />
                    </div>
                </div>

                {/* {getDataMode === "manual" &&  */}
                <div className='setup_row'>
                    <div className="row_element">
                        <Dropdown label={ i18n("monitor", 'getDataMode') } name="getDataMode" onChange={handleGetDataModeChange} value={getDataMode}
                            options={[
                                { value: "auto", label: i18n("monitor", 'yes'), hoverInfo: " "  },
                                { value: "manual", label: i18n("monitor", 'no'), hoverInfo: " " },
                        ]} />
                    </div>
                    <div className="row_element">
                        <Button label={ i18n("monitor", 'getData') } appearance="secondary" size='small' filled onClick={ getData } />
                    </div>
                </div>
                {/* } */}

                {(mainAssetType !== 'total' && includeMainAsset !== "every" && mainAssets.length === 0) && <span style={{color:Settings.getGlobalColor('danger')}}>{ i18n("monitor", 'setupNotComplete') }</span>}
                <ActivityIndicator busy={fetching}/>
            </div>
        )
    }

    handleClickOutside = (event) => {
        if (this.layoutOptionsRef && !this.layoutOptionsRef.current.contains(event.target)) {
            this.setState({showLayerOptions: false}, document.removeEventListener('mousedown', this.handleClickOutside))
        }
    }

    toggleLayerOptions() { this.setState(prevState => ({showLayerOptions: !prevState.showLayerOptions})) }

    isLayerActive(name) {
        document.addEventListener('mousedown', this.handleClickOutside);
        
        const {mainAssetType, secondaryAssetType, kpi, timeScale, componentBreakdown} = this.props.setup

        switch(name) {
            case 'assets': return mainAssetType !== "total"
            case '&': return mainAssetType !== "total" && secondaryAssetType !== "none"
            case 'group': return secondaryAssetType !== "none"
            case 'timeScale': return timeScale !== "none"
            case 'components': return componentBreakdown && ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'overhead net', 'overhead net per employee', 'total revenue', 'projected total revenue', 'total expenses', 'projected total expenses', 'wage cost', 'cost of non-billable hours'].includes(kpi)
            default: return false
        }
    }

    disableTimeScaleOption(scaleType) {
        const {startDateType, endDateType, startDate, endDate} = this.props.setup
        if (startDateType === "all" || endDateType === "all") return false
        var sameYear = "", sameMonth = "", sameWeek = "", sameQuarter = ""
        if (startDateType === "custom" && endDateType === "custom") sameYear = +startDate.split("-")[0] === +endDate.split("-")[0]
        else if (startDateType === "custom") sameYear = +startDate.split("-")[0] === +utils.moment().format("YYYY")
        else if (endDateType === "custom") sameYear = +endDate.split("-")[0] === +utils.moment().format("YYYY")
        else sameYear = true

        switch(scaleType) {
            case "year":
                return !!sameYear
            case "quarter":
                if (startDateType === "custom" && endDateType === "custom") sameQuarter = +utils.moment(startDate).utc().quarter() === +utils.moment(endDate).utc().quarter()
                else if (startDateType === "custom") sameQuarter = +utils.moment(startDate).utc().quarter() === +(endDateType === "year" ? 4 : +utils.moment().utc().quarter())
                else if (endDateType === "custom") sameQuarter = +utils.moment(endDate).utc().quarter() === +(startDateType === "year" ? 1 : +utils.moment().utc().quarter())
                else if (startDateType === "year" && endDateType === "year") sameQuarter = false
                else if (startDateType === "year") sameQuarter = +utils.moment().utc().quarter() === 1
                else if (endDateType === "year") sameQuarter = +utils.moment().utc().quarter() === 4
                else sameQuarter = true 
                return !!(sameQuarter && sameYear)
            case "month":

                if (startDateType === "custom" && endDateType === "custom") sameMonth = +startDate.split("-")[1] === +endDate.split("-")[1]
                else if (["year", "quarter"].includes(startDateType) && ["year", "quarter"].includes(endDateType)) sameMonth = false

                else if (startDateType === "custom") {
                    if (endDateType === "year") sameMonth = +startDate.split("-")[1] === 12
                    else if (endDateType === "quarter") sameMonth = +startDate.split("-")[1] === +["3", "6", "9", "12"][utils.moment().utc().quarter()]
                    else sameMonth = +startDate.split("-")[1] === +utils.moment().format("MM")
                }
                else if (endDateType === "custom") {
                    if (startDateType === "year") sameMonth = +endDate.split("-")[1] === 1
                    else if (startDateType === "quarter") sameMonth = +endDate.split("-")[1] === +["1", "4", "7", "10"][utils.moment().utc().quarter()]
                    else sameMonth = +endDate.split("-")[1] === +utils.moment().format("MM")
                }
                else if (startDateType === "year") sameMonth = +utils.moment().format("MM") === 1
                else if (endDateType === "year") sameMonth = +utils.moment().format("MM") === 12
                else if (startDateType === "quarter") sameMonth = +utils.moment().startOf('quarter').format("MM") === +utils.moment().format("MM")
                else if (endDateType === "quarter") sameMonth = +utils.moment().endOf('quarter').format("MM") === +utils.moment().format("MM")
                else sameMonth = true
                return !!(sameMonth && sameYear)
            case "week":
                if (startDateType === "custom" && endDateType === "custom") sameWeek = +utils.moment(startDate).isoWeek() === +utils.moment(endDate).isoWeek()
                else if (startDateType === "custom") sameWeek = +utils.moment(startDate).isoWeek() === +utils.moment().endOf(endDateType).format("ww")
                else if (endDateType === "custom") sameWeek = +utils.moment(endDate).isoWeek() === +utils.moment().startOf(startDateType).format("ww")
                else if (["year", "quarter", "month"].includes(startDateType) && ["year", "quarter", "month"].includes(endDateType)) sameWeek = false
                else if (startDateType === "year") sameWeek = +utils.moment().isoWeek() === +utils.moment().endOf('year').format("ww")
                else if (endDateType === "year") sameWeek = +utils.moment().isoWeek() === +utils.moment().startOf('year').format("ww")
                else if (startDateType === "quarter") sameWeek = +utils.moment().isoWeek() === +utils.moment().endOf('quarter').format("ww")
                else if (endDateType === "quarter") sameWeek = +utils.moment().isoWeek() === +utils.moment().startOf('quarter').format("ww")
                else if (startDateType === "month") sameWeek = +utils.moment().isoWeek() === +utils.moment().endOf('month').format("ww")
                else if (endDateType === "month") sameWeek = +utils.moment().isoWeek() === +utils.moment().startOf('month').format("ww")
                else sameWeek = true
                return !!(sameWeek && sameYear)
            default: return false
        }
    }
}

export default DefaultSetup

function Dropdown(props) {
    const { name, onChange, value, label, options, imgSrc, disabled } = props
    let selectedIndex = options.findIndex(item => item.value === value)

    if (selectedIndex === -1 || options[selectedIndex].hide || options[selectedIndex].disabled) selectedIndex = options.findIndex(item => (!item.hide && !item.disabled))
    return (
        <Select disabled={ selectedIndex === -1 ? true : disabled } name={ name } label={ label } imgSrc={ imgSrc } type='monitor' selectedIndex={ selectedIndex } onChange={ onChange } options={ options } />
    )
}

function Checkbox(props) {
    const { label, name, checked, onChange, disabled } = props
    return (
        <Switch disabled={ disabled } name={ name } label={ label } checked={ checked } onChange={ onChange } animate={ true }/>
    )
}

const LayerOrderContainer = styled.div`
    position: absolute;
    z-index: 2;
    background: ${props => props.theme.overlay};
    border: 1px solid ${props => props.theme.secondary};
    border-radius: 6px;
    width: calc(100% - 10px);
`
const LayerOrderNavIcons = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto 0;
`

const TagFieldTagRemove = styled.div`
    color: ${props => props.theme.label};
    :hover {
    color: ${props => props.theme.danger};
    }
`

const TagFieldTagWrapper = styled.div`
    background: ${props => props.theme.background};
    color: ${props => props.theme.subtitle};
    :hover {
      background: ${props => props.theme.background};
    }
`