import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { openCardSettings } from '../../redux/actions/monitor/monitorActions'
import { Chart as ChartElement } from '../Charts'
import { getAssetsURIForPath } from "helpers/api"
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"

import i18n from "helpers/i18n"
import api from "helpers/api"
import * as utils from "helpers/utils"

class Card extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            lastUpdated: 'now',
            chartData: this.props.card.Chart.data
        }

        this.updateCardData()
    }

    componentDidMount() {
        this.interval = setInterval( // "last time updated"
            () => {
                const { card } = this.props, { Options } = card, { updateRate, updated } = Options, { type, time } = updateRate
                let diff = new Date(Date.now()) - new Date(updated) // difference between now and last updated date in msec
                let days = Math.floor(diff / 1000 / 60 / 60 / 24) // amount of days in integer
                diff -= days * 1000 * 60 * 60 * 24 // back to msec
                let hours = Math.floor(diff / 1000 / 60 / 60) // hours
                diff -= hours * 1000 * 60 * 60
                let minutes = Math.floor(diff / 1000 / 60) // minutes
                let lastUpdated = 'now'

                if (days > 0) { // if at least 1 whole day
                    lastUpdated = days + (days === 1 ? ' day' : ' days') + ' ago'
                } else if (hours > 0) { // whole hour
                    lastUpdated = hours + (hours === 1 ? ' hour' : ' hours') + ' ago'
                } else if (minutes > 0) { // whole minute
                    lastUpdated = minutes + (minutes === 1 ? ' minute' : ' minutes') + ' ago'
                }

                if (utils.moment().format(type === 'hourly' ? 'mm' : 'HH:MM') === ('hourly' ? '00' : time)) this.updateCardData() // force update

                this.setState({ lastUpdated })
            }, 60000)
    }

    componentDidUpdate() {
        this.setState({ chartData: this.props.card.Chart.data })
    }
        
    componentWillUnmount() {
        clearInterval(this.interval) // remove timer
    }

    duplicateCard() { // duplicate this card
        const { cardID, shared } = this.props
        if (!shared) this.props.duplicateCard(cardID)
    }

    openNotificationSettings() { 
        const { card, dashboardID, shared } = this.props
        if (!shared) this.props.openNotificationSettings(card, dashboardID)
    }

    openCardSettings(e) { // opens settings for this card
        if (this.props.shared) return

        const { cardID, card } = this.props
        let element = e ? e.currentTarget : document.getElementById(`card-${cardID}`)
        let layout = element.getBoundingClientRect().toJSON()

        document.getElementById("content-container").style.overflowY = "hidden"
        this.props.openCardSettings(card, layout)
        this.props.openBlur() // make it blurry
    }

    async updateCardData(forceUpdate = false) {
        const { card } = this.props, { ID, Chart, Options, Setup } = card

        if (Setup) {
            if (forceUpdate) {
                this.props.updateCard(ID, Chart, Options, Setup, null, forceUpdate)
            } else {
                const route = 'getCardData'
                const newData = await api(`/monitor/${route}`, {...card })

                this.setState({ chartData: newData }, () => {
                    this.props.updateCard(ID, {...Chart, data: newData }, Options, Setup, null)
                })
            }
        }
    }

    fullscreenCard() { // exit fullscreen for each browser type
        if (document.fullscreenElement
            || document.webkitFullscreenElement
            || document.mozFullScreenElement
            || document.msFullscreenElement) {
            if (document.fullscreenElement) {
                document.exitFullscreen()
            } else if (document.webkitFullscreenElement) {
                document.webkitExitFullscreen()
            } else if (document.mozFullScreenElement) {
                document.mozCancelFullScreen()
            } else if (document.msFullscreenElement) {
                document.msExitFullscreen()
            }
        } else if (document.fullscreenEnabled) { // enter fullscreen for each browser type
            document.getElementById(`card-${this.props.cardID}`).requestFullscreen()
        } else if (document.webkitFullscreenEnabled) {
            document.getElementById(`card-${this.props.cardID}`).webkitRequestFullscreen()
        } else if (document.mozFullScreenEnabled) {
            document.getElementById(`card-${this.props.cardID}`).mozRequestFullScreen()
        } else if (document.msFullscreenEnabled) {
            document.getElementById(`card-${this.props.cardID}`).msRequestFullscreen()
        }
    }

    getBackgroundImage = () => {
        const { Options, BackgroundImage } = this.props.card
        const { background, gradientOptions} = Options
        const { gradientType, gradientDirection, firstStop, secondStop, gradientColor, radialShape, useGradient } = gradientOptions
        
        var gradient = ""
        if (useGradient){
            switch(gradientType) {
                case 'radial-gradient':
                    let radialDirection = gradientDirection === "middle" ? "" : `at ${gradientDirection}`
                    gradient = `${gradientType}(${radialShape} ${radialDirection}, ${gradientColor} ${firstStop}%, ${background} ${secondStop}%)`
                    break
                case 'linear-gradient':
                    let linearDirection = gradientDirection === "middle" ? "bottom right" : gradientDirection
                    gradient = `${gradientType}(to ${linearDirection}, ${background} ${firstStop}%, ${gradientColor} ${secondStop}%)`
                    break
                default: break
            }
        }
        if (BackgroundImage) {
            if (gradient) {
                return `url(${ getAssetsURIForPath("/cards/"+BackgroundImage) }), ${gradient}`
            } else {
                return `url(${ getAssetsURIForPath("/cards/"+BackgroundImage) })`
            }
        } else {
            return gradient
        }
    }

    render() {
        const { card, cardID, shared } = this.props, { lastUpdated, chartData } = this.state,
        { Options, Chart, Setup } = card, { background, name, titleFontSize, fontType, displayUnit, notification, displayPlusSign } = Options
        let titleHeight = Math.floor(parseInt(titleFontSize, 10) / 10) + parseInt(titleFontSize, 10) + 20
        const chartOptions = Chart && Chart.options && Chart.options[Chart.type]

        return (!Chart || chartData.length === 0 || ( !Array.isArray(chartData.dataset) && !['sunburst', 'bubble'].includes(Chart.type))
            ? <ActivityIndicator busy />
            : <div onDoubleClick={(e) => e.target.nodeName !== "I" && this.openCardSettings(e)} className={`card-container ${Chart.type}`} id={`card-${cardID}`} style={{fontFamily: fontType, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "50% 50%", backgroundImage: this.getBackgroundImage(), backgroundColor: background}}>
                {Chart && <h4 id={`cardTitle-${cardID}`} style={{color: chartOptions.textColor, position: Chart.type === "textdata" ? "relative": "absolute", fontSize: `${titleFontSize}px`, zIndex: "1"}}>{name}</h4>}
                {Chart && <ChartElement {...Chart} data={chartData} options={chartOptions} titleHeight={titleHeight} displayPlusSign={displayPlusSign} displayUnit={displayUnit} kpi={Setup.kpi} calcType={Setup.calcType} unitType={Setup.unitType} />} {/* The chart itself is here */}
                <div className='card_settings-container' style={{color: chartOptions.textColor}}>
                    <i style={{left: shared ? "10px" : "35px"}} className='fas fa-info-circle' title={`${i18n("monitor", "lastUpdated")} ${lastUpdated}`} />
                    {!shared && <i onClick={() => this.openNotificationSettings()} className={notification.active ? 'fas fa-bell' : 'fas fa-bell-slash'} />}
                    <div className='card_settings'>
                        <i onClick={() => this.fullscreenCard()} className='fas fa-expand' />
                        {!shared && <React.Fragment>
                            <i onClick={() => this.updateCardData(true)} className='fas fa-redo-alt' />
                            <i onClick={() => this.duplicateCard()} className='fas fa-clone' />
                            <i onClick={() => this.openCardSettings()} className='fas fa-cogs' />
                        </React.Fragment>}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({})

const mapActionsToProps = {
    openCardSettings
}

export default connect(mapStateToProps, mapActionsToProps)(Card)