import React from "react";
import styled, {css} from 'styled-components';


// HELPERS
import * as utils from "helpers/utils";


function ContentBox(props) {
    const classes = utils.createClassName(props.className, {
        "ContentBox": true,
    });

    return (
        <Div className={ classes }>
            { props.title ? (
                <ContentBoxTitle className="ContentBox-title">
                    <H1>{ props.title }</H1>
                </ContentBoxTitle>
            ) : null }
            {props.children && <div className="ContentBox-content">{ props.children }</div>}
        </Div>
    );
}

export default ContentBox;

const ContentBoxTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.background}; !important;
`;

const H1 = styled.h1`
  color: ${props => props.theme.primary}; !important;
`;

const Div = styled.div`
    ${props => props.className.split(' ').includes('ContentBox') && css`
        background: ${props.theme.overlay};
    `};
`;
