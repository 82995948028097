import {
    CREATE_CARD,
    OPEN_CARD_SETTINGS,
    CLOSE_CARD_SETTINGS
} from '../redux/actions/monitor/action-types'

const initialState = {
    pageCover: false,
    cardSettings: {
        isNew: false,
        on: false,
        card: null,
        layout: null
    }
}

const monitorReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CREATE_CARD:
            return {
                ...state,
                pageCover: true,
                cardSettings: {
                    isNew: true,
                    on: true
                }
            }
        case OPEN_CARD_SETTINGS:
            return {
                ...state,
                pageCover: true,
                cardSettings: {
                    on: true,
                    card: payload.card,
                    layout: payload.layout
                }
            }
        case CLOSE_CARD_SETTINGS:
            return {
                ...state,
                pageCover: false,
                cardSettings: {
                    isNew: false,
                    on: false,
                    card: null,
                    layout: null
                }
            }
        default:
            return state
    }
}

export default monitorReducer