import api from "helpers/api";
import Settings from "classes/Settings";

export default class GlobalColors {
    static globalColors = [];

    static async loadGlobalColors(callback){
        const result = await api("/globals/getGlobalColors", {  });

        if(JSON.stringify(result) !== Settings.get('GLOBAL_COLORS')){
            Settings.set('GLOBAL_COLORS', JSON.stringify(result));
            Settings.set('USING_DEFAULT_COLORS', false);
            GlobalColors.globalColors = result;
            callback(true);
        } else {
            callback(false);
        }
    }

    static async initGlobalColors(callback){
        if(Settings.get('USING_DEFAULT_COLORS')){
            await GlobalColors.loadGlobalColors(callback);
        } else {
            GlobalColors.globalColors = JSON.parse(Settings.get('GLOBAL_COLORS'));
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('mode');

            if (myParam === "preview") {
                callback(false)
            } else {
                GlobalColors.loadGlobalColors(callback);
            }
            
        }

    }

    static getGlobalColor(key) {
        return GlobalColors.globalColors[key];
    }

}
