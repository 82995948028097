import React from "react";
import styled from 'styled-components';

// HELPERS
import { createClassName } from "helpers/utils";


export default function ActivityIndicator(props) {
    const { busy, size = 38, width = 2, success } = props;
    const classes = createClassName(props.className, {
        "ActivityIndicator": true,
        "active": busy,
        "success" : success
    });

    if(busy) {
      return (
          <div className={ classes }>
              <svg width={ size } height={ size } viewBox={ `0 0 ${ size } ${ size }` } xmlns="http://www.w3.org/2000/svg">
                  <Circle fill="none" strokeWidth={ width } strokeLinecap="round" cx={ size / 2 } cy={ size / 2 } r={ (size / 2) - width }></Circle>
              </svg>
          </div>
      );
    } else {
      return (
          <div className={ classes }>
            <div className="check_mark">
              <Success className="sa-icon sa-success animate">
                <Line className="sa-line sa-tip animateSuccessTip"></Line>
                  <span className="sa-line sa-long animateSuccessLong"></span>
                  <div className="sa-placeholder"></div>
                <div className="sa-fix"></div>
              </Success>
            </div>
          </div>
      );
    }

};

const Circle = styled.circle`
  stroke: lighten( ${(props) => props.theme.danger} ) !important;
`;

const Success = styled.div`
  border-color: ${(props) => props.theme.success} !important;
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.success} !important;
`;
