import React from "react";
import styled, {css} from 'styled-components';

// HELPERS
import { createClassName } from "helpers/utils";

export default class RangeComponent extends React.Component {
    state = {
        focused: false,
        pageLoaded: false
    }

    get value() { return this.input.value }

    render() {
        const { props, state } = this;
        const { style, rangeStyle, name, secondName, label, value, secondValue, tooltip, min, max, hideValue, step, type, colorOne, colorTwo } = props;
        const disabled = props.disabled === true;
        const classes = createClassName(props.className, {
            "Range": true,
            "focus": state.focused,
            "disabled": disabled,
            "tooltip": !!tooltip,
            "color": type === "color"

        });

        return (
            <Div style={ style } className={ classes } $classes={classes.split(' ')}>
                <RangeLabel className="RangeField-label">{ label }</RangeLabel>
                <RangeLabel className="RangeField-label" style={secondValue ? {top:"auto", right:"auto", bottom:"-10px", left:"10px"} : {left:"auto", right:"10px"}}>{!hideValue && value}</RangeLabel>
                <RangeLabel className="RangeField-label" style={{top:"auto", right:"10px", bottom:"-10px", left:"auto"}}>{!hideValue && secondValue}</RangeLabel>
        
                <div className="multi-range">
                    <Input
                        color={colorOne}
                        id={ name }
                        className="Range-input"
                        name={ name }
                        type={ "range" }
                        value={ value }
                        disabled={ disabled }
                        min={ min }
                        max={ max }
                        step={ step }

                        onFocus={ !disabled ? this._onFocus : undefined }
                        //onBlur={ !disabled ? this._onBlur : undefined }
                        onChange={ !disabled ? this._onChange : undefined }
                        style={ rangeStyle }
                    />
                    {secondValue &&
                     <Input
                        color={colorTwo}
                        id={secondName}
                        className="Range-input"
                        name={ secondName }
                        type={ "range" }
                        value={ secondValue }
                        disabled={ disabled }
                        min={ min }
                        max={ max }
                        step={ step }

                        onFocus={ !disabled ? this._onFocus : undefined }
                        //onBlur={ !disabled ? this._onBlur : undefined }
                        onChange={ !disabled ?  this._onChange : undefined }
                        style={ rangeStyle }
                    />}
                </div>
                { !!tooltip ?
                    <TextFieldTooltip text={ tooltip } />
                : null }
            </Div>
        )

    }

    // Internal methods
    _onFocus = ({ target }) => {
        const { onFocus } = this.props;
        this.setState({ focused: true }, onFocus ? () => onFocus(target.value) : undefined);
    }

    _onBlur = ({ target }) => {
        const { onBlur } = this.props;
        this.setState({ focused: false }, onBlur ? () => onBlur(target.value) : undefined);
    }

    _onChange = (e) => {
        if(this.props.onChange) this.props.onChange(e.target.value, e.target.name);
    }
}


// PRIVATE COMPONENTS
function TextFieldTooltip({ text, onClick }) {
    return (
        <StyledTextFieldTooltip className="TextField-tooltip">
            <TextFieldTooltipIcon className="TextField-tooltip-icon">?</TextFieldTooltipIcon>
            <div className="TextField-tooltip-text">{ text }</div>
        </StyledTextFieldTooltip>
    );
}

const StyledTextFieldTooltip = styled.div`
    :hover {
      background: ${props => props.theme.secondary};
    }
`;

const TextFieldTooltipIcon = styled.div`
    background: ${props => props.theme.background};
`;

// PRIVATE FUNCTIONS
const Div = styled.div`
    background: ${props => props.theme.overlay}
    width: 100%
    ${props => props.$classes.includes('focus') && css`
        border-color: ${props.theme.secondary};
    `}
    ${props => props.$classes.includes('invalid') && css`
        border-color: ${props.theme.danger};
    `}
    ${props => props.$classes.includes('success') && css`
        border-color: ${props.theme.success};
    `}
    ${props => props.$classes.includes('table') && props.$classes.includes('focus') && css`
        border-left: 1px solid ${props.theme.secondary};
        border-bottom: 1px solid ${props.theme.secondary};
        border-image: linear-gradient(to top, #00000000 0%,${props.theme.secondary} 0%,${props.theme.secondary} 50%,#00000000 50%);
        border-image-slice: 1;
    `}
`;

const Input = styled.input`
    ${props => css`
        background: linear-gradient(${props.theme.label}, ${props.theme.label}) no-repeat center;

        &::-webkit-slider-thumb {
            background: ${props.theme.overlay};
            cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
            border: 6px solid ${props.color || props.theme.secondary};
        }

        &::-moz-range-thumb {
            background: ${props.theme.overlay};
            cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
            border: 6px solid ${props.color || props.theme.secondary};
        }
    `}
`
const RangeLabel = styled.label`
    color: ${props => props.theme.label};
`

