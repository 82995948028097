import React from "react";
import { Link } from "react-router-dom";
import styled, {css} from 'styled-components';

// COMPONENTS
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";

// HELPERS
import * as utils from "helpers/utils";


export default class Button extends React.PureComponent {
    click() {
        this.button.click();
    }

    render() {
        const { props } = this;
        const { link, label, busy, size, type, filled, appearance, disabled, fullWidth, onClick, success, style, faIcon, materialIcon } = props;
        const classes = utils.createClassName(props.className, {
            "Button": true,
            "full-width": fullWidth === true,
            "busy": busy === true,
            "small": size === "small",
            "filled": filled === true,
            "primary": appearance === "primary",
            "secondary": appearance === "secondary",
            "label": appearance === "label",
            "subtitle": appearance === "subtitle",
            "success": appearance === "success",
            "warning": appearance === "warning",
            "danger": appearance === "danger",
            "disabled": disabled
        });

        if(!!link) {
            return (
                <Link
                    ref={ ref => this.button = ref }
                    className={ classes }
                    onClick={ !disabled ? onClick : undefined}
                    to={ link }
                    data-interactive=""
                >
                    <ActivityIndicator busy={ busy } />
                    <div className="Button-label">{ label || "" }
                        {faIcon && <i className={faIcon} />}
                        {materialIcon && <i className={"material-icons"}>{materialIcon}</i>}
                    </div>
                </Link>
            );
        } else {
            return type === 'submit' ? (
                <StyledButton
                    ref={ ref => this.button = ref }
                    $classes={classes.split(' ')}
                    className={ classes }
                    type={ type || "button" }
                    onClick={ !disabled ? onClick : undefined}
                    data-interactive=""
                    style={style}
                >
                    <ActivityIndicator busy={ busy } success = { success } />
                    <div className="Button-label">{ label || "" }
                        {faIcon && <i className={faIcon} />}
                        {materialIcon && <i className={"material-icons"}>{materialIcon}</i>}
                    </div>
                </StyledButton>
            ) : (
                <StyledButtonDiv
                    ref={ ref => this.button = ref }
                    $classes={classes.split(' ')}
                    className={ classes }
                    onClick={ !disabled ? onClick : undefined}
                    data-interactive=""
                    style={style}
                >
                    <ActivityIndicator busy={ busy } success = { success } />
                    <div className="Button-label">{ label || "" }
                        {faIcon && <i className={faIcon} />}
                        {materialIcon && <i className={"material-icons"}>{materialIcon}</i>}
                    </div>
                </StyledButtonDiv>
            );
        }
    }
}

const StyledButton = styled.button`
    color: ${(props) => props.theme.subtitle};
    ${props => props.$classes.includes('primary') && css`
        color: ${props.theme.primary};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.primary};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.primary} !important;
        `}
    `}

    ${props => props.$classes.includes('secondary') && css`
        color: ${props.theme.secondary};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.secondary};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.secondary} !important;
        `}
    `}

    ${props => props.$classes.includes('label') && css`
        color: ${props.theme.label};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.label};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.label} !important;
        `}
    `}

    ${props => props.$classes.includes('subtitle') && css`
        color: ${props.theme.label};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.subtitle};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.subtitle} !important;
        `}
    `}

    ${props => props.$classes.includes('success') && css`
        color: ${props.theme.success};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.success};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.success} !important;
        `}
    `}

    ${props => props.$classes.includes('warning') && css`
        color: ${props.theme.warning};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.warning};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.warning} !important;
        `}
    `}

    ${props => props.$classes.includes('danger') && css`
        color: ${props.theme.danger};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.danger};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.danger} !important;
        `}
    `}
`;

const StyledButtonDiv = styled.div`
    color: ${props => props.theme.subtitle};
    ${props => props.$classes.includes('primary') && css`
        color: ${props.theme.primary};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.primary};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.primary} !important;
        `}
    `}

    ${props => props.$classes.includes('secondary') && css`
        color: ${props.theme.secondary};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.secondary};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.secondary} !important;
        `}
    `}

    ${props => props.$classes.includes('label') && css`
        color: ${props.theme.label};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.label};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.label} !important;
        `}
    `}

    ${props => props.$classes.includes('subtitle') && css`
        color: ${props.theme.label};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.subtitle};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.subtitle} !important;
        `}
    `}

    ${props => props.$classes.includes('success') && css`
        color: ${props.theme.success};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.success};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.success} !important;
        `}
    `}

    ${props => props.$classes.includes('warning') && css`
        color: ${props.theme.warning};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.warning};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.warning} !important;
        `}
    `}

    ${props => props.$classes.includes('danger') && css`
        color: ${props.theme.danger};
        ${props => props.$classes.includes('filled') && css`
          color: ${props.theme.overlay};
          background-color: ${props.theme.danger};
        `}
        ${props => !props.$classes.includes('filled') && css`
          border-color: ${props.theme.danger} !important;
        `}
    `}
`;