import React, { Component, Suspense } from 'react'
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"
const TextData = React.lazy(() => import('../Charts/TextData'))
const Bar = React.lazy(() => import('../Charts/Bar'))
const Bullet = React.lazy(() => import('../Charts/Bullet'))
const Calendar = React.lazy(() => import('../Charts/Calendar'))
const Heatmap = React.lazy(() => import('../Charts/Heatmap'))
const Line = React.lazy(() => import('../Charts/Line'))
const ScatterPlot = React.lazy(() => import('./ScatterPlot'))
const Pie = React.lazy(() => import('../Charts/Pie'))
const Radar = React.lazy(() => import('../Charts/Radar'))
const Sunburst = React.lazy(() => import('../Charts/Sunburst'))
const Bubble = React.lazy(() => import('../Charts/Bubble'))

const loading = (<ActivityIndicator busy/>)

export class Chart extends Component {
    render() {
        const { props } = this, { type } = props
        return (
            <React.Fragment>
                {type === 'textdata' && (<Suspense fallback={loading}>
                    <TextData {...props} />
                </Suspense>)}
                {type === 'bar' && (<Suspense fallback={loading}>
                    <Bar {...props} />
                </Suspense>)}
                {type === 'bullet' && (<Suspense fallback={loading}>
                    <Bullet {...props} />
                </Suspense>)}
                {type === 'calendar' && (<Suspense fallback={loading}>
                    <Calendar {...props} />
                </Suspense>)}
                {type === 'heatmap' && (<Suspense fallback={loading}>
                    <Heatmap {...props} />
                </Suspense>)}
                {type === 'line' && (<Suspense fallback={loading}>
                    <Line {...props} />
                </Suspense>)}
                {type === 'scatterPlot' && (<Suspense fallback={loading}>
                    <ScatterPlot {...props} />
                </Suspense>)}
                {type === 'pie' && (<Suspense fallback={loading}>
                    <Pie {...props} />
                </Suspense>)}
                {type === 'radar' && (<Suspense fallback={loading}>
                    <Radar {...props} />
                </Suspense>)}
                {type === 'sunburst' && (<Suspense fallback={loading}>
                    <Sunburst {...props} />
                </Suspense>)}
                {type === 'bubble' && (<Suspense fallback={loading}>
                    <Bubble {...props} />
                </Suspense>)}
            </React.Fragment>
        )
    }
}