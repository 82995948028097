import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LocationWrapper = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return React.cloneElement(children, { location, navigate });
};

export default LocationWrapper;