import i18n from "helpers/i18n"

export default class Dashboard {
    constructor(props) {
        this.UserID = null
        this.Title = i18n('monitor', 'newDashboard') // aka title
        this.Layouts = { // all breakpoints
            lg: [], // large screen
            md: [], // medium and etc...
            sm: [],
            xs: [],
            xxs: [],
        }
        this.BackgroundImage = null
        this.BackgroundColor = null
        this.PublicStatus = 0
        this.PublicLink = null
        this.PublicEndDate = null
        this.PublicPassword = null
        this.CompactType = 'vertical' // display and behavior options
        this.Cards = {} // the content of each card

        if (props !== undefined) { // if data is provided
            for (let prop in props) {
                this[prop] = props[prop] // fill dashboard with data
            }
        }
    }
}