import React from "react";
import styled, {css} from 'styled-components';

// HELPERS
import * as utils from "helpers/utils";
// import i18n from "helpers/i18n";


export default class Table extends React.PureComponent {
    state = {
        isScrollable: false,
        scrollStart: false,
        scrollEnd: false,
    }

    componentDidMount() {
        const { container } = this;
        const { scrollLeft, scrollWidth } = container;
        const containerMeasure = container.getBoundingClientRect();
        const isScrollable = scrollWidth > containerMeasure.width;
        const scrollStart = isScrollable && scrollLeft === 0;

        this.setState({ isScrollable, scrollStart });
    }

    render() {
        const { props, state } = this;
        const { columns, rows, renderHeaderTemplate, renderFooterTemplate, type } = props;
        const { isScrollable, scrollStart, scrollEnd } = state;
        const classes = utils.createClassName(props.className, {
            "Table": true,
            "scrollable": isScrollable,
            "scroll-start": scrollStart,
            "scroll-end": scrollEnd,
        });

        return (
            <StyledTable ref={ ref => this.container = ref } className={ classes } $classes={classes.split(' ')} onScroll={ this._onContainerScroll }>
                { !!renderHeaderTemplate && <div className="Table-header">{ renderHeaderTemplate }</div> }
                <TableContainer className="Table-container">
                    <div className="Table-wrapper">
                        <table className={`Table-table ${type}-table`} border="0" cellPadding="0" cellSpacing="10">
                            <thead>
                                <tr className="Table-columns">
                                    { columns.map(({ label, width, minWidth }, index) => (
                                        <TableColumnLabel key={ `Table-column-label-${ index }` } className="Table-column-label" style={{ width }}>
                                            <div>{ label }</div>
                                        </TableColumnLabel>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                { !!rows && rows.map((row, index) => (
                                    <TableRow key={ `TableRow-${ index }` } data={ row } />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </TableContainer>
                { !!renderFooterTemplate && <TableFooter className="Table-footer">{ renderFooterTemplate }</TableFooter> }
            </StyledTable>
        );
    }

    // Internal methods
    _onContainerScroll = ({ target: container }) => {
        const { scrollLeft, scrollWidth } = container;
        const containerMeasure = container.getBoundingClientRect();
        const isScrollable = scrollWidth > containerMeasure.width;
        const scrollStart = isScrollable && scrollLeft === 0;
        const scrollEnd = isScrollable && scrollLeft === scrollWidth - containerMeasure.width;

        this.setState({ isScrollable, scrollStart, scrollEnd });
    }
}

const StyledTable = styled.div`
    ${props => props.$classes.includes('scrollable') && css`
        ::before {
          box-shadow: 40px 0 25px -40px ${props.theme.label} inset;
        }
        ::after {
          box-shadow: -40px 0 25px -40px ${props.theme.label
        }
    `}
`;

const TableContainer = styled.div``;

const TableColumnLabel = styled.th`
    color: ${props => props.theme.label};
`;

const TableColumnValue = styled.td`
    ${props => css`
        vertical-align: top;

        input[type="text"] {
            border-left: 1px solid ${props.theme.background};
            border-bottom: 1px solid ${props.theme.background};
            border-image: linear-gradient(to top, #00000000 0%,${props.theme.background} 0%,${props.theme.background} 50%,#00000000 50%);
            border-image-slice: 1;
        }

        .focus input[type="text"] {
            border-left: 1px solid ${props.theme.secondary};
            border-bottom: 1px solid ${props.theme.secondary};
            border-image: linear-gradient(to top, #00000000 0%,${props.theme.secondary} 0%,${props.theme.secondary} 50%,#00000000 50%);
            border-image-slice: 1;
        }
    `}
`;

const TableFooter  = styled.div``;

// PRIVATE COMPONENTS
class TableRow extends React.PureComponent {
    render() {
        const { props } = this;
        const { data } = props;
        const classes = utils.createClassName("Table-row", {
            // "edit": false,
        });

        return (
            <tr className={ classes }>
                { data && Object.keys(data.props.children).map((key, index) => {
                    return data.props.children[key] !== null ? (
                    <TableColumnValue key={ `Table-row-value-${ index }` } className="Table-row-value">
                        <React.Fragment>{ data.props.children[key] }</React.Fragment>
                    </TableColumnValue>
                ) : false})}
            </tr>
        );
    }
}
