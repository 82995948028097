import React from "react"

// COMPONENTS
import Button from "components/Button/Button"
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"
import ContentBox from "components/ContentBox/ContentBox"
import Form from "components/Form/Form"
import TextField from "components/TextField/TextField"
import StackLayout from "components/layouts/StackLayout/StackLayout"

// HELPERS
import { connectRedux } from "helpers/utils"
import i18n from "helpers/i18n"
import api from "helpers/api"
import { MessageBox } from "components/MessageBox/MessageBox"

// REDUCERS
import { actions as appActions } from "reducers/app"


export default connectRedux(state => ({
    app: state.app,
}), {
    setUser: appActions.setUser,
}, class Resetpassword extends React.Component {
    state = {
        tokenValid: false,
        tokenChecked: false,
        passwordChanged: false,
        status: '',
        email: '',
        password: '',
        repeatpassword: ''
    }

    render() {
        const { loading, tokenValid, tokenChecked, passwordChanged, status, email, password, repeatpassword } = this.state
        const { search } = this.props.location
        let token = search.replace("?token=", "")

        if (token && !tokenChecked) {
            setTimeout(async () => {
                this.setState({
                    tokenChecked: true,
                    tokenValid: await this._checkResetToken(token),
                    passwordChanged: await this._checkPasswordChanged(token)
                })
            }, 0)

            return <ActivityIndicator busy />
        } else if (token && !tokenValid) {
            if (passwordChanged) {
                window.location.href = '/login'
            } else {
                return <MessageBox
                    message={i18n("errors", "invalid_reset_token")}
                    onClose={() => { window.location.href = '/password-reset'; }}
                />
            }
        } else if (token && tokenValid) {
            return (
                <div className="ResetPassword">
                    <ContentBox className="Reset-box" title=''>
                        <Form onSubmit={this._onSubmit} autoComplete={false} submitOnEnter>
                            <TextField
                                name="password"
                                type="password"
                                label={i18n("login", "password")}
                                value={password} 
                                onChange={this._onPasswordChange}
                            />
                            <TextField
                                type="password"
                                name="repeatpassword"
                                label={i18n("login", "repeat_password")}
                                value={repeatpassword}
                                onChange={this._onRepeatPasswordChange}
                            />

                            <StackLayout orientation="vertical">
                                <Button type="submit" label={i18n("login", "submit")} appearance="primary" filled={true} busy={loading} />
                            </StackLayout>
                            <div className={'status'}>{status}</div>
                        </Form>
                    </ContentBox>
                </div>
            )
        } else {
            return (
                <div className="ResetPassword">
                    <ContentBox className="Reset-box" title=''>
                        <Form onSubmit={this._onSubmit} autoComplete={false} submitOnEnter>
                            <TextField
                                name="email"
                                label={i18n("login", "email")}
                                value={email}
                                onChange={this._onEmailChange}
                            />
                            <StackLayout orientation="vertical">
                                <Button type="submit" label={i18n("login", "submit")} appearance="primary" filled={true} busy={loading} />
                            </StackLayout>
                            <div>{status}</div>
                        </Form>
                    </ContentBox>
                </div>
            )
        }
    }

    _checkResetToken = async (token) => await api("/checkToken", { token })
    _checkPasswordChanged = async (token) => await api("/passwordChanged", { token })

    _onEmailChange = (email) => this.setState({ email })
    _onPasswordChange = (password) => this.setState({ password })
    _onRepeatPasswordChange = (repeatpassword) => this.setState({ repeatpassword })

    _onSubmit = ({ email, password, repeatpassword }) => {
        const { search } = this.props.location
        let token = search.replace("?token=", "")

        if (token) {
            if (password && repeatpassword && password === repeatpassword) {
                this.setState({ loading: true }, () => {
                    setTimeout(async () => {
                        let data = { token, newpassword: password }
                        let result = await api("/changepassword", data)

                        if (result === 'Ok') {
                            api("/sendConfirmationEmail", data)
                            this.setState({ status: i18n("login", "password_changed"), loading: false })

                            setTimeout(async () => {
                                window.location.href = '/login'
                            }, 1200)
                        } else {
                            this.setState({ status: i18n("login", "password_not_changed"), loading: false })
                        }
                    }, 0)
                })
            } else {
                this.setState({ status: i18n("login", "password_mismatch") })
            }
        } else {
            this.setState({ loading: true }, () => {
                setTimeout(async () => {
                    await api("/sendPasswordResetEmail", { email })
                    this.setState({ status: i18n("login", "email_sent"), loading: false })
                }, 1000)
            })
        }
    }
})