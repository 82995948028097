import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../../node_modules/react-grid-layout/css/styles.css'
import '../../../../../../node_modules/react-resizable/css/styles.css'
import { Grid, CardSettings } from "components"
import Settings from "classes/Settings"
import Dashboard from '../../../../../classes/Dashboard'
import Card from '../../../../../classes/Card'
import styled, {css} from 'styled-components'
import Button from "components/Button/Button"
import Modal from "components/Modal/Modal"
import UploaderForm from "routes/TimeReport/components/UploaderForm/UploaderForm"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import SketchPicker from '@uiw/react-color-sketch'
import EditableField from "components/EditableField/EditableField"
import Switch from "components/Switch/Switch"
import DatePicker from "components/DatePicker/DatePicker"
import Autocomplete from "components/Autocomplete/Autocomplete"
import ShowMessageBox from "components/MessageBox/MessageBox"
import MonitorNotification from "components/MonitorNotification/MonitorNotification"

import * as utils from "helpers/utils"
import api, { getAssetsURIForPath } from "helpers/api"
import i18n from "helpers/i18n"

import {
    createCard,
    closeCardSettings
} from '../../../../../redux/actions/monitor/monitorActions'

import { resetCardSettingsData } from '../../../../../redux/actions/cardSettings/cardSettingsActions'

class Monitor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: {
                ID: 0,
                Title: i18n('monitor', 'noDashboards'),
                Layouts: {"lg":[],"md":[],"sm":[],"xs":[],"xxs":[]},
                CompactType: "vertical"
            },
            width: 0,
            isFullscreen: false,
            dropdown: false,
            dashboards: [],
            publicDashboards: [],
            oldTitle: '',
            newTitle: '',
            backgroundColorPaletteVisible: false,
            dashboardSettingsMode: "background",
            newPublicitySettings: null,
            message: '',
            notificationSettings: null
        }
        this.autocomplete = React.createRef()
    }

    async componentDidMount() {
        const { success, dashboards, publicDashboards } = await api('/monitor/dashboards/getall')

        this.resize()
        window.addEventListener('click', this.eventClick)
        window.addEventListener('keydown', this.eventKeydown)
        window.addEventListener('resize', this.eventResize)
        window.addEventListener('fullscreenchange', this.eventFullscreen)

        if (success) {
            var dashData = { dashboardMenuMode: "mine" }
            if (dashboards.length > 0) {
                dashData = {
                    dashboardMenuMode: "mine",
                    currentTab: { 
                        ID: dashboards[0].ID, 
                        Title: dashboards[0].Title,
                        Layouts: dashboards[0].Layouts,
                        CompactType: dashboards[0].CompactType
                    }
                }
            } else if (publicDashboards.length > 0) {
                dashData = {
                    dashboardMenuMode: "shared",
                    currentTab: { 
                        ID: publicDashboards[0].ID, 
                        Title: publicDashboards[0].Title
                    }
                }
            } 
            this.setState({
                ...dashData,
                dashboards: dashboards,
                publicDashboards: publicDashboards,
            })
        }
    }

    componentDidUpdate() {
        this.resize()
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.eventClick)
        window.removeEventListener('keydown', this.eventKeydown)
        window.removeEventListener('resize', this.eventResize)
        window.removeEventListener('fullscreenchange', this.eventFullscreen)
    }

    eventClick = (e) => {
        this.setState( prevState => ({ 
        dropdown: false, 
        backgroundColorPaletteVisible: 
        prevState.showDashboardSettings && prevState.dashboardSettingsMode === "background" ?
            this.bgColorRefPreview.contains(e.target) ? true 
            : this.bgColorRef.contains(e.target) ? !!prevState.backgroundColorPaletteVisible 
            : false
            : false
        }))
    }
    eventKeydown = (e) => e.which === 27 && this.setState({ dropdown: false })
    eventResize = () => this.resize()
    eventFullscreen = () => this.styleOnFullscreen(this.state.isFullscreen)

    onChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    resize() { // updating width on window resize to properly display layout
        const container = document.getElementById('monitor_view-container')

        // set current width of container to properly scale grid
        if (container !== null && container.offsetWidth > 0
            && container.offsetWidth !== this.state.width)
            this.setState({ width: container.offsetWidth })

        // on quiting fullscreen mode display edit buttons
        if ((!document.fullscreenElement && !document.webkitFullscreenElement
            && !document.mozFullScreenElement && !document.msFullscreenElement)
            && document.getElementById('card_layout-settings-container'))
            document.getElementById('card_layout-settings-container').style.display = ''
    }

    keyPressHandler(e, dashboardID) {
        if (e.key === 'Escape' || e.which === 27) {
            e.stopPropagation()
            this.setState({ newTitle: this.state.oldTitle }, () => this.changeTitle(dashboardID))
        } else if (e.key === 'Enter' || e.which === 13) this.changeTitle(dashboardID) // updating title on Enter press
        else if (e.key === 'Space' || e.which === 32) e.stopPropagation()
    }

    displayHandler(elems) { // handles display styling of passed elements
        elems.map(elem => document.getElementById(elem.id).style.display = elem.arg)
    }

    showInput(oldTitle, dashboardID) { // show input to change title of current dashboard
        this.displayHandler([
            { id: 'title-'       + dashboardID, arg: 'none' },
            { id: 'edit-title-'  + dashboardID, arg: 'none' }, // hide buttons edit, delete, create..
            { id: 'save-title-'  + dashboardID, arg: 'inline-block' }, // ..show save button and input
            { id: 'input-title-' + dashboardID, arg: 'inline-block' }])
        this.setState({ oldTitle, newTitle: oldTitle })
        document.getElementById('input-title-' + dashboardID).focus()
    }

    changeTitle(dashboardID) {
        this._updateDashboardTitle(dashboardID)
        this.displayHandler([
            { id: 'title-'      + dashboardID, arg: 'inline-block' },
            { id: 'edit-title-' + dashboardID, arg: 'inline-block' }, // show buttons edit, delete, create..
            { id: 'save-title-' + dashboardID, arg: 'none' }, // ..hide save button and input
            { id: 'input-title-'+ dashboardID, arg: 'none' }])
    }

    closeCardSettings() { // closing card settings on clicking outside of the box
        document.getElementById("content-container").style.overflowY = "auto"
        this.props.closeCardSettings()
        this.closeBlur()
    }

    openBlur() {
        document.getElementById('monitor-container').style.filter = 'blur(5px)' // make background blurry
    }

    closeBlur() {
        document.getElementById('monitor-container').style.filter = 'none' // make background clear
        this.props.resetCardSettingsData()
    }

    enterFullscreen() { // enter fullscreen for each browser type
        if (document.fullscreenEnabled)            document.getElementById('content-container').requestFullscreen()
        else if (document.webkitFullscreenEnabled) document.getElementById('content-container').webkitRequestFullscreen()
        else if (document.mozFullScreenEnabled)    document.getElementById('content-container').mozRequestFullScreen()
        else if (document.msFullscreenEnabled)     document.getElementById('content-container').msRequestFullscreen()
    }

    styleOnFullscreen(isFullscreen) {
        this.setState({ isFullscreen: !isFullscreen }, () => {
            if (this.state.isFullscreen) {
                document.getElementById('card_layout-settings-container').style.display = 'none'
                document.getElementById(`tab-${this.state.currentTab.ID}`).style.padding = '40px 32px 0 32px'
            } else document.getElementById(`tab-${this.state.currentTab.ID}`).style.padding = '0'
        })
    }

    openDropdown = () => this.setState(prevState => ({ dropdown: !prevState.dropdown }))
    
    render() {
        const { cardSettings, pageCover } = this.props
        const { isMoveable, currentTab, width, dropdown, dashboards, publicDashboards, newTitle, dashboardSettingsMode, backgroundColorPaletteVisible, newPublicitySettings, dashboardMenuMode, newColor, newImage, MessageBox } = this.state
        let dashboardList = dashboardMenuMode === "mine" ? dashboards : dashboardMenuMode === "shared" ? publicDashboards : []
        let currentCards = {}
        let currentCompactType = ""
        let currentLayouts = ""
        let backgroundImage  = ""
        let newBackgroundImage = ""
        let backgroundColor = ""
        let currentDashboard = ""
        let shared = ""
        dashboards.concat(publicDashboards).forEach((dashboard, i) => {
            if (dashboard.ID === currentTab.ID) { 
                currentCards = dashboard.Cards
                currentCompactType = dashboard.CompactType
                currentLayouts = dashboard.Layouts
                backgroundImage = dashboard.BackgroundImage
                backgroundColor = dashboard.BackgroundColor
                newBackgroundImage = dashboard.newBackgroundImage
                currentDashboard = dashboard
                shared = this.props.user.id !== dashboard.UserID
            }
        })

        return (
            <div id='monitor_view-container'>
                <div id='monitor-container'> 
                    <div id='header-container' style={{ backgroundColor: Settings.getGlobalColor('overlay') }}>
                        <nav id='dashboard_list-container' style={{ color: Settings.getGlobalColor('subtitle') }} onClick={(e) => e.stopPropagation()}>
                            <ListTitle id='dashboard_list-title' className={dropdown ? 'open' : ''} onClick={this.openDropdown}>
                                {!dropdown && (window.innerWidth > 640
                                    ? <div style={{ display: 'flex' }}>
                                        <i className='material-icons arrow'>keyboard_arrow_down</i>
                                        <h3>{currentTab.Title}</h3>
                                    </div>
                                    : <i className='fas fa-bars'/>)
                                }
                            </ListTitle>

                            <Dropdown id='dashboard_list-dropdown' style={{ display: dropdown ? 'block' : 'none' }}>
                                <div id='create_dashboard-wrapper'>
                                    <Button materialIcon={'add_to_queue'} label={ i18n('monitor', 'addDashboard') }
                                        appearance='secondary' size='small' filled onClick={() => this._createDashboard()} />
                                </div>                                
                                <EditToggler navType="menu" disabledSubModes={publicDashboards.length === 0 ? ["shared"]:[]} subMode={ dashboardMenuMode } onChange={ this._changeMenuMode } />
                                {dropdown && dashboardList.length > 0 && dashboardList.map((dashboard, i) => (
                                <Tablink id={`tablink-${dashboard.ID}`} className={`tablink ${currentTab.ID === dashboard.ID && 'active'}`} key={dashboard.ID}
                                    onClick={() => this.setState({ currentTab: { ID: dashboard.ID, Title: dashboard.Title, Layouts: dashboard.Layouts, CompactType: dashboard.CompactType }, dropdown: false })}
                                >
                                    <h3 className='tab-title' id={`title-${dashboard.ID}`} onClick={() => this.onDashboardSettingsClose()}>{dashboard.Title}</h3>
                                    <input maxLength='20' type='text' name='newTitle' value={newTitle}
                                        className='input-title' id={`input-title-${dashboard.ID}`}
                                        onKeyDown={(e) => this.keyPressHandler(e, dashboard.ID)}
                                        onBlur={() => this.changeTitle(dashboard.ID)}
                                        onClick={(e) => e.stopPropagation()} onChange={this.onChange} />
                                    <div className='dashboard_list-icon_wrapper' onClick={(e) => e.stopPropagation()}>
                                        <i className='fa fa-check' id={`save-title-${dashboard.ID}`} onClick={() => this.changeTitle(dashboard.ID)}/>
                                        {dashboardMenuMode === "mine" && <i className='fas fa-pen' id={`edit-title-${dashboard.ID}`}
                                            onClick={() => this.showInput(dashboard.Title, dashboard.ID)}/>}
                                        <i className='fas fa-clone' id={`clone-title-${dashboard.ID}`}
                                            onClick={() => this._duplicateDashboard(i)}/>
                                        <i className='fas fa-trash-alt' id={`delete-dashboard-${dashboard.ID}`}
                                            onClick={() => dashboardMenuMode === "mine" ? this._deleteOwnDashboard(dashboard.ID, dashboard.Title, i) : this._deleteSharedDashboard(dashboard.ID, dashboard.Title, i)}/> 
                                    </div>
                                </Tablink>))}
                            </Dropdown>
                        </nav>
                    </div>
                    <div id='content-container' style={{backgroundColor: newColor ? newColor : backgroundColor ? backgroundColor : (Settings.getGlobalColor('background')), backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "50% 50%", backgroundImage: newImage ? `url(${ newImage.content })` : (newBackgroundImage ? `url(${ newBackgroundImage.content })` : backgroundImage ? `url(${ getAssetsURIForPath("/dashboards/"+backgroundImage) })` : "none")}}>
                        {dashboards.concat(publicDashboards).length > 0 ? dashboards.concat(publicDashboards).map(dashboard => (
                            <div id={`tab-${dashboard.ID}`} className='tab' key={dashboard.ID} style={{ display: currentTab.ID === dashboard.ID ? 'block' : 'none' }}>
                                {(window.innerWidth > 640 || (!dropdown && window.innerWidth <= 640)) &&
                                    <div id='card_layout-settings-container' style={{ color: Settings.getGlobalColor('subtitle') }}>
                                    <Icon className='dashboardIcon fas fa-expand' onClick={() => this.enterFullscreen()}></Icon>
                                    {!shared && <React.Fragment>
                                        {utils.isMobileDevice() && <Icon className={`dashboardIcon material-icons ${isMoveable ? 'checked' : ''}`}
                                            onClick={() => this._toggleMoveable(isMoveable) }>open_with</Icon>}
                                        <Icon className={`dashboardIcon fas fa-sort-amount-up ${dashboard.CompactType === 'vertical' ? 'checked' : ''}`}
                                            onClick={() => this._updateDashboardLayouts(dashboard.ID, dashboard.Layouts, dashboard.CompactType, true)}></Icon>
                                        <Icon className={`dashboardIcon fas fa-cogs`}
                                            onClick={() => this._openDashboardSettings(currentDashboard)}></Icon>
                                        <Icon className='dashboardIcon material-icons' disabled={this.state.creatingCard}
                                            onClick={() => this.props.createCard(dashboard)}>add</Icon>
                                    </React.Fragment>}
                                </div>}
                                <Grid className='content-layout' dashboard={dashboard} width={width}
                                    isMoveable={isMoveable}
                                    shared={shared}
                                    updateDashboardLayouts={this._updateDashboardLayouts.bind(this)}
                                    updateCard={this._updateCard.bind(this, dashboard.ID, dashboard.Cards)}
                                    openNotificationSettings={this._openNotificationSettings.bind(this, currentCards)}
                                    duplicateCard={this._duplicateCard.bind(this, dashboard.ID, dashboard.Layouts, dashboard.CompactType, dashboard.Cards)}
                                    openBlur={this.openBlur}
                                />
                            </div>
                        ))
                        // a hint in case of 0 dashboards
                        : <p id='empty-message'>
                            {i18n('monitor', 'click')}
                            <i className='material-icons'>add_to_queue</i> 
                            {i18n('monitor', 'createDashboard')}
                        </p>}
                    </div>
                </div>
                
                <Modal
                    animate={ false }
                    visible={ this.state.notificationSettings !== null }
                    onClosed={ () => this._closeNotificationSettings() }
                > 
                    {this.state.notificationSettings} 
                </Modal>

                <Modal
                    animate={ false }
                    visible={ this.state.showDashboardSettings && !!newPublicitySettings }
                    onClosed={ () => this.onDashboardSettingsClose() }
                >

                    <EditToggler navType="settings" subMode={ dashboardSettingsMode } onChange={ this._changeSettingsMode } />

                    {dashboardSettingsMode === "background" &&
                    <div className="dashboardSettingsBackground">
                    <Modal visible={ this.state.uploaderContent !== null } onClosed={ () => this.setState({uploaderContent: null}) } >
                        {this.state.uploaderContent}
                    </Modal>
                        <StackLayout orientation="horizontal" justifyContent="space-around">
                            <Button label={ i18n('monitor', 'editBackgroundImage') } appearance='secondary' size='small' filled onClick={() => this.setState({uploaderContent: <UploaderForm type = "monitor" uploadedImage = { this.state.newImage ? this.state.newImage : newBackgroundImage ? newBackgroundImage : "/dashboards/"+backgroundImage} onUpload = {this._onUpload.bind(this)} />})} />
                            <div className="split">
                                <div ref={bgColorRef => this.bgColorRef = bgColorRef} >
                                    <Button label={ `${i18n('monitor', 'editBackgroundColor')}` } appearance="secondary" onClick = { () => this.setState({ backgroundColorPaletteVisible: !backgroundColorPaletteVisible }) } size='small' filled />
                                </div>
                                <div ref={bgColorRefPreview => this.bgColorRefPreview = bgColorRefPreview} className='color_picker' style={{display: backgroundColorPaletteVisible ? 'block' : 'none', position: 'absolute'}}>
                                    <SketchPicker
                                        color={newColor ? newColor : backgroundColor ? backgroundColor : Settings.getGlobalColor('background')}
                                        disableAlpha={ false }
                                        onChange={this._onColorChange}/>
                                        <div className ={ "icon material-icons deleteColorPreview" } onClick = {() => this.setState({newColor: Settings.getGlobalColor('background')}) } >delete</div>
                                </div>
                            </div>
                        </StackLayout>
                    </div>
                    }
                    {dashboardSettingsMode === "share" && 
                        <div className="dashboardSettingsShare">
                            <div className="linkOptions">
                                <Subtitle>{i18n('monitor', 'shareWithLink')}</Subtitle>
                                <div className='linkElement'>
                                    <div className="buttonContainer">
                                        <Button label={ i18n('monitor', 'generateLink') } appearance='secondary' size='small' filled onClick={() => this.generatePublicLink()} />
                                        <Button success={this.state.copied} className = { utils.createClassName("filled", { "success": this.state.copied }) } label={ i18n('monitor', 'copyLink') } appearance='secondary' size='small' filled onClick={() => this.copyPublicLink()} />
                                    </div>
                                    <div style={{display: "flex", flexDirection: "column", width: "100%", marginTop: "-10px"}}>
                                        <Textbox label={ i18n('monitor', 'publicLink') } name="PublicLink" value={ newPublicitySettings ? newPublicitySettings.PublicLink : ""} onChange={this.onPublicityChange} />
                                        <input style={{color: Settings.getGlobalColor('label')}} type="text" onClick={(e) => e.target.select()} readOnly id="fullPublicLink" className="staticText" value={(window.location.origin+"/publicdashboard?link=")+(newPublicitySettings ? newPublicitySettings.PublicLink : "")} />
                                    </div>
                                </div>
                                <div className='rowElement'>
                                    <div>
                                        <Checkbox label={ i18n('monitor', 'usePassword') } onChange={this.onPublicityChange}
                                            name='usePublicPassword' checked={newPublicitySettings.usePublicPassword} />
                                    </div>
                                    <Textbox disabled={newPublicitySettings ? !newPublicitySettings.usePublicPassword : false} label={ i18n('login', 'password') } name="PublicPassword" value={ newPublicitySettings.PublicPassword } onChange={this.onPublicityChange} />
                                </div>
                                <DatePickerContainer>
                                    <span className="TextField-label">{i18n('monitor', 'expirationDate')}</span>
                                    <DatePicker date={newPublicitySettings.PublicEndDate ? utils.moment(newPublicitySettings.PublicEndDate) : null}
                                        name="PublicEndDate" placeholder={i18n('monitor', 'noExpirationDate')} onDateChange={this.onPublicityChange} monitor />
                                </DatePickerContainer>
                            </div>
                            <div className="directShareOptions">
                                <Subtitle>{i18n('monitor', 'shareDirectly')}</Subtitle>
                                    <div style={{width:"100%", borderBottom:`1px solid ${Settings.getGlobalColor('background')}`}}>
                                        <Autocomplete ref={this.autocomplete}
                                            value={''}
                                            assetType={"users"}
                                            chosenAssets={newPublicitySettings.PublicUsers}
                                            onSelect={this.addPublicUser}
                                            placeholder={`${i18n("general", 'search')} ${i18n("general", 'managers')}`}
                                            searchUrl={`/users/manager/search`}
                                            small isMonitor />
                                    </div>
                                    {newPublicitySettings.PublicUsers.length > 0 &&
                                        <div className='asset-container'>
                                            {(newPublicitySettings.PublicUsers.map((asset, i) => {
                                                return (
                                                    <li className="TagField-tag" key={"mainAsset-"+i}>
                                                        <TagFieldTagWrapper className="TagField-tag-wrapper">
                                                            <div className="TagField-tag-value">{ asset.label }</div>
                                                            <TagFieldTagRemove className="TagField-tag-remove material-icons" onClick={() => this.removePublicUser(i)}>close</TagFieldTagRemove>
                                                        </TagFieldTagWrapper>
                                                    </li>
                                            )}))}
                                        </div>
                                    }       
                                    <StackLayout orientation="horizontal" justifyContent="space-around" style={{marginTop: "20px"}}>
                                        <Button label={ i18n("monitor", "addAllManagers") } appearance='secondary' size='small' filled onClick={() => this.addAllPublicUsers()} />
                                        <Button label={ i18n('monitor', 'clearList') } appearance='secondary' size='small' filled onClick={() => this.removeAllPublicUsers()} />
                                    </StackLayout>
                            </div>
                        </div>
                    }
                    <StackLayout orientation="horizontal">
                        <Button label={ i18n('general', 'save') } appearance="primary" onClick={() => this.saveDashboardSettings(currentTab.ID)} filled />
                    </StackLayout>
                    {MessageBox}
                </Modal>
                
                {pageCover && <div id='page_cover-container'>
                    <div id='page_cover' onClick={() => {
                        this.closeCardSettings()
                    }}></div>
                    {cardSettings.on && <CardSettings
                        isNew={cardSettings.isNew}
                        card={cardSettings.card || new Card({ ID: 'c' + Date.now(), DashboardID: currentTab.ID })}
                        layout={cardSettings.layout}
                        createCard={this._createCard.bind(this, currentTab.ID, currentLayouts, currentCompactType, currentCards)}
                        deleteCard={this._deleteCard.bind(this, currentTab.ID, currentLayouts, currentCompactType, currentCards)}
                        updateCard={this._updateCard.bind(this, currentTab.ID, currentCards)}
                        closeBlur={this.closeBlur.bind(this)}
                    />}
                </div>}
                {this.state.MessageBox}
            </div>
        )
    }

    generatePublicLink() {
        let randomString = Math.random().toString(36).substring(4)
        this.onPublicityChange(randomString, "PublicLink")
    }

    copyPublicLink() {
        const copyText = document.getElementById("fullPublicLink")
        copyText.select()
        document.execCommand('copy')

        let selected = document.getSelection()
        if (selected) selected.removeAllRanges()
        copyText.blur()

        if (!this.state.copied) {
            this.setState({copied: true})
            setTimeout(() => {
                this.setState({copied: false})
              }, 1500)
        }
    }
    
    addPublicUser = (user) => {
        let newPublicArray = [...this.state.newPublicitySettings.PublicUsers].concat(user)
        this.onPublicityListChange(newPublicArray)
    }

    removePublicUser = (index) => {
        let newPublicArray = [...this.state.newPublicitySettings.PublicUsers]
        newPublicArray.splice(index, 1)
        this.onPublicityListChange(newPublicArray)
    }

    addAllPublicUsers = async () => {
        const result = await api('/users/manager/get')
        let newPublicArray = result.data

        this.onPublicityListChange(newPublicArray)

    }

    removeAllPublicUsers = () => {
        let newPublicArray = []
        this.onPublicityListChange(newPublicArray)
    }
    
    onPublicityListChange = (newPublicArray) => {
        this.onPublicityChange(newPublicArray, "PublicUsers",
            () => {if (this.autocomplete.current) this.autocomplete.current.getInfo('')})
    }
    
    onPublicityChange = (value, name, callback) => {
        this.setState(prevState => ({
            newPublicitySettings: {
                ...prevState.newPublicitySettings, 
                [name]: value 
            }
        }), callback && (() => callback()))
    }

    _onUpload(value) {
        this.setState({uploaderContent: null, newImage: value})
    }
    _toggleMoveable = () => this.setState(prevState => ({ isMoveable: !prevState.isMoveable }))
    _changeSettingsMode = (subMode) => this.setState({ dashboardSettingsMode: subMode })
    _changeMenuMode = (subMode) => this.setState({ dashboardMenuMode: subMode })
    _onColorChange = (value) => {
        this.setState({newColor: value.hex})
    }
    // DASHBOARD Methods
    async _createDashboard() {
        const tempDashboardID = 'd' + Date.now()
        const newDashboard = new Dashboard({ ID: tempDashboardID, UserID: this.props.user.id })

        this.setState(prevState => ({
            dashboards: [...prevState.dashboards, newDashboard]
        }))

        const { success, dashboardID } = await api('/monitor/dashboards/create')
        if (success) {
            this.setState(prevState => ({
                dashboardMenuMode: "mine",
                currentTab: { ID: dashboardID, Title: newDashboard.Title, Layouts: newDashboard.Layouts, CompactType: newDashboard.CompactType },
                dashboards: [...prevState.dashboards.map((dashboard) =>
                    dashboard.ID === tempDashboardID
                    ? {...dashboard, ID: dashboardID }
                    : {...dashboard }
                )]
            }))
        } else {
            this.setState(prevState => ({
                dashboards: [...prevState.dashboards.filter((dashboard) => dashboard.ID !== tempDashboardID)]
            }))
        }
    }

    async _duplicateDashboard(i) {
        const {dashboardMenuMode, dashboards, publicDashboards} = this.state
        const tempDashboardID = 'd' + Date.now()
        const newDashboard = dashboardMenuMode === "mine" ? {...dashboards[i]} : {...publicDashboards[i]}

        this.setState(prevState => ({
            dashboards: [...prevState.dashboards, {...newDashboard, ID: tempDashboardID, Title: newDashboard.Title + ' - copy' }]
        }))

        const { success, dashboard } = await api('/monitor/dashboards/duplicate', { dashboard: newDashboard })
        
        if (success) {
            this.setState(prevState => ({
                dashboards: [...prevState.dashboards.map((d) =>
                    d.ID === tempDashboardID
                    ? {...dashboard }
                    : {...d }
                )]
            }))
        } else {
            this.setState(prevState => ({
                dashboards: [...prevState.dashboards.filter((dashboard) => dashboard.ID !== tempDashboardID)]
            }))
        }
    }

    async _updateDashboardTitle(dashboardID) {
        if (dashboardID[0] === 'd') return

        const { newTitle, oldTitle, currentTab } = this.state
        this.setState(prevState => ({
            dashboards: [...prevState.dashboards.map((dashboard, i) =>
                dashboard.ID === dashboardID
                    ? {...dashboard, Title: newTitle }
                    : {...dashboard }
            )]
        }))

        const { success } = await api('/monitor/dashboards/update/title', { dashboardID, title: newTitle })
        
        if (success && currentTab.ID === dashboardID) {
            this.setState(prevState => ({ currentTab: { ...prevState.currentTab, Title: newTitle }}))
        } else {
            this.setState(prevState => ({
                newTitle: oldTitle,
                dashboards: [...prevState.dashboards.map((dashboard, i) =>
                    dashboard.ID === dashboardID
                    ? {...dashboard, Title: oldTitle }
                    : {...dashboard }
                )]
            }))
        }
    }

    _openDashboardSettings(dashboard) {
        document.getElementById("content-container").style.overflowY = "hidden"
        this.setState({
            showDashboardSettings: true, 
            newColor: dashboard.BackgroundColor,
            newPublicitySettings: {
                PublicEndDate: dashboard.PublicEndDate || "",
                PublicLink: dashboard.PublicLink || "",
                PublicPassword: dashboard.PublicPassword || "",
                PublicUsers: dashboard.PublicUsers || [],
                usePublicPassword: dashboard.usePublicPassword || ""
            }
        })
    }

    async _updateDashboardLayouts(dashboardID, layouts, oldCompactType, changeCompactType = false) {
        if (dashboardID[0] === 'd') return

        for (let layout in layouts) {
            layouts[layout].forEach((card, i, layout) => {
                if (card.h < 9) layout[i].h = 9
                if (card.w < 12) layout[i].w = 12
            })
        }

        const compactType = changeCompactType ? (!oldCompactType ? 'vertical' : null) : oldCompactType
        
        this.setState(prevState => ({
            dashboards: [...prevState.dashboards.map((dashboard) =>
                dashboard.ID === dashboardID
                    ? {...dashboard, Layouts: layouts, CompactType: compactType }
                    : {...dashboard }
            )]
        }))

        await api('/monitor/dashboards/update/layouts', { dashboardID, layouts, compactType })
    }

    confirmWarning = (message, confirmCallback) => {
        ShowMessageBox(this, {
            title : i18n('warnings', 'warning'),
            message : message,
            buttons : [
                {
                    label: i18n('warnings', 'confirm'),
                    appearance: 'primary',
                    onClick: () => {
                        confirmCallback()
                    }
                },
                {
                    label: i18n('warnings', 'cancel'),
                    appearance: 'danger',
                }
            ]
        })
    }

    _deleteOwnDashboard(dashboardID, dashboardTitle, i) {
        if (dashboardID[0] === 'd') return

        this.confirmWarning(utils.format(i18n('warnings', 'delete_dashboard'), [dashboardTitle]), async () => {
            const dashboardToDelete = {...this.state.dashboards[i]}
            const filteredDashboards = [...this.state.dashboards.filter((dashboard) => dashboard.ID !== dashboardID)]

            this.setState(prevState => ({
                dashboards: filteredDashboards,
                currentTab: prevState.currentTab.ID === dashboardID // deleted current dashboard?
                    ? filteredDashboards.length > 0 // are there any dashboards left?
                        ? { ID: filteredDashboards[0].ID, Title: filteredDashboards[0].Title } // open the first one
                        : { ID: 0, Title: i18n('monitor', 'noDashboards') } // set default name
                    : {...prevState.currentTab } // leave it as is
            }))

            await api('/monitor/dashboards/delete', { dashboard: dashboardToDelete })
        }) 
    }
    
    async _deleteSharedDashboard(dashboardID, dashboardTitle, i) {
        if (dashboardID[0] === 'd') return

        this.confirmWarning(utils.format(i18n('warnings', 'delete_shared_dashboard'), [dashboardTitle]), async () => { // confirmation before deleting dashboard
            const dashboardToDelete = {...this.state.publicDashboards[i]}
            const filteredDashboards = [...this.state.publicDashboards.filter((dashboard) => dashboard.ID !== dashboardID)]

            this.setState(prevState => ({
                publicDashboards: filteredDashboards,
                dashboardMenuMode: filteredDashboards.length === 0 // deleted last one?
                    ? "mine" // go to my list (shared list is getting disabled)
                    : prevState.dashboardMenuMode, // leave it as is
                currentTab: prevState.currentTab.ID === dashboardID // deleted current dashboard?
                    ? filteredDashboards.length > 0 // are there any dashboards left?
                        && { ID: filteredDashboards[0].ID, Title: filteredDashboards[0].Title } // open the first one
                    : {...prevState.currentTab } // leave it as is
            }))

            await api('/monitor/dashboards/deletePublic', { dashboard: dashboardToDelete })
        })
    }

    onDashboardSettingsClose() {
        document.getElementById("content-container").style.overflowY = "auto"
        this.setState({showDashboardSettings: false, newImage: null, newColor: null, backgroundColorPaletteVisible: false, dashboardSettingsMode: "background", newPublicitySettings: null})
    }

    _openNotificationSettings(currentCards, cardContent, dashboardID) {
        this.setState({ notificationSettings: <MonitorNotification user={this.props.user} cardContent={cardContent} updateCard={this._updateCard.bind(this, dashboardID, currentCards)} /> })
    }

    _closeNotificationSettings() {
        this.setState({ notificationSettings: null})
    }

    async _saveDashboardSettings(dashboardID, publicity, image, color) {
        if (dashboardID[0] === 'd') return

        const { success, errorType, message } = await api('/monitor/dashboards/update/publicity', { dashboardID, publicity })

        if (success) {
            await api('/monitor/dashboards/update/background', { dashboardID, color, image })
            this.setState(prevState => ({
                showDashboardSettings: false,
                dashboards: [...prevState.dashboards.map((dashboard, i) =>
                    dashboard.ID === dashboardID
                    ? {...dashboard, 
                        PublicEndDate: publicity.PublicEndDate,
                        PublicLink: publicity.PublicLink,
                        PublicPassword: publicity.PublicPassword,
                        PublicUsers: publicity.PublicUsers,
                        usePublicPassword: publicity.usePublicPassword,

                        newBackgroundImage: image ? image : prevState.dashboards[i].newBackgroundImage, 
                        BackgroundColor: color
                    }
                    : {...dashboard }
                )]
            }))
        } else if (errorType === "special_link_character") {
            ShowMessageBox(this, {message : message})
        }
    }

    saveDashboardSettings = (dashboardID) => {
        this._saveDashboardSettings(dashboardID, this.state.newPublicitySettings, this.state.newImage, this.state.newColor)
    }

    // CARD Methods

    async _createCard(dashboardID, Layouts, CompactType, Cards, newCard, backgroundImageUploadData = null) {
        if (dashboardID[0] === 'd' || this.state.creatingCard) return

        this.setState({ creatingCard: true }, async () => {
            const { success, cardID, layouts } = await api('/monitor/cards/create', { dashboardID, card: newCard, layouts: Layouts, compactType: CompactType })
    
            if (success) {
                newCard = {...newCard, ID: cardID }
    
                if (backgroundImageUploadData) {
                    const { backgroundImage } = await api('/monitor/cards/update/backgroundImage', { card: newCard, image: backgroundImageUploadData })
    
                    newCard.BackgroundImage = backgroundImage
                }
    
                Cards[cardID] = newCard
    
                this.setState(prevState => ({
                    creatingCard: false,
                    dashboards: [...prevState.dashboards.map((dashboard) =>
                        dashboard.ID === dashboardID
                        ? {...dashboard, Cards, Layouts: layouts }
                        : {...dashboard }
                    )]
                }))
            } else this.setState({ creatingCard: false })
        })
    }

    async _duplicateCard(dashboardID, Layouts, CompactType, Cards, originalCardID) {
        if (originalCardID[0] === 'c' || this.state.creatingCard) return

        this.setState({ creatingCard: true }, async () => {
            const tempCard = {...Cards[originalCardID] }
    
            const { success, cardID, layouts } = await api('/monitor/cards/duplicate', { dashboardID, card: tempCard, layouts: Layouts, compactType: CompactType })
    
            if (success) {
                Cards[cardID] = {...tempCard, ID: cardID }
    
                this.setState(prevState => ({
                    creatingCard: false,
                    dashboards: [...prevState.dashboards.map((dashboard) =>
                        dashboard.ID === dashboardID
                        ? {...dashboard, Cards, Layouts: layouts }
                        : {...dashboard }
                    )]
                }))
            } else this.setState({ creatingCard: false })
        })
    }

    async _updateCard(dashboardID, Cards, cardID, Chart, Options, Setup, backgroundImageUploadData = null, forceUpdate = false) {
        if (cardID[0] === 'c') return

        const oldCard = {...Cards[cardID] }
        const newCard = {...Cards[cardID], Chart, Options, Setup }

        if (backgroundImageUploadData) {
            const { backgroundImage } = await api('/monitor/cards/update/backgroundImage', { card: newCard, image: backgroundImageUploadData })

            newCard.BackgroundImage = backgroundImage
        }

        if (forceUpdate && Setup) {    
            const newData = await api('/monitor/updateCardData', { ID: cardID, Chart, Options, Setup })
            newCard.Chart.data = newData
        }

        this.setState(prevState => ({
            notificationSettings: null,            
            dashboards: [...prevState.dashboards.map((dashboard, index) => (
                dashboard.ID === dashboardID
                    ? {
                        ...dashboard,
                        Cards: {
                            ...prevState.dashboards[index].Cards,
                            [cardID]: newCard
                        }
                    }
                    : {...dashboard }
            ))],
            publicDashboards: [...prevState.publicDashboards.map((dashboard, index) =>
                dashboard.ID === dashboardID
                    ? {
                        ...dashboard,
                        Cards: {
                            ...prevState.publicDashboards[index].Cards,
                            [cardID]: newCard
                        }
                    }
                    : {...dashboard }
            )]
        }), async () => {
            const { success } = await api('/monitor/cards/update', { card: newCard })
    
            if (!success) {
                this.setState(prevState => ({
                    notificationSettings: <MonitorNotification user={this.props.user} cardContent={oldCard} updateCard={this._updateCard.bind(this, dashboardID, Cards)} />,
                    dashboards: [...prevState.dashboards.map((dashboard) =>
                        dashboard.ID === dashboardID
                        ? {...dashboard, Cards: {...Cards, [cardID]: oldCard }}
                        : {...dashboard }
                    )]
                }))
            }
        })
    }

    async _deleteCard(dashboardID, Layouts, CompactType, Cards, cardID) {
        if (cardID[0] === 'c') return

        this.confirmWarning(i18n('warnings', 'delete_card'), async () => { // confirmation before deleting dashboard
            this.closeCardSettings()

            for (let prop in Layouts) {
                Layouts[prop] = Layouts[prop].filter((c) => +c.i !== +cardID)
            }

            let card = {...Cards[cardID]}
            delete Cards[cardID]

            this.setState(prevState => ({
                dashboards: [...prevState.dashboards.map((dashboard) =>
                    dashboard.ID === dashboardID
                    ? {...dashboard, Cards, Layouts }
                    : {...dashboard }
                )]
            }))

            await api('/monitor/cards/delete', { dashboardID, card, layouts: Layouts, compactType: CompactType })
        })
    }
}

function Textbox(props) {
    const { name, label, value, onChange, disabled, index, placeholder } = props
    return (
        <EditableField disabled={disabled} placeholder={placeholder} type="monitor" name={name} label={ label } value={ value } onChange={ onChange } index={index} />
    )
}
function Checkbox(props) {
    const { label, name, checked, onChange, style, disabled } = props
    return (
        <Switch disabled={disabled} style={style} name={ name } label={ label } checked={ checked } onChange={ onChange } animate={ true }/>
    )
}
class EditToggler extends React.PureComponent {

    render() {
        const { props } = this;
        const classes = utils.createClassName("ContextReviewContent-EditToggler", {
            "active": true
        });

        return (
            <div className={ classes }>
                <EditSubModeNav { ...props } />
            </div>
        );
    }

    // Internal methods
    _onToggle = () => {
        const { subMode, onChange } = this.props;
        if(onChange) onChange(subMode);
    }
}

function EditSubModeNav({ subMode: activeSubMode, onChange, navType, disabledSubModes = [] }) {
    const Item = ({ label, subMode }) => {
        let disabled = disabledSubModes.includes(subMode)
        return <Div className={ utils.createClassName("item", { "active": activeSubMode === subMode, "disabled": disabled }) } onClick={ !disabled ? (onChange && (() => onChange(subMode))) : undefined }>{ label }</Div>
    }
        return (
            <React.Fragment>
                {navType === "settings" ? 
                    <Nav>
                        <Item label={ i18n("general", 'background') }  subMode="background" />
                        <Item label={ i18n("monitor", 'share') }   subMode="share" />
                    </Nav>
                : navType === "menu" ? 
                    <Nav>
                        <Item label={ i18n("monitor", 'myList') }  subMode="mine" />
                        <Item label={ i18n("monitor", 'sharedWithMe') } subMode="shared" />
                    </Nav>
                : null}
            </React.Fragment>
        )
}

const Div = styled.div`
    ${props => props.className === 'top' && css`
        border-bottom: 1px solid ${props.theme.background};
    `}

    ${props => props.className.split(' ').includes('item') && css`
        color: ${props.theme.label};
    `}

    ${props =>  props.className.split(' ').includes('item') &&
                props.className.split(' ').includes('active')
                && css`
        color: ${props.theme.primary};
    `}

    ${props => props.active && css`
        background: ${props.theme.primary};
        color: ${props.theme.overlay};
        &:hover {
            background: ${props.theme.primary};
        }
    `}
`;

const Nav = styled.nav`
    background: ${props => props.theme.background};
`;

const Icon = styled.button`
    background: ${props => props.theme.overlay};
    color: ${props => props.theme.subtitle};
    
    ${!utils.isMobileDevice() && css`
        &:hover {
            background: ${props => props.theme.background};
        }
    `}
      
    ${props => props.className.split(' ').includes('checked') && css`
        background: ${props.theme.background};
    `}
`;

const Dropdown = styled.div`
    border: 1px solid ${props => props.theme.secondary};
    border-top: none;
`

const ListTitle = styled.div`
    cursor: pointer;
    ${props => props.className?.split(' ').includes('open') && css`
        border-bottom: none;
        cursor: default;
    `}
`

const Tablink = styled.div`
    background: ${props => props.theme.overlay};
    color: ${props => props.theme.subtitle};
    &:hover {
      background: ${props => props.theme.background};
    }
    .fa, .fas {
        &:hover {
            background: ${props => props.theme.overlay};
            border-radius: 5px;
        }
    }
    ${props => props.className.split(' ').includes('active') && css`
        h3 {
            color: ${props.theme.secondary};
            font-weight: 650;
        }
    `}
`

const TagFieldTagRemove = styled.div`
    color: ${props => props.theme.label};
    &:hover {
        color: ${props => props.theme.danger};
    }
`

const TagFieldTagWrapper = styled.div`
    background: ${props => props.theme.background};
    color: ${props => props.theme.subtitle};
    &:hover {
        background: ${props => props.theme.background};
    }
`
const DatePickerContainer = styled.div`
    max-width: 200px;
    .TextField-label {
        color: ${props => props.theme.label};
        position: relative;
        padding: 0;
        z-index: 9;
        font-size: 12px;
        top: 0px;
        left: 0px;
    }

    input {
        margin-left: 10px;
        border: none;
        border-left: 1px solid transparent;
        border-bottom: 1px solid ${props => props.theme.background};
        font-size: 14px;
        :focus {
            border: none;
        }
    }
    
`

const Subtitle = styled.h3`
    color: ${props => props.theme.subtitle};
    font-size: 18px;
`

const mapStateToProps = (state, props) => ({
    cardSettings: state.monitor.cardSettings,
    pageCover: state.monitor.pageCover,
    user: state.app.user
})

const mapActionstoProps = {
    createCard,
    closeCardSettings,
    resetCardSettingsData
}

export default connect(mapStateToProps, mapActionstoProps)(Monitor)
