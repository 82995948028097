import {
    START_FETCH,
    SET_PREVIEW_DATA,
    RESET_DATA
} from './action-types'

import api from "helpers/api"

const getPreviewData = (card) => async dispatch => {
    if (card.Setup) {
        const response = await api('/monitor/getPreviewData', {...card })

        dispatch({ type: START_FETCH })

        return dispatch({ type: SET_PREVIEW_DATA, payload: response })
    }
}

const resetCardSettingsData = () => dispatch => {
    return dispatch({ type: RESET_DATA })
}

export {
    getPreviewData,
    resetCardSettingsData
}