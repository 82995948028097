import React, {Component} from 'react'
import { connect } from 'react-redux'

import styled, {css} from 'styled-components';

// import i18n from "helpers/i18n";
// import { getAssetsURIForPath } from "helpers/api";

class TagCard extends Component {

    render() {
        const { ID, name, data, onClick } = this.props
        const members = data.map((e, i) => i < 6 ? <p key={i}>{e.name}</p> : '')

        return <Div className="tagContainer">
            <Div className="trapezoid">
                <Div className="circle"></Div>
            </Div>
            <Div className="Timeline-toolbar tag-card" onClick={() => onClick(ID, name, data)}>
                <div className="context-info">
                <Div className="name">{name}</Div>
                <div className='assigned'>
                    <ul>
                        {members}
                        {members.length >= 6 && (<i className="fas fa-ellipsis-v"></i>)}
                    </ul>
                </div>
                </div>
            </Div>
        </Div>
    }
}

export default connect()(TagCard)

const Div = styled.div`
    ${props => props.className.split(' ').includes('Timeline-toolbar') && css`
        border-bottom: 1px solid ${props.theme.background};
        background: ${props.theme.overlay} !important;
    `}
    ${props => props.className === 'tagContainer' && css`

    `}
    ${props => props.className === 'name' && css`
        color: ${props.theme.primary};
    `}
    ${props => props.className === 'circle' && css`
        background: ${props.theme.background};
    `}
    ${props => props.className === 'trapezoid' && css`
        border-bottom: 70px solid ${props.theme.overlay};
    `}
`;
