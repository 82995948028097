import React, {Component} from 'react'

import Settings from "classes/Settings"
import styled, {css} from 'styled-components'
import Button from "components/Button/Button"
import MessageBox from "components/MessageBox/MessageBox"
import DatePicker from "components/DatePicker/DatePicker"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import Modal from "components/Modal/Modal"
import Select from "components/Select/Select"

// HELPERS
import * as utils from "helpers/utils"
import i18n from "helpers/i18n";
import api from "helpers/api";

// import i18n from "helpers/i18n";
// import { getAssetsURIForPath } from "helpers/api";

export default class Fortnox extends Component {

    constructor(props) {
        super(props);

        this.state = {
			MessageBox: null,
			invoices: [],
			integration: [],
			generateInvoiceContent: null,
			invoiceTableFilter: {
				startDate: utils.moment().subtract(3,'months').startOf('month'),
				endDate: utils.moment().add(1,'months').endOf('month'),
				type: "InvoiceDate"
			}
        };
    }

	componentDidMount() {
		this.getFortnoxData()
	}

	getFortnoxData = async () => {
		const { startDate, endDate, type } = this.state.invoiceTableFilter
		const data = {
			type,
			startDate: utils.moment(startDate).format("YYYY-MM-DD"),
            endDate: utils.moment(endDate).format("YYYY-MM-DD")
		}

        const result = await api('integrations/fortnox', data)
		if (result.success) {
			this.setState({
				integration: result.integration,
				invoices: result.invoices
			})
		}
    }

    _generateInvoices = async (startDate, endDate, previewInvoices) => {
        const data = {
            ID: this.state.integration.ID,
			invoices: previewInvoices,
            startDate: utils.moment(startDate).format("YYYY-MM-DD"),
            endDate: utils.moment(endDate).format("YYYY-MM-DD")
        }

        const result = await api('integrations/fortnox/generateInvoices', data);

        if (result.success) {
            this.closeInvoiceModal()
			this.getFortnoxData()
        } else {
            console.log("show message box");
            MessageBox(this, {
                message: result.message,
                buttons: [
                    {
                        label: "OK",
                        appearance: "primary",
                    },
                ],
            });
        }
    };

	_onDateChange = (changeType, newValue) => {
		this.setState(previousState => ({
			invoiceTableFilter: {
				...previousState.invoiceTableFilter,
				[changeType]: newValue, 
			}
		}), () => {
			this.getFortnoxData()
		})
	}

	_onDateJump = (startDate, endDate) => {
		this.setState(previousState => ({ 
			invoiceTableFilter: {
				...previousState.invoiceTableFilter,
				startDate, 
				endDate
			}
		}), () => {
			this.getFortnoxData()
		})
	}

	_openInvoiceModal = () => {
		this.setState({ generateInvoiceContent: <InvoiceGeneration generateInvoices={this._generateInvoices} /> })
	}
	closeInvoiceModal = () => { this.setState({ generateInvoiceContent: null }) }

    render() {
        const { MessageBox, invoices, generateInvoiceContent } = this.state

        return (
			<div className="TimeReportOverview-Fortnox">
				{MessageBox}
				<div className="container">
					<TopBar
						openInvoiceModal={ this._openInvoiceModal }
						onDateChange={ this._onDateChange }
						onDateJump={ this._onDateJump }
						invoiceTableFilter={ this.state.invoiceTableFilter }
					/>

					<InvoiceTable products={invoices} />

					<Modal visible={!!generateInvoiceContent} onClosed={this.closeInvoiceModal} minHeight>
						{generateInvoiceContent}
                    </Modal>
				</div>
			</div>
        )
    }
}

class TopBar extends React.PureComponent {

	openInvoiceModal = () => {
		this.props.openInvoiceModal()
	}

	onDateChange = (changeType, newValue ) => {
		if (this.props.onDateChange) this.props.onDateChange(changeType, newValue)
	}

	onDateJump = (startDate, endDate ) => {
		if (this.props.onDateJump) this.props.onDateJump(startDate, endDate)
	}

    render() {
		const { startDate, endDate, type } = this.props.invoiceTableFilter

		const selectOptions = [
			{value: "InvoiceDate", label: i18n("fortnox", "invoiceDate")},
			{value: "DueDate", label: i18n("fortnox", "dueDate")}
		]
		const selectedIndex = selectOptions.findIndex(item => item.value === type)
		
        return (
            <Div className="TimeReportOverview-top-bar">
                <div className="start">
					<div className="period">
						<StackLayout orientation="horizontal" alignItems="end">
							<DatePicker
								type="range"
								startDate={ startDate }
								endDate={ endDate }
								onStartDateChange={ (startDate) => this.onDateChange("startDate", startDate) }
								onEndDateChange={ (endDate) => this.onDateChange("endDate", endDate) }
								onJump={ (startDate, endDate) => this.onDateJump(startDate, endDate) }
							/>
							<Select type='monitor' name={"type"} label={ "Date type" } selectedIndex={ selectedIndex } onChange={ (value) => this.onDateChange("type", value) } options={ selectOptions } />
						</StackLayout>
					</div>
				</div>

                <div className="end">
					<StyledButton className="button" onClick={ this.openInvoiceModal }>
                        <div className="icon material-icons">add</div>
                    </StyledButton>
				</div>
            </Div>
        )
    }
}

class InvoiceGeneration extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
			startDate: utils.moment().startOf('month'),
			endDate: utils.moment().endOf('month'),
			previewInvoices: []
        };
    }

	componentDidMount() {
		const { startDate, endDate } = this.state
		this.getPreviewInvoices(startDate, endDate)
	}

	generateInvoices = () => {
		const { startDate, endDate, previewInvoices } = this.state
		if (this.props.generateInvoices) this.props.generateInvoices(startDate, endDate, previewInvoices)
	}

	onDateChange = (changeType, newValue) => {
		this.setState({ [changeType]: newValue }, () => {
			this.getPreviewInvoices()
		})
	}

	onJump = (startDate, endDate) => {
		this.setState({ startDate, endDate }, () => {
			this.getPreviewInvoices()
		})
	}

	handleInvoiceToggle = (index) => {
		let previewInvoices = [...this.state.previewInvoices]
		previewInvoices[index].selected = !previewInvoices[index].selected
		
		this.setState({ previewInvoices })
	}

	getPreviewInvoices = async () => {
		const { startDate, endDate } = this.state
		const data = {
			startDate: utils.moment(startDate).format("YYYY-MM-DD"),
            endDate: utils.moment(endDate).format("YYYY-MM-DD")
		}

        const result = await api('integrations/fortnox/previewInvoices', data)
		if (result.success) {
			this.setState({
				previewInvoices: result.invoices
			})
		}
	}

    render() {
		const { startDate, endDate, previewInvoices } = this.state
		const nrSelectedInvoices = previewInvoices.filter(invoice => invoice.selected).length

        return (
            <React.Fragment>
				<StackLayout orientation="vertical">
					<Button type="submit" label={utils.format(i18n("fortnox", `generateXInvoice${nrSelectedInvoices === 1 ? "" : "s"}`),[nrSelectedInvoices])} filled appearance="primary" onClick={ this.generateInvoices } />
					<div style={{whiteSpace: "nowrap"}}>
						<DatePicker
							type="range"
							startDate={ startDate }
							endDate={ endDate }
							onStartDateChange={ (startDate) => this.onDateChange("startDate", startDate) }
							onEndDateChange={ (endDate) => this.onDateChange("endDate", endDate) }
							onJump={ (startDate, endDate) => this.onJump(startDate, endDate)  }
						/>
					</div>
				</StackLayout>
				<div style={{overflow: "auto"}}>
					<Table>
						<Thead>
							<tr>
								<th>{i18n("editable_table", "resource")}</th>
								<th>{i18n("general", "fee")}</th>
								<th>{i18n("fortnox", "quantity")}</th>
								<th>{i18n("monitor", "total")} <span>{i18n("fortnox", "short_excluding")} {i18n("fortnox", "vat")}</span></th>
							</tr>
						</Thead>
						<tbody>
							{previewInvoices.map((invoice, index) => {

								return <React.Fragment key={index}>
									<tr><th colSpan="4" style={{paddingTop: "15px"}}><input type="checkbox" checked={invoice.selected} onChange={() => this.handleInvoiceToggle(index)} /> {invoice.company} - {invoice.project}</th></tr>
									{invoice.resources.map((resource, index) => {
										
										return <Tr key={index} state="preview">
											<td>{resource.name}</td>
											<td>{Math.round(resource.fee).toLocaleString(Settings.get("REGION_FORMAT"))}</td>
											<td>{resource.quantity.toLocaleString(Settings.get("REGION_FORMAT"))} {resource.unit}</td>
											<td>{Math.round(resource.quantity * resource.fee).toLocaleString(Settings.get("REGION_FORMAT"))} SEK</td>
										</Tr>
									})}
									<Tr withBoarder>
										<td colSpan="3">{i18n("monitor", "total")}</td>
										<td>{Math.round(invoice.totalSum).toLocaleString(Settings.get("REGION_FORMAT"))} SEK</td>
									</Tr>

								</React.Fragment>
							})}
						</tbody>
					</Table>
				</div>
			</React.Fragment>
        )
    }
}

const InvoiceTable = (props) => {
	const { products } = props;
  	const { items, requestSort, sortConfig } = useSortableData(products);

	const displaySort = (name) => {
		if (!sortConfig || name !== sortConfig.key) return <IconPlaceholder className="material-icons"/>

		return (
			<Icon className="material-icons">
				{sortConfig.direction === "ascending" ? "arrow_drop_down" : "arrow_drop_up"}
			</Icon> 
		)
	}
	let totalFee = 0;

	return (
		<Table>
			<Caption>
				<StackLayout>
					<ColorBox state={"paid"}>Paid in full</ColorBox>
					<ColorBox state={"overdue"}>Unpaid due</ColorBox>
					<ColorBox state={"booked"}>Posted</ColorBox>
					<ColorBox state={"draft"}>Not posted</ColorBox>
				</StackLayout>
			</Caption>
			<Thead sortable>
				<tr>
					<th onClick={() => requestSort('company')}>
						<HeaderContent>{i18n("general", "company")} {displaySort('company')}</HeaderContent>
					</th>
					<th onClick={() => requestSort('totalFee')}>
						<HeaderContent>{i18n("fortnox", "totalFee")} <span>{i18n("fortnox", "short_including")} {i18n("fortnox", "vat")}</span> {displaySort('totalFee')}</HeaderContent>
					</th>
					<th onClick={() => requestSort('invoiceDate')}>
						<HeaderContent>{i18n("fortnox", "invoiceDate")} {displaySort('invoiceDate')}</HeaderContent>
					</th>
					<th onClick={() => requestSort('sent')}>
						<HeaderContent>{i18n("fortnox", "sent")} {displaySort('sent')}</HeaderContent>
					</th>
					<th onClick={() => requestSort('dueDate')}>
						<HeaderContent>{i18n("fortnox", "dueDate")} {displaySort('dueDate')}</HeaderContent>
					</th>
				</tr>
			</Thead>
			<tbody>
				{items.map((invoice, index) => {
					const invoiceState = invoice.paid ? "paid"
						: invoice.booked && utils.moment() > utils.moment(invoice.dueDate) ? "overdue"
						: invoice.booked ? "booked"
						: "draft"
					totalFee += invoice.totalFee;

					return <Tr state={invoiceState} key={index}>
						<td>{invoice.company}</td>
						<td>{Math.round(invoice.totalFee).toLocaleString(Settings.get("REGION_FORMAT"))} {invoice.currency}</td>
						<td>{invoice.invoiceDate}</td>
						<td><i className={"material-icons"}>{invoice.sent ? "done" : "remove"}</i></td>
						<td>{invoice.dueDate}</td>
					</Tr>
				})}
			</tbody>
			{items.length > 1 && <Tfoot>
				<tr>
					<th>{i18n("monitor", "total")}</th>
					<th>{totalFee.toLocaleString(Settings.get("REGION_FORMAT"))} SEK</th>
				</tr>
			</Tfoot>}
		</Table>
	)
}

const useSortableData = (items, config = null) => {
	const [sortConfig, setSortConfig] = React.useState(config)
	
	const sortedItems = React.useMemo(() => {
	  	let sortableItems = [...items]
	  	if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
		  		if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1
		  		}
		  		if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1
		  		}
		  		return 0
			})
	  	}
	  return sortableItems
	}, [items, sortConfig])
  
	const requestSort = (key) => {
	  	let direction = 'ascending'
	  	if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending'
	  	}
	  	setSortConfig({ key, direction })
	}
	return { items: sortedItems, requestSort, sortConfig }
  }

const Div = styled.div`
	.Select {
		width: auto;

		.Select-input {
			padding: 0;
		}

		.Select-arrow {
			height: 20px
		}
	}
            
    ${props => props.className === 'TimeReportOverview-top-bar' && css`
        background: ${props.theme.overlay};
    `}
    ${props => props.className === 'label' && css`
        color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('icon') && css`
        color: ${props.theme.label};
    `}

`
const Tr = styled.tr`
	${props => props.state === 'paid' && css`
		background: #d4f3b8;
		&:hover {
			background: #baec8c;
		}
	`}

	${props => props.state === 'booked' && css`
		background: #ffffff;
		&:hover {
			background: #e6e6e6;
		}
	`}

	${props => props.state === 'draft' && css`
		background: #fffdc1;
		&:hover {
			background: #f1ec87;
		}
	`}

	${props => props.state === 'overdue' && css`
		background: #f9b6b6;
		&:hover {
			background: #f58787;
		}
	`}

	${props => props.state === 'preview' && css`
		background: ${props.theme.overlay};
		&:hover {
			background: ${props.theme.background};
		}
	`}

	${props => props.withBoarder && css`
		td, th {
			border: 1px solid black;
			border-left: none;
			border-right: none;
		}
	`}
`

const ColorBox = styled.div`
	display: flex;
	align-items: center;
	margin: 0 10px; 
	font-weight: normal;
    font-size: 14px;

	&::before {
		content: "";
		display: block;
		margin: 0 5px;
		height: 12px;
		width: 12px;
		border: 1px solid ${props => props.theme.label};

		${props => props.state === 'paid' && css`
			background: #d4f3b8;
			&:hover {
				background: #baec8c;
			}
		`}

		${props => props.state === 'booked' && css`
			background: #ffffff;
			&:hover {
				background: #e6e6e6;
			}
		`}

		${props => props.state === 'draft' && css`
			background: #fffdc1;
			&:hover {
				background: #f1ec87;
			}
		`}

		${props => props.state === 'overdue' && css`
			background: #f9b6b6;
			&:hover {
				background: #f58787;
			}
		`}
	}
`

const Thead = styled.thead`
	background: ${props => props.theme.primary};
	color: ${props => props.theme.background};
	position: sticky;
	top: 0;

	th {
		align-items: center;
	}

	${props => props.sortable && css`
		cursor: pointer;
	`}
	
`

const Tfoot = styled.tfoot`
	background: ${props => props.theme.primary};
	color: ${props => props.theme.background};

	th {
		align-items: center;
	}
`

const Caption = styled.caption`
	padding: 0 20px;
	width: max-content;
    color: ${props => props.theme.label};
`

const Table = styled.table`
	border-collapse: separate;
	padding: 0 20px 20px 20px;
	width: 100%;
    color: black;

	td, th {
		padding: 5px 20px;
		white-space: nowrap;

		span {
			font-size: 12px;
			margin-top: 2px;
			margin-left: 4px;
		}
	}

	tbody tr th {
		padding-left: 5px;
	}
`

const StyledButton = styled.div`
    color: ${props => props.theme.subtitle};
    :hover {
      background: ${props => props.theme.background};
    }

    ${props => props.className.split(' ').includes('opened') && css`
        background: ${props.theme.overlay} !important;
    `}
`

const HeaderContent = styled.div`
    display: flex;
	align-items: center;
`

const Icon = styled.i`
    font-size: 26px;
`
const IconPlaceholder = styled.i`
	width: 26px;
	height: 26px;
	opacity: 0;
`