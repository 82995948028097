import GlobalColors from "classes/GlobalColors";

export default class Settings {
    static keysWithDefaults = Object.freeze({
        "AUTH_TOKEN": null,
        "CURRENCY": "SEK",
        "LANGUAGE": "en",
        "REGION_FORMAT": "sv-SE",
        "OVERVIEW_INCLUDE_ASSIGNED_resources": true,
        "OVERVIEW_INCLUDE_ASSIGNED_projects": true,
        "OVERVIEW_INCLUDE_ASSIGNED_shadows": true,
        "OVERVIEW_INCLUDE_UNASSIGNED_resources": true,
        "OVERVIEW_INCLUDE_UNASSIGNED_projects": true,
        "OVERVIEW_INCLUDE_UNASSIGNED_shadows": true,
        "OVERVIEW_INCLUDE_SHADOWS_resources": false,
        "OVERVIEW_INCLUDE_SHADOWS_projects": false,
        "OVERVIEW_INCLUDE_TAGS_resources": "",
        "OVERVIEW_INCLUDE_TAGS_projects": "",
        "OVERVIEW_INCLUDE_TAGS_shadows": "",
        "OVERVIEW_MODE_resources_mode": "hours",
        "OVERVIEW_MODE_projects_mode": "hours",
        "OVERVIEW_MODE_shadows_mode": "hours",
        "GLOBAL_COLORS": ``,
        "GLOBAL_TAB": ``,
        "GLOBAL_ITAB": ``,
        "USING_DEFAULT_COLORS": true,
        "PUNCHCLOCK_AUTH_TOKEN": null
    })

    static get(key) {
        try {
            if(typeof Settings.keysWithDefaults[key] === "undefined") throw new Error("UNKNOWN_KEY");

            const value = localStorage.getItem(key);
            return value !== null ? JSON.parse(atob(value)) : Settings.keysWithDefaults[key];
        } catch(e) {
            console.error({ status: e, key });
        }
    }

    static set(key, value) {
        try {
            if(typeof Settings.keysWithDefaults[key] === "undefined") throw new Error("UNKNOWN_KEY");

            localStorage.setItem(key, btoa(JSON.stringify(value)));
        } catch(e) {
            console.error({ status: e, key });
        }
    }

    static remove(key) {
        localStorage.removeItem(key);
    }

    static clear() {
        localStorage.clear();
    }

    static getGlobalColor(key) {
        return GlobalColors.getGlobalColor(key);
    }

    static setPreviewGlobalColor(key, color) {
        let colors = JSON.parse(this.get("GLOBAL_COLORS"))
        colors[key] = color.hex
        this.set("GLOBAL_COLORS", JSON.stringify(colors));
    }
    static setPreviewGlobalColors(colors) {
        this.set("GLOBAL_COLORS", JSON.stringify(colors));
    }
}
