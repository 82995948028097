import * as utils from "helpers/utils"
import Settings from "classes/Settings"

export default class Card {
    constructor(props) {
        this.Chart = {
            type: 'textdata',
            data: [],
            options: getDefaultChartOptions()
        }
        this.BackgroundImage = null
        this.Options = {
            name: 'KPI',
            fontType: 'Arial',
            titleFontSize: '20',
            displayUnit: true,
            displayPlusSign: true,
            background: Settings.getGlobalColor('overlay'),
            sameColor: false,
            gradientOptions: {
                useGradient: false,
                gradientColor: 'rgba(0, 0, 0, 1)',
                gradientType: 'linear-gradient',
                gradientDirection: 'bottom right',
                firstStop: '0',
                secondStop: '100',
                radialShape: 'circle'
            },
            updateRate: {
                type: 'hourly',
                time: '00:00'
            },
            updated: Date.now(),
            notification: {
                active: false, 
                operator: "more", 
                sumType: "total", 
                breakpoint: ""
            }
        }
        this.Setup = {
            kpi: 'gross margin',
            mainAssetType: 'total',
            includeMainAsset: "every",
            includeShadows: false,
            mainAssets: [],
            secondaryAssetType: 'none',
            includeSecondaryAsset: "every",
            secondaryAssets: [],
            calcType: 'amount',
            unitType: 'amount',
            startDateType: 'quarter',
            endDateType: 'month',
            startDate: utils.moment().startOf('quarter').format('YYYY-MM-DD'),
            endDate: utils.moment().endOf('month').format('YYYY-MM-DD'),
            timeScale: 'none',
            chartType: 'textdata',
            sortType: 'label_ascending',
            bulletRanges: [],
            bulletMarkers: [],
            layerOrder: ['timeScale', 'components', 'assets'],
            componentBreakdown: true
        }

        if (props !== undefined) { // if data is provided
            for (let prop in props) {
                this[prop] = props[prop] // fill dashboard with data
            }
        }
    }
}

function getDefaultChartOptions() {
    return {
        textdata: {
            colors: {},
            textColor: Settings.getGlobalColor('default'),
            textFontSize: '16',
            sortType: 'label_ascending',
            splitNegatives: false,
            positiveColor: 'rgba(30, 130, 76, 1)',
            negativeColor: 'rgba(207, 0, 15, 1)',
            margin: {
                top: 20,
                bottom: 20,
                right: 20, 
                left: 20,
            }
        },
        bar: {
            axisLeft: '',
            axisLeftEnabled: true,
            axisBottom: '',
            axisBottomEnabled: true,
            axisBottomAngle: 0,
            maxValue: 1000,
            maxValueAuto: true,
            minValue: -1000,
            minValueAuto: true,
            colors: {},
            textColor: Settings.getGlobalColor('default'),
            groupMode: false,
            layout: false,
            margin: {
                top: 20,
                bottom: 45,
                right: 120,
                left: 80
            },
            padding: 0.1,
            innerPadding: 0,
            outerRadius: 0,
            legend: true,
            label: true,
            gridX: false,
            gridY: true,
            textFontSize: '16',
            legendFontSize: '14',
            axisFontSize: '12'
        },
        pie: {
            colors: {},
            textColor: Settings.getGlobalColor('default'),
            margin: {
                top: 20,
                bottom: 45,
                right: 120,
                left: 80
            },
            innerRadius: 0,
            outerRadius: 0,
            angle: 0,
            legend: true,
            label: true,
            textFontSize: '16',
            legendFontSize: '14',
        },
        line: {
            axisLeft: 'amount',
            axisLeftEnabled: true,
            axisBottom: 'total',
            axisBottomEnabled: true,
            axisBottomAngle: 0,
            maxValue: 1000,
            maxValueAuto: true,
            minValue: -1000,
            minValueAuto: true,
            colors: {},
            textColor: Settings.getGlobalColor('default'),
            layout: false,
            margin: {
                top: 20,
                bottom: 45,
                right: 120,
                left: 80
            },
            legend: true,
            label: true,
            gridX: false,
            gridY: true,
            dotSize: 10,
            lineWidth: 3,
            enableArea: false,
            areaOpacity: 0.1,
            textFontSize: '16',
            legendFontSize: '14',
            axisFontSize: '12'
        },
        calendar: {
            textColor: Settings.getGlobalColor('default'),
            emptyColor: 'rgba(238, 238, 238, 1)', 
            horizontalLayout: true,
            legendColors: [
                '#FFA07A',
                '#FA8072',
                '#F08080',
                '#CD5C5C',
                '#DC143C',
                '#B22222',
                '#8B0000',
                '#800000'
            ], 
            margin: {
                top: 100,
                right: 30,
                bottom: 60,
                left: 30
            }, 
            monthBorderColor: 'rgba(255, 255, 255, 1)', 
            monthBorderWidth: 0,
            daySpacing: 2,
            dayBorderWidth: 0,
            dayBorderColor:'rgba(0, 0, 0, 1)',
            itemCount: 4,
            legend: true,
            horizontalLegend: true,
            legendFontSize: '14',
            axisFontSize: '12'
        },
        heatmap: {
            textColor: Settings.getGlobalColor('default'),
            margin: {
                top: 100,
                right: 60,
                bottom: 60,
                left: 60
            },
            autoMaxValue: true,
            maxValue: 1000,
            autoMinValue: true,
            minValue: -1000,
            forceSquare: true,
            topLegendRotation: -90,
            showTopAxis: true,
            splitNegatives: false,
            minColors: [
                'rgba(50, 205, 50, 1)',
                'rgba(19, 133, 16, 1)',
            ],
            maxColors: [
                'rgba(128, 0, 0, 1)',
                'rgba(139, 0, 0, 1)',
                'rgba(178, 34, 34, 1)',
                'rgba(220, 20, 60, 1)',
                'rgba(205, 92, 92, 1)',
                'rgba(240, 128, 128, 1)',
                'rgba(250, 128, 114, 1)',
                'rgba(255, 160, 122, 1)',
            ],
            circularCells: false,
            cellSpacing: 0,
            showLeftAxis: true, 
            leftLegendRotation: 0,
            cellBorderWidth: 0,
            cellBorderColor: 'rgba(0, 0, 0, 1)',
            textFontSize: '16',
            axisFontSize: '12'
        },
        radar: {
            autoMaxValue: true,
            maxValue: 1000,
            minValue: -1000,
            margin: {
                top: 70,
                right: 80,
                bottom: 40,
                left: 80
            },
            showLegend: true,
            linearLines: false, 
            lineWidth: 2,
            gridLevels: 5,
            linearGridShape: false, 
            showDots: true,
            dotSize: 8,
            fillOpacity: 0.25,
            showDotLabel: true,
            dotLabelYOffset: -12,
            gridColor: 'rgba(0, 0, 0, 1)',
            textFontSize: '16',
            legendFontSize: '14',
            axisFontSize: '12'
        },
        scatterPlot: {
            axisLeft: 'amount',
            axisLeftEnabled: true,
            axisBottom: 'total',
            axisBottomEnabled: true,
            axisBottomAngle: 0,
            maxValue: 1000,
            maxValueAuto: true,
            minValue: -1000,
            minValueAuto: true,
            colors: {},
            textColor: Settings.getGlobalColor('default'),
            layout: false,
            margin: {
                top: 20,
                bottom: 45,
                right: 120,
                left: 80
            },
            legend: true,
            label: true,
            gridX: false,
            gridY: true,
            dotSize: 10,
            lineWidth: 3,
            enableArea: false,
            areaOpacity: 0.1,
            legendFontSize: '14',
            axisFontSize: '12'
        },
        sunburst: {
            textFontSize: '16',
            textColor: Settings.getGlobalColor('default'),
            margin: {
                top: 40,
                right: 20,
                bottom: 20,
                left: 20
            },
            cornerRadius: 0, 
            borderWidth: 1,
            cellBorderColor: 'rgba(0, 0, 0, 1)',
            enableArcLabels: true,
            showAsPercent: false
        },
        bubble: {
            textColor: Settings.getGlobalColor('default'),
            margin: {
                top: 40,
                right: 20,
                bottom: 20,
                left: 20
            },
            enableLabel: true,
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 1,
            cellSpacing: 2,
            textFontSize: '16',
            hideZeroSumCells: true,
            showLabels: true,
            labelSkipRadius: 8,
            showAsPercent: false,
            showLabelDepth0: true,
            showLabelDepth1: false,
            showLabelDepth2: false,
            showLabelDepth3: false
        },
        bullet: {
            textColor: Settings.getGlobalColor('default'),
            reverse: false,
            measureSize: 0.3, 
            markerSize: 0.6, 
            titleRotation: 270, 
            titleOffsetX: -20, 
            margin: {
                top: 60,
                right: 20,
                bottom: 40,
                left: 40
            },
            bulletRanges: [],
            bulletRangeColors: [],
            bulletMarkers: [],
            bulletMarkColors: [],
            markersInPercent: false,
            rangesInPercent: false,
            verticalLayout: false,
            axisFontSize: '12',
            textFontSize: '16'
        }
    }
}