import React from "react"
import styled, {css} from 'styled-components'

// HELPERS
import * as utils from "helpers/utils"


export default class Switch extends React.PureComponent {
    uid = utils.createUniqueId("Switch-")
    state = {
        checked: this.props.checked
    }

    componentDidUpdate(prevProps) {
        if (prevProps.checked !== this.props.checked) this.setState({ checked: this.props.checked === true })
    }

    render() {
        const { label, description, name, small, xsmall, Fixed, disabled, animate, style } = this.props
        const { checked } = this.state
        const classes = utils.createClassName(this.props.className, {
            "Switch": true,
            "checked": checked,
            "small": small,
            "xsmall": xsmall,
            "animate": animate,
            "disabled": disabled,
            "fixed": Fixed
        })

        return (
            <StyledSwitch style={style} className={classes} $classes={classes.split(' ')}>
                <div className="Switch-info">
                    <SwitchLabel className="Switch-label">{label}</SwitchLabel>
                    {!!description && <SwitchDescription className="Switch-description">{description}</SwitchDescription>}
                </div>
                <input
                    ref={ref => this.input = ref}
                    type="checkbox"
                    className="Switch-input"
                    id={this.uid}
                    name={name}
                    checked={checked}
                    onChange={this._onChange}
                    disabled={disabled}
                />
                <StyledSwitchVisual $classes={classes.split(' ')} className="Switch-visual" htmlFor={this.uid} />
            </StyledSwitch>
        )
    }

    // Internal methods
    _onChange = (e) => {
        this.setState(prevState => ({ checked: !prevState.checked }), () => {
            const { onChange, index, name } = this.props
            if (onChange) onChange(this.state.checked, index !== undefined ? index : name)
        })
    }
}

const SwitchDescription = styled.div`
    color: ${props => props.theme.label};
`

const SwitchLabel = styled.div`
    color: ${props => props.theme.label};
`

const StyledSwitchVisual = styled.label`
    &::before {
        background: ${props => props.theme.background};
    }
    ${props => props.$classes.includes('checked') && css`
      &::before {
          background: ${props.theme.success};
          box-shadow: 0 0 0 2px ${props.theme.success};
      }
    `}
`

const StyledSwitch = styled.div`
    background: ${props => props.theme.overlay};
    ${props => props.$classes.includes('checked') && css`
        ::before {
             background: ${props.theme.success};
             box-shadow: 0 0 0 2px ${props.theme.success};
         }
    `}
`