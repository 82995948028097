import React, { Component } from 'react'

import EditableField from "components/EditableField/EditableField"
import Switch from "components/Switch/Switch"
import Select from "components/Select/Select"
import Button from "components/Button/Button"

import Settings from "classes/Settings"
import * as utils from "helpers/utils"
import i18n from "helpers/i18n"

export default class MonitorNotification extends Component {
    constructor(props) {
        super(props)
        this.state = { ...this.props.cardContent  }
    }

    onChange = (value, name) => {
        if (name === "breakpoint" && isNaN(value)) return
        this.setState(prevState => ({ 
            Options: {
                ...prevState.Options,
                notification: {
                    ...prevState.Options.notification,
                    [name]: value 
                }
            }
        }))
    }

    onSave = () => {
        const { ID, Chart, Options, Setup } = this.state
        if (this.props.updateCard) this.props.updateCard(ID, Chart, Options, Setup)
    }

    render() {
        const { timeScale, mainAssetType, secondaryAssetType, kpi } = this.state.Setup
        const { active, operator, sumType, breakpoint } = this.state.Options.notification
        let dataType = 
        timeScale !== "none" ? timeScale+"s"
        : mainAssetType  !== "total" ? mainAssetType 
        : secondaryAssetType !== "none" ? secondaryAssetType
        : null
    
        return (
            <div id='data_style-container'>
                <center style={{color: Settings.getGlobalColor("subtitle")}}>
                    <h3>{i18n("monitor", utils.toCamelCase(kpi))}</h3>
                </center>
                <div className='setup_row'>
                    <Checkbox label={i18n("general", 'active')} onChange={this.onChange} name={"active"} checked={active} /> 
                </div>
                <div className='setup_row' style={{ flexWrap: "wrap" }}>
                    <div className="sumType">
                        <Dropdown disabled={!active} label={i18n("monitor", 'sumType')} name="sumType" value={sumType} onChange={this.onChange} 
                            options={[
                                { value: "total", label: `${i18n("monitor", 'all')} ${dataType ? i18n("monitor", dataType === "users" ? "resources" : dataType) : ""}` },
                                { value: "individual",  label: `${i18n("monitor", 'individual')} ${dataType ? i18n("monitor", dataType === "users" ? "resources" : dataType) : ""}` },
                            ]} 
                        />
                    </div>
                    <div className="valueContainer">
                        <div className="operatorInput">
                            <Dropdown disabled={!active} label={ i18n("monitor", 'operator') } name="operator" value={operator} onChange={this.onChange} 
                                options={[
                                    { value: "more", label: i18n("monitor", 'moreThan') },
                                    { value: "less",  label: i18n("monitor", 'lessThan') },
                                ]} 
                            />
                        </div>
                        <div className="breakpoint">
                            <Textbox disabled={!active} label={ i18n('monitor', 'breakpoint') } name="breakpoint" value={ breakpoint } onChange={this.onChange} />
                        </div>
                    </div>
                </div>
                <div className='setup_row' style={{paddingBottom: "0px"}}>
                    <div className="notificationInfo" style={{color: Settings.getGlobalColor('label'), fontSize:"13px"}}>{utils.format(i18n("monitor", "notificationInfo"),[this.props.user.email])}</div>
                    <Button className="saveButton" label={ i18n('general', 'save') } appearance="primary" onClick={this.onSave} filled style={{marginLeft: "auto", marginTop: "auto"}} />                 
                </div>
            </div>
        )
    }
}

function Checkbox(props) {
    const { label, name, checked, onChange, disabled } = props
    return (
        <Switch disabled={ disabled } name={ name } label={ label } checked={ checked } onChange={ onChange } animate={ true }/>
    )
}

function Textbox(props) {
    const { name, label, value, onChange, disabled } = props
    return (
        <EditableField disabled={disabled} name={name} type="monitor" label={ label } value={ value } onChange={ onChange } />
    )
}

function Dropdown(props) {
    const { name, onChange, value, label, options, imgSrc, disabled } = props
    let selectedIndex = options.findIndex(item => item.value === value)
    if (options[selectedIndex].hide || options[selectedIndex].disabled) selectedIndex = options.findIndex(item => (!item.hide && !item.disabled))
    return (
        <Select disabled={ selectedIndex === -1 ? true : disabled } name={ name } label={ label } imgSrc={ imgSrc } type='monitor' selectedIndex={ selectedIndex } onChange={ onChange } options={ options } />
    )
}