import React from "react";
import serialize from "form-serialize";

// HELPERS
import { createClassName } from "helpers/utils";


export default class Form extends React.PureComponent {
    render() {
        const { children, id, autoComplete = true, style, global } = this.props
        const classes = createClassName(this.props.className, {
            "Form": true,
            "global": !!global
        })

        return (
            <form
                ref={ref => this.form = ref}
                className={classes}
                id={id}
                onKeyPress={this._onKeyPress}
                onSubmit={this._onSubmit}
                autoComplete={autoComplete === true ? "on" : "off"}
                style={style}
            >
                {children}
            </form>
        )
    }

    // Internal methods
    _onKeyPress = (e) => {
        if (e.key === "Enter" && e.target.nodeName !== "TEXTAREA") {
            e.preventDefault()
            if (this.props.submitOnEnter === true) this._onSubmit()
        }
    }

    _onSubmit = (e) => {
        const { defaultFieldValue, onSubmit } = this.props

        if (e) e.preventDefault()
        if (onSubmit) {
            const result = serialize(this.form, {
                hash: true,
                empty: true,
            })

            // Loop through result to set default values
            for (const key in result) {
                let value = result[key]

                if (typeof value === "object") value = value[value.length-1]
                if (typeof value === "undefined" || value === "") value = defaultFieldValue

                result[key] = value
            }

            onSubmit(result)
        }
    }
}
