import React, {Component} from 'react'
import { connect } from 'react-redux'

import styled, {css} from 'styled-components';

class ReminderCard extends Component {

    render() {
        const { data, ID } = this.props;
        const { title, message, reminder, recipients, rule, time } = data
        const members = data.recipients && data.recipients.length > 0 ? data.recipients.map((a, i) => {
            if(i < 6){
              return (
                <p key={i}>{a.name}</p>
              );
            } else return ''
        }) : []

        return (
          <Div className="tagContainer">
              <Div className="Timeline-toolbar punchClock-card" onClick={() => this.props.onClick(ID, title, message, reminder, recipients, rule, time)}>
                  <div className="context-info">
                    <Div className="name">{title}</Div>
                    <div className='assigned'>
                      <ul>
                        {members}
                        {members.length >= 6 && (<i className="fas fa-ellipsis-v"></i>)}
                      </ul>
                    </div>

                  </div>
              </Div>
          </Div>
        )

    }
}

export default connect()(ReminderCard)

const Div = styled.div`
    ${props => props.className.split(' ').includes('Timeline-toolbar') && css`
        border-bottom: 1px solid ${props.theme.background};
        background: ${props.theme.overlay} !important;
    `}
    ${props => props.className === 'tagContainer' && css`

    `}
    ${props => props.className === 'name' && css`
        color: ${props.theme.primary};
    `}
    ${props => props.className === 'circle' && css`
        background: ${props.theme.background};
    `}
    ${props => props.className === 'trapezoid' && css`
        border-bottom: 70px solid ${props.theme.overlay};
    `}
`

