import React from "react";

// CLASSES
import Settings from "classes/Settings";
import styled, {css} from 'styled-components';

// COMPONENTS
// import Button from "components/Button/Button";
import Form from "components/Form/Form";
// import StackLayout from "components/layouts/StackLayout/StackLayout";
import Switch from "components/Switch/Switch";
import Select from "components/Select/Select";
import TagField from "components/TagField/TagField";

// HELPERS
import { createClassName } from "helpers/utils";
import i18n from "helpers/i18n";


export default class FilterView extends React.PureComponent {
    state = {
        showActive: !this.props.type.includes('mode') && Settings.get("OVERVIEW_INCLUDE_ASSIGNED_" + this.props.type),
        showInactive: !this.props.type.includes('mode') && Settings.get("OVERVIEW_INCLUDE_UNASSIGNED_" + this.props.type),
        showShadows: !this.props.type.includes('mode') && this.props.type !== 'shadows' && Settings.get("OVERVIEW_INCLUDE_SHADOWS_" + this.props.type),
        mode: this.props.type.includes('mode') && Settings.get("OVERVIEW_MODE_" + this.props.type),
        hasChanged: false,
        tagRows: (!this.props.type.includes('mode') && Settings.get("OVERVIEW_INCLUDE_TAGS_" + this.props.type)) || [[]]
    }

    render() {
        const { type, visible, isManager } = this.props;
        const { showActive, showInactive, showShadows, mode, tagRows } = this.state;
        const classes = createClassName("FilterView", {
            "visible": !!visible
        });

        return <div className={classes} onClick={(e) => type.includes('mode') && e.stopPropagation()}>
            <div className="FilterView-overlay" onClick={this._onDismiss} />
            <Div className="FilterView-content">
                <Div className="FilterView-title">{i18n("general", `${type.includes('mode') ? 'mode': 'filter'}`)}</Div>

                <Form className={`FilterView-${type.includes('mode') ? 'mode' : 'form'}`} onSubmit={this._onFormSubmit}>
                    {isManager ?
                        type.includes('mode') ?
                            <Select name="mode" type='mode' onChange={this._onChange}
                                selectedIndex={mode === "hours" ? 0 : (mode === "fee" ? 1 : (mode === "cost" ? 2 : 3))}
                                options={[
                                    {value: "hours",  label: i18n("general", "Hours") },
                                    {value: "fee",    label: i18n("general", "fee")   },
                                    {value: "cost",   label: i18n("general", "cost")  },
                                    {value: "salary", label: i18n("general", "salary")}
                                ]}
                            />
                        : <React.Fragment>
                            <Switch name="showActive" label={i18n("filter_view", "show_assigned_" + type)} checked={showActive} onChange={this._onShowActiveChange} />
                            <Switch name="showInactive" label={i18n("filter_view", "show_unassigned_" + type)} checked={showInactive} onChange={this._onShowInactiveChange} />
                            {type !== 'shadows' && <Switch name="showShadows" label={i18n("filter_view", "show_shadows_" + type)} checked={showShadows} onChange={this._onShowShadowsChange} />}
                            {tagRows.length > 0 && tagRows.map((tagRow, tagRowIndex) =>
                                <div key={tagRowIndex} style={{width:'100%'}}>
                                    <TagField removable
                                            showAmp
                                            name="tags"
                                            label={i18n("general", "tags")}
                                            tags={tagRow}
                                            onTagAdd={(tag) => this._onTagAdd(tag, tagRowIndex)}
                                            onTagRemove={(tagIndex, callback = null) => this._onTagRemove(tagIndex, tagRowIndex, callback)}
                                            onRowRemove={() => this._onTagRowRemove(tagRowIndex)}
                                    />

                                    {tagRows.length > 1 && tagRowIndex !== tagRows.length - 1 && <div className="or-line">{i18n("filter_view", "or")}</div>}

                                    {tagRowIndex === tagRows.length - 1 && (
                                        <div onClick={this._onNewTagRow}
                                            style={{
                                                float:'right',
                                                cursor: 'pointer',
                                                paddingRight:'10px',
                                                fontSize: '20px'
                                            }}
                                        >
                                        +
                                        </div>
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    : <Switch name="showShadows" label={i18n("filter_view", "show_shadows_" + type)} checked={showShadows} onChange={this._onShowShadowsChange} />}
                </Form>
            </Div>
        </div>
    }

    // Internal methods
    _sanitizeTags = (tags) => tags.filter(tag => !!tag)

    _onDismiss = () => {
        const { onClose } = this.props

        if (this.state.tagRows.length > 0) {
            this.setState(prevState => ({
                tagRows: [...prevState.tagRows.filter((tagRow, i) => i === 0 || tagRow.length > 0)]
            }))
        }

        if (onClose) onClose()
    }

    _onNewTagRow = () => {
        this.setState(prevState => ({ tagRows: [...prevState.tagRows, []] }))
    }

    _onTagRowRemove = (indexToDelete) => {
        if (this.state.tagRows.length === 1) return

        this.setState(prevState => ({
            tagRows: [...prevState.tagRows.filter((tr, i) => indexToDelete !== i)]
        }))
    }

    _handleClick = () => {
        if (this.props.onClose) this.props.onClose()
    }

    _onShowActiveChange = () => {
        const { type } = this.props
        this.setState({ hasChanged: true, showActive: !Settings.get("OVERVIEW_INCLUDE_ASSIGNED_" + type) })
        Settings.set("OVERVIEW_INCLUDE_ASSIGNED_" + type, !Settings.get("OVERVIEW_INCLUDE_ASSIGNED_" + type))

        if (!Settings.get("OVERVIEW_INCLUDE_ASSIGNED_" + type) && !Settings.get("OVERVIEW_INCLUDE_UNASSIGNED_" + type)) {
            this.setState({ showInactive: !Settings.get("OVERVIEW_INCLUDE_UNASSIGNED_" + type) })
            Settings.set("OVERVIEW_INCLUDE_UNASSIGNED_" + type, !Settings.get("OVERVIEW_INCLUDE_UNASSIGNED_" + type))
        }

        this.props.onFilterChange()
    }

    _onShowInactiveChange = () => {
        const { type } = this.props
        this.setState({ hasChanged: true, showInactive: !Settings.get("OVERVIEW_INCLUDE_UNASSIGNED_" + type) })
        Settings.set("OVERVIEW_INCLUDE_UNASSIGNED_" + type, !Settings.get("OVERVIEW_INCLUDE_UNASSIGNED_" + type))

        if (!Settings.get("OVERVIEW_INCLUDE_ASSIGNED_" + type) && !Settings.get("OVERVIEW_INCLUDE_UNASSIGNED_" + type)) {
            this.setState({ showActive: !Settings.get("OVERVIEW_INCLUDE_ASSIGNED_" + type) })
            Settings.set("OVERVIEW_INCLUDE_ASSIGNED_" + type, true)
        }

        this.props.onFilterChange()
    }

    _onShowShadowsChange = () => {
        const { type } = this.props
        this.setState({ hasChanged: true, showShadows: !Settings.get("OVERVIEW_INCLUDE_SHADOWS_" + type) })
        Settings.set("OVERVIEW_INCLUDE_SHADOWS_" + type, !Settings.get("OVERVIEW_INCLUDE_SHADOWS_" + type))
        this.props.onFilterChange()
    }

    _onChange = (e) => {
        this.setState({ hasChanged: true })
        Settings.set("OVERVIEW_MODE_" + this.props.type, e.value)
        this.props.onClose()
    }

    _onTagAdd = (tag, tagRowIndex) => {
        if (tag) {
            this.setState(prevState => ({
                hasChanged: true,
                tagRows: [...prevState.tagRows.map((tagRow, i) =>
                    tagRowIndex === i
                        ? [...tagRow, tag]
                        : [...tagRow]
                ).filter((tagRow, i) => i === 0 || tagRow.length > 0)]
            }), () => {
                Settings.set("OVERVIEW_INCLUDE_TAGS_" + this.props.type, this.state.tagRows)
                this.props.onFilterChange()
            })
        }
    }

    _onTagRemove = (tagIndex, tagRowIndex, callback = null) => {
        if (this.state.tagRows[tagRowIndex].length === 0) return

        this.setState(prevState => ({
            hasChanged: true,
            tagRows: [...prevState.tagRows.map((tagRow, i) =>
                tagRowIndex === i
                    ? [...tagRow.filter((tag, i) => tagIndex !== i)]
                    : [...tagRow]
            ).filter((tagRow, i) => i === 0 || tagRow.length > 0)]
        }), () => {
            Settings.set("OVERVIEW_INCLUDE_TAGS_" + this.props.type, this.state.tagRows)
            this.props.onFilterChange();
            
            if (callback && this.state.tagRows[tagRowIndex]) callback(this.state.tagRows[tagRowIndex])
        })
    }

    _onFormSubmit = (formData) => {
        const { type, onApply, onClose } = this.props

        for (const key in formData) {
            const value = formData[key]

            if (key === "showActive") {
                Settings.set("OVERVIEW_INCLUDE_ASSIGNED_" + type, !value)
            } else if (key === "showInactive") {
                Settings.set("OVERVIEW_INCLUDE_UNASSIGNED_" + type, !value)
            } else if (key === "showShadows") {
                Settings.set("OVERVIEW_INCLUDE_SHADOWS_" + type, !value)
            } else if (key === "mode") {
                Settings.set("OVERVIEW_MODE_" + type, value)
            } else if (key === "tags" && value.length > 0) {
                Settings.set("OVERVIEW_INCLUDE_TAGS_" + type, value)
            }
        }

        this.setState({
            showActive: formData.showActive,
            showInactive: formData.showInactive,
            showShadows: formData.showShadows,
            mode: formData.mode,
            hasChanged: false
        })

        if (onApply) onApply(formData)
        if (onClose) onClose()
    }
}

const Div = styled.div`
    ${props => props.className === 'FilterView-title' && css`
        color: ${props.theme.subtitle};
        border-bottom: 1px solid ${props.theme.background};
    `}

    ${props => props.className === 'FilterView-content' && css`
        background: ${props.theme.overlay}
    `}

`