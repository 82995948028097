import React from "react";

// CLASSES
import Settings from "classes/Settings";

// COMPONENTS
import Button from "components/Button/Button";
import ContentBox from "components/ContentBox/ContentBox";
import Form from "components/Form/Form";
import TextField from "components/TextField/TextField";
import StackLayout from "components/layouts/StackLayout/StackLayout";

// HELPERS
import { connectRedux } from "helpers/utils";
import i18n from "helpers/i18n";
import api from "helpers/api";

// REDUCERS
import { actions as appActions } from "reducers/app";

export default connectRedux(state => ({
    app: state.app,
}), {
    setPunchClock: appActions.setPunchClock,
}, class Login extends React.Component {
    state = {
        loggingIn: false,
        wrongCredentials: false,
        credentials: {
            title: "",
            password: "",
        },
        loginmessage: ''
    }

    render() {
        const { state } = this;
        const { loggingIn, credentials } = state;
 
        return (
            <div className="Login">
                <ContentBox className="Login-box" title={ i18n("login", "sign_in") }>
                    <Form onSubmit={ this._onLogin } autoComplete={ false } submitOnEnter>
                        <TextField
                            name="title"
                            label={ i18n("login", "title") }
                            value={ credentials.title }
                            onChange={ this._onTitleChange }
                        />
                        <TextField
                            type="password"
                            name="password"
                            label={ i18n("login", "code") }
                            value={ credentials.password }
                            onChange={ this._onPasswordChange }
                        />

                        {/* <div style={{ flexDirection: "row" }}>
                            <input type="checkbox" id="rememberMyAccount" ref="rememberMyAccountInput" />
                            <label for="rememberMyAccount">{ i18n("login", "remember_my_account") }</label>
                        </div> */}
                        <div> { this.state.loginmessage } </div>
                        <StackLayout orientation="vertical">
                            <Button type="submit" label={ i18n("login", "sign_in") } appearance="primary" filled={ true } busy={ loggingIn } />
                        </StackLayout>
                    </Form>
                </ContentBox>
            </div>
        );
    }

    // Internal methods
    // _checkAuth = async function() {
    //     await api();
    // }

    _onTitleChange = (value) => this.setState({ credentials: { ...this.state.credentials, title: value } });
    _onPasswordChange = (value) => this.setState({ credentials: { ...this.state.credentials, password: value } });

    _onLogin = ({ title, password }) => {
        const { actions, navigate } = this.props;

        this.setState({ loggingIn: true }, () => {
            // const rememberMyAccount = this.refs.rememberMyAccountInput.checked;

            setTimeout(async () => { // Fake delay (temporary)
                const { success, message, punchClockData, jwt: token } = await api("/punchclock/auth/token", { title, password });
                const newStateProps = { loggingIn: false };
                let willNavigate = false;
                if(success)
                {
                    localStorage.clear();
                    Settings.set("PUNCHCLOCK_AUTH_TOKEN", token);
                    let message = '';
                    try {
                        actions.setPunchClock(punchClockData);
                        willNavigate = true;
                    } catch(error) {
                        newStateProps.wrongCredentials = true;
                        newStateProps.loginmessage = message;
                    }
                } else {
                    newStateProps.wrongCredentials = true;
                    newStateProps.loginmessage = message;
                }
                this.setState(newStateProps, () => {
                    if (willNavigate) navigate("/punchclock/timereport");
                });
            }, 0);
        });
    }
});


// PRIVATE FUNCTIONS
