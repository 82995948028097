import React, {Component} from 'react'

import styled, {css} from 'styled-components'

export default class IntegrationCards extends Component {

    constructor(props) {
        super(props)

        this.state = {
			integrationName: props.name,
            clientID: props.data.clientID || "",
            connected: !!props.data.ID,
            onClick: props.onClick
        };
    }

    render() {
        const { integrationName, clientID, onClick, connected } = this.state

        return (
            <Div className="tagContainer" onClick={ onClick }>
                <Div className="Timeline-toolbar punchClock-card">
                    <div className="context-info" style={{height: "100%"}}>

                        <Div $connected={connected} className="name">{integrationName}</Div>
                        <div className='assigned'>{clientID}</div>
                    </div>
                </Div>
            </Div>
        )
    }
}

const Div = styled.div`
    ${props => props.className.split(' ').includes('Timeline-toolbar') && css`
        border-bottom: 1px solid ${props.theme.background};
        background: ${props.theme.overlay} !important;
    `}

    ${props => props.className === 'name' &&
        (props.$connected ?
            css `color: ${props.theme.success}`
            : css `color: ${props.theme.danger}`
        )
    }
`;