import { configureStore } from "@reduxjs/toolkit"
import getRootReducer from "./reducers";

let store = null;

const createStore = () => {
    store = configureStore({
        reducer: getRootReducer(),
        devTools: process.env.NODE_ENV !== 'production',
    });

    return store
}

export const getStore = () => store;

export default createStore