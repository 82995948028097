import React from "react"
import EditableTable from "../../../../components/EditableTable/EditableTable"
import EditableField from "components/EditableField/EditableField"
// CLASSES
import styled, {css} from 'styled-components'

// COMPONENTS
import Switch from "components/Switch/Switch"
import TextField from "components/TextField/TextField"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import Button from "components/Button/Button"
import Select from "components/Select/Select"
import ShowMessageBox from "components/MessageBox/MessageBox"
import TimeField from 'react-simple-timefield'
import DatePicker from "components/DatePicker/DatePicker"

// HELPERS
import * as utils from "helpers/utils"
import { createClassName } from "helpers/utils"
import api from "helpers/api"
import i18n from "helpers/i18n"

export default class Reminders extends React.PureComponent {

    constructor(props) {
        super(props)
        const recipients = !props.isNew && props.recipients ? props.recipients.map((e, i) => ({
            selected: false,
            ...e
        })) : []
        let defaultProps = props.isNew ? {
            ID: -1,
            message: '',
            reminder: {
                type: 'weekly',
                data: {}
            },
            rule: 'none',
            time: '14:00'
        } : {
            ID: props.ID,
            message: props.message,
            reminder: props.reminder,
            rule: props.rule,
            time: props.time
        }
        
        this.state = {
            ...props,
            ...defaultProps,
            recipients: recipients,
            subMode: "settings"
        }
    }

    _deleteReminder = async (indexToDelete) => {
        const data = {ID: indexToDelete}
        await api('/globals/reminders/delete', data)

        if(this.props.onSaved) this.props.onSaved(this.state)
    }

    _onDeleteClick = () => {
        ShowMessageBox(this, {
            title : i18n('warnings', 'warning'),
            message : i18n('warnings', 'delete_reminder'),
            buttons : [
                {
                    label: i18n('warnings', 'confirm'),
                    appearance: 'primary',
                    onClick: () => {
                        if(this.state.ID !== -1) {
                            this._deleteReminder(this.state.ID)
                        }
                    }
                },
                {
                    label: i18n('warnings', 'cancel'),
                    appearance: 'danger',
                }
            ]
        })
    }

    _onAddRow = () => {
        let rows = [...this.state.recipients]
        rows.unshift({
            isNew: true,
            selected: false,
            user: "",
            name: "",
            ID: -1
        })
        this.setState({ recipients: rows })
    }

    _onAddAllHandler = async () => {
        const url = "globals/getall"
        const result = await api(url, { type: 'user' })
        const resources = Object.values(result.data)
        const { recipients } = this.state

        //Add selected to beginning of each row and filter out duplicates.
        const newRows = resources.map((e, i) => ({
            isNew: true,
            selected: false,
            ID: e.ID,
            name: e.name
        })).filter((item,i) => !recipients.find(e => e.name === item.name && !e.delete))

        this.setState({
            recipients: [...recipients, ...newRows]
        })
    }

    _onRemoveSelected = () => {
        const rows = [...this.state.recipients].map(row => {
            if (row.selected) return {...row, delete: true }
            else return {...row}
        })
        this.setState({ recipients: rows })
    }

    _onResourceSuggestionSelected = (resourceRowIndex, context) => {
        let newData = this.state.recipients
        const exists = newData.filter(function(e) {
            return e.ID === context.ID
        }).length > 0

        if(!exists) {
            newData.recipients[resourceRowIndex] = {
                name: context.label,
                ID: context.ID
            }
            this.setState({recipients: newData})
        }
    }

    _onRowChecked = (type, colKey, rowIndex, checked) => {
        const updatedRows = {...this.state.recipients}
        updatedRows[rowIndex]['selected'] = checked

        this.setState(prevState => ({
            recipients: [...prevState.recipients]
        }))
    }

    _onRuleChange = (selected) => {
        this.setState({rule: selected.value})
    }

    _onTextChange = (name, value) => {
        this.setState({ [name]: value })
    }

    _onSchedulerTypeChange = (type) => {
        let newData = {...this.state.reminder}
        newData.type = type.value
        this.setState({reminder: newData})
    }

    _onSchedulerSettingsChange = (data)  => {
        let newData = {...this.state.reminder}
        newData.data = data
        this.setState({reminder: newData})
    }

    _onAutoCompleteSelected = (e) => {
        this.forceUpdate()
    }

    _onSubmit = async () => {
        const data = this.state
        console.log(data)
        const result = await api('/globals/reminders/save', data)

        if (result.success) {
            this.setState({saved: true}, () => {
                setTimeout(async () => {
                    data.ID = parseInt(result.success, 10)

                    this.setState({saved: false})
                    this.props.onSaved(this.state)
                }, 1000)
            })
        }
    }

    onTimeChange = (time) => {
        this.setState({ time })
    }

    onSubModeChange = (mode) => this.setState({ subMode: mode })
    
    render() {
        const { message, recipients, reminder, rule, time, subMode, isNew, title } = this.state
        const classes = createClassName("ContextReviewContent-EditToggler", {
            "active": true,
        })

        const footerTemplate = (
            <div style={{ width: "100%" }}>
                <StackLayout justifyContent="space-between" rowWrap fullWidth>
                    <Button 
                        size="small" style = {{margin: '5px 0'}}
                        label={ i18n("general", "remove_selected") }
                        appearance="danger"
                        onClick={ this._onRemoveSelected } />
                    <Button 
                        size="small"
                        label={ i18n("globals", "add_all_resources") }
                        appearance="secondary"
                        filled
                        style={{margin: '5px 0'}}
                        onClick={ this._onAddAllHandler.bind(this,'resources') } />
                    <Button 
                        size="small"
                        label={ i18n("globals", "add") }
                        appearance="secondary"
                        filled
                        style={{margin: '5px 0'}}
                        onClick={ this._onAddRow } />
                </StackLayout>
            </div>
        )

        return (
            <div>
                <Div className='globalreminders' >
                    <Title>
                        <EditableField placeholder={ i18n('login', "title") } name="title" value={ title ? title : "" } type="text" onChange={ this._onTextChange } />
                    </Title>
                    <Div className={classes}>
                        <EditSubModeNav
                            subMode={subMode}
                            onChange={this.onSubModeChange} />
                    </Div>
                    {subMode === "recipients" && 
                        <EditableTable
                            context={ this }
                            type={ "reminders" }
                            rows={ recipients.filter(e => !e.delete) }
                            columns={[
                                { key: "selected",  label: "", width: "10%" },
                                { key: "users", name: "users",   label: ``, width: "90%", }
                            ]}
                            onRowChecked={ this._onRowChecked }
                            footerTemplate={ footerTemplate }
                            onAutoCompleteSelected = { this._onAutoCompleteSelected } />
                    }

                    {subMode === "settings" &&
                        <React.Fragment>
                            <Dropdown>
                                <Select name="rule"
                                    type="reminder"
                                    label={i18n('global_reminders', "rule")}
                                    selectedIndex= { 
                                        rule === 'missing' ? 0 :
                                        rule === 'is_unassigned' ? 1 :
                                        rule === 'unassigned_in_a_month' ? 2 :
                                        rule === 'none' ? 3 : 0 }
                                    onChange={ (value) => this._onRuleChange(value) }
                                    options={[
                                        { value: "missing", label:  i18n("global_reminders", 'rule_missing_timereports') },
                                        { value: "is_unassigned",  label: i18n("global_reminders", 'rule_unassigned')},
                                        { value: "unassigned_in_a_month",  label: i18n("global_reminders", 'rule_unassigned_in_month')},
                                        { value: "none",   label: i18n('monitor', 'none') }
                                    ]} />
                            </Dropdown>

                            <Message>
                                <EditableField label={ i18n('global_reminders', "message") } name="message" value={ message ? message : "" } type="text" onChange={ this._onTextChange } />
                            </Message>
                            
                            <Scheduler
                                settings={ reminder }
                                onSchedulerTypeChange={ (type) => this._onSchedulerTypeChange(type)}
                                _onSchedulerSettingsChange={(data) => this._onSchedulerSettingsChange(data)} />

                            <TimeFieldContainer>
                                <Label>{ i18n("monitor", "time") }</Label>
                                <TimeField style={{ width:"10%" }}
                                    value={time}
                                    onChange={ (_, time) => this.onTimeChange(time)} />
                            </TimeFieldContainer>
                        </React.Fragment>
                    }
             
                    <StackLayout orientation="horizontal">
                        {!isNew && <Button filled
                            label={ i18n("general", "delete") }
                            appearance="danger"
                            onClick={this._onDeleteClick.bind(this)} /> 
                        }
                        <Button success = {this.state.saved}
                            filled label={ i18n('general', isNew ? 'create' : 'save') }
                            appearance="primary"
                            onClick = { () => this._onSubmit()} />
                    </StackLayout>
                </Div>
                { this.state.MessageBox }
            </div>
        )
    }
}

class Scheduler extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            interval: props.settings.type,
            settings: props.settings.data,
        }
    }

    _onSchedulerTypeChange = (e) => {
        this.setState({interval: e.value}, () => {
            if (["monthly", "yearly"].includes(this.state.interval) && !this._schedulerSetting(this.state.interval, `${this.state.interval}_format`)) this._onSchedulerSettingsChange(`${this.state.interval}_format`, "date")
            this.props.onSchedulerTypeChange(e)
        })
    }

    _onSchedulerSettingsChange = (key, value) => {
        if (key === 'interval_start_date') value = value.target.value

        const { interval } = this.state

        let settings = {...this.state.settings}
        let changedSettings = settings[interval]

        changedSettings = changedSettings ? {...changedSettings, [key]: value  } : { [key]: value }

        settings[interval] = changedSettings

        this.setState({
          settings: settings
        }, () => this.props._onSchedulerSettingsChange(settings))
    }

    _schedulerSetting = (interval, key) => {
        return this.state.settings[interval] ? this.state.settings[interval][key] : ''
    }

    render() {
        const { interval, settings } = this.state
        const classes = createClassName("ContextReviewContent-EditToggler", {
            "active": true,
        })

        return (
            <div>
                <Div className={ classes } />
                <Div className='globalreminders' style={{marginLeft: "10px"}}>
                    <Dropdown>
                        <Select name="interval"
                            type="reminder"
                            label={ i18n("monitor", "interval") }
                            selectedIndex={
                                interval === 'daily'    ? 0 :
                                interval === 'weekly'   ? 1 :
                                interval === 'monthly'  ? 2 :
                                interval === 'yearly'   ? 3 :
                                0
                            }
                            onChange={ 
                                this._onSchedulerTypeChange
                            }
                            options={[
                                { value: "daily",   label:  i18n("global_reminders", "daily") },
                                { value: "weekly",  label:  i18n("global_reminders", "weekly")},
                                { value: "monthly",label:  i18n("global_reminders", "monthly") },
                                { value: "yearly", label:  i18n("global_reminders", "yearly")},
                            ]} />
                    </Dropdown>
                    <div style={{margin: "15px", marginTop: interval === 'daily' ? "0px" : "15px"}}>
                        {interval === 'daily' ?
                            <Div className='globalreminders'>
                                <div className="Rtable Rtable--3cols one-line-container">
                                    <div className="Rtable-cell">
                                        <TextField  
                                            type="number"
                                            label={ i18n("global_reminders", "days") }
                                            value={ parseInt(this._schedulerSetting('daily','every_days'), 10) || "" }
                                            onChange={ (value) => utils.isNumber(value) || value === '' ? this._onSchedulerSettingsChange('every_days', value) : null }/>
                                    </div>
                                    <div className="Rtable-cell">
                                        <DatePicker label={i18n("global_reminders", "starting_from")} date={settings[interval] && settings[interval].interval_start_date ? utils.moment(settings[interval].interval_start_date) : utils.moment()} onDateChange={this._onSchedulerSettingsChange.bind(this, 'interval_start_date')} />
                                    </div>

                                    <div className="Rtable-cell" />
                                </div>
                            </Div>
                        : interval === 'weekly' ?
                            <Div className='globalreminders'>
                                <div className="Rtable Rtable--7cols">
                                    <div className="Rtable-cell">
                                        <Switch small label={ i18n("global_reminders", "monday") }
                                            checked={ this._schedulerSetting('weekly','checked_every_monday') }
                                            onChange={ (checked) =>
                                                    this._onSchedulerSettingsChange('checked_every_monday', checked)
                                            } />
                                    </div>
                                    <div className="Rtable-cell">
                                        <Switch small label={ i18n("global_reminders", "tuesday") }
                                            checked={ this._schedulerSetting('weekly','checked_every_tuesday') }
                                            onChange={ (checked) =>
                                                this._onSchedulerSettingsChange('checked_every_tuesday', checked)
                                            } />
                                    </div>
                                    <div className="Rtable-cell">
                                        <Switch small label={ i18n("global_reminders", "wednesday") }
                                            checked={ this._schedulerSetting('weekly','checked_every_wednesday') }
                                            onChange={ (checked) =>
                                                this._onSchedulerSettingsChange('checked_every_wednesday', checked)
                                            } />
                                    </div>
                                    <div className="Rtable-cell">
                                        <Switch small label={ i18n("global_reminders", "thursday") }
                                            checked={ this._schedulerSetting('weekly','checked_every_thursday') }
                                            onChange={ (checked) =>
                                                this._onSchedulerSettingsChange('checked_every_thursday', checked)
                                            } />
                                    </div>
                                    <div className="Rtable-cell">
                                        <Switch small label={ i18n("global_reminders", "friday") }
                                            checked={ this._schedulerSetting('weekly','checked_every_friday') }
                                            onChange={ (checked) =>
                                                this._onSchedulerSettingsChange('checked_every_friday', checked)
                                            } />
                                    </div>
                                    <div className="Rtable-cell">
                                        <Switch small label={ i18n("global_reminders", "saturday") }
                                            checked={ this._schedulerSetting('weekly','checked_every_saturday') }
                                            onChange={ (checked) =>
                                                this._onSchedulerSettingsChange('checked_every_saturday', checked)
                                            } />
                                    </div>
                                    <div className="Rtable-cell">
                                        <Switch small label={ i18n("global_reminders", "sunday") }
                                            checked={ this._schedulerSetting('weekly','checked_every_sunday') }
                                            onChange={ (checked) =>
                                                this._onSchedulerSettingsChange('checked_every_sunday', checked)
                                            } />
                                    </div>
                                </div>
                            </Div>
                        : interval === 'monthly' ?
                            <Div className='globalreminders'>
                                <Dropdown>
                                    <Select 
                                        type="reminder"
                                        name="mode"
                                        label={  i18n("global_reminders", "format") }
                                        selectedIndex={
                                            this._schedulerSetting('monthly','monthly_format') === 'date' ? 0 :
                                            this._schedulerSetting('monthly','monthly_format') === 'weekday' ? 1 :
                                            0
                                        }
                                        onChange={ (selectedOption) => {
                                            this._onSchedulerSettingsChange('monthly_format', selectedOption.value)}
                                        }
                                        options={[
                                          { value: "date",   label:  i18n("general", "date") },
                                          { value: "weekday",  label:  i18n("general", "weekday")}
                                        ]} />
                                </Dropdown>
                                    
                                {this._schedulerSetting('monthly','monthly_format') === 'date' &&
                                    <div className="Rtable Rtable--4cols one-line-container">
                                        <div className="Rtable-cell">
                                            <TextField  
                                                type="number"
                                                label={ i18n("global_reminders", 'day') }
                                                value={ this._schedulerSetting('monthly','monthly_text_day') || ""}
                                                onChange={ (value) => value <= 31 ? this._onSchedulerSettingsChange('monthly_text_day', value) : null } />
                                        </div>
                                        <div className="Rtable-cell">
                                            <TextField  
                                                type="number"
                                                label={ i18n("global_reminders", "months") }
                                                value={ this._schedulerSetting('monthly','monthly_text_month') || ""}
                                                onChange={ (value) => this._onSchedulerSettingsChange('monthly_text_month', value) }/>
                                        </div>
                                        <div className="Rtable-cell">
                                            <DatePicker label={i18n("global_reminders", "starting_from")} date={settings[interval] && settings[interval].interval_start_date ? utils.moment(settings[interval].interval_start_date) : utils.moment()} onDateChange={this._onSchedulerSettingsChange.bind(this, 'interval_start_date')} />
                                        </div>
                                    </div>
                                }
                                {this._schedulerSetting('monthly','monthly_format') === 'weekday' && 
                                    <div className="Rtable Rtable--5cols one-line-container">
                                        <div className="Rtable-cell">
                                            <Dropdown>
                                                <Select 
                                                    type="reminder"
                                                    label={  i18n("global_reminders", "day") }
                                                    selectedIndex={
                                                        this._schedulerSetting('monthly','monthly_select_type') === 'first' ? 0 :
                                                        this._schedulerSetting('monthly','monthly_select_type') === 'second' ? 1 :
                                                        this._schedulerSetting('monthly','monthly_select_type') === 'third' ? 2 :
                                                        this._schedulerSetting('monthly','monthly_select_type') === 'fourth' ? 3 :
                                                        0
                                                    }
                                                    onChange={ (selectedOption, selectedIndex) =>{
                                                    this._onSchedulerSettingsChange('monthly_select_type', selectedOption.value)}
                                                    }
                                                    options={[
                                                    { value: "first",   label:  i18n("global_reminders", "first") },
                                                    { value: "second",  label:  i18n("global_reminders", "second")},
                                                    { value: "third",   label:  i18n("global_reminders", "third") },
                                                    { value: "fourth",  label:  i18n("global_reminders", "fourth")}
                                                    ]}/>
                                            </Dropdown>
                                        </div>
                                        <div className="Rtable-cell">
                                            <Dropdown>
                                                <Select
                                                    type="reminder"
                                                    label={  "Weekday" }
                                                    selectedIndex={
                                                        this._schedulerSetting('monthly','monthly_select_weekday') === 'monday'     ? 0 :
                                                        this._schedulerSetting('monthly','monthly_select_weekday') === 'tuesday'    ? 1 :
                                                        this._schedulerSetting('monthly','monthly_select_weekday') === 'wednesday'  ? 2 :
                                                        this._schedulerSetting('monthly','monthly_select_weekday') === 'thursday'   ? 3 :
                                                        this._schedulerSetting('monthly','monthly_select_weekday') === 'friday'     ? 4 :
                                                        this._schedulerSetting('monthly','monthly_select_weekday') === 'saturday'   ? 5 :
                                                        this._schedulerSetting('monthly','monthly_select_weekday') === 'sunday'     ? 6 :
                                                        0
                                                    }
                                                    onChange={ (selectedOption, selectedIndex) =>{
                                                    this._onSchedulerSettingsChange('monthly_select_weekday', selectedOption.value)}
                                                    }
                                                    options={[
                                                    { value: "monday",   label:  i18n("global_reminders", "monday") },
                                                    { value: "tuesday",  label:  i18n("global_reminders", "tuesday")},
                                                    { value: "wednesday",label:  i18n("global_reminders", "wednesday") },
                                                    { value: "thursday", label:  i18n("global_reminders", "thursday")},
                                                    { value: "friday",   label:  i18n("global_reminders", "friday")},
                                                    { value: "saturday", label:  i18n("global_reminders", "saturday")},
                                                    { value: "sunday",   label:  i18n("global_reminders", "sunday")}
                                                    ]} />
                                            </Dropdown>
                                        </div>
                                        <div className="Rtable-cell">
                                            <TextField  
                                                type="number"
                                                label={ i18n("global_reminders", "months") }
                                                value={ this._schedulerSetting('monthly','monthly_text_every_month') || ""}
                                                onChange={ (value) => this._onSchedulerSettingsChange('monthly_text_every_month', value) }/>
                                        </div>
                                        <div className="Rtable-cell">
                                            <DatePicker label={i18n("global_reminders", "starting_from")} date={settings[interval] && settings[interval].interval_start_date ? utils.moment(settings[interval].interval_start_date) : utils.moment()} onDateChange={this._onSchedulerSettingsChange.bind(this, 'interval_start_date')} />
                                        </div>
                                    </div>
                                }
                            </Div>
                        : interval === 'yearly' ?
                            <Div className='globalreminders'>
                                <Dropdown>
                                    <Select 
                                        type="reminder"
                                        label={ i18n("global_reminders", "format") }
                                        selectedIndex={
                                            this._schedulerSetting('yearly','yearly_format') === 'date'   ? 0 :
                                            this._schedulerSetting('yearly','yearly_format') === 'weekday'  ? 1 :
                                            0
                                        }
                                        onChange={ (selectedOption) =>{
                                          this._onSchedulerSettingsChange('yearly_format', selectedOption.value)}
                                        }
                                        options={[
                                          { value: "date",   label:  i18n("general", "date") },
                                          { value: "weekday",  label: i18n("general", "weekday")},
                                        ]} />
                                </Dropdown>

                                {this._schedulerSetting('yearly','yearly_format') === 'date' && 
                                    <div style={{display:'inline-flex'}}>
                                        <Dropdown>
                                            <Select 
                                                type="reminder"
                                                label={  i18n("global_reminders", "day") }
                                                selectedIndex={
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'january'   ? 0 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'february'  ? 1 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'march'     ? 2 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'april'     ? 3 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'may'       ? 4 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'june'      ? 5 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'july'      ? 6 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'august'    ? 7 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'september' ? 8 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'october'   ? 9 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'november'  ? 10 :
                                                    this._schedulerSetting('monthly','yearly_select_month') === 'december'  ? 12 :
                                                    0
                                                }
                                                onChange={ (selectedOption, selectedIndex) =>{
                                                  this._onSchedulerSettingsChange('yearly_select_month', selectedOption.value)}
                                                }
                                                options={[
                                                  { value: "january",   label:  i18n("global_reminders", "january") },
                                                  { value: "february",  label:  i18n("global_reminders", "february")},
                                                  { value: "march",     label:  i18n("global_reminders", "march") },
                                                  { value: "april",     label:  i18n("global_reminders", "april")},
                                                  { value: "may",       label:  i18n("global_reminders", "may")},
                                                  { value: "june",      label:  i18n("global_reminders", "june")},
                                                  { value: "july",      label:  i18n("global_reminders", "july")},
                                                  { value: "august",    label:  i18n("global_reminders", "august")},
                                                  { value: "september", label:  i18n("global_reminders", "september")},
                                                  { value: "october",   label:  i18n("global_reminders", "october")},
                                                  { value: "november",  label:  i18n("global_reminders", "november")},
                                                  { value: "december",  label:  i18n("global_reminders", "december")},
                                                ]}/>
                                        </Dropdown>
                                        <TextField  
                                            type="number"
                                            label={ i18n("global_reminders", "date") }
                                            value={ this._schedulerSetting('yearly','yearly_text_date') || ""}
                                            onChange={ (value) => utils.isNumber(value) || value === '' ? this._onSchedulerSettingsChange('yearly_text_date', value) : null } />
                                    </div>
                                }
                                {this._schedulerSetting('yearly','yearly_format') === 'weekday' &&
                                    <div style={{display:'inline-flex'}}>
                                        <Dropdown>
                                            <Select 
                                                type="reminder"
                                                label={ i18n("global_reminders", "day") }
                                                selectedIndex={
                                                    this._schedulerSetting('yearly','yearly_select_type') === 'first'  ? 0 :
                                                    this._schedulerSetting('yearly','yearly_select_type') === 'second' ? 1 :
                                                    this._schedulerSetting('yearly','yearly_select_type') === 'third'  ? 2 :
                                                    this._schedulerSetting('yearly','yearly_select_type') === 'fourth' ? 3 :
                                                    0
                                                }
                                                onChange={ (selectedOption) =>{
                                                    this._onSchedulerSettingsChange('yearly_select_type', selectedOption.value)}
                                                }
                                                options={[
                                                  { value: "first",   label:  i18n("global_reminders", "first") },
                                                  { value: "second",  label:  i18n("global_reminders", "second")},
                                                  { value: "third",   label:  i18n("global_reminders", "third") },
                                                  { value: "fourth",  label:  i18n("global_reminders", "fourth")}
                                                ]}/>
                                        </Dropdown>
                                        <Dropdown>
                                            <Select 
                                                type="reminder"
                                                label={  i18n("general", "weekday") }
                                                selectedIndex={
                                                    this._schedulerSetting('yearly','yearly_select_weekday') === 'monday'     ? 0 :
                                                    this._schedulerSetting('yearly','yearly_select_weekday') === 'tuesday'    ? 1 :
                                                    this._schedulerSetting('yearly','yearly_select_weekday') === 'wednesday'  ? 2 :
                                                    this._schedulerSetting('yearly','yearly_select_weekday') === 'thursday'   ? 3 :
                                                    this._schedulerSetting('yearly','yearly_select_weekday') === 'friday'     ? 4 :
                                                    this._schedulerSetting('yearly','yearly_select_weekday') === 'saturday'   ? 5 :
                                                    this._schedulerSetting('yearly','yearly_select_weekday') === 'sunday'     ? 6 :
                                                    0
                                                }
                                                onChange={ (selectedOption, selectedIndex) =>{
                                                    this._onSchedulerSettingsChange('yearly_select_weekday', selectedOption.value)}
                                                }
                                                options={[
                                                  { value: "monday",   label:  i18n("global_reminders", "monday") },
                                                  { value: "tuesday",  label:  i18n("global_reminders", "tuesday")},
                                                  { value: "wednesday",label:  i18n("global_reminders", "wednesday") },
                                                  { value: "thursday", label:  i18n("global_reminders", "thursday")},
                                                  { value: "friday",   label:  i18n("global_reminders", "friday")},
                                                  { value: "saturday", label:  i18n("global_reminders", "saturday")},
                                                  { value: "sunday",   label:  i18n("global_reminders", "sunday")}
                                                ]}/>
                                        </Dropdown>
                                        <Dropdown>
                                            <Select 
                                                type="reminder"
                                                label={  i18n("monitor", "month") }
                                                selectedIndex={
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'january'    ? 0 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'february'   ? 1 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'march'      ? 2 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'april'      ? 3 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'may'        ? 4 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'june'       ? 5 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'july'       ? 6 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'august'     ? 7 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'september'  ? 8 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'october'    ? 9 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'november'   ? 10 :
                                                    this._schedulerSetting('yearly','yearly_select_month') === 'december'   ? 11 :
                                                    0
                                                }
                                                onChange={ (selectedOption) =>{
                                                    this._onSchedulerSettingsChange('yearly_select_month', selectedOption.value)}
                                                }
                                                options={[
                                                  { value: "january",   label:  i18n("global_reminders", "january") },
                                                  { value: "february",  label:  i18n("global_reminders", "february")},
                                                  { value: "march",     label:  i18n("global_reminders", "march") },
                                                  { value: "april",     label:  i18n("global_reminders", "april")},
                                                  { value: "may",       label:  i18n("global_reminders", "may")},
                                                  { value: "june",      label:  i18n("global_reminders", "june")},
                                                  { value: "july",      label:  i18n("global_reminders", "july")},
                                                  { value: "august",    label:  i18n("global_reminders", "august")},
                                                  { value: "september", label:  i18n("global_reminders", "september")},
                                                  { value: "october",   label:  i18n("global_reminders", "october")},
                                                  { value: "november",  label:  i18n("global_reminders", "november")},
                                                  { value: "december",  label:  i18n("global_reminders", "december")},
                                                ]}/>
                                        </Dropdown>
                                    </div>
                                }
                            </Div>
                        : null }
                    </div>
                </Div>
            </div>
        )
    }
}

function EditSubModeNav({ subMode: activeSubMode, onChange }) {
    const Item = ({ label, subMode}) => {
        return (
            <Div className={ createClassName("item", { "active": activeSubMode === subMode }) } onClick={ onChange && (() => onChange(subMode)) } >{ label }</Div>
        )
    }

    return (
        <Div className="togglerShadow">
            <nav>
                <Item label={ i18n("general", "settings") }  subMode="settings" />
                <Item label={ i18n("global_reminders", "recipients") }   subMode="recipients" />
            </nav>
        </Div>
    )
}

const Div = styled.div`
    .DatePicker {
        border-bottom: 1px solid ${props => props.theme.background};
        input {
            padding-left: 12px;
        }
    }
    .TextField-label {
        padding: 15px 12px;
    }
    .TextField-input {
        padding-bottom: 0px;
        font-size: 16px;
    }
    ${props => props.className.split(' ').includes('globalreminders') && css`
        background: ${props.theme.overlay};
    `}
    ${props => props.className.split(' ').includes('TimeReportOverview-top-bar') && css`
        background: ${props.theme.overlay};
    `}
    ${props => props.className.split(' ').includes('top') && css`
        border-bottom: 1px solid ${props.theme.background};
    `}
    ${props => props.className.split(' ').includes('avatar') && css`
        background: ${props.theme.label} 50% 50% no-repeat;
    `}

    ${props => props.className.split(' ').includes('item') && css`
        color: ${props.theme.label};
    `}

    ${props =>  props.className.split(' ').includes('item') &&
                props.className.split(' ').includes('active')
                && css`
        color: ${props.theme.primary};
    `}

    ${props => props.className.split(' ').includes('togglerShadow') && css`
        background: ${props.theme.background};
        :hover {
            background: ${props.theme.background};
        }
    `}

    ${props => props.active && css`
        background: ${props.theme.primary};
        :hover {
            background: ${props.theme.primary};
        }
    `}

    ${props => props.className.split(' ').includes('icon') && css`
        color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('text') && css`
        color: ${props.theme.subtitle};
    `}
    ${props => props.className.split(' ').includes('color_picker') && css`
        @media (max-width: 640px) {
            transform: translate(0,8%) !important;
        }
    `}

`

const Dropdown = styled.div`
    margin-top: 30px;
    .Select {
        margin-top: -4px;
        .Select-label {
            top: -20px;
            left: -10px;
            z-index: 1;
            font-size: 12px;
            font-weight: 400;
        }
        .Select-selected {
            padding-left: 25px;
        }
        .Select-arrow {
            height: 20px;
            left: 0;
        }
    }
    ${props => props.children.props.name === "rule" && css`
        margin-left: 10px;
        margin-bottom: 10px;
    `}
`
const Title = styled.div`
    margin: auto; 
    max-width: 480px;
    .TextField-input {
        font-size: 30px; 
        font-weight: 500;
        text-align: center;
        color: ${props => props.theme.primary}
    }
`

const TimeFieldContainer = styled.div`
    margin-left: 10px;
    input {
        border: none;
        border-bottom: 1px solid ${props => props.theme.background};
        background: transparent;
        :focus {
            border-color: ${props => props.theme.secondary};
        }
    }
`
const Label = styled.p`
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 400;
    color: ${props => props.theme.label};
`

const Message = styled.div`
    margin-bottom: 10px; 
    .TextField {
        max-width: 100%;
    }
`