import React from 'react'
import styled, {css} from 'styled-components'
import Swiper from 'swiper'

// HELPERS
import * as utils from "helpers/utils"


export default class NumberSlider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedNumber: props.lastReportedHour || 0,
            inTransition: false
        }
        
        this.numbers = []
        for (let i = 0; i <= 24; i += 0.5) { this.numbers.push(i) }
    }

    componentDidMount() {
        this.mySwiper = new Swiper('#swiper-container', {
            direction: 'vertical',
            initialSlide: this.state.selectedNumber * 2,
            spaceBetween: 0,
            slidesPerView: 3,
            slidesPerGroup: 1,
            centeredSlides: true,
            freeMode: true,
            freeModeMomentumBounce: true,
            
            freeModeMomentumRatio: utils.isMobileDevice() ? 0.4 : 1,  //0.7:1      0.45
            freeModeMomentumVelocityRatio: utils.isMobileDevice() ? 0.9 : 1, //1    0.85
            freeModeMinimumVelocity: utils.isMobileDevice() ? 0.05 : 0.2, //0.1:0.2

            freeModeSticky: true,
            grabCursor: true,
            // slideToClickedSlide: true,
            preventClicks: false,
            on: {
                slideChange: () => this.mySwiper && this.setState({ selectedNumber: this.mySwiper.realIndex / 2 }),
                touchStart: (e) => {
                    if (this.mySwiper && this.mySwiper.realIndex !== parseFloat?.(e.target?.innerText) * 2 && this.state.inTransition) {
                        this.mySwiper.slideTo(parseFloat(e.target.innerText) * 2, 200)
                        this.setState({ selectedNumber: parseFloat(e.target.innerText) })
                    }
                },
                slideChangeTransitionStart: () => this.setState({ inTransition: true }),
                slideChangeTransitionEnd: () => this.setState({ inTransition: false }),
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedNumber } = this.state, { onChange } = this.props
        if (selectedNumber !== prevState.selectedNumber && typeof selectedNumber === 'number' && onChange) onChange(selectedNumber)
    }

    render() {
        const { selectedNumber } = this.state
        return (
            <StyledNumberSlider id='swiper-container'>
                <div className='swiper-wrapper' tabIndex='0' onWheel={(e) => {
                    if (e.deltaY > 0) { e.key = 'ArrowDown'; this._onKeyPress(e) }
                    else if (e.deltaY < 0) { e.key = 'ArrowUp'; this._onKeyPress(e) }}}>
                    {this.numbers.map((number, index) => (
                        <StyledNumber key={index} id={`swiper-slide-${number}`}
                            $classes={utils.createClassName('swiper-slide', {
                                'highlighted': selectedNumber === number,
                                'prev': selectedNumber - number > 0,
                                'next': selectedNumber - number < 0
                            }).split(' ')}
                            className={ utils.createClassName('swiper-slide', {
                                'highlighted': selectedNumber === number,
                                'prev': selectedNumber - number > 0,
                                'next': selectedNumber - number < 0
                            })}>
                            <p>{number}</p>
                        </StyledNumber>
                    ))}
                </div>
            </StyledNumberSlider>
        )
    }

    _onKeyPress = (e) => {
        const { state, props, mySwiper } = this
        const { selectedNumber } = state, { onEnter, onEscape } = props, { key } = e
        const isDecimal = key === 'Decimal' || key === '.' || key === ','
        let currentSelectedNumber = selectedNumber

        if (key === 'ArrowUp' && currentSelectedNumber !== 0) {
            mySwiper.slidePrev(200)
            this.setState({ selectedNumber: currentSelectedNumber - 0.5 })
        } else if (key === 'ArrowDown' && currentSelectedNumber !== 24) {
            mySwiper.slideNext(200)
            this.setState({ selectedNumber: currentSelectedNumber + 0.5 })
        } else if (key === " ") {
            //do nothing
        } else if (!isNaN(key) || isDecimal) {
            if (!this._inputNumber) this._inputNumber = ''

            if (this._inputNumber.includes('.')) {
                key <= 2 ? this._inputNumber += ''
                : key > 2 && key < 8 ? this._inputNumber += '5'
                : this._inputNumber = (selectedNumber + 1) + '.'
            } else {
                this._inputNumber += isDecimal ? '.' : key
            }
            
            if (this._inputNumber > 24) this._inputNumber = '24'

            mySwiper.slideTo(parseFloat(this._inputNumber * 2), 200)
            this.setState({ selectedNumber: parseFloat(this._inputNumber) })

            if (this._inputTimeout) clearTimeout(this._inputTimeout)
            this._inputTimeout = setTimeout(() => {
                delete this._inputNumber
                delete this._inputTimeout
            }, 500)
        } else if (key === 'Enter') {
            if (onEnter) onEnter(selectedNumber)
        } else if (key === 'Escape') {
            if(onEscape) onEscape()
        } else if (key === "Unidentified") {
            mySwiper.slideTo(parseFloat(16), 200)
            this.setState({ selectedNumber: 8 })
        }
    }
}

const StyledNumberSlider = styled.div`
    // background: ${props => props.theme.background};
    // box-shadow: 0 0 0 2px ${props => props.theme.secondary};
`;

const StyledNumber = styled.div`
    ${props => props.$classes.includes('highlighted') && css`
        color: ${props.theme.primary}
    `}
`