// CLASSES
import Settings from "classes/Settings";


export default function i18n(category, key) {
    const fallbackValue = `{{ ${category}.${key} }}`;

    try {
        return _getLanguage()[category][key] || fallbackValue;
    } catch(e) {
        return fallbackValue;
    }
}


// PRIVATE FUNCTIONS & DATA
const _getLanguage = () => {
    const langCode = Settings.get("LANGUAGE");
    return _importedLanguages[langCode] || (_importedLanguages[langCode] = require(`i18n/${langCode}.json`));
};

const _importedLanguages = {};
