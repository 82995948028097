import React from 'react'

import styled, {css} from 'styled-components'

import { getAssetsURIForPath } from "helpers/api"

const CompanyCard = ({ data, onContextReviewClick }) => {
    const { avatarPath, Name, projects, activeProjects } = data

    return <Div className="Timeline-toolbar company-card" onClick={_onContextReviewClick.bind(this, data, onContextReviewClick)}>
        <div className="context-info">
            <Div className="avatar" style={{ backgroundImage: `url(${getAssetsURIForPath(avatarPath)})` }} />
            <Div className="name">{ Name }</Div>

            <div className='projects'>
                {projects.map((e, i) => {
                    if (i < 3) return <p key={e.ID + 'c' + i}>{e.Name}</p>
                    return ''
                })}
                {projects.length > 3 && (<i className="fas fa-ellipsis-v"></i>)}
                <P $activeProjects={activeProjects}>Active projects: {activeProjects}</P>
            </div>
        </div>
    </Div>
}

const _onContextReviewClick = (data, onContextReviewClick) => {
    if (onContextReviewClick) onContextReviewClick(data.ID, data, 'company')
}

const Div = styled.div`
    ${props => props.className.split(' ').includes('Timeline-toolbar') && css`
        border-bottom: 1px solid ${props.theme.background};
    `}
    ${props => props.className.split(' ').includes('avatar') && css`
        
    `}
    ${props => props.className.split(' ').includes('name') && css`
        color: ${props.theme.primary};
    `}
    ${props => props.className.split(' ').includes('company-card') && css`
        background: ${props.theme.overlay};
    `}
`

const P = styled.p`
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 100%;
    text-align: center;
    color: ${props => props.$activeProjects > 0 ? props.theme.success : props.theme.danger};
` 

export default CompanyCard