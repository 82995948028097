import {useState, useEffect, useMemo} from "react";
import * as utils from "helpers/utils"

const Stopwatch = ({ hours, minutes, seconds }) => {
    const initialTime = useMemo(() => (
        utils.moment.duration({ hours, minutes, seconds })
    ), [hours, minutes, seconds])
    const [time, setTime] = useState(initialTime)

    useEffect(() => {
        const startTime = Date.now()

        // We need to calculate elapsed time instead of adding time directly
        // becuase interval / timeout stops working after a while of the browser window is not in focus
        const interval = setInterval(() => {
            const elapsed = Date.now() - startTime
            const newTime = utils.moment.duration(initialTime.asSeconds() + elapsed / 1000, 'seconds')
            setTime(newTime)
        }, 1000)

        return () => {
            clearTimeout(interval)
        }
      }, [initialTime])

    const hoursDisplay = String(Math.floor(time.asHours())).padStart(2, "0")
    const minutesDisplay = String(time.minutes()).padStart(2, "0")
    const secondsDisplay = String(time.seconds()).padStart(2, "0")

    return (
        <div className="stopwatch">
            {`${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`}
        </div>
    )
};

export default Stopwatch
