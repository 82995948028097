import React,{ Component } from "react"
import ShowMessageBox from "components/MessageBox/MessageBox"

import Settings from "classes/Settings"
import i18n from "helpers/i18n"
import * as utils from "helpers/utils"

export default class ImageUpload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            MessageBox: null
        }
    }

    _handleSubmit(e) {
        e.preventDefault()
    }

    _handleImageChange(e) {
        e.preventDefault()

        let maxFileSize = 50000000 // 50MB
        let reader = new FileReader()
        let file = e.target.files[0]

        reader.onloadend = () => {
            const { onPreview } = this.props
            if (onPreview) onPreview(file, reader.result)
        }

        if (file.size > maxFileSize) {
            ShowMessageBox(this, {
                message: utils.format(i18n("errors", "bigImage"),[maxFileSize / 1000000])
            })
        } else {
            reader.readAsDataURL(file)
        }
    }

    render() {
        let src = this.props.src && this.props.src.slice(-5) !== "/null" && this.props.src.slice(-10) !== "/undefined" ? this.props.src : null

        return (
            <div className="previewComponent">
                <form className="form" action="upload.php" method="POST" style={{borderColor: Settings.getGlobalColor('subtitle')}}
                    onSubmit={(e)=>this._handleSubmit(e)}
                    >
                    
                    {src && <div style={{position: "relative", width: "200px"}}>
                        <input className="input" type="file" onChange={(e)=>this._handleImageChange(e)}/>
                        <img src={`${src}`} alt="" style={{width: "200px", height: "auto"}}/>
                    </div>}

                    {!src && <div style={{position: "relative", height: "200px", width: "200px", background: Settings.getGlobalColor('background')}}>
                        <input className="input" type="file" onChange={(e)=>this._handleImageChange(e)}/> 
                        <p className="p" style={{color: Settings.getGlobalColor('default')}}>
                            Drag your files here or click in this area.
                        </p>
                    </div>}
                </form>
                {this.state.MessageBox}
            </div>
        )
    }
}
