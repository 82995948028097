import React from "react"
import TextField from "components/TextField/TextField"
import { createClassName } from "helpers/utils"
import PropTypes from "prop-types"

export default class EditableField extends React.PureComponent {
    static propTypes = {
        type: PropTypes.string.isRequired,
        split: PropTypes.bool,
        split2: PropTypes.bool,
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        onChange: PropTypes.func
    }

    render() {
        const { className, split, split2, name, label, value, disabled, table, onChange, required, placeholder, type, autoComplete } = this.props
        const classes = createClassName(className, {
            "split": split,
            "split2": split2,
            "table": table
        })
            return (
                <TextField
                    className={classes}
                    name={name}
                    placeholder={placeholder}
                    type={type !== "monitor" ? type : ""}
                    label={label}
                    value={value}
                    onChange={table ? onChange : this._onTextFieldChange}
                    disabled={disabled}
                    table={table}
                    autoComplete={autoComplete}
                    required={required} />
            )
    }

    // Internal methods
    _onTextFieldChange = (value) => {
        const { name, onChange, type, index } = this.props
        if (type === "monitor") {
            if (onChange) onChange(value, name, index)
        } else {
            if (onChange) onChange(name, value)
        }
        
    }
}