import React from "react";
import styled, {css} from 'styled-components';
import Autocomplete from "components/Autocomplete/Autocomplete"

// HELPERS
import * as utils from "helpers/utils";

export default class TagField extends React.PureComponent {
    uid = utils.createUniqueId("TagField-")
    state = {
        focus: false,
        value: false
    }

    autocompleteRef = React.createRef()

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const { label, required, onTagClick, onTagRemove, removable, showAmp } = this.props;
        const { focus, value } = this.state
        const tags = this.props.tags ? this._sanitizeTags(this.props.tags) : [];
        const hasTags = !!tags && tags.length > 0;
        const classes = utils.createClassName(this.props.className, {
            "TagField": true,
            "has-tags": hasTags,
            "focus": focus || value,
            "clickable-tags": !!tags && !!onTagClick,
            "required": !!required,
            "removable": removable
        });

        return (
            <StyledTagField className={ classes } $classes={classes.split(' ')} onClick={ this._onClick }>
                <TagFieldLabel className="TagField-label">{label || ''}</TagFieldLabel>

                <div className="TagField-tags">
                   <Autocomplete
                        canCreateTag={this.props.canCreateTag}
                        value=''
                        onSelect={(tag) => this._addSelectedTag(tag)}
                        searchUrl="/tags/autocomplete/"
                        chosenAssets={tags}
                        onBlur={this._onBlur}
                        onFocus={this._onFocus}
                        isValueSet={(value) => this._isValueSet(value)}
                        ref={this.autocompleteRef}
                        isTagField
                        small
                    />
                </div>
                {tags && <ul className="TagList">
                    {tags.map((tag, i) => <React.Fragment key={i}>
                        {!!showAmp && i > 0 && <div className='amp'>&</div>}
                        {!tag.delete && <Tag key={`tag-${ i }`}
                            index={i}
                            value={tag.Name}
                            onClick={tags && onTagClick && this._onTagClick}
                            onRemove={tags && onTagRemove && this._onTagRemove}
                        />}
                    </React.Fragment>)}
                </ul>}
            </StyledTagField>
        )
    }

    // Internal methods
    _sanitizeTags = (tags) => tags.filter(tag => !!tag)

    _stringifyTags = () => {
        const tags = this._sanitizeTags(this.props.tags)
        return tags.length > 0 ? JSON.stringify(tags) : ''
    }

    _onBlur = () => this.setState({ focus: false })
    _onFocus = () => this.setState({ focus: true })
    _isValueSet = (value) => this.setState({ value })

    _addSelectedTag = (tag) => {
        this.props.onTagAdd({ ID: tag.id, Name: tag.label })
    }

    _onTagRemove = (tagIndex) => {
        const { onTagRemove } = this.props
        onTagRemove(tagIndex, this.autocompleteRef.current && ((tags) => this.autocompleteRef.current.getInfo(this.autocompleteRef.current.state.value, tags)))
    }
}


// PRIVATE COMPONENTS
class Tag extends React.PureComponent {
    render() {
        const { value, onClick, onRemove } = this.props
        return (
            <li className="TagField-tag">
                <TagFieldTagWrapper className="TagField-tag-wrapper">
                    <div className="TagField-tag-value" onClick={ onClick ? this._onClick : undefined }>{ value }</div>
                    <TagFieldTagRemove className="TagField-tag-remove material-icons" onClick={ onRemove ? this._onRemove : undefined }>close</TagFieldTagRemove>
                </TagFieldTagWrapper>
            </li>
        )
    }

    _onRemove = () => this.props.onRemove(this.props.index)
}

const StyledTagField = styled.div`
    background: ${props => props.theme.overlay};
    border-bottom: 1px solid ${props => props.theme.background};
    /*${props => props.$classes.includes('focus') && css`
        border-color: ${props.theme.secondary};
    `}*/
`

const TagFieldTagRemove =styled.div`
    color: ${props => props.theme.label};
    &:hover {
        color: ${props => props.theme.danger
    }
`

const TagFieldTagWrapper = styled.div`
    background: ${props => props.theme.background};
    &:hover {
        background: ${props => props.theme.background};
    }
`

const TagFieldLabel = styled.label`
    color: ${props => props.theme.label};
`