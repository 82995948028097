import React from "react"
import Settings from "classes/Settings"

// COMPONENTS
// import Vector from "components/Vector/Vector"
import primary from "assets/img/globalcolorspreview/primary.png"
import defaultcolor from "assets/img/globalcolorspreview/default.png"
import secondary from "assets/img/globalcolorspreview/secondary.png"
import overlay from "assets/img/globalcolorspreview/overlay.png"
import background from "assets/img/globalcolorspreview/background.png"
import label from "assets/img/globalcolorspreview/label.png"
import subtitle from "assets/img/globalcolorspreview/subtitle.png"
import success from "assets/img/globalcolorspreview/success.png"
import danger from "assets/img/globalcolorspreview/danger.png"

// HELPERS
import * as api from "helpers/api"
import * as utils from "helpers/utils"
import i18n from "helpers/i18n"

export default class Tooltip extends React.PureComponent {
    state = {
        hidden: true,
        top: 0,
        left: 0
    }

    tooltipRef = React.createRef()

    componentDidUpdate() {
        if (this.props.data) setTimeout((() => this.show()), 500)
        else this.hide()

        if (this.tooltipRef.current) {
            const { left, right, top, bottom, width, height } = this.tooltipRef.current.getBoundingClientRect() // tooltip box dimensions
            const { innerWidth, innerHeight } = window // screen dimensions
            const { data } = this.props, { y, mobile } = data // cell dimensions
            
            if (left < 0 && width <= innerWidth) this.setState({ left: 0 + 'px' }) // if tooltip overflows to the left without navbar
            if (innerWidth > 640 && left < 64 && width <= innerWidth - 64) this.setState({ left: 64 + 'px' }) // if tooltip overflows to the left with navbar
            if (right > innerWidth && ((innerWidth <= 640 && width <= innerWidth) || (innerWidth > 640 && width <= innerWidth - 64))) this.setState({ left: (innerWidth - width) + 'px' }) // if tooltip overflows to the right
            if (top < 0) this.setState({ top: 0 + 'px' }) // if tooltip overflows to the top without navbar
            if (innerWidth <= 640 && top < 64) this.setState({ top: (y + data.height) + 'px' }) // if tooltip overflows to the top with navbar
            if (mobile && bottom > y && top < y + data.height) this.setState({ top: (y - height) + 'px' }) // if tooltip overflows over cell on mobile and if it is not a save from overflow to the top
            if (bottom > innerHeight && ((innerWidth <= 640 && y - height >= 64) || (innerWidth > 640 && y - height >= 0))) this.setState({ top: (y - height) + 'px' }) // if tooltip overflows to the bottom
        }
    }

    show = () => this.setState({ hidden: false })
    hide = () => this.setState({ hidden: true, top: 0, left: 0 })

    render() {
        const { hidden } = this.state
        const { data } = this.props

        if (data && !hidden) {
            if (data.globalcolorpreview) {
                let styles = { 
                    top: 130,
                    left: data.x - 500
                }
                const classes = utils.createClassName("timeline-tooltip", {
                    'hidden': hidden
                })
                const chosenPreview = data.globalcolorpreview

                const availablePreviews = {
                    'primary' : primary,
                    'default': defaultcolor,
                    'secondary': secondary,
                    'overlay': overlay,
                    'background': background,
                    'label': label,
                    'subtitle': subtitle,
                    'success': success,
                    'warning': '',
                    'danger': danger,
                    'debug': '',
                }

                const previewImg = Object.entries(availablePreviews).filter((e) => e[0] === chosenPreview)[0]
                const previewSrc = previewImg ? previewImg[1] : null

                return (previewSrc &&
                    <div className={classes} style={styles}>
                        <div className="tooltip-wrapper image">
                            <div key={`cell-tooltip-${0}`} className="tooltip-item">
                                <img src={previewSrc} style={{ width:'450px', height: '450x' }} alt="" />
                            </div>
                        </div>
                    </div>
                )
            } else {
                const { date, cell, mode, x, y, height, mobile } = data
                const classes = utils.createClassName("timeline-tooltip", {
                    'hidden': hidden
                })
                
                let unitvalues = mode === "hours"  ? (cell !== undefined ? (cell.values !== undefined   ? cell.values : cell.hour !== undefined ? [cell.hour] : [] ): []):
                                 mode === "fee"    ? (cell !== undefined ? (cell.fees   !== undefined   ? cell.fees   :  cell.fee !== undefined && cell.hour !== 0 ? [cell.fee * cell.hour]  : [] ): []):
                                 mode === "salary" ? (cell !== undefined ? (cell.salaries !== undefined ? cell.salaries : cell.hourlySalary !== undefined ? [ cell.hourlySalary * cell.hour * cell.salaryMultiplier / 100] : [] ) : []):
                                 mode === "cost"   ? (cell !== undefined ? (cell.costs !== undefined    ? cell.costs : cell.hourlySalary !== undefined ? [ utils.calculateHourlyCost(cell.hourlySalary * cell.hour * cell.salaryMultiplier / 100, cell.payroll, cell.pension) ] : [] ):[])  :
                                 []

                let length = data.data.filter((d, i) => !(cell.placeholders && cell.placeholders[i])).length
                let styles = {
                    top: this.state.top || (mobile ? y - 24 - (30 * length) : y + height),
                    left: this.state.left || x
                }

                const allPlaceholders = cell.placeholders ? cell.placeholders.every((placeholder,i) => cell.shadows[i] || placeholder) : false
                const onlyShadow = allPlaceholders ? cell.placeholders.every((placeholder,i) => cell.shadows[i] ? allPlaceholders : placeholder) : false

                return(
                    <div ref={this.tooltipRef} className={classes} style={styles} >
                        <div className="tooltip-wrapper">
                            <span className="tooltip-date">{date}</span>
                            {data.data.map(({ avatarPath, title, unit, startDate, endDate}, i) => { //startDate and endDate is undefined
                                if ((endDate !== undefined && utils.moment(endDate).diff(utils.moment(date)) < 0) 
                                || (startDate !== undefined && utils.moment(startDate).diff(utils.moment(date)) > 0)
                                || (onlyShadow && !cell.shadows[i])
                                || (cell.placeholders ? cell.placeholders[i] : false) //if cell has placeholder, check 
                                || (!onlyShadow && cell !== undefined &&
                                    cell.shadows !== undefined &&
                                    (!!cell.shadows[i] && !unitvalues[i]))
                                )
                                {
                                    return ''
                                } else {
                                    return <div key={`cell-tooltip-${i}`} className="tooltip-item">
                                        <img className="tooltip-avatar" src={api.getAssetsURIForPath(avatarPath)} alt="" style={{ background: Settings.getGlobalColor('label') }}/>
                                        <strong className="tooltip-title">{title} {unitvalues[i] !== undefined && unitvalues[i] !== null ? (Math.round(unitvalues[i] * 2) / 2) % 1 === 0 ? (Math.round(unitvalues[i] * 2) / 2).toLocaleString(Settings.get("REGION_FORMAT")) : <span> {((Math.round(unitvalues[i] * 2) / 2) - 0.5).toLocaleString(Settings.get("REGION_FORMAT")) + ","}<span className="decimal">5</span></span> : "0"} {unitvalues[i] !== undefined ? unit : ""}</strong>
                                    </div>
                                }
                            })}
                            {cell.total && <span className="tooltip-total">{`Period total: ${cell.total[mode] !== null ? (Math.round(cell.total[mode] * 2) / 2).toLocaleString(Settings.get("REGION_FORMAT")) : '0'} ${mode === 'hours' ? i18n("general", "short_hours"): Settings.get("CURRENCY")}`}</span>}
                        </div>
                    </div>
                )
            }
        }

        return <div></div>
    }
}