import React from "react";

import styled, {css} from 'styled-components';

// COMPONENTS


// HELPERS
import * as utils from "helpers/utils";


export default class Modal extends React.Component {
    _animationDuration = 200

    state = {
        visible: this.props.visible,
    }

    componentDidUpdate(prevProps, prevState) {
        if (!!this.props.visible !== !!prevState.visible
            && !!this.props.visible !== !!prevProps.visible
        ) {
            const visible = this.props.visible === true;

            this.setState({ visible }, () => {
                if (this.props.children) {
                    const { onDismiss, onOpened, onClosed } = this.props
                    
                    if (onDismiss && !visible) onDismiss()
                    
                    if (visible) {
                        if (onOpened) onOpened();
                    } else {
                        if (onClosed) onClosed();
                    }
                }
            })
        }
    }

    render() {
        const { props, state } = this;
        const { children, scrollDirection, minHeight } = props;
        if(!children) return null;

        const classes = utils.createClassName(props.className, {
            "Modal": true,
            "visible": state.visible
        });

        return (
            <div className={ classes }>
                <div className="Modal-overlay" onClick={ this._onOverlayClick } />
                <Div className={ `Modal-content ${minHeight ? "minHeight" : ""} scrollable-${scrollDirection || "y"}`}>{ children }</Div>
            </div>
        );
    }

    // Internal methods
    _onOverlayClick = () => {
        this.setState({ visible: false }, () => {
            const { onDismiss, onClosed } = this.props;

            if(onDismiss) onDismiss();
            if(onClosed) onClosed();
        });
    }
}

const Div = styled.div`
    ${props => props.className.split(' ').includes('Modal-content') && css`
        background: ${props.theme.overlay};
    `}
`;
