import React, { Component } from "react"
import PunchClock from "components/PunchClock/PunchClock"
import { getAssetsURIForPath } from "helpers/api"
import Settings from "classes/Settings"
import styled from "styled-components"
export default class PunchClockResources extends Component {
    constructor(props) {
        super(props)
            this.state = {
            search: "",
            resource: this.props.resource,
            busy: false
        }
    }
    componentDidUpdate() {
        if (this.props.resource.activeProjects.length !== this.state.resource.activeProjects.length) {
            this.setState({resource: this.props.resource})
        }
    }

    render() {
        const { name, resourceTarget, onClose, reportTime, onSingleReset, timeElapsed, onStartHandler, onStopHandler } = this.props
        let resource = this.state.resource
        
        const projects = resource.projects.map((project) => {
        
            if (!resource.startArray[project.Name]) {
                resource.startArray[project.Name] = []
                resource.stopArray[project.Name] = []
            }

            return (
                <Div
                    key={project.ID}
                    className="projects"
                    onClick={ (e) => { 
                        if (resource.activeProjects.includes(project.Name)) {
                            e.currentTarget.style.background = Settings.getGlobalColor("success")
                            e.currentTarget.getElementsByTagName("h2")[0].style.color = Settings.getGlobalColor("background")
                        } else {
                            e.currentTarget.style.background = Settings.getGlobalColor('background')
                            e.currentTarget.getElementsByTagName("h2")[0].style.color = Settings.getGlobalColor("primary")
                        }
                    }}
                    style={{
                        backgroundColor: resource.activeProjects.includes(project.Name)
                            ? Settings.getGlobalColor("success")
                            : Settings.getGlobalColor('background'),
                    }}
                >
                    <img
                        className="img"
                        src={getAssetsURIForPath(project.Logo)}
                        alt=""
                        style={{background:Settings.getGlobalColor("label")}}
                    />
              
                    <div className="text-stopwatch-container">
                        <H2 $active={resource.activeProjects.includes(project.Name)} className="name">{project.Name}</H2>
                        <div className="stopwatch-card-container">
                            <PunchClock
                                project={project}
                                name={name}
                                resource={this.state.resource}
                                onClose={onClose}
                                resourceTarget={resourceTarget}
                                reportTime={reportTime}
                                timeElapsed={timeElapsed}
                                onSingleReset={onSingleReset}
                                onStartHandler={onStartHandler}
                                onStopHandler={onStopHandler}
                            />
                        </div>
                    </div>
                </Div>
            ) 
        })

        return (
            <React.Fragment>
                <div className="projects-container">
                    <center className="name">
                        <H1>{name}</H1>
                    </center>
                    <div>{projects}</div>
                </div>
            </React.Fragment>
        )
    }
}

const H1 = styled.h1`
    color: ${props => props.theme.primary}
`
const H2 = styled.h2`
    color: ${props => Settings.getGlobalColor(props.$active ? "background" : "primary")}
`
const Div = styled.div`
    background: ${props => props.theme.background}
`