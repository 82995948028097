import { createConstants } from "helpers/utils";


// CONSTANTS
export const constants = createConstants("APP", [
    "SET_STATE",
    "SET_USER",
    "SET_OPTIONS",
    "SET_PUNCHCLOCK"
]);


const initialState = {
    busy: false,
    user: null,
    options: {},
    punchClock: null
};

const app = (state = initialState, action) => {
    const { type } = action;

    switch(type) {
        case constants.SET_STATE:
            return { ...state, ...action.state };
        case constants.LOADING:
            return { ...state, loading: true };
        case constants.SET_USER:
            return { ...state, user: {...action.user} };
        case constants.SET_PUNCHCLOCK:
            return { ...state, punchClock: action.punchClock };
        case constants.SET_OPTIONS:
            return { ...state, options: action.options };
        default:
            return state;
    }
};


// ACTIONS
export const actions = {
    setState: () => ({ type: constants.SET_STATE }),
    setUser: (user) => ({ type: constants.SET_USER, user }),
    setOptions: (options) => ({ type: constants.SET_OPTIONS, options }),
    setPunchClock: (punchClock) => ({type: constants.SET_PUNCHCLOCK, punchClock }),
};

export default app