import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "assets/css/icons.css";
import "./index.min.css";

import createStore from "./store";
import App from "./App";

const AppWithRedux = (
    <Provider store={ createStore() }>
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={ <App/> } />
            </Routes>
        </BrowserRouter>
    </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(AppWithRedux)
