import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeCardSettings } from '../../redux/actions/monitor/monitorActions'
import { getPreviewData } from '../../redux/actions/cardSettings/cardSettingsActions'
import ChartStyle from './ChartStyle/ChartStyle'
import DataSetup from './DataSetup/DataSetup'
import { Chart } from '../Charts'
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"
import {convertHslToRgb, convertRgbToHsl } from "helpers/utils"
import Button from "components/Button/Button"
import StackLayout from "components/layouts/StackLayout/StackLayout"

import { getAssetsURIForPath } from "helpers/api"
import Settings from "classes/Settings"
import styled, {css} from 'styled-components'
import { createClassName, moment } from "helpers/utils"
import i18n from "helpers/i18n"
import { isNumber } from 'react-simple-timefield'

class CardSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isNew: this.props.isNew,
            ID: this.props.card.ID,
            chart: this.props.card.Chart,
            cardOptions: this.props.card.Options, // current and future options are in state
            setup: this.props.card.Setup,
            isDataSetup: true,
            getDataMode: 'auto',
            update: false,
            subMode: "general",
            pageLoaded: false,
            backgroundImageUploadData: this.props.card.BackgroundImage,
            uploaderForm: null
        }

        this.dataSetup = React.createRef()
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    handleKeyPress(e) {
        if (e.which === 27) {
            document.removeEventListener('keydown', this.handleKeyPress)
            this.closeCardSettings()
        } else if (e.which === 13 && e.target.parentNode.parentNode.className !== 'autocomplete-container') {
            document.removeEventListener('keydown', this.handleKeyPress)
            if (this.state.isNew) this.createCard()
            else this.saveCardSettings()
        }
    }

    componentDidMount() {
        const { options, type } = this.state.chart
        const { bulletRanges, bulletRangeColors, bulletMarkers, bulletMarkColors } = options[type]

        if (type === "bullet") {
            let ranges = [...bulletRanges]
            let rangeColors = [...bulletRangeColors]

            let markers = [...bulletMarkers]
            let markColors = [...bulletMarkColors]

            let rangeIndex = 0
            rangeColors.sort(() => {rangeIndex++; return ranges[rangeIndex] - ranges[rangeIndex - 1];})
            ranges.sort((a, b) => a - b)

            let markIndex = 0
            markColors.sort(() => {markIndex++; return markers[markIndex] - markers[markIndex - 1];})
            markers.sort((a, b) => a - b)

            this.updateChartOptions({
                bulletRanges: ranges,
                bulletRangeColors: rangeColors,
                bulletMarkers: markers,
                bulletMarkColors: markColors
            })

            this.updateSetup({
                bulletRanges: ranges,
                bulletMarkers: markers
            })
            
        }

        document.addEventListener('keydown', this.handleKeyPress)

        //set state pageLoaded: true after page loaded and then remove listener
        document.addEventListener('load', this.setState({ pageLoaded: true }, () => document.removeEventListener('load', this.setState({ pageLoaded: true }))))

    }

    componentDidUpdate(prevProps) {
        const { fetching, newData } = this.props
        const { update, setup, chart } = this.state
        const { options, type } = chart
        const { mainAssetType, includeMainAsset, mainAssets } = setup
    if (update && ((mainAssetType === 'total' || mainAssets.length > 0) || includeMainAsset === "every") && !fetching) {
            this.getData()
            this.setState({ update: false })
        }
        if (newData !== prevProps.newData) {
            this.updateChart({ data: newData }, () => {
                this.handleAssetColorChange({ hexa: null }, null, newData.keys, newData.dataset, true)
                if (options[type].splitNegatives && type === "heatmap") {
                    let nrMinColors = options[type].minColors.length
                    let nrMaxColors = options[type].maxColors.length
                    let maxValue = options[type].autoMaxValue ? 
                        this.getMaxValueFromDataset() : options[type].maxValue
        
                    let minValue = (Math.floor((nrMinColors * maxValue + 2 * nrMinColors + nrMaxColors) / nrMaxColors)) * -1
        
                    this.updateChartOptions({ autoMinValue: false, minValue: minValue })
                }
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress)
    }

    getData = () => {
        const { ID, setup, cardOptions } = this.state
        this.props.getPreviewData({ ID, Setup: setup, Options: cardOptions })
    }

    closeCardSettings = () => { // cancel w/o any changes
        document.getElementById("content-container").style.overflowY = "auto"
        this.props.closeCardSettings() // CANCEL
        this.props.closeBlur() // unmake it blurry
    }

    saveCardSettings = () => { // save w/ all changes
        const { ID, chart, cardOptions, setup, uploaderForm } = this.state
        this.props.updateCard(ID, chart, cardOptions, setup, uploaderForm, true) // SAVE CHANGES, force update data
        this.closeCardSettings()
    }

    createCard = () => { // save w/ all changes
        const { ID, chart, cardOptions, setup, uploaderForm } = this.state
        this.props.createCard({ ID, Chart: chart, Options: cardOptions, Setup: setup }, uploaderForm) // SAVE CHANGES
        this.closeCardSettings()
    }

    deleteCard() { // delete this card
        this.props.deleteCard(this.props.card.ID)
    }

    getBackgroundImage = () => {
        const { cardOptions, uploaderForm, backgroundImageUploadData } = this.state
        const { gradientType, gradientDirection, firstStop, secondStop, gradientColor, radialShape, useGradient } = cardOptions.gradientOptions
       
        var gradient = ""
        if (useGradient) {
            switch(gradientType) {
                case 'radial-gradient':
                    let radialDirection = gradientDirection === "middle" ? "" : `at ${gradientDirection}`
                    gradient = `${gradientType}(${radialShape} ${radialDirection}, ${gradientColor} ${firstStop}%, ${cardOptions.background} ${secondStop}%)`
                    break
                case 'linear-gradient':
                    let linearDirection = gradientDirection === "middle" ? "bottom right" : gradientDirection
                    gradient = `${gradientType}(to ${linearDirection}, ${cardOptions.background} ${firstStop}%, ${gradientColor} ${secondStop}%)`
                    break
                default: break
            }
        }
        
        if (uploaderForm) {
            if (gradient) {
                return `url(${ uploaderForm.content }), ${gradient}`
            } else {
                return `url(${ uploaderForm.content })`
            }
        } else if (backgroundImageUploadData) {
            if (gradient) {
                return `url(${ getAssetsURIForPath("/cards/"+backgroundImageUploadData) }), ${gradient}`
            } else {
                return `url(${ getAssetsURIForPath("/cards/"+backgroundImageUploadData) })`
            }
        } else {
            return gradient
        }
    }

    render() {
        const { layout } = this.props
        const { isNew, chart, cardOptions, setup, isDataSetup, getDataMode, subMode, pageLoaded } = this.state
        const { type, options } = chart

        if (pageLoaded) {
            var element = document.getElementById("chart-preview").getBoundingClientRect()

            if (layout) {
                var previewZoom = Math.min(element.height / (layout.height), element.width / (layout.width))
                var previewHeight = (layout.height * previewZoom)
                var previewWidth = (layout.width * previewZoom)
            }
        }

        return (
            <div id='card_settings-container'>
                <div id='card_settings' style={{background: Settings.getGlobalColor('overlay')}}> {/* bg of card as preview, is needed? */}
                    <div id="chart-setup">
                        {isDataSetup
                            ? <DataSetup ref={this.dataSetup}
                                setup={setup}
                                chartType={chart.type}
                                onDateChange={this.onDateChange}
                                handleChartTypeChange={this.handleChartTypeChange}
                                handleSetupChange={this.handleSetupChange}
                                addMainAsset={this.addMainAsset}
                                deleteMainAsset={this.deleteMainAsset}
                                addSecondaryAsset={this.addSecondaryAsset}
                                deleteSecondaryAsset={this.deleteSecondaryAsset}
                                getData={this.getData}
                                getDataMode={getDataMode}
                                handleGetDataModeChange={this.handleGetDataModeChange}
                                fetching={this.props.fetching} 
                                changeLayerOrder={this.changeLayerOrder} />
                            : <ChartStyle 
                                {...chart } options={options[type]}
                                calcType={setup.calcType}
                                cardData = {this.state}
                                handleSortTypeChange={this.handleSortTypeChange}
                                handleChartTypeChange={this.handleChartTypeChange}
                                handleStyleChange={this.handleStyleChange}
                                handleTextColorChange={this.handleTextColorChange}
                                handleGradientChange={this.handleGradientChange}
                                handleGradientOptionChange={this.handleGradientOptionChange}
                                resetTextColor={this.resetTextColor}
                                handleAssetColorChange={this.handleAssetColorChange}
                                resetAssetColor={this.resetAssetColor} 
                                handleUpdateRateChange={this.handleUpdateRateChange} 
                                handleCardNameChange={this.handleCardNameChange}
                                handleCardOptionsChange={this.handleCardOptionsChange}
                                handleBackgroundChange={this.handleBackgroundChange} 
                                handleChartColorChange={this.handleChartColorChange} 
                                handleArrayColorChange={this.handleArrayColorChange} 
                                addArrayColor={this.addArrayColor}
                                removeArrayColor={this.removeArrayColor}
                                handleBulletValues={this.handleBulletValues} 
                                updateBackgroundImage={this.updateBackgroundImage} />
                        }
                </div>
                    <div id="chart-preview">
                        <div className="preview_card" style={{
                            overflowY: type === "textdata" ? "auto": "hidden", 
                            outline: `${element && type === "textdata" ? 1 / previewZoom : 1}px solid ${Settings.getGlobalColor('label')}`, 
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "50% 50%",
                            backgroundColor: cardOptions.background,
                            position:"relative",
                            width: !layout ? '100%' : (`${element && type !== "textdata" ? previewWidth : layout.width}px`), 
                            height: !layout ? '100%' : (`${element && type !== "textdata" ? previewHeight : layout.height}px`),
                            fontFamily: cardOptions.fontType,
                            transform: `translate(-50%, -50%) ${element && type === "textdata" ? `scale(${previewZoom})`: ""}`,
                            backgroundImage: this.getBackgroundImage(),
                            color: "black"
                        }}>
                            <h4 id="preview_card_title" style={{position: (type === "textdata" ? "relative": "absolute"), paddingTop: type !== "textdata" ? `${10 * previewZoom}px` : "auto", color: options[type].textColor, fontSize: `${(element && type === "textdata" ? 1 : previewZoom) * cardOptions.titleFontSize}px`}}>{cardOptions.name}</h4>
                            {this.isEmpty(chart.data) ? <ActivityIndicator busy/>
                                : <Chart {...chart} displayUnit={cardOptions.displayUnit} options={options[type]} kpi={setup.kpi} displayPlusSign={cardOptions.displayPlusSign} calcType={setup.calcType} unitType={setup.unitType} previewZoom={previewZoom} />}
                        </div>
                    </div>
                    <div id="misc">
                        <EditToggler active={ !isDataSetup } subMode={ subMode } onChange={ this._changeModes } />
                    </div>
                    <div id="card_settings-buttons">
                        <StackLayout orientation="horizontal" style={{marginTop: "0px"}}>
                            {!isNew && <Button label={ i18n('monitor', 'delete') } appearance="danger" onClick={() => this.deleteCard()} filled />}
                            <Button appearance="primary" filled label={ i18n('monitor', isNew ? 'create' : 'save') } onClick={isNew ? this.createCard : this.saveCardSettings} />
                        </StackLayout>
                    </div>
                </div>
            </div>
        )
    }

    // SETUP METHODS
    _changeModes = (editing, subMode) => this.setState({ isDataSetup: editing ? false : true, subMode })

    updateSetup = (value, callback) => {
        this.setState(prevState => ({
            setup: {
                ...prevState.setup,
                ...value
            }
        }), callback && (() => callback()))
        if (this.state.getDataMode === 'auto') {
            this.updateChart({ data: [] })
            this.setState({ update: true })
        }
    }

    handleSetupChange = (value, name) => {
        const { setup } = this.state

        const componentBreakdown = name === 'kpi' ?
                ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'overhead net', 'overhead net per employee'].includes(value) ? true
                : ['total revenue', 'projected total revenue', 'total expenses', 'projected total expenses', 'wage cost', 'cost of non-billable hours'].includes(value) ? setup.componentBreakdown
                : false
            : name === "componentBreakdown" ? value : setup.componentBreakdown

        const calcType = name === 'kpi'
            ? ['projected net margin hit rate', 'employee turnover', 'chargeability resource', 'projected chargeability resource', 'chargeability project', 'projected chargeability project'].includes(value) // here those that can only have "%" type
                ? '%'
                : ['gross margin', 'net margin', 'extras net', 'overhead net', 'projected net margin'].includes(value) // here those that can have both "amount" and "%" types
                    ? setup.calcType
                    : 'amount'
            : name === 'calcType'
                ? value
                : setup.calcType

        const unitType = name === 'kpi'
            ? ['employment period', 'age', 'project duration', 'available work hours', 'gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate',
                'extras net', 'overhead net', 'projected margin', 'overhead net per employee'].includes(value) // here those that can only have "%" type
                ? 'amount'
                : setup.unitType
            : name === 'unitType'
                ? value 
                : setup.unitType

        const timeScale = name === 'chartType'
                ? value === 'calendar'
                    ? "day"
                    : setup.timeScale
            : name === 'startDateType'
                ? this.timeScaleOptionDisabled(value, setup.endDateType)
                    ? 'none'
                    : setup.timeScale
                : name === 'endDateType'
                    ? this.timeScaleOptionDisabled(setup.startDateType, value)
                        ? 'none'
                        : setup.timeScale
                    : name === 'timeScale'
                        ? value
                        : setup.timeScale

        const mainAssetType = 
            name === 'kpi' ?
                ['overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee'].includes(value)
                || (['extras net', 'extras fee', 'extras cost', 'employee turnover'].includes(value) && setup.mainAssetType === 'users') 
                || (['extras net', 'extras fee',  'extras cost'].includes(value) && setup.mainAssetType === 'usertags') 
                || (['number of projects',  'resources without projects', 'employment period'].includes(value) && setup.mainAssetType === 'projects')
                || (['resources without projects', 'employment period'].includes(value) && setup.mainAssetType === 'projectTags')
                || (['resources without projects', 'employment period'].includes(value) && setup.mainAssetType === 'companies')
                ? 'total' :
                setup.mainAssetType : 
            name === 'mainAssetType' ? value : setup.mainAssetType
            
        // if kpi or main asset type changed, check if secondary asset type is valid. if not, change to none
        const kpi = name === "kpi" ? value : setup.kpi
        const secondaryAssetType = 
            name === "secondaryAssetType" ? value :
            name === "chartType" && ["textdata", "pie", "calendar", "bullet"].includes(value) ? "none" :
            name === "kpi" || mainAssetType !== setup.mainAssetType || timeScale !== setup.timeScale ?
                setup.secondaryAssetType === 'users' ? 
                    ['extras net', 'overhead net', 'extras fee', 'overhead fee', 'extras cost', 'overhead cost', 'overhead net per employee', 
                    'employee turnover', 'chargeability project', 'projected chargeability project'].includes(kpi)
                    || (["users"].includes(mainAssetType) && timeScale === "none") ? "none" : setup.secondaryAssetType :
                setup.secondaryAssetType === 'projects' ? 
                    ['overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee', 'resources without projects', 
                    'employment period', 'chargeability resource', 'projected chargeability resource'].includes(kpi)
                    || (["projects"].includes(mainAssetType) && timeScale === "none") ? "none" : setup.secondaryAssetType :
                setup.secondaryAssetType === 'userTags' ? 
                    ['extras net', 'overhead net', 'extras fee', 'overhead fee', 'extras cost', 'overhead cost', 
                    'overhead net per employee', 'employment period', 'chargeability project', 'projected chargeability project'].includes(kpi)
                    || (["userTags"].includes(mainAssetType) && timeScale === "none") ? 'none' : setup.secondaryAssetType :
                setup.secondaryAssetType === 'projectTags' ?
                    ['overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee', 'resources without projects', 
                    'employment period', 'chargeability resource', 'projected chargeability resource'].includes(kpi)
                    || (["projectTags"].includes(mainAssetType) && timeScale === "none") ? 'none' : setup.secondaryAssetType :
                setup.secondaryAssetType === 'companies' ? 
                    ['overhead net', 'overhead fee', 'overhead cost', 'overhead net per employee', 'resources without projects', 
                    'employment period', 'chargeability resource', 'projected chargeability resource'].includes(kpi)
                    || (["projects", "companies"].includes(mainAssetType) && timeScale === "none") ? 'none' : setup.secondaryAssetType :
                setup.secondaryAssetType === 'extras' ? 
                    ['overhead net', 'hourly fee', 'overhead fee', 'wage cost', 'hourly wage cost', 'salary', 'pension', 'payroll', 
                    'overhead cost', 'overhead net per employee', 'cost of non-billable hours', 'resources in external projects', 'resources in internal projects', 
                    'resources without projects', 'number of employees', 'new hires', 'leavers', 'employment period', 'employee turnover', 'employee alteration', 'age', 'gender', 
                    'number of new projects', 'number of ongoing projects', 'number of finished projects', 'number of external projects', 'project duration', 'new resources in project', 
                    'reported hours', 'billed hours', 'available work hours', 'chargeability resource', 'projected chargeability resource', 'chargeability project', 'projected chargeability project', 'new clients', 'recurring clients'].includes(kpi)
                    || ["users", 'userTags'].includes(mainAssetType) ? 'none' : setup.secondaryAssetType :
                setup.secondaryAssetType === 'overheads' ? 
                    ['gross margin', 'extras net', 'hourly fee', 'extras fee', 'wage cost', 'hourly wage cost', 'salary', 'pension', 'payroll', 
                    'extras cost', 'cost of non-billable hours', 'resources in external projects', 'resources in internal projects', 'resources without projects', 
                    'number of employees', 'new hires', 'leavers', 'employment period', 'employee turnover', 'employee alteration', 'age', 'gender', 'number of new projects', 
                    'number of ongoing projects', 'number of finished projects', 'number of external projects', 'project duration', 'new resources in project', 'reported hours', 
                    'billed hours', 'available work hours', 'chargeability resource', 'projected chargeability resource', 'chargeability project', 'projected chargeability project', 'new clients', 'recurring clients'].includes(kpi)
                    || mainAssetType !== "total" ? 'none' : setup.secondaryAssetType :
                setup.secondaryAssetType === 'components' ? 
                    ['hourly fee', 'extras fee', 'overhead fee', 'salary', 'pension', 'payroll', 'extras cost', 
                    'overhead cost', 'resources in external projects', 'resources in internal projects', 'resources without projects', 'number of employees', 'new hires', 
                    'leavers', 'employment period', 'employee turnover', 'employee alteration', 'age', 'number of new projects', 'number of ongoing projects', 
                    'number of finished projects', 'number of external projects', 'project duration', 'new resources in project', 'reported hours', 'billed hours', 'available work hours', 
                    'chargeability resource', 'projected chargeability resource', 'chargeability project', 'projected chargeability project', 'new clients', 'recurring clients'].includes(kpi) 
                    || ( calcType === "%" && ['gross margin', 'net margin', 'projected net margin', 'projected net margin hit rate', 'extras net', 'overhead net', 'projected margin', 'overhead net per employee'].includes(kpi)) ? 'none' : setup.secondaryAssetType :
                setup.secondaryAssetType
            : setup.secondaryAssetType

        const mainAssets = name === 'mainAssetType' ? [] : [...setup.mainAssets]
        const secondaryAssets = name === 'secondaryAssetType' ? [] : [...setup.secondaryAssets]

        const sortType = name === "sortType" ? value : setup.sortType

        const startDateType = name === 'startDateType' ? value : setup.startDateType
        const startDate = name === 'startDate'
            ? value
            : startDateType === 'all'
                ? setup.startDate
                : startDateType !== 'custom'
                    ? moment().startOf(startDateType).format('YYYY-MM-DD')
                    : setup.startDate

        const endDateType = name === 'endDateType' ? value : setup.endDateType
        const endDate = name === 'endDate'
            ? value
            : endDateType === 'all'
                ? setup.endDate
                : endDateType === 'workweek'
                    ? moment().isoWeekday(5).format('YYYY-MM-DD')
                    : endDateType !== 'custom'
                        ? moment().endOf(endDateType).format('YYYY-MM-DD')
                        : setup.endDate

        this.updateSetup({
            [name]: value,
            mainAssets, // clear assets on assetType change
            mainAssetType, // reset assetType on some KPI
            calcType,
            unitType, // show options available for proper KPI:s
            secondaryAssetType,
            secondaryAssets,
            sortType,
            timeScale,
            startDate,
            endDate,
            componentBreakdown
        })
    }

    timeScaleOptionDisabled(startDateType, endDateType) {
        const { timeScale, startDate, endDate } = this.state.setup
        if (startDateType === "all" || endDateType === "all") return false
        var sameYear = "", sameQuarter = "", sameMonth = "", sameWeek = ""

        if (startDateType === "custom" && endDateType === "custom") sameYear = +startDate.split("-")[0] === +endDate.split("-")[0]
        else if (startDateType === "custom") sameYear = +startDate.split("-")[0] === +moment().format("YYYY")
        else if (endDateType === "custom") sameYear = +endDate.split("-")[0] === +moment().format("YYYY")
        else sameYear = true

        switch(timeScale) {
            case "year":
                return !!sameYear
            case "quarter":
                if (startDateType === "custom" && endDateType === "custom") sameQuarter = +moment(startDate).utc().quarter() === +moment(endDate).utc().quarter()
                else if (startDateType === "custom") sameQuarter = +moment(startDate).utc().quarter() === +(endDateType === "year" ? 4 : +moment().utc().quarter())
                else if (endDateType === "custom") sameQuarter = +moment(endDate).utc().quarter() === +(startDateType === "year" ? 1 : +moment().utc().quarter())
                else if (startDateType === "year" && endDateType === "year") sameQuarter = false
                else if (startDateType === "year") sameQuarter = +moment().utc().quarter() === 1
                else if (endDateType === "year") sameQuarter = +moment().utc().quarter() === 4
                else sameQuarter = true 
                return !!(sameQuarter && sameYear)
            case "month":

                if (startDateType === "custom" && endDateType === "custom") sameMonth = +startDate.split("-")[1] === +endDate.split("-")[1]
                else if (["year", "quarter"].includes(startDateType) && ["year", "quarter"].includes(endDateType)) sameMonth = false

                else if (startDateType === "custom") {
                    if (endDateType === "year") sameMonth = +startDate.split("-")[1] === 12
                    else if (endDateType === "quarter") sameMonth = +startDate.split("-")[1] === +["3", "6", "9", "12"][moment().utc().quarter()]
                    else sameMonth = +startDate.split("-")[1] === +moment().format("MM")
                }
                else if (endDateType === "custom") {
                    if (startDateType === "year") sameMonth = +endDate.split("-")[1] === 1
                    else if (startDateType === "quarter") sameMonth = +endDate.split("-")[1] === +["1", "4", "7", "10"][moment().utc().quarter()]
                    else sameMonth = +endDate.split("-")[1] === +moment().format("MM")
                }
                else if (startDateType === "year") sameMonth = +moment().format("MM") === 1
                else if (endDateType === "year") sameMonth = +moment().format("MM") === 12
                else if (startDateType === "quarter") sameMonth = +moment().startOf('quarter').format("MM") === +moment().format("MM")
                else if (endDateType === "quarter") sameMonth = +moment().endOf('quarter').format("MM") === +moment().format("MM")
                else sameMonth = true

                return !!(sameMonth && sameYear)
            case "week":
                if (startDateType === "custom" && endDateType === "custom") sameWeek = +moment(startDate).isoWeek() === +moment(endDate).isoWeek()
                else if (startDateType === "custom") sameWeek = +moment(startDate).isoWeek() === +moment().endOf(endDateType).format("ww")
                else if (endDateType === "custom") sameWeek = +moment(endDate).isoWeek() === +moment().startOf(startDateType).format("ww")
                else if (["year", "quarter", "month"].includes(startDateType) && ["year", "quarter", "month"].includes(endDateType)) sameWeek = false
                else if (startDateType === "year") sameWeek = +moment().isoWeek() === +moment().endOf('year').format("ww")
                else if (endDateType === "year") sameWeek = +moment().isoWeek() === +moment().startOf('year').format("ww")
                else if (startDateType === "quarter") sameWeek = +moment().isoWeek() === +moment().endOf('quarter').format("ww")
                else if (endDateType === "quarter") sameWeek = +moment().isoWeek() === +moment().startOf('quarter').format("ww")
                else if (startDateType === "month") sameWeek = +moment().isoWeek() === +moment().endOf('month').format("ww")
                else if (endDateType === "month") sameWeek = +moment().isoWeek() === +moment().startOf('month').format("ww")
                else sameWeek = true

                return !!(sameWeek && sameYear)
            default: return false
        }
    }


    addMainAsset = (asset) => {
        let mainAssets = [...this.state.setup.mainAssets]
        mainAssets.push(asset)
        this.updateSetup({ mainAssets })
    }

    deleteMainAsset = (i) => {
        let mainAssets = [...this.state.setup.mainAssets]
        mainAssets.splice(i, 1)
        this.updateSetup({ mainAssets }, () => {if (this.dataSetup.current.mainAutocomplete.current) this.dataSetup.current.mainAutocomplete.current.getInfo('')})
    }

    addSecondaryAsset = (asset) => {
        let secondaryAssets = [...this.state.setup.secondaryAssets]
        secondaryAssets.push(asset)
        this.updateSetup({ secondaryAssets })
    }

    deleteSecondaryAsset = (i) => {
        let secondaryAssets = [...this.state.setup.secondaryAssets]
        secondaryAssets.splice(i, 1)
        this.updateSetup({ secondaryAssets }, () => {if (this.dataSetup.current.secondaryAutocomplete.current) this.dataSetup.current.secondaryAutocomplete.current.getInfo('')})
    }

    toggleSetup = () => {
        if (this.state.isDataSetup) {
            document.getElementById('slider-circle').style.transform = 'translateX(34px)'
            document.getElementById('slider-wrapper').style.backgroundPositionX = '20%'
        } else {
            document.getElementById('slider-circle').style.transform = 'translateX(0px)'
            document.getElementById('slider-wrapper').style.backgroundPositionX = '75%'
        }
        this.setState(prevState => ({ isDataSetup: !prevState.isDataSetup }))
    }

    handleGetDataModeChange = (value, name) => {
        this.setState({ [name]: value })
    }

    changeLayerOrder = (index, direction) => {
        let layerOrder = [...this.state.setup.layerOrder]
        const operation = direction === "up" ? -1 : direction === "down" ? 1 : 0
        const newIndex = index + operation

        if (newIndex < 0 || newIndex > 2) return false

        const tmp = layerOrder[index]
        layerOrder[index] = layerOrder[newIndex]
        layerOrder[newIndex] = tmp

        this.handleSetupChange(layerOrder, "layerOrder")
    }

    // CHART STYLE METHODS

    handleStyleChange = (value, name) => {
        const { type, options } = this.state.chart
        
        let margin = {...options[type].margin}

        // if (name === 'legend') {
        //     switch(type) {
        //         case 'bar':
        //             margin.right ? 120 : 20
        //             break
        //         case 'pie':
        //             margin.bottom ? 40 : 20
        //             break
        //         case 'line':
        //             margin.right ? 120 : 20
        //             break
        //         default: break
        //     }
        // }

        if (type === "heatmap") {
            if (options[type].splitNegatives || name === "splitNegatives") {
                let maxValue = undefined 
                switch(name) {
                    case "splitNegatives":
                        if (value) {
                            maxValue = options[type].autoMaxValue ? 
                                this.getMaxValueFromDataset() : options[type].maxValue
                        }
                        break
                    case "autoMaxValue":
                        maxValue = value ? this.getMaxValueFromDataset() : options[type].maxValue
                        break
                    case "maxValue":
                        maxValue = value
                        break
                    default: break
                }

                if (maxValue) {
                    let nrMinColors = options[type].minColors.length
                    let nrMaxColors = options[type].maxColors.length
                    let minValue = Math.floor((nrMinColors * maxValue + 2 * nrMinColors + nrMaxColors) / nrMaxColors)
                    this.updateChartOptions({margin, autoMinValue: false, minValue: minValue * -1, [name]: value})
                } else {
                    this.updateChartOptions({margin, [name]: value})
                }
            }   
        }

        if (name === 'top' || name === 'bottom' || name === 'right' || name === 'left') {
            const maxValue = type === "textdata" ? 500 : 200
            margin[name] = value === '' || value < 0 ? 0 : value > maxValue ? maxValue : parseInt(value, 10)
        }
        if (name === 'padding')
            value = value === '' || value < 0 ? 0 : value > 0.9 ? 0.9 : parseFloat(value)

        if (name === 'innerPadding')
            value = value === '' || value < 0 ? 0 : value > 10 ? 10 : parseInt(value, 10)

        if (name === 'angle')
            value = value === '' || value < 0 ? 0 : value > 45 ? 45 : parseInt(value, 10)

        if (name === 'innerRadius')
            value = value === '' || value < 0 ? 0 : value > 0.95 ? 0.95 : parseFloat(value)

        if (name === 'outerRadius')
            value = value === '' || value < 0 ? 0 : value > 45 ? 45 : parseInt(value, 10)

        if (name === 'axisBottomAngle')
            value = value === '' ? 0 : value < -90 ? -90 : value > 90 ? 90 : parseInt(value, 10)

        if (name === 'dotSize')
            value = value === '' ? 0 : value < 0 ? 0 : value > 60 ? 60 : parseInt(value, 10)

        if (name === 'lineWidth')
            value = value === '' ? 0 : value < 0 ? 0 : value > 20 ? 20 : parseInt(value, 10)

        if (name === 'areaOpacity')
            value = value === '' ? 0.1 : value < 0.1 ? 0.1 : value > 1 ? 1 : parseFloat(value, 10)

        if (name === 'minValue' || name === 'maxValue')
            value =  /^-?([0-9]{1,})$/.test(value) ? parseFloat(value, 10) : options[type][name]
        
        if (name === 'textFontSize') {
            value = value === '' ? 16 : value
        }
        if (name === 'titleFontSize') {
            value = value === '' ? 20 : value
        }
        if (name === 'sortType') {
            value = value === '' ? 'label_ascending' : value
        }
        if (name === 'fontType') {
            value = value === '' ? '' : value
        }

        if (['axisLeftEnabled', 'axisBottomEnabled', 'groupMode', 'layout', 'legend', 'label',
        'gridX', 'gridY', 'enableArea', 'minValueAuto', 'maxValueAuto', 'displayPlusSign'].includes(name)) {
            value = !!value
        }

        (type !== "heatmap" || !(options[type].splitNegatives || name === "splitNegatives")) && this.updateChartOptions({
            margin,
            [name]: value
        })
    }
    getMaxValueFromDataset = () => {
        var maxNumber;
        this.state.chart.data.dataset.forEach((element) => {
            for (const [value] of Object.entries(element)) {
                if (typeof value === "number") {
                    if (!maxNumber || value > maxNumber) maxNumber = value
                }
            }
        }) 
        return maxNumber
    }

    handleAssetColorChange = ({ hexa }, key, keys = [], dataset = [], allRandom = false, force = false) => {
        const { type, options, data } = this.state.chart
        const sameColor = ["sunburst"].includes(type) ? false : this.state.cardOptions.sameColor

        let colors = {...options[type].colors}

        if (!['line', 'pie', "bullet"].includes(type)) {
            if (allRandom) {
                if (sameColor) {
                    let color = this.getRandomColor()
                    data.keys.forEach(key => {
                        if (!colors[key] || force) {
                            colors[key] = color
                        }
                    })
                } else {
                    keys.forEach(key => {
                        if (!colors[key] || force) {
                            colors[key] = this.getRandomColor()
                        }
                    })
                }
            } else {
                if (sameColor) {
                    data.keys.forEach(key => {
                        colors[key] = hexa
                    })
                } else {
                    colors[key] = hexa
                }
            }
        } else {
            if (allRandom) {
                if (sameColor) {
                    let color = this.getRandomColor()
                    dataset.forEach(data => {
                        if (!colors[data.id] || force) colors[data.id] = color
                    })
                } else {
                    dataset.forEach(data => {
                        if (!colors[data.id] || force) colors[data.id] = this.getRandomColor()
                    })
                }
            } else {
                if (sameColor) {
                    data.dataset.forEach((data)=>{
                        colors[data.id] = hexa
                    })
                } else {
                    colors[key] = hexa
                }
            }
        }

        this.updateChartOptions({ colors })
    }

    handleTextColorChange = (color) => {
        this.updateChartOptions({ textColor: color.hexa })
    }

    resetAssetColor = (key) => {
        const { type, options } = this.state.chart
        const colors = {
            ...options[type].colors,
            [key]: Settings.getGlobalColor('background')
        }
        this.updateChartOptions({ colors })
    }

    resetTextColor = () => {
        this.updateChartOptions({ textColor: Settings.getGlobalColor('subtitle') })
    }

    // CHART METHODS

    updateChart = (value, callback) => {
        this.setState(prevState => ({
            chart: {
                ...prevState.chart,
                ...value
            }
        }), callback && (() => callback()))
    }

    handleChartTypeChange = (value, name) => {
        this.updateChart({ type: value, data: [] })
        this.handleSetupChange(value, name)
    }
    
    handleSortTypeChange = (value, name) => {
        this.handleStyleChange(value, name)
        this.handleSetupChange(value, name)
    }

    handleBulletValues = (value, name, index) => {
        const { options, type } = this.state.chart
        value = value[0] === "0" ? value.slice(1) : value
        if (isNumber(value) || !value) {
            let ranges = [...options[type][name]]
            if (ranges[index] === 0) ranges[index] = value ? Number(value) : 0
            else ranges[index] = value ? Number(value) : 0

            this.updateChartOptions({[name]: ranges})
    
            this.updateSetup({[name]: ranges})
        }
    }

    updateBackgroundImage = (value) => {
        this.setState(prevState => ({
            ...prevState,
            uploaderForm: value
        }))
    }

    updateChartOptions = (value, callback) => {
        const { type, options } = this.state.chart
        this.setState(prevState => ({
            chart: {
                ...prevState.chart,
                options: {
                    ...options,
                    [type]: {
                        ...options[type],
                        ...value
                    }
                }
            }
        }))
    }

    // CARD OPTIONS METHODS

    updateCardOptions = (value, callback) => {
        this.setState(prevState => ({
            cardOptions: {
                ...prevState.cardOptions,
                ...value
            }
        }), callback && (() => callback()))
    }

    handleCardNameChange = (value, name) => {
        this.updateCardOptions({ [name]: value })
    }
    handleCardOptionsChange = (value, name) => {
        this.updateCardOptions({ [name]: value })
    }

    handleUpdateRateChange = (value, label) => {
        this.updateCardOptions({ updateRate: {...this.state.cardOptions.updateRate, [label]: value }})
    }

    handleGradientOptionChange = (value, name) => {
        if (name === "useGradient") {
            let rgba = this.state.cardOptions.background.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/)?.slice(1, 4)
            
            let newRgb = [255, 255, 255]
            if (rgba) {
                let hsl = convertRgbToHsl(rgba)
                if (hsl[2] >= 50) {
                    hsl[2] -= 20
                } else {
                    hsl[2] += 20
                }
                newRgb = convertHslToRgb(hsl)
            }

            this.updateCardOptions({ gradientOptions: {
                ...this.state.cardOptions.gradientOptions,
                gradientColor: `rgba(${newRgb[0]}, ${newRgb[1]}, ${newRgb[2]}, ${1})`,
                useGradient: value
            }})
        } else {
            this.updateCardOptions({ gradientOptions: {
                ...this.state.cardOptions.gradientOptions, 
                [name] : value,
            }})
        }
    }

    handleBackgroundChange = (color) => {
        const { r, g, b, a } = color.rgba
        this.updateCardOptions({ background: `rgba(${r}, ${g}, ${b}, ${a})` })
    }

    handleGradientChange = (color) => {
        const { r, g, b, a } = color.rgba
        this.updateCardOptions({ gradientOptions: {
            ...this.state.cardOptions.gradientOptions,
            gradientColor: `rgba(${r}, ${g}, ${b}, ${a})`
        }})
    }

    handleChartColorChange = (name, color) => {
        this.updateChartOptions({ [name]: color.hexa })
    }

    handleArrayColorChange = (name, index, color) => {
        const { options, type } = this.state.chart
        
        let items = [...options[type][name]]
       
        items[index] = color.hexa

        this.updateChartOptions({[name]: items})
    }

    addArrayColor = (name, push = true) => {
        const { options, type } = this.state.chart
        
        let items = [...options[type][name]]
        if (push) items.push(`rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${1})`)
        else items.unshift(`rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${1})`)

        if (options[type].splitNegatives && type === "heatmap") {
            let nrMinColors = options[type].minColors.length
            let nrMaxColors = options[type].maxColors.length
            
            if (name === "maxColors") nrMaxColors++
            else if (name === "minColors") nrMinColors++

            let maxValue = options[type].autoMaxValue ? 
            this.getMaxValueFromDataset() : options[type].maxValue

            let minValue = Math.floor((nrMinColors * maxValue + 2 * nrMinColors + nrMaxColors) / nrMaxColors)

            this.updateChartOptions({autoMinValue: false, minValue: minValue * -1, [name]: items})
        } else if (type === "bullet") {
            if (name === "bulletMarkColors") {
                let markers = [...options[type].bulletMarkers]
                if (push) markers.push(0)
                else markers.unshift(0)
                this.updateChartOptions({[name]: items, bulletMarkers: markers})
            } else if (name === "bulletRangeColors") {
                let ranges = [...options[type].bulletRanges]
                if (push) ranges.push(0)
                else ranges.unshift(0)
                this.updateChartOptions({[name]: items, bulletRanges: ranges})
            } else {
                this.updateChartOptions({[name]: items})
            }
        } else {
            this.updateChartOptions({[name]: items})
        }
        
    }
    removeArrayColor = (name, index) => {
        const { options, type } = this.state.chart
        
        let items = [...options[type][name]]
        items.splice(index, 1)

        if ((name === "maxColors" || name === "minColors") && options[type].splitNegatives) {
            let nrMinColors = options[type].minColors.length
            let nrMaxColors = options[type].maxColors.length

            if (name === "maxColors") nrMaxColors--
            else if (name === "minColors") nrMinColors--

            let maxValue = options[type].autoMaxValue ? 
            this.getMaxValueFromDataset() : options[type].maxValue

            let minValue = Math.floor((nrMinColors * maxValue + 2 * nrMinColors + nrMaxColors) / nrMaxColors)

            this.updateChartOptions({autoMinValue: false, minValue: minValue * -1, [name]: items})
        } else if (type === "bullet") {
            if (name === "bulletMarkColors") {
                let markers = [...options[type].bulletMarkers]
                markers.splice(index, 1)
                this.updateChartOptions({[name]: items, bulletMarkers: markers})
            } else if (name === "bulletRangeColors") {
                let ranges = [...options[type].bulletRanges]
                ranges.splice(index, 1)
                this.updateChartOptions({[name]: items, bulletRanges: ranges})
            } else {
                this.updateChartOptions({[name]: items})
            }
        } else {
            this.updateChartOptions({[name]: items})
        }
    }

    // OTHER METHODS
    
    getRandomColor() {
        let color = '#'
        for (var i = 0; i < 6; i++) { color += '0123456789ABCDEF'[Math.floor(Math.random() * 16)] }
        return color
    }

    isEmpty(obj) { for(let key in obj) { if (obj.hasOwnProperty(key)) return false } return true }
}

const mapStateToProps = (state, props) => ({
    newData: state.cardSettings.newData,
    fetching: state.cardSettings.fetching
})

const mapActionsToProps = {
    closeCardSettings,
    getPreviewData
}

export default connect(mapStateToProps, mapActionsToProps)(CardSettings)

class EditToggler extends React.PureComponent {

    render() {
        const { props } = this;
        const { active } = props;
        const classes = createClassName("ContextReviewContent-EditToggler", {
            "active": active
        });

        return (
            <div className={ classes }>
                <Div className={"toggler"} onClick={ this._onToggle } $active={active} >
                    <Div className="icon material-icons">build</Div>
                    <Div className="text">{ i18n("monitor", 'editStyles') }</Div>
                </Div>
                <EditSubModeNav { ...props } />
            </div>
        );
    }

    // Internal methods
    _onToggle = () => {
        const { active, subMode, onChange } = this.props;
        if(onChange) onChange(!active, subMode);
    }
}

function EditSubModeNav({ type, active, subMode: activeSubMode, onChange }) {
    const Item = ({ label, subMode }) => (
        <Div className={ createClassName("item", { "active": activeSubMode === subMode }) } onClick={ onChange && (() => onChange(active, subMode)) }>{ label }</Div>
    )

    return (
        <Nav>
            <Item label={ i18n("general", 'general') }  subMode="general" />
            <Item label={ i18n("monitor", 'colors') }   subMode="colors" />
        </Nav>
    )   
}

const Div = styled.div`
    ${props => props.className === 'top' && css`
        border-bottom: 1px solid ${props.theme.background};
    `}

    ${props => props.className.split(' ').includes('item') && css`
        color: ${props.theme.label};
    `}

    ${props =>  props.className.split(' ').includes('item') &&
                props.className.split(' ').includes('active')
                && css`
        color: ${props.theme.primary};
    `}

    ${props => props.className === 'toggler' && css`
        background: ${props.theme.background};
        &::after {
            background: ${props.theme.primary};
        }

        ${props => props.className.split(' ').includes('icon') && css`
            color: ${props.theme.label};
        `}
        ${props => props.className.split(' ').includes('text') && css`
            color: ${props.theme.overlay};
        `}

    `}

    ${props => props.$active && css`
        background: ${props.theme.primary};
        color: ${props.theme.overlay};
        :hover {
            background: ${props.theme.primary};
        }
    `}
`;
const Nav = styled.nav`
    background: ${props => props.theme.background};
`;